import React from 'react';

// Components
import Styled from '../app-bars/primary-nav-bar.styled';
import HeaderTabs from '../header-tabs/header-tabs.component';
import { Menu, Close } from '@mui/icons-material';
import IconButton from 'src/components/shared/buttons/icon-button/icon-button.component';

// Hooks
import useResponsive from 'src/hooks/useResponsive';

import PvolveLogo from '../../../../assets/pvolve.svg';

interface PrimaryNavBarProps {
    handleSetMenuOpen: (open: boolean) => () => void;
    menuOpen: boolean;
    accountComponent: React.ReactNode;
}

const PrimaryNavBar = ({ handleSetMenuOpen, menuOpen, accountComponent }: PrimaryNavBarProps) => {
    const { isTabletAndMobile } = useResponsive();

    return (
        <Styled.PrimaryNavBar>
            <Styled.PrimaryNavBarInternalWrapper>
                <Styled.TabsWrapper>
                    {!isTabletAndMobile ? (
                        <HeaderTabs />
                    ) : menuOpen ? (
                        <IconButton
                            Icon={Close}
                            variant="text"
                            size="large"
                            onClick={handleSetMenuOpen(false)}
                        />
                    ) : (
                        <IconButton
                            Icon={Menu}
                            variant="text"
                            size="large"
                            onClick={handleSetMenuOpen(true)}
                        />
                    )}
                </Styled.TabsWrapper>
                <Styled.Logo aria-label="Home" data-test="Header-Logo-Button">
                    <img alt="P.volve logo" height={17} role="img" src={PvolveLogo} width={109} />
                </Styled.Logo>
                <Styled.AccountWrapper>{!menuOpen && accountComponent}</Styled.AccountWrapper>
            </Styled.PrimaryNavBarInternalWrapper>
        </Styled.PrimaryNavBar>
    );
};

export default PrimaryNavBar;
