import { createSelector } from 'reselect';
import { WebState } from './reducer';

const root = (state: { web: WebState }) => state.web;

const internalAccessEnabled = createSelector(root, (root) => root?.internalAccessEnabled);

export default {
    root,
    internalAccessEnabled,
};
