import React from 'react';
import classnames from 'classnames';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

const ResponsivePadding: React.FC = ({ children }) => {
    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);

    const paddingClassnames = classnames(
        isDesktop ? 'padding-horizontal--large' : 'padding-horizontal--small'
    );

    return (
        <div style={{ margin: '0 auto' }} className={paddingClassnames}>
            {children}
        </div>
    );
};

export default ResponsivePadding;
