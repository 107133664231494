
import { takeEvery, put, take, select } from 'redux-saga/effects';
import allSettled from 'promise.allsettled';
import Fuse from 'fuse.js';

import Pvolve from '@pvolve/sdk';
import { routineSaga, sendTo } from '@pvolve/sdk/src/app/utils/sagas';
import Actions from '@pvolve/sdk/src/app/actions';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import Selectors from '@pvolve/sdk/src/app/selectors';

import { ContentTypes, WORKOUT_DURATIONS, SEARCH_FIELDS } from './actions';
import { isEmpty } from 'lodash';

const {
  content: { find, load, loaded, search, index, findAll },
  auth: { federated, login, signup },
} = Actions;

const stats = {};
const CALCULATED_FIELDS = {
  workout: {
    duration: (workout: IWorkout) => {
      const length = workout?.fields?.length;
      if (length) {
        for (const [key, range] of Object.entries(WORKOUT_DURATIONS)) {
          if (length >= range[0]) {
            if (!range[1] || length <= range[1]) {
              stats[key] = stats[key] || 0;
              stats[key] = stats[key] + 1;
              return key;
            }
          }
        }
      }
    },
  },
};

const indexByType = {
  workout: new Fuse([], {
    keys: Object.values(SEARCH_FIELDS.workout),
    threshold: 0,
    distance: 0,
  }),
};

class ContentSaga {
  init = function* () {
    yield takeEvery(search.trigger, saga.search);
    yield takeEvery(index.trigger, saga.index);
    yield takeEvery(find.trigger, saga.find);
    yield takeEvery(findAll.trigger, saga.findAll);
    yield takeEvery(load, saga.load);
  };

  /**
   * @deprecated
   */
  load = function* () {
    console.warn("content.load is deprecated. use content.findAll.trigger instead");
    yield put(findAll.trigger());
  };

  find = routineSaga({
    routine: find,
    *request({ payload: { contentType, ...options } }) {
      const response = yield Pvolve.api.content.find(contentType, options);
      return { contentType, response };
    },
  });

  findAll = routineSaga({
    routine: findAll,
    *request() {
      const all = yield allSettled(
        Object.values(ContentTypes).map(contentType => Pvolve.api.content.find(contentType))
      );

      const contentTypes = Object.values(ContentTypes);
      const results = {};
      for(const i in contentTypes) {
        results[contentTypes[i]] = all[i];
      }
      return { results };
    },
    *afterSuccess() {
      for(const contentType of Object.keys(indexByType)) {
        yield put(index.trigger({ contentType }));
      }
    },
  });

  search = routineSaga({
    routine: search,
    *request({ payload: { name = 'default', contentType, query } }) {
      const searchConfig = SEARCH_FIELDS[contentType];
      const index = indexByType[contentType];
      if (!index) {
        throw `IndexNotFound for ${contentType}`;
      }

      const predicates = [];
      const equipmentids = [];
      for (const [key, values] of Object.entries(query)) {
        const objectKey = searchConfig[key];
        if (values instanceof Array) {
          if (values.length > 0) {
            if(key === 'equipment') {
              equipmentids.push(...values);
            }
            predicates.push({
              $or: values.map(value => ({ [objectKey]: value })),
            });
          }
        } else {
          predicates.push(values);
        }
      }
      let results = index.search({ $and: predicates });
      let equipmentOnlyList = [];
      let equipmentWithAdditionalList = [];
      let wasFilteredByEquipment = false;
      if(equipmentids.length) {
        results.forEach((entry) => {
          const { item: { fields: { equipment } } } = entry;
          const mapResult = equipment.map(equip => equip.sys.id);
          if(mapResult.length <= equipmentids.length && mapResult.every(res => equipmentids.indexOf(res) > -1)) {
            equipmentOnlyList.push(entry);
          } else {
            equipmentWithAdditionalList.push(entry);
          }
        });
        wasFilteredByEquipment = true;
      }

      return {
        name,
        results,
        resultsWithEquipmentOnly: equipmentOnlyList,
        resultsWithAdditionalEquipment: equipmentWithAdditionalList,
        wasFilteredByEquipment,
      };
    },
  });

  index = routineSaga({
    routine: index,
    *request({
      type,
      payload: {
        contentType,
      },
    }) {
      const index = indexByType[contentType];
      const calculatedFields = CALCULATED_FIELDS[contentType];

      if (index) {
        index.setCollection([]);

        const data = yield select(Selectors.content.data) || {};

        const content = data[contentType];
        if(content) for (const item of content) {
          const addedFields = {};
          for (const [key, getValue] of Object.entries(calculatedFields)) {
            addedFields[key] = getValue(item);
          }
          if (isEmpty(item.fields.type)) {
            index.add({ ...item, ...addedFields });
          }
        }
      }
    },
  });
}
export const saga = new ContentSaga();