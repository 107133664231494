import React from 'react';
import classnames from 'classnames';
import { Grid } from 'semantic-ui-react';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';
import { breakParagraphs } from 'src/utils/miscellanous';

interface IHeadingWithParagraph {
    headingLabel?: string;
    headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    headingWeight?: 'semibold' | 'bold' | 'normal';
    headingTransform?: 'upper' | 'lower' | '';
    headingClass?: string;
    pLabel?: string;
    pClass?: string;
    alignment: 'horizontal' | 'vertical';
    secondaryHeadingClass?: string;
    secondaryHeadingLabel?: string;
}

const HeadingWithParagraph = ({
    headingLabel,
    headingSize,
    headingWeight = 'normal',
    headingTransform = '',
    headingClass = '',
    pLabel = '',
    pClass,
    alignment,
    secondaryHeadingClass = '',
    secondaryHeadingLabel,
}: IHeadingWithParagraph) => {
    const isVertical: boolean = alignment === 'vertical';
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);
    const Heading: keyof JSX.IntrinsicElements = headingSize ?? 'h2';
    const paragraphs = breakParagraphs(pLabel, pClass);

    return (
        <Grid className="margin--0 heading-paragraph-container">
            {isVertical ? (
                <>
                    <Grid.Row className="padding-bottom--0 padding-top--small">
                        <Heading className={`${headingWeight} ${headingTransform} ${headingClass}`}>
                            {headingLabel}
                            {secondaryHeadingLabel && (
                                <span
                                    className={`${secondaryHeadingClass} normal padding-right--0`}
                                >
                                    {secondaryHeadingLabel}
                                </span>
                            )}
                        </Heading>
                    </Grid.Row>
                    <Grid.Row className="padding-top--0 padding-bottom--small">
                        {paragraphs}
                    </Grid.Row>
                </>
            ) : (
                <>
                    <Grid.Column className="padding-horizontal--0" computer={4} mobile={16}>
                        <Heading className={`${headingWeight} ${headingTransform} ${headingClass}`}>
                            {headingLabel}
                            {secondaryHeadingLabel && (
                                <span
                                    className={`${secondaryHeadingClass} normal padding-right--0`}
                                >
                                    {secondaryHeadingLabel}
                                </span>
                            )}
                        </Heading>
                    </Grid.Column>
                    <Grid.Column
                        className={classnames(isMobile && 'padding-horizontal--0')}
                        computer={12}
                        mobile={16}
                    >
                        {paragraphs}
                    </Grid.Column>
                </>
            )}
        </Grid>
    );
};

export default HeadingWithParagraph;
