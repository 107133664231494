import { createActions } from 'redux-actions';
import { DefaultRoutine, DefaultRoutineActions } from '../../utils';

interface FeatureFlagsActions {
  flags: {
    phaseAndFunction: DefaultRoutineActions;
  };
}

const Actions = <FeatureFlagsActions>(<unknown>createActions({
  FLAGS: {
    PHASE_AND_FUNCTION: DefaultRoutine,
  },
}));

export default Actions;
