import React, { useEffect, useState, memo } from 'react';
import Selectors from 'src/state/root-selectors';

import { useIsFTU } from 'src/components/my-plan/MyPlanContext';
import { useSelector } from 'react-redux';

import MyPlanBanner from './banner/my-plan-banner.component';
import HeaderMaterial from './header.component';
import { SHOPIFY_URL_DEFAULT } from 'src/constants/url-constants';
import NYNYBannerWithButton from 'src/components/shared/nyny-banner-with-button/nyny-banner-with-button.component';
import NameCollection from 'src/components/shared/name-collection/name-collection.component';

interface HeaderProps {
    path: string;
}

const Header = ({ path }: HeaderProps) => {
    const isFTU = useIsFTU();
    const shopifyUrl: string = useSelector(Selectors.config.shopifyUrl) || SHOPIFY_URL_DEFAULT;
    const [isMyPlan, setIsMyPlan] = useState(!!path.match(/my-plan/gi));
    const [bannerBeforeQuote, setBannerBeforeQuote] = useState(false);

    const hasBanner = isMyPlan && !isFTU;

    useEffect(() => {
        setIsMyPlan(!!path.match(/my-plan/gi));
    }, [path, shopifyUrl]);

    const HeaderAndBanner = ({ hasBanner }: { hasBanner: boolean }) => {
        return (
            <>
                <NameCollection />

                <HeaderMaterial />
                <NYNYBannerWithButton path={path} setBannerShown={setBannerBeforeQuote} />

                {hasBanner && <MyPlanBanner bannerBeforeQuote={bannerBeforeQuote} />}
            </>
        );
    };

    return <HeaderAndBanner hasBanner={hasBanner} />;
};

export default memo(Header);
