import React from 'react';

// Types
import { ButtonProps as MuiButtonProps } from '@mui/material';

// Components
import Styled from './account-button.styled';

// Utils
import { getInitials } from 'src/utils/header-utils';

interface AccountButtonProps extends MuiButtonProps {
    onClick: () => void;
    firstName: string;
    lastName: string;
    showLabel: boolean;
}

const AccountButton = ({
    onClick,
    firstName,
    lastName,
    showLabel,
    ...props
}: AccountButtonProps) => {
    const buttonClass = showLabel ? 'showLabel' : '';

    return (
        <Styled.AccountButton {...props} className={buttonClass} onClick={onClick}>
            <Styled.AccountInitials className={buttonClass}>
                {getInitials(firstName, lastName)}
            </Styled.AccountInitials>
            {showLabel && <>My Account</>}
        </Styled.AccountButton>
    );
};

export default AccountButton;
