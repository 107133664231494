import {each, get, set, toNumber, trim} from 'lodash';
import produce, {Draft} from 'immer';
import {eachDayOfInterval, endOfWeek, format, isToday, isTomorrow, isYesterday, startOfWeek} from 'date-fns';
import moment from 'moment';
import he from 'he';
import {Action} from 'redux';
import {ActionFunction1, ActionFunctions} from 'redux-actions';

export const delay = (timeoutMs: number) => new Promise((resolve) => {
  setTimeout(resolve, timeoutMs);
});


export const PATTERNS = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  phone: /^\+1\d{10}$/,
  birthday: /\d{2}\/\d{2}\/\d{4}/,
};

export const PASSWORD_FORMAT_MESSAGE = 
  'Password must be at least 8 characters, contain 1 uppercase letter, and 1 number.';
export const EMAIL_FORMAT_MESSAGE = 'Email is invalid';

export const trimTransform = (__: string, originalValue: string) => {
  return trim(originalValue);
};


// parses date string and returns date
// it was originally intended to take in things like this: 2020-07-24 and returns a Date: Fri Jul 24 2020 00:00:00 GMT-0700
export const parseDate = (dateString: string): Date => moment(dateString).toDate();

const routineReducers = <State extends object>() => ({
  request: (path:string) => (draft:State) => {
    set(draft, `${path}.loading`, true);
    set(draft, `${path}._persist`, false);
  },
  success: (path:string) => (draft:State) => {
    set(draft, `${path}.failed`, false);
    set(draft, `${path}.loadedAt`, Date.now());

    const routineDraftState = get(draft, path);
    delete routineDraftState.error;
  },
  failure: (path: string) => (draft:State, { error }) => {
    set(draft, `${path}.failed`, true);
    set(draft, `${path}.error`, error);
  },
  fulfill: (path:string) => (draft:State) => {
    set(draft, `${path}.loading`, false);
  },
  clear: (path:string) => (draft:State) => {
    set(draft, path, {});
  },
});

export function handleActionsImmer<State extends object>(actionsMap: {
  [key:string]: (draft: State, ...args) => void
}, defaultState:State, routineMap:{
  [key:string]: DefaultRoutineActions
} = {}) {
  const reducers = routineReducers<Draft<State>>();
  // one can pass in actions that look like this ACTION_NAME||ACTION_NAME2
  // these should be decomposed one time to ease lookup in the future. Without
  // this logic, this doesn't work with combineActions
  const singleActionsMap = Object.keys(actionsMap).reduce((map, actionName) => {
    const actionNames = actionName.split('||');
    for (let parsedActionName of actionNames) {
      map[parsedActionName] = actionsMap[actionName];
    }
    return map;
  }, {});

  const routineActionsMap: {
    [actionName: string]: (draft: Draft<State>, payload?: any) => void
  } = {};
  each(routineMap, (routine, path) => {
    routineActionsMap[routine.request] = reducers.request(path);
    routineActionsMap[routine.success] = reducers.success(path);
    routineActionsMap[routine.failure] = reducers.failure(path);
    routineActionsMap[routine.fulfill] = reducers.fulfill(path);
    routineActionsMap[routine.clear]   = reducers.clear(path);
  });

  return (state = defaultState, { type, payload }) =>
    produce(state, draft => {
      const routineAction = routineActionsMap[type];
      if (routineAction) {
        routineAction(draft, payload);
      }

      const action = singleActionsMap[type];
      return action && action(draft, payload);
    });
}

export function payloadActions(actionNames: string[]) {
  const actions: {
    [key:string]: Function
  } = {};

  actionNames.forEach(actionName => { 
    actions[actionName] = (args: any) => args;
  });

  return actions;
}

const RoutineActionNames = <const> [
  'TRIGGER',
  'REQUEST',
  'SUCCESS',
  'FAILURE',
  'FULFILL',
  'CLEAR',
];

type RoutineActions = {
  [key in typeof RoutineActionNames[number]]: ActionFunctions<any>;
};

export const DefaultRoutine = <RoutineActions>payloadActions(<string[]><unknown>RoutineActionNames);

export interface RoutineState {
  loading?: boolean;
  failed?: boolean;
  _persist?: boolean;
}

export const RoutineInitialState: RoutineState = {
  loading: false,
  failed: false,
  _persist: false,
};




export const INTERNAL_DATE_FORMAT = 'yyyy-MM-dd';
export const PF_PHASES_DATE_FORMAT = 'MM-dd-yyyy';
export const WORKOUT_DATE_FORMAT = 'MM.dd.yy';
export const EXPIRATION_DATE_FORMAT = 'M/d/yy';

export const formatInternalDate = (date: Date) => {
  return format(date, INTERNAL_DATE_FORMAT);
};

export const DefaultInitialState = () => ({
  loading: false,
});


export const resizeTo = (
  originalWidth = 0,
  originalHeight = 0,
  maxWidth = 0,
  maxHeight = maxWidth,
  scaleUp = true,
) => {
  const width = toNumber(originalWidth);
  const height = toNumber(originalHeight);
  const defaults = { width, height };

  if (width === 0 || height === 0) {
    return defaults;
  }

  if ((maxWidth < width || scaleUp) && width >= height) {
    return { width: maxWidth, height: (maxWidth / width) * height };
  }

  if (maxHeight < height && height >= width) {
    return { height: maxHeight, width: (maxHeight / height) * width };
  }

  return defaults;
};

export function toDuration(number: string) {
  const sec_num = parseInt(number, 10); // don't forget the second param
  let hours:any = Math.floor(sec_num / 3600);
  let minutes:any = Math.floor((sec_num - hours * 3600) / 60);
  let seconds:any = sec_num - hours * 3600 - minutes * 60;

  if (isNaN(minutes)) {
    minutes = 0;
  }

  if (isNaN(seconds)) {
    seconds = 0;
  }

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
}

export function stripHTML(text: string) {
  const REMOVE_HTML_TAGS_PATTERN = /(<([^>]+)>)/gi;
  return text ? he.decode(text.replace(REMOVE_HTML_TAGS_PATTERN, '')) : null;
}

// TODO DELETE THIS And All references!!!
// TODO use i18n to get the local and pass this back to the web service
const DEFAULT_LOCALE = 'en-US';

export class ContentfulModel {
  sys: {
    id: string
  };
  fields: {
    [key: string]: any
  };

  constructor({ sys, fields }) {
    this.sys = sys;
    this.fields = fields;
  }

  get id() {
    return this.sys.id;
  }

  get sku() {
    return this.field('sku');
  }

  field(key: string) {
    return get(this.fields[key], DEFAULT_LOCALE);
  }
}

export function getWeekDays(date = new Date()): Date[] {
  const weekStart = startOfWeek(date, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
  const days = eachDayOfInterval({
    start: weekStart,
    end: weekEnd,
  });

  return days;
};

export const dayLabel = (date: Date, series: boolean) => {
  return !series
    ? 'Our Newest'
    : isToday(date)
    ? "Today's"
    : isTomorrow(date)
    ? "Tomorrow's"
    : isYesterday(date)
    ? "Yesterday's"
    : `${format(date, 'EEEE')}'s`;
};

interface CallbackActions {
  onFailure?: (args: any) => void;
  onFulfill?: (args: any) => void;
  onSuccess?: (args: any) => void;
}

export type BasicActionFunction<PayloadType = void> = ActionFunction1<PayloadType, Action<PayloadType>> & string;

export interface DefaultRoutineActions<
  TriggerInput = void,
  SuccessInput = any,
  FailureInput = any,
  RequestInput = any,
  FulfillInput = any,
  ClearInput = any | void,
> {
  trigger: BasicActionFunction<TriggerInput | CallbackActions>;
  request: BasicActionFunction<RequestInput>;
  success: BasicActionFunction<SuccessInput>;
  failure: BasicActionFunction<FailureInput>;
  fulfill: BasicActionFunction<FulfillInput>;
  clear:   BasicActionFunction<ClearInput>;
}

export type PayloadFor<T extends (...args:any) => any> = Parameters<T>[0];

export const sortWorkoutsByDate = (workouts: any[], path = 'fields.date') => {
  const sorted = [...workouts].sort((a: any, b: any) => {
      const aDate = get(a, path); 
      const aSort = aDate ? new Date(aDate).getTime() : 0;

      const bDate = get(b, path);
      const bSort = bDate ? new Date(bDate).getTime() : 0;

      return bSort - aSort;
  });

  return sorted;
}
