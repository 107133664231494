import React from 'react';
import * as Styles from 'src/styles/split.module.scss';

interface SplitProps {
    children?: React.ReactNode | string;
}

const Split = ({ children }: SplitProps) => (
    <div className="alignItems--center">
        <div className={Styles.border} />
        <div className={Styles.content}>{children}</div>
        <div className={Styles.border} />
    </div>
);

export default Split;
