import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Spinner } from 'react-activity';
import { Link } from 'gatsby';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { Icon, PreviewVideoPlayer } from 'src/components/shared';
import { fancyTimeFormat } from 'src/utils/gen-utils';
import { useSaveUrl } from '../../utils/url-utils';
import { isIOS, useDeviceDetect } from 'src/utils/breakpoint-utils';
import { ExtraContent } from 'src/components/shared';

import * as Styles from 'src/styles/scheduled-workouts.module.scss';

interface VideoPlayerControlsProps {
    loading: boolean;
    onVideoClick: () => void;
    paused?: boolean;
    previewVideoSrc?: string;
    slug: string;
    hasOverlay?: boolean;
    pausedAt?: number;
    level?: string;
    seriesName?: string;
    completePercentage?: number;
    seriesSlug?: string;
    thumbnail?: object;
    extraContentPath?: string;
    phaseData?: object;
}

const VideoPlayerControls = ({
    loading,
    onVideoClick,
    paused,
    previewVideoSrc,
    slug,
    hasOverlay,
    pausedAt,
    level,
    seriesName,
    completePercentage,
    seriesSlug,
    thumbnail,
    phaseData,
    extraContentPath,
}: VideoPlayerControlsProps) => {
    const saveUrl: () => void = useSaveUrl('initialUrl');

    const device = useDeviceDetect();
    const isMobile = device?.type === 'mobile';
    const isIOSDevice = isIOS();

    return (
        <>
            {paused ? (
                <div className={Styles.resumableActions}>
                    <div>
                        <LinkWithPrevPath
                            className={Styles.actionRow}
                            onClick={saveUrl}
                            to={`/workouts/${slug}/video`}
                        >
                            <div className={Styles.resumeButton}>
                                <Icon name="pv-play" size={37} />{' '}
                            </div>
                            <div className={Styles.caption}>
                                resume from {fancyTimeFormat(pausedAt)}
                            </div>
                        </LinkWithPrevPath>

                        <LinkWithPrevPath
                            className={Styles.actionRow}
                            onClick={saveUrl}
                            to={`/workouts/${slug}/video?t=0`}
                        >
                            <div className={Styles.replayButton}>
                                <Icon name="pv-replay" size={58} />
                            </div>
                            <div className={Styles.caption}>restart from beginning</div>
                        </LinkWithPrevPath>
                    </div>
                </div>
            ) : (
                <LinkWithPrevPath
                    to={`/workouts/${slug}/video`}
                    className={Styles.playButton}
                    onClick={saveUrl}
                >
                    <Icon name="pv-play" size={26} />
                </LinkWithPrevPath>
            )}

            <p className={`p1 accent bold ${Styles.workoutLevel}`}>{level}</p>

            {!paused &&
                seriesName &&
                (!!extraContentPath ? (
                    <ExtraContent
                        template={extraContentPath}
                        seriesName={seriesName}
                        seriesSlug={seriesSlug}
                        location={'video'}
                        phaseData={phaseData}
                    />
                ) : (
                    <Link to={`/series/${seriesSlug}`}>
                        <div className={Styles.seriesName}>
                            <div
                                style={{
                                    width: `${completePercentage}%`,
                                }}
                                className={Styles.percentageBar}
                            ></div>
                            <div className={Styles.seriesNameTitle}>
                                <h6>{seriesName}</h6>
                                <span>{`${completePercentage}% complete`}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            {loading && <Spinner className={Styles.spinner} size={40} speed={0.75} />}
            {(!previewVideoSrc && thumbnail) || (isMobile && isIOSDevice) ? (
                <BackgroundImage className={Styles.thumbnail} fixed={thumbnail?.fixed} />
            ) : (
                <PreviewVideoPlayer
                    src={previewVideoSrc}
                    onClick={onVideoClick(pausedAt)}
                    paused={paused}
                    pausedAt={pausedAt}
                    hasOverlay={hasOverlay}
                />
            )}
        </>
    );
};

export default VideoPlayerControls;
