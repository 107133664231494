import { useCallback, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'src/state/root-actions';

/*
 * Custom hook to be call on Anchor onClick, which returns a function that dispatches a CTA Click Segment Event, taking as param the event from the onClick itself.
 */
export const useDispatchSegmentEvents = () => {
    const dispatch = useDispatch();

    const dispatchCTAClickedEvent = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            const target = event.target as HTMLAnchorElement;
            dispatch(
                Actions.segment.track.ctaClicked({
                    cta_name: target.dataset?.ctaName,
                    event,
                })
            );
        },
        [dispatch]
    );

    const dispatchAppButtonClickedEvent = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            const target = event.target as HTMLAnchorElement;
            dispatch(
                Actions.segment.track.appButtonClicked({
                    app_button_name: target.dataset?.appButtonName,
                    event,
                })
            );
        },
        [dispatch]
    );

    return { dispatchCTAClickedEvent, dispatchAppButtonClickedEvent };
};
