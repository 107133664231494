import { TokenTypes } from './TokenStore';

export interface ITokenStore {
  access: string;
  id: string;
  refresh: string;
  clear(): void;
  updateTokens(tokens: { [key in TokenTypes]: string | undefined }): void;
}

export default class TokenStoreFactory {
  constructor(
    private stores: Record<string, ITokenStore>,
    private defaultKey: string = 'tokenStore',
  ) {}

  clear() {
    for (const key in this.stores) {
      if (Object.prototype.hasOwnProperty.call(this.stores, key)) {
        this.stores[key].clear();
      }
    }
  }

  updateTokens(tokens: { [key in TokenTypes]: string | undefined }) {
    for (const key in this.stores) {
      if (Object.prototype.hasOwnProperty.call(this.stores, key)) {
        this.stores[key].updateTokens(tokens);
      }
    }
  }

  get access(): string {
    return this.stores[this.defaultKey].access;
  }

  get id(): string {
    return this.stores[this.defaultKey].id;
  }

  get refresh(): string {
    return this.stores[this.defaultKey].refresh;
  }
}
