import Actions from './actions';
import { handleActionsImmer } from '../../utils';

export const initialState = {
  data: {},
};

const {
  challenge: { list },
} = Actions;

export default handleActionsImmer(
  {
    [list.success]: (draft, data) => {
      if (data) {
        draft.data = data?.object;
      }
    },
  },
  initialState,
);
