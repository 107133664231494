import auth from './modules/auth/selectors';
import series from './modules/series/selectors';
import settings from './modules/settings/selectors';
import workouts from './modules/workouts/selectors';
import boot from './modules/boot/selectors';
import config from './modules/config/selectors';
import content from './modules/content/selectors';
import favorites from './modules/favorites/selectors';
import phases from './modules/phaseFunction/selectors';
import subscriptions from './modules/subscriptions/selectors';
import music from './modules/music/selectors';
import flags from './modules/featureFlag/selectors';
import questionnaire from './modules/questionnaire/selectors';
import workoutCategories from './modules/workoutCategories/selectors';
import seriesCategory from './modules/seriesCategory/selectors';

export default {
  auth,
  series,
  settings,
  workouts,
  boot,
  content,
  config,
  favorites,
  phases,
  subscriptions,
  music,
  flags,
  questionnaire,
  workoutCategories,
  seriesCategory
};
