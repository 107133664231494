import React, { useCallback } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Styled from '../app-bars/announcement-nav-bar.styled';
import { KindOfTab, linkToExternal } from 'src/utils/route-utils';
import { DEFAULT_ANNOUNCEMENT_BAR_BUTTONS } from 'src/constants/header-constants';

const AnnouncementNavBar = () => {
    const theme: Theme = useTheme();
    const announcementBarButtons = DEFAULT_ANNOUNCEMENT_BAR_BUTTONS;

    const handleAnnouncementBarClick = useCallback(
        (path: string) => () => linkToExternal(path, KindOfTab.NEW_TAB),
        []
    );

    return (
        <Styled.AnnouncementNavBar>
            <Styled.AnnouncementNavBarInternalWrapper>
                <Styled.Links>
                    {announcementBarButtons.map((tab, index) => {
                        return (
                            <Styled.AnnouncementNavBarButton
                                key={index}
                                startIcon={
                                    <tab.Icon sx={{ color: theme.palette.brandColors.emberGlow }} />
                                }
                                variant="text"
                                onClick={handleAnnouncementBarClick(tab.link)}
                            >
                                {tab.text}
                            </Styled.AnnouncementNavBarButton>
                        );
                    })}
                </Styled.Links>
            </Styled.AnnouncementNavBarInternalWrapper>
        </Styled.AnnouncementNavBar>
    );
};

export default AnnouncementNavBar;
