// extracted by mini-css-extract-plugin
export const mini = "scheduled-workouts-module--mini--3eE84";
export const tiny = "scheduled-workouts-module--tiny--1-TBR";
export const small = "scheduled-workouts-module--small--30H5d";
export const medium = "scheduled-workouts-module--medium--3Y7Zc";
export const p3 = "scheduled-workouts-module--p3--1vqH3";
export const large = "scheduled-workouts-module--large--111Iq";
export const p2 = "scheduled-workouts-module--p2--30I5o";
export const l1 = "scheduled-workouts-module--l1--l9SNE";
export const label = "scheduled-workouts-module--label--3uiDI";
export const big = "scheduled-workouts-module--big--1sMZR";
export const p1 = "scheduled-workouts-module--p1--vpzLZ";
export const huge = "scheduled-workouts-module--huge--3CA1w";
export const p0 = "scheduled-workouts-module--p0--2AVx_";
export const huge36 = "scheduled-workouts-module--huge36--2NNQ0";
export const huge52 = "scheduled-workouts-module--huge52--2C9l8";
export const massive = "scheduled-workouts-module--massive--ReoKX";
export const h1 = "scheduled-workouts-module--h1--2vhNL";
export const h2 = "scheduled-workouts-module--h2--3S8Bz";
export const h3 = "scheduled-workouts-module--h3--2ihlQ";
export const h4 = "scheduled-workouts-module--h4--2StHV";
export const h5 = "scheduled-workouts-module--h5--65Awl";
export const h6 = "scheduled-workouts-module--h6--2n9vw";
export const accent = "scheduled-workouts-module--accent--1eV5Z";
export const accenth5 = "scheduled-workouts-module--accenth5--1Gklj";
export const accenth6 = "scheduled-workouts-module--accenth6--2Bby3";
export const upper = "scheduled-workouts-module--upper--15lED";
export const lower = "scheduled-workouts-module--lower--3_Up2";
export const underline = "scheduled-workouts-module--underline--193zl";
export const bold = "scheduled-workouts-module--bold--v1Pv1";
export const semibold = "scheduled-workouts-module--semibold--1PC-Z";
export const normal = "scheduled-workouts-module--normal--1NsFm";
export const stacked = "scheduled-workouts-module--stacked--23mDe";
export const link = "scheduled-workouts-module--link--ULvh9";
export const textLinks = "scheduled-workouts-module--text-links--6cdws";
export const container = "scheduled-workouts-module--container--3UmDi";
export const thumbnail = "scheduled-workouts-module--thumbnail--2tjoB";
export const spinner = "scheduled-workouts-module--spinner--3igVc";
export const videoContainer = "scheduled-workouts-module--video-container--TwBTV";
export const detailsContainer = "scheduled-workouts-module--details-container--2JWdm";
export const description = "scheduled-workouts-module--description--2EQuk";
export const workoutDescription = "scheduled-workouts-module--workout-description--3pvn8";
export const seeMore = "scheduled-workouts-module--see-more--38Ibj";
export const workoutHeader = "scheduled-workouts-module--workout-header--1O4dt";
export const workoutName = "scheduled-workouts-module--workout-name--pdNKi";
export const workoutLevel = "scheduled-workouts-module--workout-level--4IFGM";
export const instructorName = "scheduled-workouts-module--instructor-name--1Cp2O";
export const equipmentList = "scheduled-workouts-module--equipment-list--3Ni3Z";
export const equipmentIconLine = "scheduled-workouts-module--equipment-icon-line--1s_PK";
export const equipmentName = "scheduled-workouts-module--equipment-name--12xMn";
export const favIconLine = "scheduled-workouts-module--fav-icon-line--1K6vZ";
export const favText = "scheduled-workouts-module--fav-text--1pV5r";
export const seriesInfoContainer = "scheduled-workouts-module--series-info-container--3djOt";
export const seriesName = "scheduled-workouts-module--series-name--3JPvj";
export const phaseTag = "scheduled-workouts-module--phase-tag--1k1_c";
export const seriesNameTitle = "scheduled-workouts-module--series-name-title--2tOc2";
export const percentageBar = "scheduled-workouts-module--percentage-bar--1KUtO";
export const clearFloat = "scheduled-workouts-module--clear-float--34-qc";
export const linkMore = "scheduled-workouts-module--link-more--2hBxw";
export const resumableActions = "scheduled-workouts-module--resumable-actions--3leJZ";
export const pvIcon = "scheduled-workouts-module--pv-icon--2g4dQ";
export const pvPlay = "scheduled-workouts-module--pv-play--2dzuW";
export const pvReplay = "scheduled-workouts-module--pv-replay--3KtAt";
export const actionRow = "scheduled-workouts-module--action-row--Ri95c";
export const caption = "scheduled-workouts-module--caption--37ONU";
export const resumeButton = "scheduled-workouts-module--resume-button--2ZPyi";
export const replayButton = "scheduled-workouts-module--replay-button--k2mwF";
export const playButton = "scheduled-workouts-module--play-button--1xb6P";
export const previewVideoContainer = "scheduled-workouts-module--preview-video-container--2GZcT";
export const vjsModalDialog = "scheduled-workouts-module--vjs-modal-dialog--1RTem";