import { createSelector } from 'reselect';
import { IState } from '../../../redux/selectors';

import { IVideo } from './types';

const video = (state: IState) => state.video;

const volume = createSelector(video, (video: IVideo) => {
  return video.volume;
});

export default {
  volume,
};
