import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { HeaderMenuItem, HeaderTabItem } from 'types';
import { AnnouncementBarButton } from 'types/header';
import {
    CLASSES_PATH,
    LEGACY_ACCOUNT_PATH,
    LEGACY_LOGIN_PATH,
    LEGACY_LOGOUT_PATH,
    LOCATIONS_PATH,
    LVS_PATH,
    SERIES_PATH,
    SHOPIFY_URL,
    MYPLAN_PATH_DEV,
    FRANCHISES_PATH,
} from './urls';

export const HEADER_TABS: HeaderTabItem[] = [
    { href: MYPLAN_PATH_DEV, label: 'Home' },
    { href: CLASSES_PATH, label: 'Classes' },
    { href: LVS_PATH, label: 'Live' },
    { href: SERIES_PATH, label: 'Series' },
];

export const LOCATIONS_TABS: HeaderTabItem[] = [
    { href: `${SHOPIFY_URL}pages/method`, label: 'Our Method' },
    { href: `${SHOPIFY_URL}collections/all/`, label: 'Shop' },
    { href: `${SHOPIFY_URL}blogs/all/tagged/testimonial`, label: 'Testimonials' },
];

export const LOCATIONS_TABS_COUNT = LOCATIONS_TABS.length;

const SHOP_MENU_ITEM: HeaderMenuItem = {
    href: `${SHOPIFY_URL}collections/all-entitled`,
    label: 'Shop',
    openInNewTab: true,
};

export const STUDIOS_MENU_ITEMS: HeaderMenuItem[] = [
    {
        href: `${SHOPIFY_URL}pages/chicago`,
        label: 'Chicago Studio schedule',
        openInNewTab: true,
    },
    {
        href: `${SHOPIFY_URL}pages/new-york`,
        label: 'NYC Studio schedule',
        openInNewTab: true,
    },
    {
        href: `${SHOPIFY_URL}pages/los-angeles`,
        label: 'LA Studio schedule',
        openInNewTab: true,
    },
    {
        href: `${SHOPIFY_URL}pages/virtual-studio-classes`,
        label: 'LVS Studio schedule',
        openInNewTab: true,
    },
];
export const LOCATIONS_MENU_ITEMS: HeaderMenuItem[] = [
    {
        href: LOCATIONS_PATH,
        label: 'Locations',
    },
];
export const AUTHENTICATED_MENU_ITEMS: HeaderMenuItem[][] = [
    [
        {
            href: LEGACY_ACCOUNT_PATH,
            label: 'Account',
        },
        SHOP_MENU_ITEM,
    ],
    [
        {
            href: LEGACY_LOGOUT_PATH,
            label: 'Logout',
        },
    ],
];

export const UNAUTHENTICATED_MENU_ITEMS: HeaderMenuItem[][] = [
    [
        {
            href: LEGACY_LOGIN_PATH,
            label: 'Login',
        },
        SHOP_MENU_ITEM,
    ],
];

export const DEFAULT_ANNOUNCEMENT_BAR_BUTTONS: AnnouncementBarButton[] = [
    {
        Icon: ShoppingBagOutlinedIcon,
        link: `${SHOPIFY_URL}collections/equipment`,
        text: 'Shop',
    },
    {
        Icon: PlaceOutlinedIcon,
        link: LOCATIONS_PATH,
        text: 'Locations',
    },
    {
        Icon: StorefrontOutlinedIcon,
        link: FRANCHISES_PATH,
        text: 'Franchise',
    },
];

export const LOCATIONS_ANNOUNCEMENT_BAR_BUTTONS: AnnouncementBarButton[] = [
    { Icon: PlaceOutlinedIcon, link: LOCATIONS_PATH, text: 'Locations' },
    {
        Icon: StorefrontOutlinedIcon,
        link: FRANCHISES_PATH,
        text: 'Franchise',
    },
];
