import React, { useCallback, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Icon } from 'src/components/shared';
import { getErrorMessage } from 'src/utils/form-utils';

import * as Styles from 'src/styles/stay-in-touch.module.scss';
import { useDispatch } from 'react-redux';
import Actions from 'src/state/root-actions';

const StayInTouch = () => {
    const dispatch = useDispatch();
    const [emailValue, setEmailValue] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleEmail = useCallback(() => {
        const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;
        if (emailRegex.test(emailValue)) {
            const email = emailValue.toLowerCase();
            dispatch(
                Actions.segment.identify({
                    userId: email,
                    traits: {
                        email_marketing_opted_in: 'opted_in',
                        email,
                    },
                })
            );
            dispatch(
                Actions.segment.track.marketingOptedIn({
                    email,
                    source_detail: 'footer',
                    source: 'app.pvolve.com',
                    is_email_opt_in: true,
                })
            );
            setShowSuccess(true);
        } else {
            setShowError(true);
        }
    }, [emailValue]);

    return (
        <div>
            <h6 className={Styles.stayInTouchTitle}>Let's stay in touch</h6>
            <p className={Styles.stayInTouchDescription}>
                Get fitness tips, tricks and motivations right in your inbox. But don’t worry, not
                too often.
            </p>
            <Form onSubmit={handleEmail} className={Styles.stayInTouchInputContainer}>
                {showSuccess ? (
                    <div className={Styles.stayInTouchSuccess}>
                        <Icon size={15} name="pv-checkmark-circle" color="black" />
                        <span>Subscribed! Thanks for signing up!</span>
                    </div>
                ) : (
                    <>
                        <Form.Input
                            className={Styles.stayInTouchInput}
                            id="email"
                            name="email"
                            type="text"
                            placeholder="email"
                            onChange={(e) => {
                                setEmailValue(e.target.value);
                            }}
                            error={showError && getErrorMessage('Please enter an email address')}
                        />
                        <Button className={Styles.stayInTouchBtn} circular>
                            <Icon size={24} name="pv-arrow-right" />
                        </Button>
                    </>
                )}
            </Form>
        </div>
    );
};

export default StayInTouch;
