import BaseService from './BaseService';

class StatsServiceV2 extends BaseService {
  constructor() {
    super({ configPath: 'services.statsV2' });
  }

  async workouts(id) {
    return await this.requestResource(
      'workouts',
      {
        method: 'GET',
        params: { id }
      }
    )
  }
}

export default StatsServiceV2;
