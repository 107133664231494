import { delay, race, take } from 'redux-saga/effects';

export enum BootSequenceLifecycleActions {
  start = 'start',
  shutdown = 'shutdown',
}

export enum Priority {
  HIGH = 0,
  NORMAL = 100,
  LOW = 1000,
};

const DEFAULT_PRIORITY = Priority.NORMAL;

export default class BootSequence {
  timeoutMs: number;
  private actions: {
    [key in BootSequenceLifecycleActions]: {}
  };
  private triggers: {
    [key in BootSequenceLifecycleActions]?: {}
  };

  constructor(options?: { timeoutMs: number }) {
    this.timeoutMs = options?.timeoutMs | 5 * 1000;

    this.actions = {
      start: {},
      shutdown: {},
    };

    this.triggers = {
    };
  }

  get startWhen() {
    return this.triggers.start;
  }

  get shutdownWhen() {
    return this.triggers.shutdown;
  }

  set startWhen(action) {
    this.triggers.start = action;
  }

  set shutdownWhen(action) {
    this.triggers.shutdown = action;
  }

  registerSagas(...sagas) {
    this.onStart(...sagas.map((saga) => ({ saga: saga.init.bind(saga) })));
  }

  onStart(...configs) {
    this._addSequence(BootSequenceLifecycleActions.start, configs);
  }

  onShutdown(...configs) {
    this._addSequence(BootSequenceLifecycleActions.shutdown, configs);
  }

  _addSequence(group: BootSequenceLifecycleActions, configs) {
    let number = 0;
    for (let config of configs) {
      const { routine, saga } = config;
      if(routine) {
        this.actions[group][routine.trigger.toString()] = config;

      } else if(saga) {
        this.actions[group][`saga-${number++}`] = config;

      }
    }
  }

  get start() {
    return Object.values(this.actions.start);
  }

  get shutdown() {
    return Object.values(this.actions.shutdown);
  }

}

const SEQUENCES: { [key:string]: BootSequence } = {};

export function getBootSequence(name: string, config?): BootSequence {
  SEQUENCES[name] = SEQUENCES[name] || new BootSequence(config);
  return SEQUENCES[name];
}

export function sequences() {
  return SEQUENCES;
}

/**
 * @param triggerName 'system.start'
 *
 * @return an object mapping priority to actions
 */
export function getActions(name: string, startOrShutdown) {
  const sequence = SEQUENCES[name];
  const allActions = sequence[startOrShutdown];

  const byPriority = {};

  for (let { priority = DEFAULT_PRIORITY, routine, timeout, saga } of allActions) {
    byPriority[priority] = byPriority[priority] || { triggers: [], fulfills: [], sagas: [] };

    if (routine?.trigger) {
      byPriority[priority].triggers.push(routine.trigger);
    }
    else if(saga) {
      byPriority[priority].sagas.push(saga);
    }

    if (routine?.fulfill) {
      const fulfillWithTimeout = race({
        fulfill: take(routine.fulfill),
        timeout: delay(timeout || sequence.timeoutMs),
      });

      byPriority[priority].fulfills.push(fulfillWithTimeout);
    }
  }

  return byPriority;
}
