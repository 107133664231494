import { isEmpty, map } from 'lodash';
import BaseService from './BaseService';

export interface Product {
  sku: string;
  title: string;
  description: string;
  product_type: string;
  image_url: string;
  category: string;
  name: string;
  shopify_product_id?: number;
  shopify_variant_id?: number;
  shipping_details: {
    weight: number;
  };
  cost: {
    unit_amount: number;
    currency: string;
    currency_amount: number;
    taxable: boolean;
  };
  subscription_details: {
    interval_type: string;
    interval: number;
    trial_days: number;
  };
  created_at: string;
  updated_at: string;
  subscriptionPeriodAndroid?: string;
}

export interface Subscription {
  subscription: any;
  user_id: string;
  subscription_id: string;
  provider: string;
  state: string;
  entitlements: string[];
  identifier: string;
  original_purchase_date: string;
  expiration_date: string;
  cancellation_date: string;
  product_id: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateSubscriptionResponse {
  response_code: number;
  message?: string;
  data: {
    subscription_id: string;
    status: string;
    current_sku: string;
    upcoming_sku?: string;
    effective_at: string;
  };
}

export interface SubscriptionBillingAddress {
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  country: string;
}
export interface UpdateSubscriptionAttributes {
  new_sku?: string;
  cancel?: boolean;
  code?: string;
  billing_address?: SubscriptionBillingAddress;
}
class SubscriptionService extends BaseService {
  constructor() {
    super({ configPath: 'services.subscription' });
  }

  async create(source, receipt) {
    return await this.requestResource('sub', {
      method: 'POST',
      data: {
        source: source,
        receipt: receipt,
      },
    });
  }

  async get(): Promise<{
    subscriptions: Subscription[];
  }> {
    return await this.requestResource('sub', {
      method: 'GET',
    });
  }

  async getBySubscriptionId(subscription_id: string) {
    return await this.requestResource('subId', {
      method: 'GET',
      params: { subscription_id },
    });
  }

  async update(
    subscription_id: string,
    attributes: UpdateSubscriptionAttributes,
  ): Promise<UpdateSubscriptionResponse> {
    const response = await this.requestResource('updateSubscription', {
      method: 'POST',
      data: {
        subscription_id,
        ...attributes,
      },
    });

    const { response_code, message, data } = response;

    if (response_code) {
      throw message;
    } else {
      return response;
    }
  }

  async cancel(subscription_id) {
    return await this.update(subscription_id, { cancel: true });
  }

  async reactivate(subscription_id) {
    return await this.update(subscription_id, { cancel: false });
  }

  /*
   * Activates a subscription using the given activation code
   */
  async activateSubscription(code: string) {
    return await this.requestResource('activateSubscription', {
      method: 'POST',
      data: { code },
    });
  }

  /*
   * Verifies an activation code
   */
  async verifyActivationCode(code: string) {
    return await this.requestResource('verifyActivationCode', {
      method: 'POST',
      data: { code },
      tokenType: 'none',
    });
  }

  /*
   * Verifies an Optum activation code
   */
  async verifyPartnerActivationCode(provider: string, member_id: string) {
    return await this.requestResource('verifyOptumActivationCode', {
      method: 'GET',
      params: { provider, member_id },
      tokenType: 'none',
    });
  }

  /*
   * Retrieves current subscription products
   */
  async listSubscriptionProducts(sku?: string | string[]): Promise<Product[]> {
    if (typeof sku === 'string') {
      sku = [sku];
    }

    let options = {};
    if (!isEmpty(sku)) {
      options = {
        queryParams: { sku },
        queryOptions: { arrayFormat: 'repeat' },
      };
    }

    return await this.requestResource('listSubscriptionProducts', {
      tokenType: 'none',
      method: 'GET',
      ...options,
    });
  }
}

export default SubscriptionService;
