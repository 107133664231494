import React from 'react';
import { Icon } from 'src/components/shared';
import classNames from 'classnames';
import * as Styles from 'src/styles/tooltip.module.scss';

interface TooltipProps {
    icon: string;
    title: string;
    children: any;
}

const Tooltip = ({ icon, title, children }: TooltipProps) => {
    return (
        <div
            className={classNames(
                Styles.tooltipContainer,
                'display-inline-flex',
                'justify-content--center'
            )}
        >
            <div className="display-row" data-tip={children}>
                <Icon name={icon} size={23} />
                <h5 className={classNames(Styles.tooltipTitle, 'accent', 'bold')}>{title}</h5>
            </div>

            <div className={classNames('text-align--left', Styles.tooltipTextContainer)}>
                <p className="p3">{children}</p>
            </div>
        </div>
    );
};

export default Tooltip;
