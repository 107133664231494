export type RedirectResult = { type: 'success'; url: string };
export type BrowserResult = { type: 'cancel' | 'dismiss' };
export type AuthSessionResult = RedirectResult | BrowserResult;

export interface AuthBrowser {
  openAuthSessionAsync(
    url: string,
    redirectUrl: string,
  ): Promise<AuthSessionResult>;
}

/**
 * A cross platform AuthBrowser
 *
 */
export default class DefaultAuthBrowser {
  constructor(
    private linking: {
      addEventListener: (eventName: string, Function) => void,
      removeEventListener: (eventName: string, Function) => void,
      openURL: (url:string) => Promise<void>,
    },
    private browser: {
      openAuth: (url:string, redirectUrl:string, options?: any) => Promise<AuthSessionResult>,
      isAvailable: () => Promise<boolean>,
    }
  ) {
  }

  async openAuthInAppBrowser(
    url: string,
    redirectUrl: string,
  ): Promise<AuthSessionResult> {
    const { browser } = this;

    return await browser.openAuth(url, redirectUrl, {
      // iOS Properties
      ephemeralWebSession: true,
      // Android Properties
      showTitle: false,
      enableUrlBarHiding: true,
      enableDefaultShare: false,
    });
  }

  /*
  async openAuthSessionAsyncIOS(url: string, redirectUrl: string): Promise<AuthSessionResult> {
    const finalUrl = await RNSFAuthenticationSession.getSafariData(url, redirectUrl);
    return { url: finalUrl, type: 'success' };
  }
  */

  async openAuthSessionAsyncDefault(
    url: string,
    redirectUrl: string,
  ): Promise<AuthSessionResult> {
    const { linking } = this;
    const session: {
      handler?: (event: { url: string }) => void;
    } = {};

    const foundValidRedirect = new Promise<AuthSessionResult>(resolve => {
      session.handler = (event: { url: string }) => {
        if (event.url.startsWith(redirectUrl)) {
          resolve({ url: event.url, type: 'success' });
        }
      };

      linking.addEventListener('url', session.handler);
    });

    try {
      await linking.openURL(url);
      return await foundValidRedirect;
    } finally {
      if (session.handler) {
        linking.removeEventListener('url', session.handler);
      }
    }
  }

  async openAuthSessionAsync(
    url: string,
    redirectUrl: string,
  ): Promise<AuthSessionResult> {
    const { browser } = this;

    if (await browser.isAvailable()) {
      return await this.openAuthInAppBrowser(url, redirectUrl);
    } else {
      return await this.openAuthSessionAsyncDefault(url, redirectUrl);
    }
  }
}