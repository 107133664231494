import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
    fontFamily: 'sans-serif',
    h1: {
        fontFamily: 'PP Right Grotesk',
        fontWeight: 900,
        fontSize: 96,
        lineHeight: '96px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    h2: {
        fontFamily: 'PP Right Grotesk',
        fontWeight: 900,
        fontSize: 60,
        lineHeight: '60px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    h3: {
        fontFamily: 'PP Right Grotesk',
        fontWeight: 900,
        fontSize: 48,
        lineHeight: '48px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    h4: {
        fontFamily: 'PP Right Grotesk Wide',
        fontWeight: 500,
        fontSize: 34,
        lineHeight: '42px',
        letterSpacing: '0.25px',
    },
    h5: {
        fontFamily: 'PP Right Grotesk Wide',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '32px',
    },
    h6: {
        fontFamily: 'Muller',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '32px',
        letterSpacing: '3px',
        textTransform: 'uppercase',
    },
    h7: {
        fontFamily: 'PP Right Grotesk',
        fontWeight: 900,
        fontSize: 32,
        lineHeight: '32px',
        textTransform: 'uppercase',
    },
    body1: {
        fontFamily: 'Muller',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    body2: {
        fontFamily: 'Muller',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.15px',
    },
    subtitle1: {
        fontFamily: 'PP Right Grotesk Wide',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.15px',
    },
    subtitle2: {
        fontFamily: 'PP Right Grotesk Wide',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '22px',
        letterSpacing: '0.1px',
    },
    caption: {
        fontFamily: 'Muller',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '14.4px',
        letterSpacing: '0.4px',
    },
    overline: {
        fontFamily: 'Muller',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '14.4px',
        letterSpacing: '2px',
        textTransform: 'uppercase',
    },
    button: {
        fontFamily: 'PP Right Grotesk Wide',
        textTransform: 'none',
    },
};

export default typography;
