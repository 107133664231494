import { TabItem } from 'src/components/shared/tabs.component';
import {
    ZELDA_HOME_LINK,
    CLASSES_LINK,
    SERIES_LINK,
    LIVE_LINK,
    LOCATIONS_LINK,
} from 'src/constants/url-constants';

export interface HeaderTabItem extends TabItem {
    pathname: string;
    ctaName: string;
}

export interface HeaderMenuItem {
    label: string;
    // has pathname XOR url
    pathname?: string;
    url?: string;
    ctaName: string;
    openInNewTab?: boolean;
}

export const LOGO_CTA_NAME = 'topnav-pvolveLogo';
export const BUTTON_SHOP_CTA_NAME = 'topnav-shop-all';
export const MENU_SHOP_CTA_NAME = 'menu-shop';
export const FREE_TRIAL_CTA_NAME = 'freeTrial-header';

export const HEADER_TABS: HeaderTabItem[] = [
    {
        label: 'Home',
        pathname: ZELDA_HOME_LINK,
        ctaName: 'topnav-streaming-myPlan',
    },
    {
        label: 'Classes',
        pathname: CLASSES_LINK,
        ctaName: 'topnav-streaming-classes',
    },
    {
        label: 'Live',
        pathname: LIVE_LINK,
        ctaName: 'topnav-streaming-live',
    },
    {
        label: 'Series',
        pathname: SERIES_LINK,
        ctaName: 'topnav-streaming-series',
    },
];

export const HEADER_TABS_COUNT = HEADER_TABS.length;
export const HEADER_TAB_WIDTH = 125;

export const getShopLink = (shopifyUrl: string) => `${shopifyUrl}/collections/all-entitled`;

const getShopMenuItem = (shopifyUrl: string): HeaderMenuItem => {
    return {
        label: 'Shop',
        url: getShopLink(shopifyUrl),
        ctaName: MENU_SHOP_CTA_NAME,
    };
};

const getStudiosMenuItems = (shopifyUrl: string): HeaderMenuItem[] => [
    {
        label: 'Refer a Friend',
        url: `${shopifyUrl}/pages/refer`,
        ctaName: 'topnav-refer-ReferAFriend',
    },
];

const getLoggedInItems = (shopifyUrl: string): HeaderMenuItem[][] => {
    const loggedInItems = [];
    loggedInItems.push([
        {
            label: 'Account',
            pathname: '/account',
            ctaName: 'menu-account',
        },
        getShopMenuItem(shopifyUrl),
    ]);
    loggedInItems.push(getStudiosMenuItems(shopifyUrl));

    loggedInItems.push([LOCATIONS_MENU_ITEM]);

    loggedInItems.push([
        {
            label: 'Logout',
            pathname: '/logout',
            ctaName: 'menu-logout',
        },
    ]);
    return loggedInItems;
};

const getLoggedOutItems = (shopifyUrl: string): HeaderMenuItem[][] => {
    const loggedOutItems = [
        [
            {
                label: 'Login',
                pathname: '/continue',
                ctaName: 'menu-login',
            },
            getShopMenuItem(shopifyUrl),
        ],
        getStudiosMenuItems(shopifyUrl),
    ];

    loggedOutItems.push([LOCATIONS_MENU_ITEM]);

    return loggedOutItems;
};

const LOCATIONS_MENU_ITEM = {
    label: 'Locations',
    pathname: LOCATIONS_LINK,
    ctaName: 'menu-locations',
};

export const getHeaderMenuItems = (isLoggedIn: boolean, shopifyUrl: string): HeaderMenuItem[][] => {
    return isLoggedIn ? getLoggedInItems(shopifyUrl) : getLoggedOutItems(shopifyUrl);
};

export const getStartedUrl = (shopifyUrl?: string) =>
    shopifyUrl ? `${shopifyUrl}/pages/dup-trial-annual` : '';
