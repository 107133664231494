import React from 'react';
import classNames from 'classnames';
import { navigate, useLocation, WindowLocation } from '@reach/router';

import Icon from './Icon';

import * as buttonStyles from 'src/styles/square-close-button.module.scss';
import { isPvolveDomain } from 'src/utils/url-utils';

export interface LocationState {
    prevPath: string | number;
}
interface ButtonProps {
    className?: string;
    size?: number;
    onClick?: () => {};
}

const SquareCloseButton = ({ className, size, onClick }: ButtonProps) => {
    const { state } = useLocation() as WindowLocation<LocationState>;

    const goBack = () => {
        if (document.referrer && window?.sessionStorage?.getItem('fromVideo') === null) {
            // exiting the SPA app
            if (window?.history) {
                window.history.back();
            }
        } else {
            // SPA app, use gatsby to navigate to previous path
            // Also go this path if coming from a video (flag set up in VideoPlayer.tsx in function onClose)
            if (window?.sessionStorage?.getItem('fromVideo') === 'true') {
                window?.sessionStorage?.removeItem('fromVideo');
            }
            const prevPath = state?.prevPath ? state.prevPath : -1;
            navigate(prevPath);
        }
    };

    if (!state?.prevPath) {
        // If navigating linking from a non-pvolve page, don't show the 'X' button
        if (document.referrer && !isPvolveDomain(document.referrer)) {
            return null;
        }

        // If there is no referrer, then don't show the 'X' button
        if (!document.referrer) {
            return null;
        }
    }

    return (
        <button
            onClick={onClick ? onClick : goBack}
            className={classNames(buttonStyles.closeButton, 'pv-close-button', className)}
        >
            <Icon name="pv-close" size={size || 16} color="black" />
        </button>
    );
};

export default SquareCloseButton;
