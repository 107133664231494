import { isEmpty } from 'lodash';
import React, { useRef, useEffect } from 'react';
import { Icon } from 'src/components/shared';
import * as Styles from 'src/styles/font-or-svg-icon.module.scss';
import Colors from 'src/utils/colors';
import { GlyphName } from '../shared/pvolveGlyphMap';

interface FontOrSvgIconProps {
    icon?: GlyphName;
    svg?: string;
    color?: string | typeof Colors;
    size?: number;
}

const FontOrSvgIcon = ({ icon, svg, color, size = 35 }: FontOrSvgIconProps) => {
    const svgRef = useRef<HTMLDivElement>();
    const styles = {
        ...(color ? { fill: color } : {}),
        height: size,
        width: size,
    };

    useEffect(() => {
        if (svg) {
            svgRef.current.innerHTML = svg;
        }
    }, [svgRef, svg]);

    return !isEmpty(svg) ? (
        <div ref={svgRef} className={Styles.svgIcon} style={styles} />
    ) : (
        <Icon name={icon} style={color ? { color } : null} size={size} />
    );
};

export default FontOrSvgIcon;
