import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Styled = {
    AppBar: styled(AppBar)(({ theme }) => ({
        boxShadow: theme.shadows[0],
        alignItems: 'center',
        zIndex: 1110,
    })),

    SignInButton: styled(Button)(({ theme }) => ({
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        letterSpacing: 0.4,
    })),
};

export default Styled;
