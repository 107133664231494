import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const hoverTransition = 'transform 0.3s ease-out, opacity 0.3s ease-out';

const Styled = {
    HeaderTabsWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },

        '.MuiTabs-root': {
            alignItems: 'center',
        },

        '.MuiButtonBase-root': {
            padding: 0,
            minWidth: 0,
            marginRight: theme.spacing(5),
            minHeight: 0,
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            letterSpacing: 0.4,
            color: theme.palette.primary.main,

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(1, 1.375),
                lineHeight: '26px',
            },

            '&.Mui-selected': {
                fontWeight: 700,
            },

            '&::before': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                transform: 'scaleX(0)',
                transformOrigin: 'left bottom',
                width: '100%',
                height: 2,
                backgroundColor: theme.palette.brandColors.emberGlow,
                opacity: '0',
                transition: hoverTransition,
            },

            '&:hover': {
                '&::before': {
                    opacity: '1',
                    transform: 'scaleX(1)',
                    transition: hoverTransition,
                },
            },
        },

        '.MuiTabs-indicator': {
            display: 'none',
        },

        '.MuiTabs-flexContainer': {
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
    })),
};

export default Styled;
