/* URLS */
import { getEnvUrls } from 'src/utils/route-utils';

export const SHOPIFY_URL_DEFAULT = 'https://www.pvolve.com';

/* PATHS */
export const UPGRADE_PLAN_LINK = '/account/subscription/update';
export const FREE_TRIAL_LINK = '/checkout/?sku=12month_trial7&features=true';
export const CLASSES_LINK = '/classes';
export const SERIES_LINK = '/series';
export const LIVE_LINK = '/live';
export const LOCATIONS_LINK = '/locations';

export const ZELDA_HOME_LINK = '/home';

export const MY_PLAN_PNF_PATH = '/my-plan/phase-function';

const ENV_URLS = getEnvUrls();
export const NO_SUPPORT_FACEBOOK = `${ENV_URLS.shopifyUrl}blogs/news/facebook-login-no-longer-supported`;
export const NO_SUPPORT_APPLE_GOOGLE = `${ENV_URLS.shopifyUrl}blogs/news/google-apple-login-are-no-longer-supported`;
