/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { parse } from 'query-string';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { find, get, isEmpty, merge, parseInt } from 'lodash';
import Helmet from 'react-helmet';

import Selectors from 'src/state/root-selectors';
import { isValidMuxUrl, redirect } from 'src/utils/url-utils';
import { getMuxVideoSource, getStoryboard } from 'src/utils/sdk-utils';
import 'video.js/dist/video-js.css';
import 'src/styles/video-player.scss';
import { v4 as uuidv4 } from 'uuid';
import { EventPayload, VideoEventType } from '@pvolve/sdk/src/app/types';
import {
    ContentSelectors,
    IState,
    IWorkoutHistoryItem,
    IWorkoutTypes,
} from '@pvolve/sdk/src/redux/selectors';
import FeedPlayer from 'src/components/music/FeedPlayer';
import VolumeControls from './VolumeControls';
// import 'videojs-mux';
import 'videojs-vtt-thumbnails';

import { useLocation, WindowLocation } from '@reach/router';
import { FeedStates } from '@pvolve/sdk/src/app/modules/music/types';
import Actions from '@pvolve/sdk/src/app/actions';
import { isIOS, useDeviceDetect, useScreenWidth } from 'src/utils/breakpoint-utils';
import { notifyBugsnagError } from 'src/utils/bugsnag';
import NextUpButton from 'src/components/video/NextUpButton';
import { GeneralButton } from 'src/components/ui';
import { Icon } from 'src/components/shared';

import { getStartedUrl } from '../layout/header/header-constants';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

const PROGRESS_TRACKING_RESOLUTION_MS = 1000;

interface VideoPlayerLocationState {
    prevPath: string;
}

export interface VideoPlayerEvents {
    onClose?: (player: VideoJsPlayer) => void;
    onEnd?: (player: VideoJsPlayer) => void;
    onError?: (player: VideoJsPlayer, error: ErrorConstructor) => void;
    onLoadedData?: (player: VideoJsPlayer) => void;
    onMute?: () => void;
    onPlay?: (player: VideoJsPlayer) => void;
    onPause?: (player: VideoJsPlayer) => void;
    onProgress?: (player: VideoJsPlayer, time: number) => void;
    onReady?: (player: VideoJsPlayer) => void;
    onSeeked?: (player: VideoJsPlayer) => void;
    onSeeking?: (player: VideoJsPlayer) => void;
    onUnmute?: () => void;
    onVolumeChange?: (player: VideoJsPlayer, volume: number) => void;
}

interface VideoPlayerProps {
    /**
     * Contentful Workout ID
     */
    videoId: string;

    /**
     * video.js options
     */
    options?: VideoJsPlayerOptions;

    /**
     * Callback functions that can be called when events are thrown
     */
    events?: VideoPlayerEvents;

    /**
     * The type of video playing, defaults to `workout`
     */
    type?: 'workout' | 'series';

    entitled: boolean;

    history?: IWorkoutHistoryItem;
}

const connector = connect((state: IState, { videoId }: { videoId: string }) => ({
    userId: Selectors.auth.userId(state),
    workout: ContentSelectors.workout.indexedBy.id(state)[videoId],
    series: ContentSelectors.series.indexedBy.id(state)[videoId],
    music: Selectors.music.music(state),
    volume: Selectors.video.volume(state),
    nextWorkout: ContentSelectors.workout.next(state),
    feedFmEnabled: !Selectors.account.userAttributes(state)?.object?.feed_fm_disabled,
    closedCaptionsEnabled: Selectors.account.userAttributes(state)?.object?.closed_captions,
    shopifyUrl: Selectors.config.shopifyUrl(state),
}));

// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md
const VideoPlayer = ({
    videoId,
    options = {},
    events = {},
    type = 'workout',
    entitled,
    userId,
    workout,
    series,
    history,
    music,
    volume,
    nextWorkout,
    feedFmEnabled,
    closedCaptionsEnabled,
    shopifyUrl,
}: VideoPlayerProps & ConnectedProps<typeof connector>) => {
    const dispatch = useDispatch();
    const location = useLocation() as WindowLocation<VideoPlayerLocationState>;
    const device = useDeviceDetect();

    const isMobile = device?.type === 'mobile';
    const isIOSDevice = isIOS();

    const [videoSrc, setVideoSrc] = useState<videojs.Tech.SourceObject>();
    const [storyboard, setStoryboard] = useState<string>();
    const [startedMuted, setStartedMuted] = useState(false);
    const [startedPaused, setStartedPaused] = useState(false);
    const [enableMusic, setEnableMusic] = useState(false);
    // const [mux, setMux] = useState<{ environmentKey: string }>();
    const [muted, setMuted] = useState<Boolean>(false);
    const [userActive, setUserActive] = useState<Boolean>(false);
    const [playing, setPlaying] = useState<Boolean>(!isMobile);
    const [musicPlayerEngaged, setMusicPlayerEngaged] = useState<boolean>(false);
    const [initialPlayStarted, setInitialPlayStarted] = useState<boolean>(false);

    const [isVolumeOpen, setIsVolumeOpen] = useState(false);
    const lastPlayingState = useRef<FeedStates>();
    const musicState = useRef<FeedStates>();
    const [textTracks, setTextTracks] = useState<videojs.TextTrackList | never[]>([]);
    const lastProgressEmittedAt = useRef<number>();
    const videoNode = useRef<HTMLVideoElement>(null);
    const textTrack = useRef<videojs.TextTrack>();
    const toggleCaptionsButton = useRef<videojs.Button>();
    const player = useRef<VideoJsPlayer>();
    const screenWidth = useScreenWidth();
    const [nextVideoButton, setNextVideoButton] = useState(false);
    const nextVideoButtonState = useRef<Boolean>(nextVideoButton);
    const nextWorkoutUrl = useRef<string>('');
    const musicPlayer = useRef();

    const isMobileLandscape = isMobile && window.matchMedia('(orientation: landscape)').matches;

    const isTalk = workout?.fields?.type === IWorkoutTypes.talk;

    /**
     * Video.js has a fluid mode that keeps the player sized to a particular aspect ratio.
     * By default, fluid mode will use the intrinsic size of the video once loaded but you
     * can change it with classes or with the aspectRatio option.
     */
    const defaultOptions: VideoJsPlayerOptions = {
        controls: true,
        fluid: true,
        aspectRatio: '16:9',
        preload: 'auto',
        language: 'en',
        nativeControlsForTouch: isMobile,
        controlBar: {
            playToggle: false,
            pictureInPictureToggle: false,
            subsCapsButton: false,
            fullscreenToggle: false,
        },
        userActions: {
            hotkeys: true,
        },
        html5: {
            autoplay: true,
            vhs: {
                cacheEncryptionKeys: true,
                enableLowInitialPlaylist: true,
                handleManifestRedirects: true,
            },
        },
    };

    const loadFromStartingPoint = useCallback(async () => {
        const params = parse(location.search);
        let startingTimeCode: number;

        if (params.t && player.current) {
            const time = params.t as string;
            startingTimeCode = parseInt(time);
        } else {
            const { target_seconds = 0, timecode = 0 } = history || {};
            startingTimeCode = target_seconds - timecode < 30 ? 0 : timecode;
        }

        if (player.current) {
            player.current.currentTime(startingTimeCode);

            try {
                await player.current.play();
            } catch (e) {
                setStartedPaused(true);
            }
        }

        if (startingTimeCode === 0) {
            eventPayload(VideoEventType.VIDEO_START, startingTimeCode);
        }
    }, [location, player]);

    const loadVideo = useCallback(async () => {
        // fieldName is either `video` or `previewVideo`
        const fieldName = entitled && type !== 'series' ? 'video' : 'previewVideo';
        const assetId = type === 'workout' ? workout.sys.id : series.sys.id;

        try {
            const { enableMusic, uri } = await getMuxVideoSource(assetId, type, fieldName);

            if (isValidMuxUrl(uri)) {
                setVideoSrc(uri);
            }

            setEnableMusic(entitled && enableMusic);
        } catch (error) {
            notifyBugsnagError(error);
            console.warn('Error loading video source');
        }

        try {
            const response = await getStoryboard(assetId, 'workout', entitled);

            if (isValidMuxUrl(response?.uri)) {
                setStoryboard(response?.uri);
            }
        } catch (error) {
            notifyBugsnagError(error);
            console.warn('Error loading storyboard');
        }
    }, [entitled, workout, series]);

    // const loadMuxConfig = useCallback(async () => {
    //     const config = await Pvolve.api.config.find('mux');
    //     setMux(config);
    // }, []);

    const loadCurrentWorkout = useCallback(() => {
        if (workout) {
            dispatch(Actions.workouts.loadCurrent.trigger(workout));
        }
    }, [workout]);

    useEffect(() => {
        loadVideo();
        // loadMuxConfig();
        loadCurrentWorkout();
    }, []);

    useEffect(() => {
        nextWorkoutUrl.current = nextWorkout ? nextWorkout.url : '';
    }, [nextWorkout]);

    useEffect(() => {
        musicState.current = music.state;
    }, [music, musicState]);

    useEffect(() => {
        if (player.current) {
            player.current.volume(volume);
        }
    }, [player, volume]);

    useEffect(() => {
        if (player.current && storyboard) {
            player.current.vttThumbnails({
                src: storyboard,
            });
        }
    }, [storyboard]);

    /**
     * Anytime the src updates and has a value,
     * create a new player and open it
     */
    useEffect(() => {
        if (!videoSrc) {
            return;
        }

        const playerInitTime = Date.now();

        player.current = videojs(videoNode.current, {
            ...defaultOptions,
            ...options,
            sources: [videoSrc],
        });

        loadFromStartingPoint();

        // if (type === 'workout') {
        //     const data = {
        //         env_key: mux.environmentKey, // required
        //         viewer_user_id: userId,

        //         // Metadata
        //         player_name: 'P.volve Web',
        //         player_init_time: playerInitTime,

        //         video_id: videoId,
        //         video_title: workout?.fields?.name,
        //         video_series: workout?.fields?.series?.sys?.id,
        //         video_stream_type: 'on-demand',
        //     };

        //     player.current.mux({
        //         debug: false,
        //         data,
        //     });
        // }

        initPlayerUI();
        registerEvents();

        return () => {
            player.current?.dispose();
            unregisterEvents();
        };
    }, [videoSrc]);

    useEffect(() => {
        /**
         * Add a CC button
         */
        if (isEmpty(textTracks) || !player.current) {
            return;
        }

        const matchTextKind = ({ kind }: HTMLTrackElement) => kind.match(/subtitles|captions/);
        textTrack.current = find(textTracks, matchTextKind);

        if (textTrack.current) {
            const Button = videojs.getComponent('Button');
            toggleCaptionsButton.current = new Button(player.current, {
                clickHandler: vjsHandlers.onToggleCaptions,
            });
            toggleCaptionsButton.current.addClass('vjs-toggle-cc');
            const secondaryControls = player.current.getChild('SecondaryControls');
            secondaryControls?.addChild(toggleCaptionsButton.current, {}, 0);

            if (closedCaptionsEnabled) {
                vjsHandlers.onToggleCaptions();
            }
        }
    }, [closedCaptionsEnabled, textTracks]);

    useEffect(() => {
        if (player.current) {
            if (!musicPlayerEngaged || initialPlayStarted) {
                setUserActive(false);
            }
        }
    }, [musicPlayerEngaged, initialPlayStarted, player, setUserActive]);

    useEffect(() => {
        if (musicPlayerEngaged) {
            hideVolumeControls();
        }
    }, [musicPlayerEngaged]);

    /**
     * Can be used to fast forward, rewind, or jump to a certain time.
     *
     * @param {*} timeInSeconds Positive or Negative time measured in seconds
     */
    const seek = useCallback(
        (timeInSeconds: number) => {
            /** ToDo: compare to end of video/video length */
            if (player.current) {
                player.current.currentTime(
                    Math.max(0, player.current.currentTime() + timeInSeconds)
                );
            }
        },
        [player]
    );

    const onClose = useCallback(() => {
        const { state } = location;

        if (!entitled) {
            window?.sessionStorage?.setItem('fromVideo', 'true');
            navigate(getStartedUrl(shopifyUrl), { state: { prevPath: state?.prevPath } });
            return;
        }

        if (nextVideoButtonState.current && !!nextWorkoutUrl.current) {
            window.location.href = nextWorkoutUrl.current;
            return;
        }

        if (state?.prevPath) {
            if (state?.prevPath == ZELDA_HOME_LINK) {
                redirect(state.prevPath);
            } else {
                navigate(state.prevPath);
            }
        } else if (document.referrer) {
            // TODO: follow up on this because in an SPA app, document.referrer is never set
            if (document.referrer === window.location.href) {
                if (type === 'workout') {
                    navigate('/workouts/' + workout.fields.slug);
                } else if (type === 'series') {
                    navigate('/series/' + series.fields.slug);
                }
            } else {
                // It's necessary to use sessionStorage instead of saving this value in the store
                // because on next up videos the page reloads and it would lose its value
                const initialUrl = window?.sessionStorage?.getItem('initialUrl');
                if (initialUrl) {
                    window?.sessionStorage?.removeItem('initialUrl');
                    navigate(initialUrl);
                    return;
                }
                window.location.href = document.referrer;
            }
        } else if (workout && type === 'workout') {
            navigate('/workouts/' + workout.fields.slug);
        } else if (series && type === 'series') {
            navigate('/series/' + series.fields.slug);
        } else {
            if (window) {
                window.history.back();
            }
        }
    }, [entitled, location, nextWorkout]);

    const initPlayerUI = useCallback(() => {
        if (!player.current || isMobile) {
            return;
        }
        /**
         * Get video.js components / component classes
         * @see https://docs.videojs.com/tutorial-components.html
         * @see https://docs.videojs.com/component
         */
        player.current.userActive(true);
        const Component = videojs.getComponent('Component');
        const Button = videojs.getComponent('Button');
        /**************************
         * PLAYER UI ELEMENTS
         **************************/
        /**
         * Remove 'BigPlayButton' that is on the top left by default
         */
        player.current.removeChild('BigPlayButton');
        player.current.removeChild('TextTrackSettings');
        player.current.on('keydown', vjsHandlers.onHotkeyDown);
        /**************************
         * CONTROL BAR UI ELEMENTS
         **************************/
        const controlBar = player.current.getChild('ControlBar');
        /**
         * Create Custom `SecondaryControls` Component for layout & positioning
         * that wraps the Volume Panel, CC Button, and FullScreen Toggler
         */
        const secondaryControlsComponent = videojs.extend(Component, {
            constructor: function () {
                Component.apply(this, arguments);
                this.addClass('vjs-secondary-controls');
            },
        });
        videojs.registerComponent('SecondaryControls', secondaryControlsComponent);
        const secondaryControls = player.current.addChild('SecondaryControls');

        // secondaryControls.addChild('SubsCapsButton');
        secondaryControls.addChild('FullscreenToggle');
        /**
         * Add a CloseButton to top right that triggers a 'close' event
         */
        const closeBtn = secondaryControls?.addChild('CloseButton');
        closeBtn.on('close', onClose);

        /**
         * Create Custom `PrimaryControls` Component for layout & positioning
         * that wraps the Rewind, Play/Pause, and FF Buttons
         */
        const primaryControlsComponent = videojs.extend(Component, {
            constructor: function () {
                Component.apply(this, arguments);
                this.addClass('vjs-primary-controls');
            },
        });
        videojs.registerComponent('PrimaryControls', primaryControlsComponent);
        const primaryControls = controlBar.addChild('PrimaryControls');
        const forwardButton = new Button(player.current, {
            clickHandler: seek.bind(this, 15),
        });
        forwardButton.addClass('vjs-forward-button');
        const rewindButton = new Button(player.current, {
            clickHandler: seek.bind(this, -15),
        });
        rewindButton.addClass('vjs-rewind-button');
        const volumePanelButton = new Button(player.current, {
            clickHandler: handleVolumeToggle,
        });
        volumePanelButton.addClass('vjs-toggle-volume-panel');
        primaryControls.addChild('PlayToggle');
        primaryControls.addChild(rewindButton);
        primaryControls.addChild(forwardButton);
        primaryControls.addChild(volumePanelButton);
        player.current.volume(volume);
    }, [player, isMobile]);

    const eventPayload = useCallback(
        (eventType: VideoEventType, currentTime?: number, data: any = {}) => {
            if (!player.current || !entitled) {
                return;
            }

            const duration = Math.floor(player.current.duration());
            const series_id = get(workout, 'fields.series.sys.id');
            const visitor_id = uuidv4();
            const user_id = userId;
            const video_id = workout?.fields?.video?.sys?.id;
            const { error } = data;

            let payload: EventPayload = {
                event_id: uuidv4(),
                event_type: eventType,
                event_timestamp: new Date().getTime(),
                user_id,
                visitor_id,
                video_id: video_id!,
                series_id,
                model_type: type,
                model_id: videoId,
                workout_id: videoId,
                user_agent: navigator.userAgent,
                time: Math.floor(currentTime || 0),
                duration,
                device: {
                    kind: navigator.platform,
                    os_name: navigator.appName,
                    os_version: navigator.appVersion,
                    type: navigator.vendor,
                },
                screen: {
                    height: parseInt(
                        getComputedStyle(player.current.el()).height.replace('px', '')
                    ),
                    orientation: 'LANDSCAPE',
                    width: parseInt(getComputedStyle(player.current.el()).width.replace('px', '')),
                },
                error,
            };

            if (history && history.status !== 'session_ended') {
                merge(payload, { session_id: history.session_id });
            }

            //This is only for Playlisting
            const showNextUpButton = duration - Math.floor(currentTime || 0) <= 10;
            nextVideoButtonState.current = showNextUpButton;
            setNextVideoButton(showNextUpButton);

            dispatch(Actions.analytics.track(payload));
        },
        [entitled, player, userId, workout]
    );

    /** Event Handlers */
    const vjsHandlers: array = {
        onHotkeyDown: useCallback(({ key }) => {
            switch (key) {
                case 'ArrowRight':
                    seek(15);
                    break;

                case 'ArrowLeft':
                    seek(-15);
                    break;

                case 'c':
                case 'C':
                    vjsHandlers.onToggleCaptions();
                    break;
            }
        }, []),

        onToggleCaptions: useCallback(() => {
            if (!textTrack.current || !toggleCaptionsButton.current) {
                return;
            }
            const currentMode = textTrack.current.mode;
            textTrack.current.mode = currentMode === 'showing' ? 'hidden' : 'showing';
            toggleCaptionsButton.current.toggleClass('active');
        }, [textTrack, toggleCaptionsButton]),

        onReady: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_READY, player.current!.currentTime());
            if (events.onReady) {
                events.onReady(player.current!);
            }
        }, [player]),

        onPlay: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_PLAY, player.current!.currentTime());
            if (events.onPlay) {
                events.onPlay(player.current!);
            }

            if (lastPlayingState.current === FeedStates.playing) {
                dispatch(Actions.music.play());
                musicPlayer.current?.play();
            }
            //        setMusicPlayerEngaged(false);
            setPlaying(true);
            setStartedPaused(false);
        }, [lastPlayingState, music, player]),

        onPause: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_PAUSE, player.current!.currentTime());
            if (events.onPause) {
                events.onPause(player.current!);
            }

            if (musicState.current) {
                lastPlayingState.current = musicState.current;
            }
            dispatch(Actions.music.pause());
            musicPlayer.current?.pause();
            setPlaying(false);
        }, [music, musicState, player, lastPlayingState]),

        onDispose: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_CLOSE, player.current!.currentTime());
            if (events.onClose) events.onClose(player.current!);
        }, [player]),

        onError: useCallback(
            (err: ErrorConstructor) => {
                eventPayload(VideoEventType.VIDEO_ERROR, player.current!.currentTime(), {
                    error: err,
                });
                if (events.onError) {
                    events.onError(player.current!, err);
                }
            },
            [player]
        ),

        onLoadedData: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_LOADED, player.current!.currentTime());
            if (player.current) {
                setTextTracks(player.current.textTracks());
                player.current.focus(); // required to capture hotkeys
            }

            if (events.onLoadedData) {
                events.onLoadedData(player.current!);
            }
        }, [player]),

        onEnded: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_END, player.current!.currentTime());
            if (events.onEnd) {
                events.onEnd(player.current!);
            }

            onClose();
        }, [player]),

        onTimeUpdate: useCallback(() => {
            if (
                !lastProgressEmittedAt.current ||
                new Date().getTime() >
                    lastProgressEmittedAt.current + PROGRESS_TRACKING_RESOLUTION_MS
            ) {
                const time = player.current!.currentTime();
                eventPayload(VideoEventType.VIDEO_PROGRESS, time);

                if (events.onProgress) {
                    events.onProgress(player.current!, time);
                }

                lastProgressEmittedAt.current = new Date().getTime();
            }
        }, [lastProgressEmittedAt, player]),

        onSeeking: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_SEEK, player.current!.currentTime());
            if (events.onSeeking) {
                events.onSeeking(player.current!);
            }
        }, [player]),

        onSeeked: useCallback(() => {
            if (events.onSeeked) {
                events.onSeeked(player.current!);
            }
        }, [player]),

        onVolumeChange: useCallback(() => {
            const volume = player.current!.volume();

            if (events.onVolumeChange) {
                events.onVolumeChange(player.current!, volume);
            }
        }, [player]),

        onUserActive: useCallback(() => {
            setUserActive(true);
        }, []),

        onUserInactive: useCallback(() => {
            if (!musicPlayerEngaged || initialPlayStarted) {
                setUserActive(false);
            }
        }, [musicPlayerEngaged, initialPlayStarted]),

        onCanPlayThrough: useCallback(() => {
            setInitialPlayStarted(true);
        }, []),

        onMute: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_MUTED, player.current!.currentTime());
            if (events.onMute) {
                events.onMute();
            }
        }, [player]),

        onUnmute: useCallback(() => {
            eventPayload(VideoEventType.VIDEO_UNMUTED, player.current!.currentTime());
            if (events.onUnmute) {
                events.onUnmute();
            }
        }, [player]),

        onUnmuteClick: useCallback(() => {
            player.current?.muted(false);
            const _volume = player.current?.volume() || 0.5;
            dispatch(Actions.video.setVolume(_volume));
            setStartedMuted(false);
        }, [music, player, volume]),

        onPlayClick: useCallback(() => {
            setStartedPaused(false);
            const playerPromise = player.current?.play();
            if (playerPromise) {
                playerPromise.then().catch((e) => {
                    console.error('Play onClick Error: ', e);
                    setStartedPaused(true);
                });
            }
        }, [player]),
    };

    const toggleVolumePanel = useCallback(() => {
        setIsVolumeOpen(true);
    }, []);

    const toggleMute = useCallback(() => {
        if (player.current) {
            const newMuteState = !player.current.muted();
            player.current.muted(newMuteState);
            const _volume = player.current?.volume() || 0.5;
            dispatch(Actions.video.setVolume(_volume));
            setMuted(newMuteState);
        }
    }, [player, muted]);

    const handleVolumeToggle = isMobile || isIOSDevice ? toggleMute : toggleVolumePanel;

    const hideVolumeControls = () => {
        setIsVolumeOpen(false);
    };

    const vjsEvents = [
        'Play',
        'Pause',
        'Ready',
        'Dispose',
        'Error',
        'LoadedData',
        'Ended',
        'TimeUpdate',
        'Seeking',
        'Seeked',
        'VolumeChange',
        'Mute',
        'Unmute',
        'UserActive',
        'UserInactive',
        'CanPlayThrough',
    ];

    const binderParams = useCallback((action: string) => {
        const event = action.toLowerCase();
        const callback = vjsHandlers[`on${action}`];
        return [event, callback];
    }, []);

    const registerEvents = useCallback(() => {
        const registerEvent = (action: string) => {
            player.current?.on(...binderParams(action));
        };

        if (player.current) {
            vjsEvents.forEach(registerEvent);
        }
    }, [player]);

    const unregisterEvents = useCallback(() => {
        const unregisterEvent = (action: string) => {
            player.current?.off(...binderParams(action));
        };

        if (player.current) {
            vjsEvents.forEach(unregisterEvent);
        }
    }, [player]);

    const containerClass = classNames('video-container', {
        open: true,
        muted,
        userActive,
        playing,
        initialPlayStarted,
        musicPlayerEngaged,
        isMobileLandscape,
    });

    return (
        <div className={containerClass}>
            <Helmet>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width viewport-fit=cover"
                />
            </Helmet>
            <div data-vjs-player>
                <video ref={videoNode} className="video-js" />
                <h3 className="title bold upper">{workout?.fields?.name}</h3>
                {isVolumeOpen && (
                    <VolumeControls onHide={hideVolumeControls} enableMusic={enableMusic} />
                )}
                {feedFmEnabled &&
                    player.current &&
                    !isMobile &&
                    !isIOSDevice &&
                    enableMusic &&
                    !isTalk && (
                    <FeedPlayer
                        childRef={(m) => (musicPlayer.current = m)}
                        video={player.current}
                        volumeClicked={isVolumeOpen}
                        setMusicPlayerEngaged={setMusicPlayerEngaged}
                    />
                )}
                {startedMuted && (
                    <button className="unmute upper" onClick={vjsHandlers.onUnmuteClick}>
                        TAP TO UNMUTE
                    </button>
                )}
                {startedPaused && !isMobile && (
                    <Icon
                        name={'pv-play-outline'}
                        className="play"
                        size={178}
                        onClick={vjsHandlers.onPlayClick}
                    />
                )}
                {nextVideoButton && !!nextWorkout && !!nextWorkout.workout && (
                    <NextUpButton nextWorkout={nextWorkout} />
                )}
            </div>
            {isMobile && !playing && (
                <div className="close-button-container">
                    <GeneralButton onClick={onClose} size="small" color="control" label="Close" />
                </div>
            )}
        </div>
    );
};

export default connector(memo(VideoPlayer));
