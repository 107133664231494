import { createSelector } from 'reselect';
import { IQuestionnaireState, IState } from '@pvolve/sdk/src/redux/selectors';
interface FavoritesAttributes {
    created_at: string;
    object: {
        instructors: string[];
        nutrition: string[];
        series: string[];
        workouts: string[];
    };
    updated_at: string;
    user_id: string;
    version: number;
}

export interface UserAttributes {
    created_at: string;
    object?: {
        birthday?: string;
        closed_captions?: boolean;
        email?: string;
        experience_count?: number;
        first_name?: string;
        gender?: string;
        is_experienced?: boolean;
        last_name?: string;
        phone?: string;
        username?: string;
        feed_fm_disabled?: boolean;
        feed_fm_playing?: boolean;
        partner?: Record<string, unknown>;
        wifi_only?: boolean;
    };
    updated_at: string;
    user_id: string;
    version: number;
}

const account = (state: any) => state.account;

const Selectors = {
    attrs: (state) => state.account.getAllAttrs?.data || {},
    favorites: (state) => state.account.favorites?.data || [],
    userCohort: (state) => state.account.userCohort || '',
    hasAnsweredOnboarding: (state) => state.account.getAllAttrs?.data?.hasAnsweredOnboarding,
    defaultStationId: (state) => state.account.getAllAttrs?.data?.object?.default_station_id,
    email: (state) => state.account.email,
    cx: (state) => state.account.cx,
    loadingTokenAttrs: (state) => state.account.loadingTokenAttrs,
};

const loading = createSelector(
    (state: any) => state.account.getAllAttrs,
    (state: any) => state.account.saveUserAttrs,
    (getUsers, saveUsers) => {
        const isLoading: boolean = !!(getUsers?.loading || saveUsers?.loading);
        return isLoading;
    }
);

const loaded = createSelector(
    (state: any) => state.account,
    (a) => !a.getAllAttrs.loading
);

const cohortLoaded = createSelector(
    (state: any) => state.account,
    (a) => !a.loadingCohort
);

const favorites = createSelector(Selectors.attrs, (attrs) => {
    const favoriteAttrs: FavoritesAttributes = attrs.favorites;
    return favoriteAttrs;
});

const userAttributes = createSelector(Selectors.attrs, (attrs) => {
    const userAttrs: UserAttributes = attrs?.user;
    return userAttrs;
});

const favoriteWorkouts = createSelector(
    favorites,
    (favorites) => favorites?.object?.workouts || []
);

const questionnaire = (state: IState): IQuestionnaireState => state.questionnaire;

const questionnaireSavedAnswers = createSelector(
    questionnaire,
    Selectors.attrs,
    (questionnaire: IQuestionnaireState, accountAttrs) => {
        const answers = accountAttrs?.questionnaires?.object?.questionnaires?.find(
            (e) => e.id === questionnaire?.questionnaireContent?.id
        );
        return answers;
    }
);

const questionnaireAnswersList = createSelector(Selectors.attrs, (accountAttrs) => {
    return accountAttrs?.questionnaires?.object?.questionnaires || [];
});

export default {
    ...Selectors,
    cohortLoaded,
    favoriteWorkouts,
    loaded,
    loading,
    userAttributes,
    questionnaireAnswersList,
    questionnaireSavedAnswers,
};
