import { SegmentActions } from '@pvolve/sdk/src/app/modules/segment/actions';
import { BasicActionFunction } from '@pvolve/sdk/src/app/utils';
import { LooseObject } from '@pvolve/sdk/src/app/modules/segment/types';
import { custom_track_events } from './events';
import { saga as segment } from 'src/state/segment/sagas';

export interface LocalSegmentActions {
    segment: {
        track: {
            [event in keyof typeof custom_track_events]: BasicActionFunction<LooseObject | void>;
        };
    };
}

export const SegmentTrackActions = (segment.segmentActions as unknown) as LocalSegmentActions &
    SegmentActions;
