import { createActions } from 'redux-actions';
import {
  BasicActionFunction,
  DefaultRoutine,
  DefaultRoutineActions,
} from '../../utils';
import { IQuestionnaireAnswersState } from '../../../redux/selectors';

interface AuthActions {
  auth: {
    authenticated: BasicActionFunction;
    unauthenticated: BasicActionFunction;
    tokensReady: BasicActionFunction;
    checkEmail: DefaultRoutineActions;
    changePassword: DefaultRoutineActions<{
      oldPassword: string;
      newPassword: string;
    }>;
    forgotPassword: DefaultRoutineActions;
    resendConfirmationCode: DefaultRoutineActions;
    incrementOnboarding: BasicActionFunction;
    load: DefaultRoutineActions;
    login: DefaultRoutineActions<{
      email: string;
      password: string;
      logInAsTokens?: {
        id_token: string;
        access_token: string | undefined;
        refresh_token: string;
        expires_in: number;
      };
    }>;
    logout: DefaultRoutineActions;
    processOauthCode: DefaultRoutineActions<{
      code: string;
      redirectUri: string;
    }>;
    refresh: DefaultRoutineActions;
    deactivate: DefaultRoutineActions;
    resetPassword: DefaultRoutineActions;
    signup: DefaultRoutineActions;
    update: DefaultRoutineActions;
    federated: DefaultRoutineActions;
    updateSettings: DefaultRoutineActions;
    acceptWaiver: DefaultRoutineActions;
    confirmForgotPassword: DefaultRoutineActions;
    clearSignupErrors: DefaultRoutineActions;
    changeDefaultStation: DefaultRoutineActions;
    incrementExperience: DefaultRoutineActions;
    watchForEntitlements: DefaultRoutineActions;
    updateUserAttributes: DefaultRoutineActions;
    submitQuestionnaireAnswers: DefaultRoutineActions<IQuestionnaireAnswersState>;
    getTokenAttributes: DefaultRoutineActions;
    getAllAttrs: DefaultRoutineActions;
  };
}

const Actions = <AuthActions>(<unknown>createActions({
  AUTH: {
    AUTHENTICATED: undefined,
    UNAUTHENTICATED: undefined,
    TOKENS_READY: undefined,
    CHECK_EMAIL: DefaultRoutine,
    CHANGE_PASSWORD: DefaultRoutine,
    FORGOT_PASSWORD: DefaultRoutine,
    RESEND_CONFIRMATION_CODE: DefaultRoutine,
    INCREMENT_ONBOARDING: undefined,
    LOAD: DefaultRoutine,
    LOGIN: DefaultRoutine,
    LOGOUT: DefaultRoutine,
    REFRESH: DefaultRoutine,
    DEACTIVATE: DefaultRoutine,
    RESET_PASSWORD: DefaultRoutine,
    SIGNUP: DefaultRoutine,
    UPDATE: DefaultRoutine,
    FEDERATED: DefaultRoutine,
    UPDATE_SETTINGS: DefaultRoutine,
    ACCEPT_WAIVER: DefaultRoutine,
    CONFIRM_FORGOT_PASSWORD: DefaultRoutine,
    CLEAR_SIGNUP_ERRORS: DefaultRoutine,
    CHANGE_DEFAULT_STATION: DefaultRoutine,
    INCREMENT_EXPERIENCE: DefaultRoutine,
    PROCESS_OAUTH_CODE: DefaultRoutine,
    WATCH_FOR_ENTITLEMENTS: DefaultRoutine,
    UPDATE_USER_ATTRIBUTES: DefaultRoutine,
    SUBMIT_QUESTIONNAIRE_ANSWERS: DefaultRoutine,
    GET_TOKEN_ATTRIBUTES: DefaultRoutine,
    GET_ALL_ATTRS: DefaultRoutine,
  },
}));

export const authSuccessActions = [
  Actions.auth.load.success,
  Actions.auth.login.success,
  Actions.auth.federated.success,
  Actions.auth.signup.success,
];

export default Actions;
