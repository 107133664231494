import { BootSequenceLifecycleState, BootState } from './actions';
import { createSelector } from 'reselect';

const root = (state: { boot: BootState }) => state.boot;
const app = createSelector(root, root => root.app);
const appReady = createSelector(root, root => root.app?.start?.state === BootSequenceLifecycleState.end);
const user = createSelector(root, root => root.user);
const system = createSelector(root, root => root.system);

export default {
  root,
  system,
  app,
  appReady,
  user,
};
