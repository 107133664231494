export const PHASES: {
  MENSTRUAL: string
  FOLLICULAR: string
  OVULATORY: string
  LUTEAL: string
} = {
  MENSTRUAL: "menstrual",
  FOLLICULAR: "follicular",
  OVULATORY: "ovulatory",
  LUTEAL: "luteal",
};
