import { Country, State, City } from 'country-state-city';
import { compact } from 'lodash';

export const WEEK_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const CALENDAR_TYPE = 'ISO 8601';

export const CALENDAR_LOCALE = 'en-US';

const rejectStates = [
    'AS',
    'UM-81',
    'GU',
    'PR',
    'UM-84',
    'UM-86',
    'UM-67',
    'UM-89',
    'UM-71',
    'UM-76',
    'MP',
    'UM-95',
    'UM',
    'VI',
    'UM-79',
];

interface CountryStateTypes {
    isoCode: string;
    name: string;
}

const conformRawData = ({ isoCode, name }: CountryStateTypes) => ({
    key: isoCode,
    value: isoCode,
    text: name,
});

const conformRawStates = ({ isoCode, name }: CountryStateTypes) =>
    !rejectStates.includes(isoCode) && {
        key: isoCode,
        value: isoCode,
        text: name,
    };

export const STATES = compact(State.getStatesOfCountry('US').map(conformRawStates));
export const COUNTRIES = Country.getAllCountries().map(conformRawData);
