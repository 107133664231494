import { noop } from 'lodash';
import React, { useState } from 'react';

// todo rename this file LayoutContext.tsx

export interface PartnerLogoType {
    width: number;
    height: number;
    src: string;
    alt: string;
}

export interface LayoutData {
    layout: 'plain' | 'none';
    headerBgColor: string;
    partnerLogo?: PartnerLogoType;
}

interface LayoutContextState {
    layoutData?: LayoutData;
    setLayoutData: React.Dispatch<React.SetStateAction<LayoutData | undefined>>;
}

const defaultContextValue: LayoutContextState = {
    layoutData: undefined,
    setLayoutData: noop,
};

export const LayoutContext = React.createContext(defaultContextValue);

export const LayoutProvider: React.FC = ({ children }) => {
    const [layoutData, setLayoutData] = useState<LayoutData>();
    return (
        <LayoutContext.Provider value={{ layoutData, setLayoutData }}>
            {children}
        </LayoutContext.Provider>
    );
};
