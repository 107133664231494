import { takeEvery, select } from 'redux-saga/effects';
import { routineSaga } from '../../utils/sagas';
import Actions from './actions'
import Pvolve from '@pvolve/sdk';

const { seriesCategory: { getSeriesCategory } } = Actions

class SeriesCategory {
    *init() {
        yield takeEvery(getSeriesCategory.trigger, saga.getSeriesCategory)
    }

    getSeriesCategory = routineSaga({
        routine: getSeriesCategory,
        *request() {
            const userCohort: string = yield select(state => !!state.account ? state.account.userCohort : state.auth.user?.user_cohort);
            const options = { tags: `cohort${userCohort}`, operator: 'all' };
            const response = yield Pvolve.api.contentV2.content.list('series_categories', options);
            return response?.items
        }
    })
}

export const saga = new SeriesCategory()

export default function* combined() {
    yield takeEvery(getSeriesCategory.trigger, saga.getSeriesCategory)
}
