import { useState, useEffect, useCallback } from 'react';
import { UAParser } from 'ua-parser-js';

/**
  Supported Breakpoints + Sizes:
    - Desktop - 1440px and bigger
    - Tablet (Landscape) - 1439px to 1024px
    - Tablet (Portrait) - 1023px to 600px
    - Mobile - 599px and smaller

  Tablet (Portrait) to Mobile are size (not structural) differences.
  Desktop to tablet (Landscape) are size (not structural) differences
**/

const xxl = 1440; // DesktopBreakpoint         (>= 1440)
const lg = 1024; // TabletLandscapeBreakpoint (1024 - 1439)
const sm = 600; // TabletPortraitBreakpoint  (600 - 1023)
// Mobile                    (< 600)

export enum Breakpoints {
    sm = 'sm',
    lg = 'lg',
    xxl = 'xxl',
}

interface Breakpoint {
    [key: string]: number;
}

const BREAKPOINTS: Breakpoint = {
    sm: sm,
    lg: lg,
    xxl: xxl,
};

export const breakpointDown = (bp: Breakpoints): boolean => BREAKPOINTS[bp] > window.innerWidth;
export const breakpointUp = (bp: Breakpoints): boolean => BREAKPOINTS[bp] <= window.innerWidth;

export enum Directions {
    down = 'down',
    up = 'up',
}

export const useBreakpoints = (screenSize: Breakpoints, direction: Directions) => {
    const getBreakpoint = useCallback(() => {
        switch (direction) {
            case Directions.up:
                return breakpointUp(screenSize);

            case Directions.down:
                return breakpointDown(screenSize);

            default:
                return false;
        }
    }, [direction, screenSize]);

    const [state, setState] = useState(getBreakpoint());

    const updateBreakpoint = useCallback(() => {
        setState(getBreakpoint());
    }, [getBreakpoint]);

    useEffect(() => {
        if (window) {
            window.addEventListener('resize', updateBreakpoint);
            return () => window.removeEventListener('resize', updateBreakpoint);
        }
    }, [updateBreakpoint]);

    return state;
};

export const useScreenWidth = () => {
    const [state, setState] = useState(window.innerWidth);

    const updateScreenWidth = useCallback(() => {
        setState(window.innerWidth);
    }, [window.innerWidth]);

    useEffect(() => {
        if (window) {
            window.addEventListener('resize', updateScreenWidth);
            return () => window.removeEventListener('resize', updateScreenWidth);
        }
    }, [updateScreenWidth]);

    if (state >= BREAKPOINTS.xxl) {
        return Breakpoints.xxl;
    } else if (state >= BREAKPOINTS.lg) {
        return Breakpoints.lg;
    } else {
        return Breakpoints.sm;
    }
};

export const useDeviceDetect = () => {
    const [device, setDevice] = useState<UAParser.IDevice>();

    useEffect(() => {
        const uaString = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const parser = new UAParser(uaString);
        setDevice(parser.getDevice());
    }, []);

    /**
     * Possible 'device.type':
     *  undefined, console, mobile, tablet, smarttv, wearable, embedded
     */
    return device;
};

export const isIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
