import auth from '@pvolve/sdk/src/app/modules/auth/selectors';
import favorites from '@pvolve/sdk/src/app/modules/favorites/selectors';
import phases from '@pvolve/sdk/src/app/modules/phaseFunction/selectors';
import subscriptions from '@pvolve/sdk/src/app/modules/subscriptions/selectors';
import account from 'src/state/account/selectors';
import web from 'src/state/web/selectors';
import config from '@pvolve/sdk/src/app/modules/config/selectors';
import music from '@pvolve/sdk/src/app/modules/music/selectors';
import orders from './orders/selectors';
import questionnaire from '@pvolve/sdk/src/app/modules/questionnaire/selectors';
import workoutCategories from '@pvolve/sdk/src/app/modules/workoutCategories/selectors';
import partner from './partner/selectors';
import promos from './promos/selectors';
import video from '@pvolve/sdk/src/app/modules/video/selectors';
import flags from '@pvolve/sdk/src/app/modules/featureFlag/selectors';
import series from '@pvolve/sdk/src/app/modules/series/selectors';
import seriesCategory from '@pvolve/sdk/src/app/modules/seriesCategory/selectors';
import workouts from '@pvolve/sdk/src/app/modules/workouts/selectors';
import challenge from '@pvolve/sdk/src/app/modules/challenge/selectors';

export default {
    auth,
    account,
    favorites,
    phases,
    subscriptions,
    config,
    music,
    orders,
    partner,
    promos,
    video,
    web,
    flags,
    questionnaire,
    workoutCategories,
    series,
    seriesCategory,
    workouts,
    challenge,
};
