import { createSelector } from 'reselect';
import { IState } from '../../../redux/selectors';

const Selectors = {
  phaseAndFunction: (state: IState) => state?.flags?.phaseAndFunction,
};

const phaseAndFunction = createSelector(
  Selectors.phaseAndFunction,
  data => data || {},
);

export default {
  phaseAndFunction,
};
