import {
    LooseObject,
    SubscriptionPlanUpdateEventPayload,
    SubscriptionPlanUpdateProperties,
} from '../types';
import { stringOrUndefined } from '../utils/index';
import { track_events } from '../events';

const {
    appSubscriptionPageViewed,
    appSubscriptionCancelStepViewed,
    appSubscriptionCancellationSubmitted,
    appSubscriptionUpdatePageViewed,
    appSubscriptionUpdateConfirmationModalViewed,
    appSubscriptionUpdateConfirmationModalAccepted,
    appSubscriptionPlanUpdateSubmitted,
    accountLoggedIn,
} = track_events;

const helpers = {
    session_step_count: 0,
    setStep() {
        this.session_step_count = 0;
    },
    increaseStep() {
        this.session_step_count++;
    },
};

export default {
    events: {
        [appSubscriptionPageViewed]: (data: LooseObject) => {
            helpers.setStep();
            return {};
        },
        [appSubscriptionCancelStepViewed]: (data: LooseObject) => {
            helpers.increaseStep();
            return { step: helpers.session_step_count };
        },
        [appSubscriptionCancellationSubmitted]: (data: LooseObject) => ({}),
        [appSubscriptionUpdatePageViewed]: (data: LooseObject) => ({}),
        [appSubscriptionUpdateConfirmationModalViewed]: (
            data: LooseObject
        ) => ({}),
        [appSubscriptionUpdateConfirmationModalAccepted]: (
            data: LooseObject
        ) => ({}),
        [appSubscriptionPlanUpdateSubmitted]: (
            data: SubscriptionPlanUpdateEventPayload
        ): SubscriptionPlanUpdateProperties => {
            return {
                coupon: stringOrUndefined(data?.order_details?.coupon),
                discount: data?.order_details?.discount,
                sku: data?.subscription_details?.sku,
                subscription_platform: 'web',
                subscription_price: data?.subscription_details?.cost.currency_amount,
                subscription_term: data?.subscription_details?.subscription_details.interval?.toString(),
                subtotal: data?.order_details?.subtotal,
            };
        },
        [accountLoggedIn]: (data: LooseObject) => ({}),
    },
};
