import React from 'react';
/* import { isEmpty } from 'lodash'; */
/* import placeholder from 'src/assets/placeholders/placeholder-square.png'; removed this since it caused an issue when pushing changes  */
import Colors, { ColorNames } from 'src/utils/colors';
import * as Styles from 'src/styles/hero-with-image.module.scss';

interface HeroWithImageProps {
    image?: string;
    bgColor?: ColorNames;
    children?: React.ReactNode;
    alignment?: 'right' | 'left';
    isTabletPortrait: boolean;
}

const HeroWithImage = ({
    alignment = 'right',
    bgColor = 'lightMint',
    children,
    image,
    isTabletPortrait,
}: HeroWithImageProps) => {
    /* const backgroundImage = { backgroundImage: `url(${isEmpty(image) ? placeholder : image})` }; removed this since it caused an issue when pushing changes */
    const backgroundImage = { backgroundImage: `url(${image})` };
    const backgroundColor = {
        backgroundImage: `linear-gradient(white 20%, ${Colors[bgColor]} 20% 80%, white 20%)`,
    };
    const imageClassNames =
        alignment === 'right'
            ? 'justifyContent-flex-end'
            : 'justifyContent-flex-start'; /* still needs to be tested for left alignment, right works fine */

    return (
        <div>
            <div
                className={`justifyContent-space-between alignItems--center ${Styles.heroWrapper}`}
                style={backgroundColor}
            >
                {!isTabletPortrait && <div className={`${Styles.heroChildren}`}>{children}</div>}
                <div className={`${imageClassNames} ${Styles.heroImageWrapper}`}>
                    <div style={backgroundImage} />
                </div>
            </div>
            {isTabletPortrait && <div className={`${Styles.heroChildren}`}>{children}</div>}
        </div>
    );
};

export default HeroWithImage;
