import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { format, parseISO } from 'date-fns';
import { ResolvedWorkout } from '@pvolve/sdk/src/app/modules/content/selectors';

interface LastCompletedProps {
    full?: boolean;
    label?: string;
    className?: string;
    workout: ResolvedWorkout;
}

const connector = connect((state: IState) => ({
    history: PvolveSelectors.workouts.completedHistoryById(state),
}));

const LastCompleted = ({
    dispatch,
    full = false,
    history,
    workout,
    label,
    className = '',
}: LastCompletedProps & ConnectedProps<typeof connector>) => {
    if (!history) {
        return null;
    }
    const workoutHistory = history[workout.contentful_id];

    const timestamp = workoutHistory?.started_at;
    const yearFormat = full ? 'yyyy' : 'yy';
    const completed_at = timestamp ? format(parseISO(timestamp), `M.d.${yearFormat}`) : null;
    const pretty_date = label ? (
        <p className={`${className} accent bold`}>
            {label} {completed_at}
        </p>
    ) : (
        <p>{completed_at}</p>
    );

    return completed_at && workoutHistory?.is_completed ? pretty_date : null;
};

export default connector(LastCompleted);
