import React from 'react';
import { Accordion } from 'semantic-ui-react';

import { footerLink } from 'src/styles/footer.module.scss';

const Panel = ({ data, index }) => {
    const footerItem = data[index];

    const renderLinks = ({ title, href }, linkIndex) => (
        <a
            className={footerLink}
            href={href}
            rel="noreferrer"
            target={footerItem.title === 'social' ? '_blank' : '_self'}
            key={`title-${linkIndex}`}
        >
            {title}
        </a>
    );

    return <div>{footerItem.items.map(renderLinks)}</div>;
};

const FooterMobile = ({ data }) => {
    const footerItems = data.map(({ title, items }, index) => ({
        key: `panel-${index}`,
        items,
        title: { content: title, icon: '' },
        content: { content: <Panel index={index} data={data} /> },
    }));

    return <Accordion exclusive={false} fluid panels={footerItems} />;
};

export default FooterMobile;
