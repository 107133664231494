import React from 'react';
import classNames from 'classnames';
import { first } from 'lodash';

import { Icon } from 'src/components/shared';
import Colors from '../../utils/colors';

import * as Styles from 'src/styles/details-card.module.scss';

interface CardProps {
    icon?: string;
    numTimesCompleted?: Number;
    recentlyDate?: string;
    text?: string;
    title: string;
    customDescription?: boolean;
}

const DetailsCard = ({
    icon,
    numTimesCompleted = 0,
    recentlyDate,
    text,
    title,
    customDescription,
}: CardProps) => {
    const numberIconClasses = classNames('bold', Styles.icon);

    let cardTitle = title;
    let cardText: string | undefined = text;

    let iconComponent = () =>
        icon && (
            <Icon
                className={Styles.icon}
                name={icon}
                style={{ borderColor: Colors.black }}
                color={Colors.black}
                size={16}
            />
        );

    if (title === 'times_completed') {
        cardTitle = 'times completed';
        cardText = recentlyDate ? `most recently ${recentlyDate}` : undefined;
        iconComponent = () => <p className={numberIconClasses}>{numTimesCompleted}</p>;
    } else if (title === 'series_completed') {
        const completed = !!recentlyDate;
        const customTitle = customDescription ? 'cycles ' : '';
        const customNotCompleted = customDescription ? 'no' : 'not';

        cardTitle = completed
            ? `${customTitle}completed`
            : `${customNotCompleted} ${customTitle}completed`;
        cardText = recentlyDate ? `most recently ${recentlyDate}` : undefined;

        iconComponent = () => {
            return completed ? (
                <Icon className={Styles.icon} name="pv-checkmark" size={16} />
            ) : (
                <p className={numberIconClasses}>{''}</p>
            );
        };
    }

    return (
        <div className={Styles.detailsCard}>
            <div className={Styles.iconWrapper}>{iconComponent()}</div>
            <div>
                <h5 className="accent bold">{cardTitle}</h5>
                {cardText && <p className="p2">{cardText}</p>}
            </div>
        </div>
    );
};

export default DetailsCard;
