import React, { useEffect } from 'react';

//Hooks
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Components
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

//Types
import { ChangeEvent, FormEvent } from 'react';

//Styles
import Styled from './name-collection-modal.styled';

//Assets
import HeaderIcon from 'src/assets/hand-waving.svg';

//Utils
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import { isEmpty } from 'lodash';

// Constants
import { DEFAULT_NAME_VALUES } from 'src/constants/name-collection-constants';

interface DialogProps extends MuiDialogProps {
    open: boolean;
    setOpen: (prop: boolean) => void;
}

const NameCollectionModal = ({ open, setOpen }: DialogProps) => {
    const dispatch = useDispatch();
    const userAttributes = useSelector(Selectors.account.userAttributes);
    const user = useSelector(Selectors.auth.user);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
    });
    const [showError, setShowError] = useState(false);
    const appUrl: string = useSelector(Selectors.config.appUrl);

    const isInvalidValue = (stateValue: string) => {
        const valuesList = [DEFAULT_NAME_VALUES.FIRST_NAME, DEFAULT_NAME_VALUES.LAST_NAME];
        const result = valuesList.filter((value) => value === stateValue);

        return result;
    };
    const invalidValues = isInvalidValue(formData.firstName) || isInvalidValue(formData.lastName);

    const saveUserAttributes = useCallback(() => {
        const newVersion = userAttributes?.version + 1;
        dispatch(
            Actions.account.saveUserAttrs.trigger({
                firstName: formData.firstName,
                lastName: formData.lastName,
                version: newVersion,
            })
        );
    }, [dispatch, formData.firstName, formData.lastName, userAttributes?.version]);

    useEffect(() => {
        if (user && open) {
            dispatch(
                Actions.segment.identify({
                    userId: user.email,
                })
            );
            dispatch(
                Actions.segment.track.modalViewed({
                    source: `${appUrl}`,
                    modal_name: 'Name Collection',
                    modal_detail: 'Name Collection',
                })
            );
        }
    }, [appUrl, dispatch, open, user]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!formData.firstName || !formData.lastName || !isEmpty(invalidValues)) {
            setShowError(true);
            return;
        }

        if (user) {
            dispatch(
                Actions.segment.identify({
                    userId: user.email,
                    traits: {
                        firstName: `${formData.firstName}`,
                        lastName: `${formData.lastName}`,
                    },
                })
            );
            dispatch(
                Actions.segment.track.accountDetailsUpdated({
                    firstName: `${formData.firstName}`,
                    lastName: `${formData.lastName}`,
                })
            );
            dispatch(
                Actions.segment.track.modalSubmitted({
                    source: `${appUrl}`,
                    modal_name: 'Name Collection',
                    modal_detail: 'Name Collection',
                })
            );
        }

        saveUserAttributes();
        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setShowError(false);
        const { name, value } = event.target;
        setFormData((formData) => {
            return {
                ...formData,
                [name]: value,
            };
        });
    };

    return (
        <Styled.Dialog open={open} maxWidth="xs" scroll="body">
            <Styled.DialogHeader>
                <img alt="Hand Waving" height={32} src={HeaderIcon} width={32} />
                <Styled.DialogTitle variant="h6">Hey there!</Styled.DialogTitle>
            </Styled.DialogHeader>
            <Styled.DialogContent>
                <Styled.Content variant="subtitle2">
                    We realized we don’t know your name yet. Please tell us so that we can
                    personalize your experience!
                </Styled.Content>

                <Styled.Form onSubmit={handleSubmit}>
                    <Styled.TextField
                        hiddenLabel
                        name="firstName"
                        placeholder="First name"
                        variant="outlined"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    <Styled.TextField
                        hiddenLabel
                        name="lastName"
                        placeholder="Last name"
                        variant="outlined"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    {showError && (
                        <Styled.Alert severity="error">Please complete to continue</Styled.Alert>
                    )}
                    <Styled.Button type="submit" variant="contained" size="small">
                        Continue
                    </Styled.Button>
                </Styled.Form>
            </Styled.DialogContent>
        </Styled.Dialog>
    );
};

export default NameCollectionModal;
