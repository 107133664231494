// extracted by mini-css-extract-plugin
export const mini = "split-module--mini--23I4m";
export const tiny = "split-module--tiny--1gxwr";
export const small = "split-module--small--MqycG";
export const medium = "split-module--medium--34k64";
export const p3 = "split-module--p3--NDPaf";
export const large = "split-module--large--2BiQs";
export const p2 = "split-module--p2--3H_6U";
export const l1 = "split-module--l1--3chOl";
export const label = "split-module--label--1gZvQ";
export const big = "split-module--big--AR2As";
export const p1 = "split-module--p1--1t3lX";
export const huge = "split-module--huge--dIVJW";
export const p0 = "split-module--p0--2h77b";
export const huge36 = "split-module--huge36--3stiL";
export const huge52 = "split-module--huge52--3ag2l";
export const massive = "split-module--massive--gw5dr";
export const h1 = "split-module--h1--38TqA";
export const h2 = "split-module--h2--_CMHT";
export const h3 = "split-module--h3--12E3Z";
export const h4 = "split-module--h4--3g0mi";
export const h5 = "split-module--h5--1_CWv";
export const h6 = "split-module--h6--2HRyz";
export const accent = "split-module--accent--3KWQO";
export const accenth5 = "split-module--accenth5--3KBnd";
export const accenth6 = "split-module--accenth6--Vhmqr";
export const upper = "split-module--upper--1AaLj";
export const lower = "split-module--lower--HhgPJ";
export const underline = "split-module--underline--3VBm4";
export const bold = "split-module--bold--2yT8F";
export const semibold = "split-module--semibold--XnHS6";
export const normal = "split-module--normal--28b-m";
export const stacked = "split-module--stacked--2qlYn";
export const link = "split-module--link--1YLM1";
export const textLinks = "split-module--text-links--8A_xW";
export const border = "split-module--border--Mf7me";
export const content = "split-module--content--2qSC5";