import { get, set } from 'lodash';
import { createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { BasicActionFunction, handleActionsImmer } from '../../../app/utils';
import { BootSequenceLifecycleActions, Priority } from './BootSequence';

export enum BootSequenceLifecycleState {
  begin = 'begin',
  end = 'end',
}

export interface BootState {
  [sequenceName: string]: {
    [key in BootSequenceLifecycleActions]: {
      state: BootSequenceLifecycleState,
      priority: number,
    }
  }
}

export const initialState: BootState = {
};

export interface BootActionParams {
  name: string,
  action: BootSequenceLifecycleActions,
  priority?: number,
  halted?: boolean,
}

export interface BootSequenceActions {
  boot: {
    begin:  BasicActionFunction<BootActionParams>,
    end:    BasicActionFunction<BootActionParams>,
    runPriority: BasicActionFunction<BootActionParams>,
  }
}

export const Actions = <BootSequenceActions><unknown>createActions({
  BOOT: {
    BEGIN: undefined,
    END:   undefined,
    RUN_PRIORITY: undefined,
  }
});


export const Reducer = handleActionsImmer<BootState>({
  [Actions.boot.begin]: (draft: BootState, { name, action }: BootActionParams) => {
    set(draft, `${name}.${action}.state`, 'begin');
  },
  [Actions.boot.end]: (draft: BootState, { name, action }: BootActionParams) => {
    set(draft, `${name}.${action}.state`, 'end');
  },
  [Actions.boot.runPriority]: (draft: BootState, { name, action, priority }: BootActionParams) => {
    set(draft, `${name}.${action}.priority`, priority);
  },
}, initialState);


const root = (state: { boot: BootState }) => state.boot;

const loading = createSelector(
  root,
  (boot) => {
    const state = boot.user?.start?.state;
    const priority = boot.user?.start?.priority;
    return state === BootSequenceLifecycleState.begin && (
      priority === Priority.HIGH
    );
  }
);

export const Selectors = {
  root,
  loading,
};


export default Actions;

// this resolves whether a boot name.action completed already or in the past
const bootEndedBefore = {
};

export function bootEnded(name, action) {
  return ({ type, payload = {} }) => {
    const key = `${name}.${action}`;
    if (!bootEndedBefore[key]) {
      const nameFromAction = get(payload, 'name');
      const actionFromAction = get(payload, 'action');

      if (nameFromAction === name
       && actionFromAction === action
       && type === Actions.boot.end.toString()) {
        bootEndedBefore[key] = true;
      }

    }
    return bootEndedBefore[key];
  };
}