import { find, max, min, map, compact, isNull } from 'lodash';
import { Product } from '../../../SubscriptionService';
import { Discount } from '../../../CommerceService';
import { priceDescription, pricePerMonthEstimate } from './utils';

// Helper class to calculate savings relative to the highest-priced
// product in a list and price changes based on discount codes

export interface BaseProductOffer<ProductType> {
    product: ProductType;
    savings: number;
    discountedSavings: number;
    monthlyPrice: number;
    discountedMonthlyPrice: number;
    billingPrice: string;
    discountedBillingPrice: number;
    hasDiscount: boolean;
}

export interface ProductOffer extends BaseProductOffer<Product> {};

class ProductGroup {
    products: Product[];
    discount?: Discount;

    constructor(products: Product[], discount?: Discount) {
        this.products = compact(products);
        this.discount = discount;
    }

    get discountDescription(): string {
        if (!this.discount) { return null; }

        let discountAmount;

        if (this.discount.type === 'percentage') {
            discountAmount = `${this.discount.percentage}%`;
        } else {
            const amount = this.discount.amount / 100;
            discountAmount = amount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        }


        return `${discountAmount} off`;
    }

    productDiscount(product: Product): number {
        const { discount } = this;

        if (discount) {
            const meetsSkuRestrictions = isNull(discount.entitled_skus) || (Array.isArray(discount.entitled_skus) && discount.entitled_skus.includes(product.sku));
            const meetsMinAmountRestrictions = product?.cost?.unit_amount >= discount?.minimum_amount;

            if (meetsSkuRestrictions && meetsMinAmountRestrictions) {
                if (discount.type === 'percentage') {
                    return product.cost.unit_amount * discount.percentage / 100;
                } else {
                    // Don't return a discount that's higher than the price
                    return min([discount.amount, product.cost.unit_amount]);
                }
            }
        }

        return 0;
    }

    normalizedPrice = (product: Product, discount_amount?: number): number => {
        let amount = product.cost.unit_amount;
        if (discount_amount) {
            amount = amount - discount_amount;
        }

        const { subscription_details } = product;
        if (!subscription_details) {
          return 0;
        }
        const { interval_type, interval } = subscription_details;

        if (interval_type === 'day') {
            return amount * 365 / interval;
        } else if (interval_type === 'week') {
            return amount * 52 / interval;
        } else if (interval_type === 'year') {
            return amount / interval;
        } else {
            return amount * 12 / interval;
        }
    }

    // Find the most expensive per-year price in the list
    get highestNormalizedPrice(): number {
        return max(map(this.products, product => this.normalizedPrice(product))) || 0;
    }

    discountedNormalizedPrice = (product: Product) => {
        const discount = this.productDiscount(product);
        return this.normalizedPrice(product, discount);
    }

    calculateSavings = (product: Product) => {
        const base = this.highestNormalizedPrice;
        const price = this.normalizedPrice(product);
        if (base > price) {
            return Math.round((1 - price / base) * 100);
        } else {
            return 0;
        }
    }

    calculateDiscountedSavings = (product: Product) => {
        const base = this.highestNormalizedPrice;
        const price = this.discountedNormalizedPrice(product);
        const savings = Math.round((1 - price / base) * 100);
        if (base > price && savings > this.calculateSavings(product)) {
            return Math.round((1 - price / base) * 100);
        } else {
            return 0;
        }
    }

    calculateDiscountedMonthlyPrice = product => {
        const discount = this.productDiscount(product);
        return pricePerMonthEstimate(product, discount);
    }

    calculateDiscountedBillingPrice = product => {
        const discount = this.productDiscount(product);
        const price = product.cost.unit_amount - discount;
        return Math.round(price) / 100;
    }

    get offers(): ProductOffer[] {
        return map(this.products, product => {
            const discount = this.productDiscount(product);
            return {
                product,
                savings: this.calculateSavings(product),
                discountedSavings: this.calculateDiscountedSavings(product),
                monthlyPrice: pricePerMonthEstimate(product),
                discountedMonthlyPrice: this.calculateDiscountedMonthlyPrice(product),
                billingPrice: priceDescription(product),
                discountedBillingPrice: this.calculateDiscountedBillingPrice(product),
                hasDiscount: discount > 0,
            }
        })
    }
}

export default ProductGroup;
