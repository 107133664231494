export interface IFeedStation {
  /**
   * @property
   * The station id will change between different sessions - and should not be used,
   * for instance, to remember a user's favorite station. Instead, you should use the station's
   * name or a value in the options object.
   */
  id: string;

  /**
   * @property
   * refers to the last time the contents of a station with this name were updated.
   */
  last_updated: string;

  /**
   * @property
   * The name of the station
   */
  name: string;

  on_demand: number;

  /**
   * @property
   * object can be any arbitrary JSON object that you provide to feed.fm. We suggest you use this for storing foreign keys or values that you wish to use to search for particular stations. Some examples: storing a 'genre' or your own 'id' or numeric BPM range.
   */
  options: {
      background_image_url: string;
      image: string;
      'mixtape-bg': string;
      'mixtape-thumb': string;
      subhead: string;
      subheader: string;
  };
  pre_gain: number;
}

export enum FeedEvent {
    /**
     * Feed.fm doesn't believe that the client is located in a region for which music is licensed for playback.
     * When this event is emitted, you can assume the player will no longer function.
     * This event is only emitted once, shortly after construction of the Player instance,
     * so register for this event early!
     */
    MUSIC_UNAVAILABLE = 'music-unavailable',

    /**
     * This is sent when playback of the current song is complete or is aborted
     * (due to a skip, for instance).
     */
    PLAY_COMPLETED = 'play-completed',

    /**
     * This is sent when playback of the current song is paused.
     */
    PLAY_PAUSED = 'play-paused',

    /**
     * This is sent when playback of the current song is resumed after pausing.
     */
    PLAY_RESUMED = 'play-resumed',

    /**
     * This is sent when playback of a specific song has started.
     * Details of the song that has just started are passed as an argument and are
     * described in "Station and Play objects" below.
     */
    PLAY_STARTED = 'play-started',

    /**
     * This is sent when the 'stop()' method is called in Player
     */
    PLAY_STOPPED = 'play-stopped',

    /**
     * If there are no more songs that a user can listen to, this event is triggered.
     */
    PLAYS_EXHAUSTED = 'plays-exhausted',

    /**
     * If a call was made to skip() to skip the current song, but the server denied it
     * (due to skip restrctions), then this event will be emitted.
     */
    SKIP_DENIED = 'skip-denied',

    /**
     * This event provides the list of stations available to the player, as provided by the server.
     * It is triggered before any music starts, after the player has contacted feed.fm.
     * See the `Station` interface for details on what the station object looks like.
     */
    STATIONS = 'stations',
}

export enum FeedStates {
    /**
     * the player has no song actively playing or paused
     */
    idle = 'idle',

    /**
     * the player is currently playing a song
     */
    playing = 'playing',

    /**
     * the player is paused
     */
    paused = 'paused',
}

export type FeedState = keyof typeof FeedStates;

export interface Player {
    session: {
        config: SessionConfig;
    };
    getCurrentState: () => FeedStates;
    getDuration: () => number;
    getStations: () => Promise<IFeedStation[]>;
    getVolume: () => number;
    initializeAudio: () => void;
    isMuted: () => boolean;
    isPaused: () => boolean;
    play: () => void;
    pause: () => void;
    skip: () => void;
    stop: () => void;
    tune: () => void;
    off: (name: FeedEvent | 'all', callback: (event: FeedEvent) => void) => void;
    on: (name: FeedEvent | 'all', callback: (event: FeedEvent) => void) => void;
    once: (name: FeedEvent | 'all', callback: (event: FeedEvent) => void) => void;

    /**
     * Mutes the music player
     * @param {boolean} isMuted - `true` or `false`
     */
    setMuted: (isMuted: boolean) => void;

    setStationId: (stationId: string) => void;

    /**
     * Sets the volume on the player.
     * @param {number} volume - Values between 0 and 100
     */
    setVolume: (volume: number) => void;
}

export interface Artist {
    id: string;
    name: string;
}

export interface Track {
    id: string;
    title: string;
}

export interface Release {
    id: string;
    title: string;
}

export interface AudioFile {
    artist: Artist;
    bitrate: number;
    codec: string;
    duration_in_seconds: number;
    extra: {
        artwork: string;
    };
    id: string;
    replaygain_track_gain: number;
    release: Release;
    track: Track;
    url: string;
}

export interface SessionConfig {
    current: {
        canSkip: boolean;
        play: {
            audio_file: AudioFile;
            id: string;
            station: IFeedStation;
        };
        retryCount: number;
        started: boolean;
    };
    formats: string;
    bitrate: number;
    pendingPlay: {
        audio_file: AudioFile;
        id: string;
        station: IFeedStation;
    };
}

export interface IMusic {
  currentStation: IFeedStation | null;
  state: FeedStates;
  stations: IFeedStation[];
  trainerVolume: number;
  volume: number;
}
