import { takeEvery } from 'redux-saga/effects';
import Pvolve from '@pvolve/sdk';

import Actions from '../contentV2/actions';
import { routineSaga } from '@pvolve/sdk/src/app/utils/sagas';

const { partner } = Actions;

class PartnersSaga {
    *init() {
        yield takeEvery(partner.show.trigger, saga.show);
    }

    show = routineSaga({
        routine: partner.show,
        *request({ payload: { slug } }) {
            const response = yield Pvolve.api.partner.show(slug);
            return response;
        },
    });
}

export const saga = new PartnersSaga();

export default function* combined() {
    yield takeEvery(partner.show.trigger, saga.show);
}
