import React from 'react';
import classnames from 'classnames';
import { Segment, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import { Icon } from 'src/components/shared';

import * as bannerStyles from 'src/styles/banner.module.scss';

export type BannerTypes = 'DEFAULT' | 'ERROR' | 'INVERTED' | 'SUCCESS' | 'WARNING';

type BannerType = {
    [key in BannerTypes]: {
        color: SemanticCOLORS;
        icon: SemanticICONS;
    };
};

const TYPES: BannerType = {
    DEFAULT: {
        color: 'grey',
        icon: 'info circle',
    },
    ERROR: {
        color: 'red',
        icon: 'warning circle',
    },
    INVERTED: {
        // @ts-ignore
        color: 'white',
        icon: 'info circle',
    },
    SUCCESS: {
        color: 'green',
        icon: 'check circle',
    },
    WARNING: {
        color: 'orange',
        icon: 'warning sign',
    },
};

interface BannerProps {
    className?: string;
    withIcon?: boolean;
    type?: BannerTypes;
}

const Banner: React.FC<BannerProps> = ({
    children,
    className,
    withIcon,
    type = 'DEFAULT',
    ...rest
}) => {
    const segmentClasses = classnames(bannerStyles.banner, {
        [`${className}`]: !!className,
    });

    return (
        <Segment inverted color={TYPES[type].color} className={segmentClasses} {...rest}>
            {withIcon && <Icon name={TYPES[type].icon} />}
            <p>{children}</p>
        </Segment>
    );
};

export default Banner;
