// extracted by mini-css-extract-plugin
export const feedPlayerContainer = "feed-player-module--feed-player-container--2LCbZ";
export const dynamicContainer = "feed-player-module--dynamic-container--3voZE";
export const controllers = "feed-player-module--controllers--2CZvm";
export const skipNotice = "feed-player-module--skipNotice--1qeAb";
export const button = "feed-player-module--button--PBlOf";
export const pvMusic = "feed-player-module--pv-music--HT9p0";
export const disabled = "feed-player-module--disabled--tcmDK";
export const music = "feed-player-module--music--3KyjT";
export const musicButton = "feed-player-module--music-button--2bDS_";
export const songInfo = "feed-player-module--song-info--2dALG";
export const cta = "feed-player-module--cta--1TpOF";
export const artistTrack = "feed-player-module--artist-track--3Rxkf";
export const artist = "feed-player-module--artist--1mZtQ";
export const track = "feed-player-module--track--2kRr3";
export const by = "feed-player-module--by--2cZLq";
export const stationsContainer = "feed-player-module--stationsContainer--3eDSh";
export const credit = "feed-player-module--credit--28aKQ";
export const stations = "feed-player-module--stations--1MiH2";
export const close = "feed-player-module--close--3rZZy";
export const stationWrapper = "feed-player-module--stationWrapper--3EI5l";
export const station = "feed-player-module--station--3HmgK";
export const stationArt = "feed-player-module--stationArt--zbNm6";
export const stationName = "feed-player-module--stationName--1SXrh";
export const playingLabel = "feed-player-module--playing-label--2pJeH";
export const mobileCopy = "feed-player-module--mobile-copy--3qkPz";
export const setDefaultButton = "feed-player-module--setDefaultButton--3sabX";
export const defaultLabel = "feed-player-module--defaultLabel--3MhwP";