import React from 'react';

// Components
import Styled from './banner-with-button.styled';

interface BannerWithButtonProps {
    className?: string;
    buttonAction?: () => void;
    buttonText?: string;
    bodyText: string | Element[];
    titleText?: string;
}

export const BannerWithButton = ({
    className = '',
    buttonAction,
    buttonText,
    bodyText,
    titleText,
}: BannerWithButtonProps) => {
    return (
        <div className={className}>
            <Styled.Alert
                aria-label="Beta banner"
                icon={false}
                action={
                    buttonText &&
                    buttonAction && (
                        <Styled.Button variant="contained" onClick={buttonAction}>
                            {buttonText}
                        </Styled.Button>
                    )
                }
                color="tertiary"
                variant="outlined"
            >
                {titleText && <Styled.Title>{titleText}</Styled.Title>}
                {bodyText}
            </Styled.Alert>
        </div>
    );
};

export default BannerWithButton;
