import React, { useEffect, useState } from 'react';
import * as Styles from 'src/styles/progress-bar.module.scss';
import 'src/styles/progress-bar-config.scss';

interface ProgressArrowProps {
    firstDay: string;
    lastDay: string;
    currentDay: string;
    currentPhase: string;
    vertical?: boolean;
}

const ProgressArrow = ({
    firstDay,
    lastDay,
    currentDay,
    currentPhase,
    vertical,
}: ProgressArrowProps) => {
    const progressDirection = vertical ? 'top' : 'left';
    const [arrowPosition, setArrowPosition] = useState({ [progressDirection]: '0%' });

    useEffect(() => {
        /* calculate arrow position based on current day and last day */
        /* convert to numbers if day comes in string form */
        const firstDayNbr = parseInt(firstDay);
        const lastDayNbr = parseInt(lastDay);
        const currentDayNbr = parseInt(currentDay);

        if (currentDayNbr === firstDayNbr) {
            return setArrowPosition({
                [progressDirection]: vertical ? `calc(0% + 15px)` : `0%`,
            });
        }
        if (currentDayNbr === lastDayNbr) {
            return setArrowPosition({
                [progressDirection]: vertical ? `calc(100% - 15px)` : `100%`,
            });
        }

        let numberBlocks = lastDayNbr - firstDayNbr;
        const position = (100 * (currentDayNbr - firstDayNbr)) / numberBlocks;
        setArrowPosition({ [progressDirection]: `${Math.trunc(position).toString()}%` });
    }, [firstDay, lastDay, currentDay, currentPhase, vertical]);

    return (
        <div className={Styles.arrowContainer} style={vertical ? arrowPosition : undefined}>
            <div className={Styles.progressArrow} style={!vertical ? arrowPosition : undefined}>
                <div className={`arrow-bgcolor-pv-${currentPhase}`}></div>
                {vertical && <span className={Styles.progressLabel}>You are here</span>}
            </div>
        </div>
    );
};

export default ProgressArrow;
