import React from 'react';
import { Link } from 'gatsby';

// This component is used in the WorkoutCard and My Plan / TinyListCard components. If the workout is a rest or walk day, the wrapper will return a div instead of a link.

const LinkWrapper = ({
    props,
    children,
    className = '',
    isRestOrWalkDay,
    renderItem: RenderItem = Link,
}: {
    props: any;
    className?: string;
    isRestOrWalkDay: boolean;
    children: React.ReactNode;
    renderItem?: React.FunctionComponent<any> | typeof Link;
}) => {
    return isRestOrWalkDay ? (
        <div className={className}>{children}</div>
    ) : (
        <RenderItem className={className} {...props}>
            {children}
        </RenderItem>
    );
};

export default LinkWrapper;
