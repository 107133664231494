import { getEnvUrls } from 'src/utils/route-utils';

const ENV_URLS = getEnvUrls();
export const SHOPIFY_URL = ENV_URLS?.shopifyUrl || '';

export const LEGACY_LOGIN_PATH = '/continue';
export const SIGN_UP_PATH = '/signup';
export const LEGACY_LOGOUT_PATH = '/logout';
export const LEGACY_ACCOUNT_PATH = '/account';
export const LEGACY_WORKOUTS_PATH = '/workouts';

export const MYPLAN_PATH = '/my-plan';
export const MYPLAN_PATH_DEV = '/home'; // TODO to be replaced by MYPLAN_PATH

export const SERIES_PATH = '/series';
export const UPGRADE_PLAN_LINK = '/account/subscription/update';
export const FREE_TRIAL_LINK = '/checkout/?sku=12month_trial7&features=true';

export const LOCATIONS_PATH = '/locations';
export const LVS_PATH = '/live';
export const LVS_BOOKED_CLASS_PATH = `${LVS_PATH}/booked-classes`;
export const FRANCHISES_PATH = 'https://www.pvolvefranchise.com/';

export const FALL_CHALLENGE_PBALL_PAGE = 'pages/september-challenge-22-pball';

// video-player error
export const GOOGLE_CHROME = 'https://www.google.com/chrome/';
export const MICROSOFT_EDGE = 'https://www.microsoft.com/en-us/edge';
export const MOZILLA_FIREFOX = 'https://www.mozilla.org/en-US/firefox/new/';
export const APPLE_SAFARI = 'https://support.apple.com/downloads/safari';
