import React, { useEffect, useMemo, useState } from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { connect, ConnectedProps } from 'react-redux';
import Helmet from 'react-helmet';

import FooterMobile from 'src/components/layout/FooterMobile';
import FooterDesktop from 'src/components/layout/FooterDesktop';

import { getFooterItems } from 'src/components/layout/footer-constants';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

import * as Styles from 'src/styles/footer.module.scss';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import StayInTouch from './StayInTouch';

import FooterIcon from 'src/assets/pvolve-logo.svg';

const connector = connect((state: IState) => ({
    userId: Selectors.auth.userId(state),
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
    featureFlags: PvolveSelectors.config.featureFlags(state),
}));

const Footer = ({ shopifyUrl, featureFlags, userId }: ConnectedProps<typeof connector>) => {
    const isMobile = useBreakpoints(Breakpoints.lg, Directions.down);
    const footerData = useMemo(() => getFooterItems(shopifyUrl, featureFlags), [
        shopifyUrl,
        featureFlags,
    ]);

    const [showNewsletter, setShowNewsletter] = useState(true);

    useEffect(() => {
        setShowNewsletter(!userId);
    }, [userId]);
    return (
        <footer className={Styles.footerWrapper}>
            <Helmet>
                <script data-cfasync="false">{`
                  window.ju_num="6508B0F5-EB65-4B0B-BD12-F02465FA0238";window.asset_host='//cdn.jst.ai/';(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');
                `}</script>
            </Helmet>
            <div className={Styles.footerEmailContainer}>
                {showNewsletter && <StayInTouch></StayInTouch>}
                <img alt="Pvolve-logo" width="176" height="50" src={FooterIcon} />
            </div>
            {isMobile ? <FooterMobile data={footerData} /> : <FooterDesktop data={footerData} />}
            <div className={Styles.copyright}>
                <p className="tiny">© PVOLVE 2023. ALL RIGHTS RESERVED</p>
            </div>
        </footer>
    );
};

export default connector(Footer);
