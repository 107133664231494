import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    HEADER_HEIGHT,
    HEADER_PADDING_SIDES,
    HEADER_MOBILE_PADDING_SIDES,
    UI_MAX_WIDTH,
} from 'src/constants/ui';
import { styled } from '@mui/material/styles';

const Styled = {
    AnnouncementNavBar: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.brandColors.bone,
        height: HEADER_HEIGHT.announcement,
        width: '100%',
    })),

    Announcement: styled(Typography)(({ theme }) => ({
        paddingRight: theme.spacing(HEADER_PADDING_SIDES),

        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        },

        backgroundColor: theme.palette.brandColors.bone,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
    })),

    Links: styled(Box)(({ theme }) => ({
        paddingRight: theme.spacing(HEADER_PADDING_SIDES),
        backgroundColor: theme.palette.brandColors.white,

        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        },

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingLeft: theme.spacing(5),
    })),

    AnnouncementNavBarButton: styled(Button)(({ theme }) => ({
        padding: theme.spacing(0),
        marginLeft: theme.spacing(6),
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: '0.4px',
    })),

    AnnouncementNavBarInternalWrapper: styled(Box)(() => ({
        maxWidth: UI_MAX_WIDTH,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    })),
};

export default Styled;
