import Actions from '../root-actions';
import { bootEnded } from '@pvolve/sdk/src/app/modules/boot/actions';
import { getBootSequence, Priority } from '@pvolve/sdk/src/app/modules/boot/BootSequence';
import { all, take } from 'redux-saga/effects';

const user = getBootSequence('user', {});

user.startWhen = all([take(Actions.auth.authenticated), take(bootEnded('app', 'start'))]);

user.onStart(
    { priority: Priority.HIGH, routine: Actions.web.handleLoggedIn },
    { routine: Actions.flags.phaseAndFunction },
    { routine: Actions.favorites.list },
    { routine: Actions.workouts.loadStats },
    { routine: Actions.series.enrolled },
    { routine: Actions.subscriptions.load },
    { routine: Actions.subscriptions.loadPaymentMethods },
    { routine: Actions.account.getAllAttrs },
    { routine: Actions.account.getTokenAttributes },
    { routine: Actions.phases.list }
);

user.shutdownWhen = all([take(Actions.auth.unauthenticated)]);

user.onShutdown({ priority: Priority.HIGH, routine: Actions.web.handleLoggedOut });
