import { SegmentSaga } from '@pvolve/sdk/src/app/modules/segment/sagas';
import { IdentifyFields, TrackFields } from '@pvolve/sdk/src/app/modules/segment/types';
import checkout from './event-handlers/checkout';
import { custom_page_events, custom_track_events } from './events';

const appendWebSource = (props: LooseObject | undefined) => {
    return { ...(props || {}), source_app: 'Web app' };
};

class WebSegmentSaga extends SegmentSaga {
    constructor() {
        super({
            custom_event: custom_track_events,
            custom_eventHandler: checkout.events,
        });
    }

    identify({ userId, traits }: IdentifyFields) {
        window?.analytics.identify(userId, traits);
    }

    track({ event, properties }: TrackFields) {
        window?.analytics.track(event, properties);
    }

    page(pathname: string | undefined): void {
        if (!pathname || !custom_page_events.includes(pathname)) {
            return;
        }
        window?.analytics.page();
    }
}

export const saga = new WebSegmentSaga();
