import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Loader } from 'semantic-ui-react';
import { Icon } from 'src/components/shared';
require('src/styles/button.scss');

export type ButtonType = 'button' | 'submit' | 'reset' | undefined;
export type ColorType =
    | 'primary'
    | 'secondary'
    | 'control'
    | 'warning'
    | 'danger'
    | 'basic'
    | undefined;
export type IconPositionType = 'start' | 'end' | undefined;
export type SizeType = 'giant' | 'large' | 'medium' | 'small' | 'tiny' | undefined;

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    color: ColorType;
    disabled: boolean;
    iconName: string;
    iconPosition: IconPositionType;
    isLoading: boolean;
    isTag: boolean;
    label: string;
    size: SizeType;
    type: ButtonType;
}

const GeneralButton = ({ color = 'primary', size = 'medium', ...props }: Partial<ButtonProps>) => {
    const { disabled, iconName, iconPosition, isLoading, isTag, label } = props;

    const showIcon = Boolean(iconName && !isLoading);
    const showLabel = Boolean(label && !isLoading);

    const buttonClasses: string = classNames({
        button: true,
        [`color-${color}`]: true,
        disabled,
        end: iconName && iconPosition === 'end',
        'no-label': !showLabel,
        [`size-${size}`]: true,
        tag: isTag,
    });

    const hoverClasses: string = classNames({
        'hover-overlay': true,
        'disabled-hover': disabled,
    });

    const spanClasses: string = classNames({
        end: iconName && iconPosition === 'end',
        start: iconName && iconPosition !== 'end',
    });

    const iconSizes: { [key: string]: number } = {
        giant: 20,
        large: 20,
        medium: 16,
        small: 14,
        tiny: 10,
    };

    return (
        <button {...(props as any)} className={buttonClasses}>
            {isLoading && <Loader active />}
            {!isLoading && <div className={hoverClasses} />}
            {showIcon && <Icon name={iconName || ''} size={iconSizes[size]} />}
            {showLabel && <span className={spanClasses}>{label}</span>}
        </button>
    );
};

export default GeneralButton;
