interface Glyph {
    [key: string]: string;
}
const GlyphMap: Glyph = {
    'pv-minus': '\ue970',
    'pv-airplay': '\ue96a',
    'pv-plus': '\ue969',
    'pv-filters-horizontal-outline': '\ue968',
    'pv-rabbit-fast': '\ue967',
    'pv-egg': '\ue966',
    'pv-mat-definition': '\ue965',
    'pv-hamburger': '\ue95e',
    'pv-womens-wellness': '\ue95d',
    'pv-tutorials': '\ue909',
    'pv-quick-boost': '\ue90d',
    'pv-no-equipment': '\ue92c',
    'pv-strength-and-sculpt': '\ue92d',
    'pv-cardio-burn': '\ue92e',
    'pv-recovery-and-stretch': '\ue92f',
    'pv-the-foundation': '\ue930',
    'pv-mat': '\ue949',
    'pv-pball': '\ue931',
    'pv-pankle-weight': '\ue932',
    'pv-step': '\ue933',
    'pv-foam-roller': '\ue934',
    'pv-slant-board': '\ue935',
    'pv-ankle-band': '\ue936',
    'pv-p3-trainer': '\ue937',
    'pv-pband': '\ue938',
    'pv-gliders': '\ue939',
    'pv-hand-weights': '\ue93a',
    'pv-circle': '\ue95c',
    'pv-slash': '\ue958',
    'pv-wifi': '\ue94b',
    'pv-wifi-off': '\ue94c',
    'pv-alert-circle': '\ue94d',
    'pv-alert-circle-outline': '\ue94e',
    'pv-info': '\ue94f',
    'pv-info-outline': '\ue950',
    'pv-alert-triangle': '\ue951',
    'pv-alert-triangle-outline': '\ue952',
    'pv-question-mark-circle': '\ue953',
    'pv-question-mark-circle-outline': '\ue954',
    'pv-checkmark-circle': '\ue955',
    'pv-checkmark-circle-outline': '\ue956',
    'pv-shopping-bag': '\ue94a',
    'pv-cog': '\ue940',
    'pv-layers': '\ue941',
    'pv-chevron-down': '\ue942',
    'pv-chevron-up': '\ue943',
    'pv-chevron-right': '\ue944',
    'pv-chevron-left': '\ue945',
    'pv-thumbs-up': '\ue946',
    'pv-leaf': '\ue947',
    'pv-s-open-quote': '\ue948',
    'pv-ribbon': '\ue93f',
    'pv-filters-horizontal': '\ue93e',
    'pv-facebook': '\ue929',
    'pv-apple': '\ue92a',
    'pv-google': '\ue92b',
    'pv-close': '\ue90e',
    'pv-target': '\ue910',
    'pv-progress-arrow': '\ue911',
    'pv-flag': '\ue912',
    'pv-external-link': '\ue913',
    'pv-heart-outline': '\ue914',
    'pv-arrow-left': '\ue915',
    'pv-arrow-right': '\ue916',
    'pv-arrow-up': '\ue917',
    'pv-arrow-down': '\ue918',
    'pv-eyeball': '\ue919',
    'pv-eyeball-off': '\ue957',
    'pv-pencil': '\ue91a',
    'pv-star': '\ue90f',
    'pv-clock-outline': '\ue908',
    'pv-heart': '\ue90a',
    'pv-clock': '\ue90b',
    'pv-logo': '\ue900',
    'pv-logo-name': '\ue901',
    'pv-calendar': '\ue902',
    'pv-calendar-outline': '\ue903',
    'pv-library': '\ue904',
    'pv-fire-outline': '\ue90c',
    'pv-library-outline': '\ue905',
    'pv-checkmark': '\ue906',
    'pv-ellipses-horizontal': '\ue907',
    'pv-closed-caption-outline': '\ue91b',
    'pv-closed-caption': '\ue91c',
    'pv-collapse': '\ue91d',
    'pv-expand': '\ue91e',
    'pv-jump-back': '\ue91f',
    'pv-jump-forward': '\ue93b',
    'pv-music': '\ue920',
    'pv-music-solid': '\ue979',
    'pv-play': '\ue923',
    'pv-pause': '\ue978',
    'pv-pause-outline': '\ue959',
    'pv-pause-big-outline': '\ue921',
    'pv-pause-big': '\ue922',
    'pv-play-big': '\ue93c',
    'pv-play-outline': '\ue95a',
    'pv-play-big-outline': '\ue93d',
    'pv-replay': '\ue924',
    'pv-skip-outline': '\ue95b',
    'pv-skip': '\ue925',
    'pv-stop': '\ue926',
    'pv-volume-off': '\ue95f',
    'pv-volume-off-outline': '\ue960',
    'pv-volume': '\ue961',
    'pv-volume-outline': '\ue962',
    'pv-volume-down': '\ue963',
    'pv-volume-down-outline': '\ue964',
    'pv-volume-up': '\ue928',
    'pv-volume-up-outline': '\ue927',
    'pv-play-outline-white': '\ue96b',
    'pv-movement': '\ue973',
    'pv-foods': '\ue972',
    'pv-meals': '\ue971',
    'pv-shape-1': '\ue96c',
    'pv-shape-2': '\ue96d',
    'pv-shape-3': '\ue96e',
    'pv-shape-4': '\ue96f',
    'pv-mind': '\ue977',
    'pv-file': '\ue976',
    'pv-meals-filled': '\ue974',
    'pv-foods-filled': '\ue975',
    'pv-message': '\ue97a',
    'pv-star-outline': '\ue97b',
    'pv-clipboard-outline': '\ue97c',
    'pv-person-outline': '\ue97d',
    'pv-tv-outline': '\ue97e',
    'pv-logo-brand': '\ue97f',
    'pv-dumbbell-filled': '\ue986',
    'pv-dumbbell-outline': '\ue985',
    'pv-folder-dumbbell-filled': '\ue984',
    'pv-folder-dumbbell-outline': '\ue983',
    'pv-house-filled': '\ue980',
    'pv-house-outline': '\ue981',
    'pv-user-circle-outline': '\ue982',
    'pv-meditation-and-mindfulness': '\ue98a',
    'pv-modifications': '\ue989',
    'pv-strength-and-stability': '\ue988',
    'pv-targeted-movement-therapy': '\ue987',
    'pv-monitor-play-outline': '\ue98c',
    'pv-monitor-play-filled': '\ue98b',
};

export type GlyphName = keyof typeof GlyphMap;

export default GlyphMap;
