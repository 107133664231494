import { spawn, take } from 'redux-saga/effects';
import Actions, { SegmentActions } from './actions';
import { track_events } from './events';
import { getEventName, getEventMapper, eventToAction } from './utils/index';
import {
    LooseObject,
    IdentifyFields,
    Options,
    TrackFields,
} from './types';

/**
 * SegmentSaga Base Class for Segment Analytics Tracking and Identify calls
 *
 * **Notes**
 * 
 * - Subclasses are expected to set `track` and `identify` functions from client AnalyticsJS
 * - `track` calls are mapped from a static events list which are then translated to `redux actions` making it easy to call each event as method.
 * - If properties are needed on the `track` call, an `event-handler` is required for the `mapper` to succesfully process the action payload.
 * 
 * Argument `options` helps to include custom events/eventHandlers which are not automatically mapped to `redux actions`, interface for custom events should be defined manually.
 *  - `custom_event` Object following `track_events` format:
 *    - { [eventName:string]: "Event Name"}
 *  - `custom_eventHandler` Object following `EventMapper` format:
 *    - { ["Event Name"]: (data:any) => {...properties} }
 * 
 * @param options An object with `custom_events` and `custom_eventHandler`
 */
export class SegmentSaga {
    #events = { ...track_events };
    #eventHandler = getEventMapper();

    constructor(
        options: Options = {
            custom_event: {},
            custom_eventHandler: {}
        }
    ) {
        this.#events = Object.assign(
            {},
            this.#events,
            options.custom_event
        );
        this.#eventHandler = Object.assign(
            {},
            this.#eventHandler,
            options.custom_eventHandler
        );
    }

    identify(args: IdentifyFields) {
        /// analytics.identify
    }

    track(args: TrackFields) {
        // analytics.track
    }

    get segmentActions(): SegmentActions {
        return Actions(eventToAction(this.#events));
    }

    private identifyHandler(identifyCallAction: LooseObject) {
        try {
            const userId: IdentifyFields['userId'] = identifyCallAction.payload?.userId;
            const traits: IdentifyFields['traits'] = identifyCallAction.payload?.traits;

            if (!userId) console.error("Identify call error: UserId is null or undefined");
            if (traits?.email) traits.email = traits?.email.toLowerCase();
            
            this.identify({ userId: userId.toLowerCase(), traits});
        } catch (err) {
            throw new Error(err);
        }
    }

    private trackHandler(trackCallAction: LooseObject) {
        try {
            const event = getEventName(trackCallAction.type, this.#events);
            const mapper = this.#eventHandler[event];
            const properties = typeof mapper === 'function' ? mapper(trackCallAction.payload) : {};

            if (!properties) console.warn("Track call interrupted: Properties are null or undefined");
            if (!event) console.error("Track call error: Event is null or undefined");

            this.track({ event, properties });
        } catch (err) {
            throw new Error(err);
        }
    }

    *init() {
        yield spawn([this, this.ajsWatcher]);
    }

    *ajsWatcher() {
        while (true) {
            let identify = false;
            const action = yield take(({ type }: { type: string }) =>
                type === "SEGMENT/IDENTIFY"
                    ? (identify = true)
                    : !!this.#events[type?.replace(/\w+\//gi, '')]
            );
            if (action) {
                if (identify) {
                    this.identifyHandler(action);
                } else {
                    this.trackHandler(action);
                }
            }
        }
    }
}
