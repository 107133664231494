import { combineActions } from 'redux-actions';
import Actions from './actions';
import { handleActionsImmer } from '../../utils';

export const initialState = {
  add: {},
  remove: {},
  data: {
    instructors: [],
    series: [],
    workouts: [],
  },
  version: null,
};

const {
  favorites: { add, list, remove },
} = Actions;

export default handleActionsImmer(
  {
    [combineActions(add.success, list.success, remove.success)]: (draft, data) => {
      if (data) {
        draft.version = data.version;
        draft.data = data?.object;
      }
    },
  },
  initialState,
  {
    add,
    list,
    remove,
  },
);
