import Actions from './actions';
import { takeEvery } from 'redux-saga/effects';
import Pvolve from '@pvolve/sdk';
import { routineSaga } from '../../utils/sagas';

const {
    questionnaire: { loadQuestionnaireContent },
  } = Actions;

class QuestionnaireSaga {
    *init() {
        yield takeEvery(loadQuestionnaireContent.trigger, saga.loadQuestionnaireContent);
    }

    loadQuestionnaireContent = routineSaga({
        routine: loadQuestionnaireContent,
        *request({ payload: { category, subcategory } }) {
        const options = { category, subcategory };
        const response = yield Pvolve.api.contentV2.content.list('questionnaires', options);
        return response.questionnaire;
        },
    });
}

export const saga = new QuestionnaireSaga();

export default function* combined() {
    yield takeEvery(loadQuestionnaireContent.trigger, saga.loadQuestionnaireContent);
}
