import React from 'react';
import classnames from 'classnames';
import glyphMap, { GlyphName } from './pvolveGlyphMap';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import 'src/styles/icons.scss';

interface PvolveIconProps {
    name: GlyphName | SemanticICONS;
    size?: string | number;
    color?: string;
    bgcolor?: string;
    style?: React.CSSProperties;
    className?: string;
    onClick?: (args: any) => void;
}

const PvolveIcon = ({
    name,
    size = 16,
    color,
    bgcolor,
    style,
    className = '',
    ...props
}: PvolveIconProps) => {
    const classes = classnames('pv-icon', name, className, {
        [`bgcolor-pv-${bgcolor}`]: bgcolor,
        [`color-pv-${color}`]: color,
    });
    const finalStyles = {
        fontSize: size,
        ...style,
    };

    if (!!glyphMap[name]) {
        return <i className={classes} style={finalStyles} {...props} />;
    }

    const iconName = name as SemanticICONS;
    return <Icon name={iconName} className={className} style={style} {...props} />;
};

export default PvolveIcon;
