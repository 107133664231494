import { IState } from '@pvolve/sdk/src/redux/selectors';
import { filter } from 'lodash';
import { createSelector } from 'reselect';

const root = (state: IState) => state.promos;
const banners = createSelector(root, (rootCombiner) => {
    return filter(rootCombiner.data, { promoType: 'banner' });
});

const hasBanners = createSelector(banners, (bannersCombiner) => !!bannersCombiner.length);

const modules = createSelector(root, (rootCombiner) =>
    filter(rootCombiner.data, { promoType: 'module' })
);

const topModule = createSelector(modules, (modulesCombiner) =>
    filter(modulesCombiner, { moduleType: 'top' })
);

const bottomModule = createSelector(modules, (modulesCombiner) =>
    filter(modulesCombiner, { moduleType: 'bottom' })
);

export interface BannerDataTypes {
    copy: string;
    copy2?: string;
    link?: string;
    linkEntitled?: string;
    linkUnentitled?: string;
    icon?: string;
    bannerType: 'black' | 'pink';
    color?: string;
    backgroundColor?: string;
    closable?: boolean;
}

export interface ModuleDataTypes {
    headline1: string;
    headline2?: string;
    body: string;
    link?: string;
    cta?: string;
    image: string;
    ctaColor?: string;
    ctaTextColor?: string;
    backgroundColor?: string;
    color?: string;
}

export default {
    root,
    banners,
    hasBanners,
    modules,
    topModule,
    bottomModule,
};
