import { handleActionsImmer } from '../../utils';
import Actions from '../../actions';
import { IQuestionnaireContentState } from '../../../redux/selectors';

export const initialState = {
  questionnaireContent: null,
};

const {
    questionnaire: {
        loadQuestionnaireContent,
        cleanQuestionnaire,
    },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [cleanQuestionnaire]: draft => {
      draft.questionnaireContent = null;
    },

    [loadQuestionnaireContent.success]: (draft, data: IQuestionnaireContentState) => {
      draft.questionnaireContent = data;
    }
  },
  initialState,
);

export default Reducer;
