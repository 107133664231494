import React from 'react';
import * as Styles from 'src/styles/next-up-button.module.scss';
import { Icon } from 'src/components/shared';

const NextUpButton = ({ nextWorkout }) => {
    const goNext = () => {
        window.location.href = nextWorkout.url;
    };
    return (
        <div className={Styles.nextUpButtonContainer}>
            <div className={Styles.nextUpButton}>
                <div className={Styles.textContainer}>
                    <p className={Styles.textItalic}>next up</p>
                    <p className={Styles.textBold}>{nextWorkout.workout?.fields.name}</p>
                    <p className={Styles.textItalic}>
                        {nextWorkout.index} of {nextWorkout.totalWorkouts}{' '}
                    </p>
                </div>
                <div className={Styles.countdownPlayIcon} onClick={goNext}>
                    <Icon
                        className={Styles.playIcon}
                        name="pv-play-outline-white"
                        size={18}
                        color="white"
                    />
                    <svg>
                        <circle r="18" cx="20" cy="20"></circle>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default NextUpButton;
