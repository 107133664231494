import React, { useCallback, useEffect, useState } from 'react';

// Components
import Styled from './header.styled';
import AccountButton from './account-button/account-button.component';
import { AccountCircleOutlined } from '@mui/icons-material';
import AnnouncementNavBar from './app-bars/announcement-nav-bar.component';
import PrimaryNavBar from './app-bars/primary-nav-bar.component';
import NavDrawerMobile from './nav-drawer-mobile/nav-drawer-mobile.component';

// Constants & Utils
import { linkToExternal } from 'src/utils/url-utils';
import { LEGACY_ACCOUNT_PATH, LEGACY_LOGIN_PATH } from 'src/constants/urls';
import { DEFAULT_ANNOUNCEMENT_BAR_BUTTONS } from 'src/constants/header-constants';
import { isEmpty, isNull } from 'lodash';

// Hooks
import useResponsive from 'src/hooks/useResponsive';

import Selectors from 'src/state/root-selectors';
import { useSelector } from 'react-redux';

export const HeaderMaterial = () => {
    // STATE
    const [menuOpen, setMenuOpen] = useState(false);

    const { isTabletAndMobile } = useResponsive();

    const user = useSelector(Selectors.account.userAttributes)?.object;
    const isLoggedIn: boolean = useSelector(Selectors.auth.loggedIn);

    const handleSetMenuOpen = useCallback(
        (open: boolean) => () => {
            setMenuOpen(open);
        },
        []
    );

    const handleAccountClick = useCallback(
        (path: string) => () => {
            linkToExternal(path);
        },
        []
    );

    const announcementBarButtons = DEFAULT_ANNOUNCEMENT_BAR_BUTTONS;

    useEffect(() => {
        // This is done to force close the menu when changing views, to avoid any odd behavior
        !isTabletAndMobile && setMenuOpen(false);
    }, [isTabletAndMobile, setMenuOpen]);

    // Will remove this in another commit
    const renderAccount = isLoggedIn ? (
        user && (
            <AccountButton
                variant="text"
                size="large"
                onClick={handleAccountClick(LEGACY_ACCOUNT_PATH)}
                firstName={user?.first_name || ''}
                lastName={user?.last_name || ''}
                showLabel={menuOpen ? true : false}
            />
        )
    ) : (
        <Styled.SignInButton
            startIcon={<AccountCircleOutlined />}
            variant="text"
            size="large"
            onClick={handleAccountClick(LEGACY_LOGIN_PATH)}
        >
            {menuOpen || !isTabletAndMobile ? 'Sign in' : ''}
        </Styled.SignInButton>
    );

    const NavBar = (
        <Styled.AppBar aria-label="Navigation bar" color="secondary" position="sticky">
            {!isTabletAndMobile && <AnnouncementNavBar />}
            <PrimaryNavBar
                handleSetMenuOpen={handleSetMenuOpen}
                menuOpen={menuOpen}
                accountComponent={renderAccount}
            />
            {menuOpen && isTabletAndMobile && (
                <NavDrawerMobile
                    announcementBarButtons={announcementBarButtons}
                    accountComponent={renderAccount}
                />
            )}
        </Styled.AppBar>
    );

    return NavBar;
};

export default HeaderMaterial;
