export const getFooterItems = (shopifyUrl, featureFlags = {}) => {
    const supportItems = [
        {
            title: 'FAQ',
            href: `${shopifyUrl}/pages/faq`,
        },
        {
            title: 'Returns',
            href: `${shopifyUrl}/pages/return-policy`,
        },
        {
            title: 'Education Discount',
            href: `${shopifyUrl}/pages/student-discount`,
        },
        {
            title: 'First Responder Discount',
            href: `${shopifyUrl}/pages/health-heroes`,
        },
        {
            title: 'Careers',
            href: 'https://www.linkedin.com/company/p-volve/',
        },

        {
            title: 'Contact',
            href: `${shopifyUrl}/pages/contact`,
        },
    ];

    if (featureFlags?.trainerJobs?.enabled) {
        supportItems.push({
            title: 'Trainer Jobs',
            href: `${shopifyUrl}/pages/trainer-jobs`,
        });
    }

    return [
        {
            title: 'support',
            items: supportItems,
        },
        {
            title: 'about',
            items: [
                {
                    title: 'About',
                    href: `${shopifyUrl}/pages/about-pvolve`,
                },
                {
                    title: 'Advisory Board',
                    href: `${shopifyUrl}/pages/advisory-board`,
                },
                {
                    title: 'Press',
                    href: `${shopifyUrl}/pages/press`,
                },
                {
                    title: 'Affiliate Program',
                    href:
                        'https://app.impact.com/campaign-mediapartner-signup/Pvolve.brand?execution=e1s1',
                },
                {
                    title: 'Franchising',
                    href: 'https://www.pvolvefranchise.com',
                },
                {
                    title: 'Corporate Partnership',
                    href: `${shopifyUrl}/pages/corporate`,
                },
            ],
        },
        {
            title: 'social',
            items: [
                {
                    title: 'Instagram',
                    href: 'https://www.instagram.com/pvolve',
                },
                {
                    title: 'YouTube',
                    href: 'https://www.youtube.com/pvolvefitness',
                },
                {
                    title: 'Facebook',
                    href: 'https://www.facebook.com/pvolve',
                },
                {
                    title: 'Twitter',
                    href: 'https://twitter.com/pvolvefitness',
                },
                {
                    title: 'Pinterest',
                    href: 'https://www.pinterest.com/pvolvefitness/_shop/',
                },
                {
                    title: 'Linkedin',
                    href: 'https://www.linkedin.com/company/p-volve/',
                },
            ],
        },
        {
            title: 'legal',
            items: [
                {
                    title: 'Privacy Policy',
                    href: `${shopifyUrl}/pages/privacy-policy`,
                },
                {
                    title: 'Terms of Service',
                    href: `${shopifyUrl}/pages/terms-of-service`,
                },
                {
                    title: 'liability waiver',
                    href: `${shopifyUrl}/pages/fitness-class-waiver`,
                },
                {
                    title: 'do not sell my info',
                    href: `${shopifyUrl}/pages/privacy-policy`,
                },
            ],
        },
    ];
};
