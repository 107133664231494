import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

interface InspirationalQuote {
    headline: string;
    subheading: string;
}

interface InspirationalQuoteProps {
    children: (quoteText: InspirationalQuote) => JSX.Element;
}

const InspirationalQuote = ({ children }: InspirationalQuoteProps) => {
    const data = useStaticQuery(graphql`
        query MotivationalBannerTextQuery {
            allContentfulQuote(sort: { order: DESC, fields: createdAt }) {
                edges {
                    node {
                        headline
                        subheading
                    }
                }
            }
        }
    `);

    return children(data && data.allContentfulQuote.edges.map((edge: any) => edge.node)[0]);
};

export default InspirationalQuote;
