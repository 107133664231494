import React, { HTMLAttributes } from 'react';
import { navigate } from 'gatsby';

import { Pill } from 'src/components/shared';
import * as pillStyles from 'src/styles/pill.module.scss';

interface BackToPillProps extends HTMLAttributes<HTMLDivElement> {
    to?: string;
    bgcolor?: string;
    label?: string;
    className?: string;
    withClickHandle?: boolean;
}

const BackToPill = ({
    to = '/workouts',
    bgcolor = 'white',
    label = 'Back',
    className = '',
    withClickHandle = true,
}: BackToPillProps) => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate(to);
    };
    const props = {
        ...(withClickHandle && {
            onClick: handleClick,
        }),
        bgcolor,
        className,
    };

    return (
        <Pill {...props}>
            <span
                className={`upper display-flex align-items--center link-with-arrow pv-icon pv-arrow-left text-links ${pillStyles.goBack}`}
            >
                {label}
            </span>
        </Pill>
    );
};

export default BackToPill;
