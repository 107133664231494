import isEmpty from 'lodash/isEmpty';
import { USER_COHORT, ENTITLEMENT_SOURCE } from '../auth/constants';
import Pvolve from '@pvolve/sdk';

export const getUserCohort = async (onboardingReleaseDate, payload) => {
  const whitelistResponse = await Pvolve.api.featureFlag.featureFlagByName(
    payload?.onboardingBetaUsers,
  );
  const isException = whitelistResponse?.onboardingBetaUsers?.result;

  const entitlementSource =
    payload?.tokenAttributes?.entitlement_source;

  if (!isEmpty(ENTITLEMENT_SOURCE[entitlementSource])) {
    return ENTITLEMENT_SOURCE[entitlementSource];
  }

  const isTrial = payload?.tokenAttributes?.is_trial;

  if (isTrial.toLowerCase() === 'true') {
    return USER_COHORT.trialist;
  }

  if (isException) {
    return USER_COHORT.d2c;
  }

  const compareDate = new Date(onboardingReleaseDate).getTime();
  const lastEntitledAt = payload?.tokenAttributes?.entitled_at;
  const lastEntitledDate = new Date(lastEntitledAt).getTime();
  if (isEmpty(lastEntitledAt) || lastEntitledDate < compareDate) {
    return USER_COHORT.pvolve;
  }

  const featureResponse = await Pvolve.api.featureFlag.featureFlagByName(
    payload?.onboardingD2C,
  );
  const onboardingFeatureEnabled = featureResponse?.onboardingD2C?.result;
  return onboardingFeatureEnabled ? USER_COHORT.d2c : USER_COHORT.pvolve;
};
