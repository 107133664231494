import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import * as creditCardStyles from 'src/styles/credit-card.module.scss';
import Colors from 'src/utils/colors';

interface StripeCardElementProps {
    onChange: (e: StripeCardElementChangeEvent) => void;
}

const StripeCardElement = ({ onChange }: StripeCardElementProps) => {
    const elementOptions = {
        style: {
            base: {
                color: Colors.gray90,
                fontFamily: "'Muller', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                fontSize: '16px',
                '::placeholder': {
                    color: Colors.gray30,
                },
                textTransform: 'lowercase',
            },
            invalid: {
                color: Colors.lightRed,
                iconColor: Colors.lightRed,
            },
        },
    };

    return (
        <CardElement
            className={creditCardStyles.stripeCardElement}
            options={elementOptions}
            onChange={onChange}
        />
    );
};

export default StripeCardElement;
