import { createActions } from 'redux-actions';

interface AnalyticsActions {
  analytics: {
    track: any;
    eventsPut: any;
  };
}

const Actions = <AnalyticsActions>(<unknown>createActions({
  ANALYTICS: {
    TRACK: undefined,
    EVENTS_PUT: undefined,
  },
}));

export default Actions;
