import { BasicActionFunction } from '@pvolve/sdk/src/app/utils';
import { createActions } from 'redux-actions';

interface OrdersActions {
    orders: {
        setDraftId: BasicActionFunction<{ id: string }>;
        clearDraftId: BasicActionFunction;
    };
}
const Actions = (createActions({
    ORDERS: {
        SET_DRAFT_ID: undefined,
        CLEAR_DRAFT_ID: undefined,
    },
}) as unknown) as OrdersActions;

export default Actions;
