import React from 'react';
import withLocation from 'src/components/withLocation';
import * as confirmationStyles from 'src/styles/confirmation-page.module.scss';
import CheckoutFooter from '../checkout/CheckoutFooter';
import CircleCheckmark from './CircleCheckmark';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import Colors from 'src/utils/colors.tsx';

const ConfirmationPage = ({ children, title, subtitle }) => {
    const isDesktop = useBreakpoints(Breakpoints.sm, Directions.up);

    return (
        <>
            <section className={confirmationStyles.contentWrapper}>
                <div className={confirmationStyles.contentBackground} />
                <aside className={confirmationStyles.primaryContent}>
                    <div className={confirmationStyles.checkmarkWrapper}>
                        <CircleCheckmark
                            checkColor="desert"
                            circleBgColor={Colors.black}
                            size={isDesktop ? 60 : 45}
                        />
                    </div>
                    <div className={confirmationStyles.primaryText}>
                        <p className={`upper ${confirmationStyles.primaryTitle}`}>
                            {title || 'Confirmed'}
                        </p>
                        {subtitle && (
                            <p className={`upper ${confirmationStyles.primarySubtitle}`}>
                                {subtitle}
                            </p>
                        )}
                    </div>
                </aside>
                <aside className={confirmationStyles.secondaryContent}>{children}</aside>
            </section>

            <CheckoutFooter />
        </>
    );
};
export default withLocation(ConfirmationPage);
