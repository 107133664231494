import { FavoriteCollectionName, IState } from '@pvolve/sdk/src/redux/selectors';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon } from 'src/components/shared';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';

import * as Styles from 'src/styles/favorite-button.module.scss';

interface BaseFavoriteProps {
    collection: FavoriteCollectionName;
    id: string;
    className?: string;
    size?: number;
    showLabel?: boolean;
}

const add = Actions.favorites.add.trigger;
const remove = Actions.favorites.remove.trigger;

const connector = connect(
    (state: IState, { id, collection }: BaseFavoriteProps) => ({
        isFavorite: Selectors.favorites.isFavorite(state)(collection, id),
    }),
    {
        setFavorite: (isFavorite: boolean, collection: FavoriteCollectionName, id: string) =>
            isFavorite ? remove({ collection, id }) : add({ collection, id }),
    }
);

interface FavoriteProps extends BaseFavoriteProps, ConnectedProps<typeof connector> {}

const ICON_STYLE = {
    FAVORITE: {
        name: 'pv-heart',
        color: 'red',
    },
    NOT_FAVORITE: {
        name: 'pv-heart-outline',
        color: 'gray-30',
    },
};

const FavoriteButton = ({
    isFavorite,
    collection,
    id,
    className,
    setFavorite,
    size = 33,
    showLabel = false,
}: FavoriteProps) => {
    const iconProps = isFavorite ? ICON_STYLE.FAVORITE : ICON_STYLE.NOT_FAVORITE;

    return (
        <button
            className={Styles.favoriteButton}
            onClick={() => setFavorite(isFavorite, collection, id)}
        >
            <span>
                <Icon
                    className={className}
                    size={size}
                    {...iconProps}
                    style={{ cursor: 'pointer' }}
                />
                {showLabel && (
                    <h5 className="accent margin-top--0">
                        {!isFavorite ? 'add to favorites' : 'remove from favorites'}
                    </h5>
                )}
            </span>
        </button>
    );
};

export default connector(FavoriteButton);
