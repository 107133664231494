import Actions from './actions';
import { takeEvery, select } from 'redux-saga/effects';
import Pvolve from '@pvolve/sdk';
import { routineSaga } from '../../utils/sagas';
import { ENTITLEMENT_SOURCE, getUserCohortsString } from '../auth/constants';
import { isEmpty } from 'lodash';

const {
  workoutCategories: { loadWorkoutCategoriesContent },
} = Actions;

class WorkoutCategoriesSaga {
  *init() {
    yield takeEvery(
      loadWorkoutCategoriesContent.trigger,
      saga.loadWorkoutCategoriesContent,
    );
  }

  loadWorkoutCategoriesContent = routineSaga({
    routine: loadWorkoutCategoriesContent,
    *request() {
      let response = {
        targetedCategories: null,
        defaultCategories: null
      };
      const userCohort = yield select(state => state.account ? state.account.userCohort : state.auth.user.user_cohort);
      const hasTargetedContent = !isEmpty(ENTITLEMENT_SOURCE[userCohort]);
      if(hasTargetedContent) {
        const options = { tags:  `cohort${userCohort}`, operator: 'all'};
        const targetedCategories = yield Pvolve.api.contentV2.content.list(
          'workout_categories',
          options,
        );
        response.targetedCategories = targetedCategories?.items;
      }
      const options = { tags:  getUserCohortsString(), operator: 'in'};
      const defaultCategories = yield Pvolve.api.contentV2.content.list(
        'workout_categories',
        options,
      );
      response.defaultCategories = defaultCategories?.items;
      return response;
    },
  });
}

export const saga = new WorkoutCategoriesSaga();

export default function* combined() {
  yield takeEvery(
    loadWorkoutCategoriesContent.trigger,
    saga.loadWorkoutCategoriesContent,
  );
}
