import { createActions } from 'redux-actions';
import { DefaultRoutineActions, DefaultRoutine } from '../../utils';

interface PhasesActions {
  phases: {
    list: DefaultRoutineActions,
  };
}

const Actions = <PhasesActions><unknown>createActions({
  PHASES: {
    LIST: DefaultRoutine,
  },
});

export default Actions;