// extracted by mini-css-extract-plugin
export const phaseHeader = "progress-bar-module--phase-header--KyUs7";
export const progressBar = "progress-bar-module--progress-bar--2AyKg";
export const progressBarContainer = "progress-bar-module--progress-bar-container--2FjVw";
export const progressBarHorizontal = "progress-bar-module--progress-bar-horizontal--2pJ_Q";
export const progressBarVertical = "progress-bar-module--progress-bar-vertical--Nor4g";
export const progressBlock = "progress-bar-module--progress-block--3AmlJ";
export const range = "progress-bar-module--range--2QvTS";
export const blockName = "progress-bar-module--block-name--21mOk";
export const arrowContainer = "progress-bar-module--arrow-container--2Ugen";
export const progressArrow = "progress-bar-module--progress-arrow--35QxX";
export const progressLabel = "progress-bar-module--progress-label--2lTUM";
export const active = "progress-bar-module--active--1cEKo";