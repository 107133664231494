import defaultAxios from 'axios';
import AxiosAdapter from './AxiosAdapter';
import LocalConfigService from './LocalConfigService';
import HttpConfigService from './HttpConfigService';
import LocalTokenStore from './LocalTokenStore';
import MemoryConfigStore from './MemoryConfigStore';
import AppSyncAdapter from './AppSyncAdapter';
import TokenStoreFactory from './TokenStoreFactory';

export default function configure(
  coreInstance,
  {
    config,
    axios,
    requestAdapter,
    factoryStore,
    configService,
    environments,
    AppSyncClientClass,
    configStore,
    authStore,
  },
) {
  if (environments) {
    configService = new HttpConfigService(environments);
  } else if (config) {
    configService = new LocalConfigService(config);
  }

  configService.configStore = configStore || new MemoryConfigStore();

  coreInstance.register('config', configService);
  coreInstance.api.tokens.tokenStore =
    factoryStore ||
    new TokenStoreFactory({ tokenStore: new LocalTokenStore() });
  coreInstance.requestAdapter =
    requestAdapter || new AxiosAdapter(axios || defaultAxios);
  coreInstance.api.auth.authStore = authStore;

  if (AppSyncClientClass) {
    coreInstance.graphQLAdapter = new AppSyncAdapter(
      coreInstance,
      AppSyncClientClass,
    );
  }
}
