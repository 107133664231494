import React from 'react';
import { isEmpty, reduce, values } from 'lodash';

import InfoMessage, { InfoMessageTypes } from 'src/components/shared/InfoMessage';

export const GENERAL_ERROR_MSG = 'Oops, something went wrong. Please try again.';

export const FORM_MESSAGES = {
    passwordValidation: 'Please enter a valid password. The password must be at least 6 characters',
    passwordShortValidation: 'At least 6 characters',
    upperCaseLetter: 'Contain at least 1 uppercase letter',
    containNumber: 'Contain at least 1 number',
    requiredField: 'Required',
    invalidEmail: 'Invalid email address',
    invalidPhone: 'Invalid phone number',
    invalidDate: 'Invalid date',
    requiredEmail: 'Please enter a valid email address',
    invalidCard: 'Please enter a valid credit card',
    emailRequired: 'Email required',
    firstNameRequired: 'First name required',
    lastNameRequired: 'Last name required',
    termsRequired: 'Must agree to Terms of Service',
};

/**
 * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
 */
export const STRONG_PASSWORD_REGEX = /^(?=.{6,})/;
export const PASS_CONTAIN_UPPERCASE_REGEX = /[A-Z]/;
export const PASS_CONTAIN_NUMBER_REGEX = /\d/;

export const MAX_INPUT_LENGTH = 64;

export const getErrorMessage = (error: string, floatIcon: boolean = false) => {
    return getInfoMessage(error, 'ERROR', floatIcon);
};

export const getErrorWithBkgMessage = (error: string, floatIcon: boolean = false) => {
    return getInfoMessage(error, 'ERROR', floatIcon, 'ERROR');
};

export const getInfoMessage = (
    msg: string,
    type: InfoMessageTypes = 'DEFAULT',
    floatIcon: boolean = false,
    severity: InfoMessageTypes = 'DEFAULT'
) => <InfoMessage content={msg} type={type} floatIcon={floatIcon} severity={severity} />;

/**
 *
 * @param valueObj
 * Typically a `FormValues` object
 */
export const hasAllValues = (valueObj: { [key: string]: any }) => {
    return reduce(
        values(valueObj),
        function (memo, value) {
            return memo && !isEmpty(value?.toString());
        },
        true
    );
};

/**
 *
 * @param inputName String with input name content
 * @returns String with error message
 */
export const validateInputName = (inputName?: string) => {
    let error: string = '';

    if (!inputName?.trim()) {
        error = FORM_MESSAGES.requiredField;
    }

    return error;
};

/**
 *
 * @param values
 * An object with an `email` attribute
 */
export const validateEmail = (values: { email?: string }) => {
    const error: { email?: string } = {};

    if (!values.email) {
        error.email = FORM_MESSAGES.requiredEmail;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        error.email = FORM_MESSAGES.invalidEmail;
    }

    return error;
};

export const validatePassword = (values: { password?: string }) => {
    const error: { password?: string } = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    } else if (!STRONG_PASSWORD_REGEX.test(values.password)) {
        error.password = FORM_MESSAGES.passwordValidation;
    }

    return error;
};

export const validatePasswordUpper = (values: { password?: string }) => {
    const error: { password?: string } = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    } else if (!PASS_CONTAIN_UPPERCASE_REGEX.test(values.password)) {
        error.password = FORM_MESSAGES.upperCaseLetter;
    }

    return error;
};

export const validatePasswordNumber = (values: { password?: string }) => {
    const error: { password?: string } = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    } else if (!PASS_CONTAIN_NUMBER_REGEX.test(values.password)) {
        error.password = FORM_MESSAGES.containNumber;
    }

    return error;
};
