import React from 'react';
import classnames from 'classnames';
import { Grid, List } from 'semantic-ui-react';

import * as footerStyles from 'src/styles/footer.module.scss';

const FooterDesktop = ({ data }) => (
    <Grid className={footerStyles.footerGrid}>
        {data.map((footerItem) => {
            const sectionTitleClassnames = classnames('p2', footerStyles.sectionTitle);

            return (
                <Grid.Row key={footerItem.title} verticalAlign="middle">
                    <Grid.Column className="padding--0" verticalAlign="middle" width={2}>
                        <p className={sectionTitleClassnames}>{footerItem.title}</p>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <List className={footerStyles.list} verticalAlign="middle" horizontal>
                            {footerItem.items.map(({ title, href }) => {
                                return (
                                    <List.Item
                                        alt={title}
                                        as="a"
                                        className={footerStyles.footerLink}
                                        href={href}
                                        key={title}
                                        rel="noreferrer"
                                        role="link"
                                        target={footerItem.title === 'social' ? '_blank' : '_self'}
                                    >
                                        {title}
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Grid.Column>
                </Grid.Row>
            );
        })}
    </Grid>
);

export default FooterDesktop;
