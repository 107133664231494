import { handleActionsImmer } from '@pvolve/sdk/src/app/utils';
import Actions from './actions';

export const initialState = {
    seriesCategoryList: []
};

const { seriesCategory: { getSeriesCategory } } = Actions

const Reducer = handleActionsImmer(
    {
        [getSeriesCategory.success]: (draft, data) => {
            draft.seriesCategoryList = data
        }
    },
    initialState,
);

export default Reducer;
