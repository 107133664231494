import { createSelector } from 'reselect';
import { every, forEach, get, isEmpty } from 'lodash';
import {
  IWorkout,
  IWorkoutCategory,
} from '@pvolve/sdk/src/models/contentful_types';
import {
  IState,
  IWorkoutHistoryItem,
  IWorkoutHistoryStatus,
  ContentSelectors,
} from '@pvolve/sdk/src/redux/selectors';
import { format } from 'date-fns';
import { INTERNAL_DATE_FORMAT } from '@pvolve/sdk/src/app/utils';
import { orderBy } from 'lodash';
import { sortWorkoutsByDate } from '../../utils';

const workouts = (state: IState) => state.workouts;

const onboardingCategoryId = (state: IState) => {
  return get(state, 'config.config.services.workouts.planOnboardingWorkoutCategoryId');
};

const stats = (state: IState) => state.workouts.stats || {};

const historyItems = (state: IState) => state.workouts.history.data || [];
const historyLoading = (state: IState) => state.workouts.history.loading;

const historyItemsWithValidWorkouts = createSelector(
  historyItems,
  ContentSelectors.workout.indexedBy.id,
  (items, index) => items.filter(item => index[item.workout_id]),
);

const historyById = createSelector(historyItemsWithValidWorkouts, items => {
  const byWorkoutId: {
    [key: string]: IWorkoutHistoryItem;
  } = {};

  for (const history of items) {
    byWorkoutId[history.workout_id] = history;
  }

  return byWorkoutId;
});

const historyByDate = createSelector(historyItemsWithValidWorkouts, items => {
  const byDate: { [key: string]: IWorkoutHistoryItem[] } = {};
  for (const history of items) {
    const date = new Date(history.started_at);

    byDate[format(date, INTERNAL_DATE_FORMAT)] =
      byDate[format(date, INTERNAL_DATE_FORMAT)] || [];

    byDate[format(date, INTERNAL_DATE_FORMAT)].push(history);
  }

  return byDate;
});

const completedHistoryById = createSelector(historyItemsWithValidWorkouts, items => {
  const byWorkoutId: {
    [key: string]: IWorkoutHistoryItem;
  } = {};

  for (const history of items.filter(item => item?.is_completed)) {
    byWorkoutId[history.workout_id] = history;
  }

  return byWorkoutId;
});

// any history item that is not 'ended'
const resumableHistory = createSelector(historyItemsWithValidWorkouts, items =>
  orderBy(
    items.filter(item => item?.status !== IWorkoutHistoryStatus.session_ended),
    [item => new Date(item.started_at)], ['desc']
  )
);

// any history item that is not 'ended'
const completedHistory = createSelector(historyItemsWithValidWorkouts, items =>
  items.filter(item => item?.is_completed),
);

const completedHistoryByDate = createSelector(historyItemsWithValidWorkouts, items => {
  const byDate: { [key: string]: IWorkoutHistoryItem[] } = {};
  for (const history of items) {
    if (history.is_completed) {
      const date = new Date(history.completed_at);

      byDate[format(date, INTERNAL_DATE_FORMAT)] =
      byDate[format(date, INTERNAL_DATE_FORMAT)] || [];

      byDate[format(date, INTERNAL_DATE_FORMAT)].push(history);
    }
  }
  return byDate;
});

const completedHistoryByWorkoutId = createSelector(completedHistory, items => {
  const byWorkoutId: { [key: string]: IWorkoutHistoryItem[] } = {};

  for (const history of items) {
    const workoutId = history.workout_id;
    if (workoutId) {
      byWorkoutId[workoutId] = byWorkoutId[workoutId] || [];
      byWorkoutId[workoutId].push(history);
    }
  }

  return byWorkoutId;
});

const closedWorkoutsByDate = createSelector(
  historyItemsWithValidWorkouts,
  items => {
    return items?.slice()?.sort((a, b) => b.started_at - a.started_at);
  },
);

const nextWorkoutWhenNotEnrolled = createSelector(
  completedHistoryByWorkoutId,
  ContentSelectors.workout.indexedBy.id,
  ContentSelectors.workout.new,
  (historyByWorkoutId, workoutsById, newWorkouts) => {
    // find the first "new" workout that we haven't done
    for (const workout of newWorkouts) {
      if (!historyByWorkoutId[workout?.sys?.id]) {
        return workout;
      }
    }

    // find any workout that we haven't done
    for (const workout of Object.values(workoutsById)) {
      if (!historyByWorkoutId[workout?.sys?.id]) {
        return workout;
      }
    }
  },
);

const history = historyById;

const statsLoading = createSelector(stats, stats => stats.loading);

const statsReady = createSelector(stats, stats => stats.loadedAt);
const statsData = createSelector(stats, stats => stats.data);

const onboardingWorkouts = createSelector(
  onboardingCategoryId,
  ContentSelectors.workout.indexedBy.id,
  ContentSelectors.workoutCategory.indexedBy.id,
  (id, workoutById, workoutCategoriesById) => {
    const category: IWorkoutCategory = workoutCategoriesById[id];
    const workouts = category?.fields?.workouts || [];
    return workouts.map(workout => workoutById[workout.sys.id]).filter(v => v);
  },
);

const onboardingWorkoutCompleted = createSelector(
  onboardingCategoryId,
  history,
  onboardingWorkouts,
  (id, history, workouts) => {
    const historyForWorkouts = workouts.map(w => history[w.sys.id]);
    const completed =
      !isEmpty(workouts) &&
      every(historyForWorkouts, item => item?.is_completed);

    return completed;
  },
);

const targetedNewWorkouts = createSelector(workouts, workouts => workouts?.targetedNewWorkouts);

const getTargetedNewWorkouts = createSelector(targetedNewWorkouts,
  (targetedNewWorkouts) => {
  
  return targetedNewWorkouts?.map((workout: IWorkout) => {
    return {
      sys: { id: workout?.id}
    };
  });
});

const getTargetedNewWorkoutsSortedByDate = createSelector(targetedNewWorkouts,
  (targetedNewWorkouts) => {

  const sortedWorkouts = sortWorkoutsByDate(targetedNewWorkouts, 'date');
  
  return sortedWorkouts?.map((workout: IWorkout) => {
    return {
      sys: { id: workout?.id}
    };
  });
});

export default {
  workouts,
  onboardingCategoryId,
  history,
  historyByDate,
  historyLoading,
  closedWorkoutsByDate,
  completedHistoryByDate,
  completedHistoryByWorkoutId,
  completedHistoryById,
  nextWorkoutWhenNotEnrolled,
  resumableHistory,
  stats,
  statsLoading,
  statsReady,
  statsData,
  onboardingWorkouts,
  onboardingWorkoutCompleted,
  getTargetedNewWorkouts,
  getTargetedNewWorkoutsSortedByDate,
};
