import { filter, first, includes } from 'lodash';
import { isAfter, isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { createSelector } from 'reselect';
import { IState } from '../../../redux/selectors';

const root = (state: IState) => state.config?.config;

const shopifyUrl = createSelector(root, root => root?.shopify?.url);

const appUrl = createSelector(root, root => root?.hosts[root.environment].app);

const cookieDomain = createSelector(root, root => root?.shopify?.cookieDomain);

const commerce = createSelector(root, root => root.commerce);

const shopifyCookieDomain = createSelector(
  shopifyUrl,
  cookieDomain,
  (shopifyUrl, cookieDomain) => {
    const shopDomain = new URL(shopifyUrl).hostname;
    return cookieDomain || shopDomain;
  },
);

const stripeKey = createSelector(root, root => root?.stripe?.publishableKey);

const redirectPath = createSelector(root, root => root?.cognito?.redirectPath);

const feed = createSelector(root, root => root?.feed);

const maintenance = createSelector(root, root => root?.maintenance);

const maintenanceEnabled = createSelector(
  maintenance,
  maintenance => maintenance?.enabled,
);

const banner = createSelector(root, root => root?.banner);

const promoTop = createSelector(root, root => root?.promoTop);

const promoBottom = createSelector(root, root => root?.promoBottom);

const services = createSelector(root, root => root?.services);

const defaultPromo = createSelector(root, root => root?.defaultPromo);

const orderConfirmationPromo = createSelector(
  root,
  root => root?.orderConfirmationPromo,
);

const header = createSelector(root, root => root?.header);

const filterEnabledConfigs = configs => {
  const enabledConfigs = filter(configs, config => {
    if (config?.enabled) {
      const {
        // Month is 0-based, January = 0, December = 11
        startTimestamp: [startYear, startMonth, startDay, startHour, startMin],
        // Month is 0-based, January = 0, December = 11
        endTimestamp: [endYear, endMonth, endDay, endHour, endMin],
      } = config;

      const now = new Date();
      const startDate = new Date(
        startYear,
        startMonth - 1,
        startDay,
        startHour,
        startMin,
        0,
        0,
      );
      const endDate = new Date(
        endYear,
        endMonth - 1,
        endDay,
        endHour,
        endMin,
        59,
        999,
      );

      const tzStartDate = zonedTimeToUtc(startDate, 'America/New_York');
      const tzEndDate = zonedTimeToUtc(endDate, 'America/New_York');

      return isAfter(now, tzStartDate) && isBefore(now, tzEndDate);
    }

    return false;
  });

  return enabledConfigs || [];
};

const enabledBannerConfigs = createSelector(banner, banner => {
  if (!banner) {
    return [];
  }

  return filterEnabledConfigs(banner);
});

const enabledPromoTopConfigs = createSelector(promoTop, promoTop => {
  if (!promoTop) {
    return [];
  }

  return filterEnabledConfigs(promoTop);
});

const enabledPromoBottomConfigs = createSelector(promoBottom, promoBottom => {
  if (!promoBottom) {
    return [];
  }

  return filterEnabledConfigs(promoBottom);
});

const enabledDefaultPromoConfigs = createSelector(
  defaultPromo,
  defaultPromo => {
    if (!defaultPromo) {
      return [];
    }

    return filterEnabledConfigs(defaultPromo);
  },
);

const enabledOrderConfirmationPromoConfigs = createSelector(
  orderConfirmationPromo,
  orderConfirmationPromo => {
    if (!orderConfirmationPromo) {
      return [];
    }

    return filterEnabledConfigs(orderConfirmationPromo);
  },
);

const enabledHeaderCTAConfigs = createSelector(header, header => {
  if (!header?.cta) {
    return [];
  }

  return filterEnabledConfigs(header.cta);
});

const headerCTA = createSelector(enabledHeaderCTAConfigs, first);

const iosPromo = createSelector(root, root => root?.iosPromo);

const enabledIosPromoConfigs = createSelector(iosPromo, iosPromo => {
  if (!iosPromo) {
    return [];
  }

  return filterEnabledConfigs(iosPromo);
});

const environment = createSelector(root, root => root?.environment);

const allowPurchaseBypass = createSelector(
  environment,
  env => includes(['qa', 'dev'], env) || process.env.NODE_ENV === 'development',
);

const featureFlags = createSelector(root, root => root?.featureFlags);

const onboarding = createSelector(root, root => root?.onboarding);

const trialUrl = createSelector(root, root => root?.trialUrl);

const challenge = createSelector(root, root => root?.challenge)

const hotjarSelector = createSelector(root, root => root?.hotjar);

export default {
  root,
  commerce,
  maintenance,
  maintenanceEnabled,
  feed,
  shopifyUrl,
  appUrl,
  shopifyCookieDomain,
  stripeKey,
  services,
  banner,
  promoTop,
  promoBottom,
  defaultPromo,
  header,
  headerCTA,
  iosPromo,
  orderConfirmationPromo,
  enabledBannerConfigs,
  enabledPromoTopConfigs,
  enabledPromoBottomConfigs,
  enabledDefaultPromoConfigs,
  enabledIosPromoConfigs,
  enabledHeaderCTAConfigs,
  enabledOrderConfirmationPromoConfigs,
  cognito: {
    redirectPath,
  },
  environment,
  allowPurchaseBypass,
  featureFlags,
  onboarding,
  trialUrl,
  challenge,
  hotjarSelector
};
