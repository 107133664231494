// extracted by mini-css-extract-plugin
export const mini = "highlighted-series-hero-module--mini--1flPN";
export const tiny = "highlighted-series-hero-module--tiny--10-u-";
export const newTag = "highlighted-series-hero-module--new-tag--NDGcW";
export const small = "highlighted-series-hero-module--small--3sGf2";
export const medium = "highlighted-series-hero-module--medium--28wSv";
export const p3 = "highlighted-series-hero-module--p3--y2-18";
export const large = "highlighted-series-hero-module--large--Aem3T";
export const p2 = "highlighted-series-hero-module--p2--2s3Bp";
export const l1 = "highlighted-series-hero-module--l1--3OSpB";
export const label = "highlighted-series-hero-module--label--9kd6R";
export const big = "highlighted-series-hero-module--big--9UnKm";
export const p1 = "highlighted-series-hero-module--p1--1baU3";
export const huge = "highlighted-series-hero-module--huge--3XioV";
export const p0 = "highlighted-series-hero-module--p0--3MMUU";
export const huge36 = "highlighted-series-hero-module--huge36--2QrpL";
export const huge52 = "highlighted-series-hero-module--huge52--1FcQb";
export const massive = "highlighted-series-hero-module--massive--1q70G";
export const h1 = "highlighted-series-hero-module--h1--CS3We";
export const h2 = "highlighted-series-hero-module--h2--KruGM";
export const h3 = "highlighted-series-hero-module--h3--3oKt6";
export const h4 = "highlighted-series-hero-module--h4--2RhuY";
export const h5 = "highlighted-series-hero-module--h5--3J8M6";
export const h6 = "highlighted-series-hero-module--h6--p3f1H";
export const accent = "highlighted-series-hero-module--accent--2GJ0T";
export const accenth5 = "highlighted-series-hero-module--accenth5--xutC9";
export const accenth6 = "highlighted-series-hero-module--accenth6--zeSyg";
export const upper = "highlighted-series-hero-module--upper--1buyS";
export const lower = "highlighted-series-hero-module--lower--3nVML";
export const underline = "highlighted-series-hero-module--underline--1sm6b";
export const bold = "highlighted-series-hero-module--bold--K5Vad";
export const semibold = "highlighted-series-hero-module--semibold--xGweO";
export const normal = "highlighted-series-hero-module--normal--1F6os";
export const stacked = "highlighted-series-hero-module--stacked--25ZCj";
export const link = "highlighted-series-hero-module--link--22iNs";
export const textLinks = "highlighted-series-hero-module--text-links--wzNTF";
export const highlightedHero = "highlighted-series-hero-module--highlighted-hero--1KF1D";
export const highlightedCardWrapper = "highlighted-series-hero-module--highlighted-card-wrapper--2zkAy";
export const highlightedCardInfo = "highlighted-series-hero-module--highlighted-card-info--2o-5M";
export const highlightedCardPreview = "highlighted-series-hero-module--highlighted-card-preview--1Orv9";
export const highlightedCardImage = "highlighted-series-hero-module--highlighted-card-image--2v8_4";
export const highlightedCardVideo = "highlighted-series-hero-module--highlighted-card-video--2Ezwq";
export const hightlightedButtonGroup = "highlighted-series-hero-module--hightlighted-button-group--MZRmD";
export const buttonLeft = "highlighted-series-hero-module--button-left--33tbH";
export const buttonRight = "highlighted-series-hero-module--button-right--1xRAV";