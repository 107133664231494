// extracted by mini-css-extract-plugin
export const mini = "border-block-with-button-module--mini--2Dc3D";
export const tiny = "border-block-with-button-module--tiny--3fIxH";
export const small = "border-block-with-button-module--small--i2OPG";
export const medium = "border-block-with-button-module--medium--lO7lK";
export const p3 = "border-block-with-button-module--p3--6Qvbd";
export const large = "border-block-with-button-module--large--1GCxq";
export const p2 = "border-block-with-button-module--p2--28oDq";
export const l1 = "border-block-with-button-module--l1--22ld-";
export const label = "border-block-with-button-module--label--2kkfl";
export const big = "border-block-with-button-module--big--1sfEt";
export const p1 = "border-block-with-button-module--p1--eNIBD";
export const huge = "border-block-with-button-module--huge--1PGPe";
export const p0 = "border-block-with-button-module--p0--2WYfU";
export const huge36 = "border-block-with-button-module--huge36--2CjdO";
export const huge52 = "border-block-with-button-module--huge52--etUe6";
export const massive = "border-block-with-button-module--massive--3l_ig";
export const h1 = "border-block-with-button-module--h1--2mvzd";
export const h2 = "border-block-with-button-module--h2--31mpn";
export const h3 = "border-block-with-button-module--h3--1xgSe";
export const h4 = "border-block-with-button-module--h4--pPcVE";
export const h5 = "border-block-with-button-module--h5--2cxp5";
export const h6 = "border-block-with-button-module--h6--5FSi-";
export const accent = "border-block-with-button-module--accent--2NIuk";
export const accenth5 = "border-block-with-button-module--accenth5--DZhPt";
export const accenth6 = "border-block-with-button-module--accenth6--1_jp8";
export const upper = "border-block-with-button-module--upper--1bjGR";
export const lower = "border-block-with-button-module--lower--1mSJR";
export const underline = "border-block-with-button-module--underline--3nIKW";
export const bold = "border-block-with-button-module--bold--2fUn0";
export const semibold = "border-block-with-button-module--semibold--SMidf";
export const normal = "border-block-with-button-module--normal--26eGa";
export const stacked = "border-block-with-button-module--stacked--3WtWl";
export const link = "border-block-with-button-module--link--PdI3I";
export const textLinks = "border-block-with-button-module--text-links--2wnGV";
export const borderBlockContainer = "border-block-with-button-module--border-block-container--3qdIk";
export const button = "border-block-with-button-module--button--JKpRN";
export const iconHeader = "border-block-with-button-module--icon-header--2sJRl";
export const top = "border-block-with-button-module--top--qxFjf";
export const left = "border-block-with-button-module--left--1XYM0";
export const childrenContainerHorizontal = "border-block-with-button-module--children-container-horizontal--4jWFP";
export const buttonContainer = "border-block-with-button-module--button-container--LUbRF";