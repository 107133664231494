import { get } from 'lodash';
import { handleActionsImmer } from '../../utils';
import Actions from '../../actions';
import { FeedStates, IFeedStation, IMusic } from './types';

export const initialState: IMusic = {
  currentStation: null,
  state: FeedStates.idle,
  stations: [],
  trainerVolume: 0.5,
  volume: 0.5,
};

const {
  music: {
    changeStation,
    pause,
    play,
    state,
    stations,
    setTrainerVolume,
    setVolume,
  },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [changeStation]: (draft: IMusic, { station }: { station: IFeedStation }) => {
      draft.currentStation = station;
    },

    [pause]: (draft: IMusic) => {
      draft.state = FeedStates.paused;
    },

    [play]: (draft: IMusic) => {
      draft.state = FeedStates.playing;
    },

    [setTrainerVolume]: (draft: IMusic, data: number) => {
      draft.trainerVolume = data;
    },

    [state]: (draft: IMusic, data: FeedStates) => {
      draft.state = data;
    },

    [stations]: (draft: IMusic, data: IFeedStation[]) => {
      draft.stations = data;
    },

    [setVolume]: (draft, data: number) => {
      draft.volume = data;
    },
  },
  initialState,
);

export default Reducer;