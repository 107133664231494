import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

import Actions from '@pvolve/sdk/src/app/actions';
import AccountActions from 'src/state/account/actions';
import WebActions from './web/actions';
import ContentV2Actions from './contentV2/actions';
import OrderActions from './orders/actions';
import { SegmentTrackActions } from 'src/state/segment/actions';

const AppActions = createActions({
    INITIAL_PAGE_LOAD: DefaultRoutine,
});

export default {
    ...Actions,
    ...AppActions,
    account: AccountActions.account,
    web: WebActions.web,
    orders: OrderActions.orders,
    promos: ContentV2Actions.promos,
    partner: ContentV2Actions.partner,
    segment: SegmentTrackActions.segment,
};
