import React, { useCallback, useEffect, useState } from 'react';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Link } from 'gatsby';

interface TabsProps extends MuiTabsProps {
    onClick: (...args: any[]) => void;
    sectionName: string;
    selectedTab: number | boolean;
    tabItems: TabItem[];
}

export interface TabItem {
    label: string;
    ctaName?: string;
    pathname?: string;
}

const Tabs = ({ onClick, sectionName, selectedTab, tabItems, ...props }: TabsProps) => {
    const [value, setValue] = useState(selectedTab);

    useEffect(() => {
        setValue(selectedTab);
    }, [selectedTab]);

    const onClickTab = useCallback(
        (e, index: number) => {
            setValue(index);
            onClick(e, index);
        },
        [onClick]
    );

    const tab = (tabItem: TabItem, index: number) => {
        const { label, ctaName, pathname } = tabItem;
        const props: { [k: string]: any } = {};

        if (ctaName) {
            props['data-cta-name'] = ctaName;
        }

        if (pathname) {
            props.component = Link;
            props.to = pathname;
            props.state = { prevPath: pathname };
        }

        return (
            <Tab
                data-test={`${sectionName}-${label}-Button`}
                key={label}
                label={label}
                value={index}
                onClick={(e) => onClickTab(e, index)}
                {...props}
            />
        );
    };

    return (
        <MuiTabs value={value} variant="scrollable" scrollButtons="auto" {...props}>
            {tabItems?.map(tab)}
        </MuiTabs>
    );
};

export default Tabs;
