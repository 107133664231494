const CHALLENGE_COPIES = {
    NO_LOGGED: {
        BANNER_TEXT: 'The New Year’s Ultimate Evolution Challenge Starts Jan 2.',
        BUTTON_TEXT: 'Sign Up',
    },
    REMINDER: {
        BANNER_TEXT: 'REMINDER: The New Year’s Ultimate Evolution Challenge Kicks Off Jan 2.',
    },
    IN_PROGRESS_JOIN: {
        BANNER_TEXT: 'The New Year’s Challenge Is Here!',
        BUTTON_TEXT: 'Join the Challenge',
    },
    IN_PROGRESS_JOINED: {
        BANNER_TEXT: 'The New Year’s Challenge Is Here!',
        BUTTON_TEXT: 'Go to Challenge',
    },
};

const CHALLENGE_INFO = {
    CHALLENGE_LINK: (shopifyUrl: string) => `${shopifyUrl}/pages/ultimate-evolution-challenge`,
    SERIES_PAGE_LINK: '/series/ultimate-evolution-challenge-NYNY1122',
    SERIES_ID: '2rLJjY0s59fXx1mTUXxTRQ',
    SERIES_NAME: 'The New Year’s Ultimate Evolution Challenge',
};

const CHALLENGE_MODAL_COPIES = {
    CHANGE_SERIES_BUTTON: 'Yes, Change Series',
    DONT_CHANGE_SERIES_BUTTON: 'No, Do not change series',
    MODAL_TEXT: (changeFrom: string, changeTo: string) =>
        `Are you sure you want to leave ${changeFrom} and join ${changeTo}?`,
};

const CHALLENGE_SLUG = 'NYNY2023';

export const NYNY2023 = {
    CHALLENGE_COPIES,
    CHALLENGE_INFO,
    CHALLENGE_MODAL_COPIES,
    CHALLENGE_SLUG,
};
