// extracted by mini-css-extract-plugin
export const footerWrapper = "footer-module--footer-wrapper--Ap0Ia";
export const mini = "footer-module--mini--26gDx";
export const tiny = "footer-module--tiny--3stFk";
export const small = "footer-module--small--3hu3A";
export const medium = "footer-module--medium--1TJYk";
export const p3 = "footer-module--p3--A9rZk";
export const large = "footer-module--large--6KuOo";
export const p2 = "footer-module--p2--3CXs0";
export const l1 = "footer-module--l1--3y3ep";
export const label = "footer-module--label--2fiSZ";
export const big = "footer-module--big--oVAo3";
export const p1 = "footer-module--p1--BjASJ";
export const huge = "footer-module--huge--3G52F";
export const p0 = "footer-module--p0--YM2sd";
export const huge36 = "footer-module--huge36--NhSG6";
export const huge52 = "footer-module--huge52--zkRFc";
export const massive = "footer-module--massive--1i30A";
export const h1 = "footer-module--h1--1nnTa";
export const h2 = "footer-module--h2--3jQj9";
export const h3 = "footer-module--h3--3A_8J";
export const h4 = "footer-module--h4--aKCoX";
export const h5 = "footer-module--h5--2_zjc";
export const h6 = "footer-module--h6--11ECt";
export const accent = "footer-module--accent---CJVH";
export const accenth5 = "footer-module--accenth5--2Fe7v";
export const accenth6 = "footer-module--accenth6--3il_8";
export const upper = "footer-module--upper--AEla_";
export const lower = "footer-module--lower--1HzHj";
export const underline = "footer-module--underline--2pOff";
export const bold = "footer-module--bold--2Wi6B";
export const footerLink = "footer-module--footer-link--2e-co";
export const semibold = "footer-module--semibold--JocW4";
export const normal = "footer-module--normal--2mv8G";
export const stacked = "footer-module--stacked--1ERSg";
export const link = "footer-module--link--36SmG";
export const textLinks = "footer-module--text-links--18rYd";
export const logo = "footer-module--logo--1wiU6";
export const sectionTitle = "footer-module--section-title--18JY9";
export const list = "footer-module--list--2Cuvj";
export const copyright = "footer-module--copyright--1B3BS";
export const footerGrid = "footer-module--footer-grid--1wk2G";
export const footerEmailContainer = "footer-module--footer-email-container--11h_p";