import { takeEvery, delay, take, race, put, fork, select } from 'redux-saga/effects';
import { loginRequired, routineSaga, sendTo } from '../../utils/sagas';
import Actions from '../../actions';
import Pvolve from '@pvolve/sdk';
import { VideoEventType } from '../../types';
import { get } from 'lodash';

const {
  content: { loaded: contentLoaded },
  analytics: { eventsPut, track },
  series: { enrolled },
  workouts: { complete, loadHistory, loadStats, loadTargetedNewWorkouts },
} = Actions;

class WorkoutSaga {
  *init() {
    yield takeEvery(contentLoaded, sendTo(loadHistory.trigger));
    yield takeEvery(contentLoaded, sendTo(loadStats.trigger));
    yield takeEvery(loadStats.trigger, loginRequired(saga.stats));
    yield takeEvery(loadHistory.trigger, loginRequired(saga.load));
    yield takeEvery(complete.trigger, loginRequired(saga.complete));
    yield takeEvery(loadTargetedNewWorkouts.trigger, saga.loadTargetedNewWorkouts);
  }

  loadTargetedNewWorkouts = routineSaga({
    routine: loadTargetedNewWorkouts,
    *request() {
        const userCohort = yield select(state => !!state.account ? state.account.userCohort : state.auth.user.user_cohort);
        const options = { tags: `cohort${userCohort},new`, operator: 'all' };
        const response = yield Pvolve.api.contentV2.content.list('workouts', options);
        return response?.items;
      }
  })

  complete = routineSaga({
    routine: complete,
    *request({ payload: { workout_id }}) {
      return yield Pvolve.api.workout.completed_workout(workout_id);
    }
  });

  load = routineSaga({
    routine: loadHistory,
    *request() {
      return yield Pvolve.api.workout.history();
    },
  });

  stats = routineSaga({
    routine: loadStats,
    *request() {
      return yield Pvolve.api.stats.workouts();
    },
  });

  *watchTrackingEvents() {
    const videoCloseEvent = ({ type, payload }) =>
      type === track.toString() &&
      get(payload, 'event_type') === VideoEventType.VIDEO_CLOSE;

    let playerHasClosed = false;

    while (true) {
      const { videoClosed, savedTrackingEvents } = yield race({
        videoClosed: take(videoCloseEvent),
        savedTrackingEvents: take(eventsPut),
      });

      if (videoClosed) {
        playerHasClosed = true;
      }

      if (savedTrackingEvents && playerHasClosed) {
        yield delay(2000);
        yield put(enrolled.trigger());
        yield put(loadStats.trigger());
        yield put(loadHistory.trigger());
        playerHasClosed = false;
      }
    }
  }
}

function* initializeFeed() {
  const feed = yield Pvolve.api.config.find('feed');
  /* TODO

  audioPlayerService.initialize({
    token: feed?.token || 'demo',
    secret: feed?.secret || 'demo',
    enableBackgroundMusic: false,
  });
  */
}

export const saga = new WorkoutSaga();

export default function* combined() {
  yield takeEvery(contentLoaded, sendTo(loadHistory.trigger));
  yield takeEvery(contentLoaded, sendTo(loadStats.trigger));
  yield takeEvery(loadStats.trigger, loginRequired(saga.stats));
  yield takeEvery(loadHistory.trigger, loginRequired(saga.load));
  yield takeEvery(['persist/REHYDRATE', 'SYNC/CONFIG/UPDATE'], initializeFeed);
  yield fork(saga.watchTrackingEvents);
  yield takeEvery(loadTargetedNewWorkouts.trigger, saga.loadTargetedNewWorkouts);
}
