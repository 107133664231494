import { first, parseInt } from 'lodash';
import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Spinner } from 'react-activity';
import classNames from 'classnames';
import Selectors from 'src/state/root-selectors.js';
import Actions from '@pvolve/sdk/src/app/actions';
import { IFeedStation, IState } from '@pvolve/sdk/src/redux/selectors';

import * as Styles from 'src/styles/feed-player.module.scss';
import { useBreakpoints } from 'src/utils/breakpoint-utils';

const connector = connect((state: IState) => ({
    music: Selectors.music.music(state),
}));

interface FeedStationProps {
    station: IFeedStation;
    isActive: boolean;
    isInternationalStation: boolean;
    onPress: (arg: string) => void;
}

const CurrentlyPlaying = ({ active }: { active: boolean }) =>
    active ? <p className={Styles.playingLabel}>currently playing</p> : null;

const FeedStation = ({
    dispatch,
    station,
    isActive,
    onPress,
    isInternationalStation,
}: FeedStationProps & ConnectedProps<typeof connector>) => {
    const { id, name, options } = station;
    const [loading, setLoading] = useState(false);
    const active = isActive;
    const tabletSize = useBreakpoints('lg', 'down');
    const backgroundImage = `url(${options.background_image_url})`;

    const onDefaultPress = useCallback(() => {
        setLoading(true);
        const onFulfill = () => {
            setLoading(false);
            onPress(id);
        };

        dispatch(
            Actions.auth.changeDefaultStation.trigger({
                defaultStationId: name,
                onFulfill,
            })
        );
    }, [dispatch, id]);

    return (
        <div key={id} className={classNames(Styles.stationWrapper, { active })}>
            <button className={Styles.station} onClick={onDefaultPress} style={{ backgroundImage }}>
                {loading && <Spinner size={40} speed={0.75} />}
                {!tabletSize && !isInternationalStation && (
                    <>
                        <h3 className={`${Styles.stationName} bold upper stacked`}>{name}</h3>
                        <CurrentlyPlaying active={active} />
                    </>
                )}
            </button>
            {tabletSize && (
                <div className={Styles.mobileCopy}>
                    <h4 className={`${Styles.stationName} bold upper`}>{name}</h4>
                    <CurrentlyPlaying active={active} />
                </div>
            )}
        </div>
    );
};

export default connector(FeedStation);
