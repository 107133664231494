import CookieTokenStore from '../CookieTokenStore';
import ReduxConfigStore from './ReduxConfigStore';
import ReduxTokenStore from './ReduxTokenStore';
import ReduxAuthStore from './ReduxAuthStore';
import configure from '../configure';
import LocalStorageTokenStore from '../LocalStorageTokenStore';
import TokenStoreFactory from '../TokenStoreFactory';

export default (coreInstance, options) => {
  const {
    tokenStoreOptions,
    store,
    cookies,
    cookieOptions = {
      refresh: {
        expires: 30,
        domain: process.env.AUTH_COOKIE_DOMAIN
      },
      id: {
        expires: 30,
        domain: process.env.AUTH_COOKIE_DOMAIN
      },
    },
    configStoreOptions,
    authStoreOptions,
  } = options;

  const configStore = new ReduxConfigStore(store, configStoreOptions);
  const delegate = new ReduxTokenStore(store, tokenStoreOptions);
  const tokenStore =
    options.tokenStore ||
    new CookieTokenStore({
      cookies,
      delegate,
      cookieOptions,
    });
  const authStore = new ReduxAuthStore(store, authStoreOptions);
  const localStorageTokenStore = new LocalStorageTokenStore();
  const factoryStore = new TokenStoreFactory({
    tokenStore,
    localStorageTokenStore,
  });

  return configure(coreInstance, {
    ...options,
    factoryStore,
    configStore,
    authStore,
  });
};
