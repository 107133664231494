import React, { useMemo } from 'react';

interface MyPlanProviderProps {
    isFTU: boolean;
    children: React.ReactNode;
}

const MyPlanContext = React.createContext({ isFTU: false });

export const MyPlanProvider = ({ children, isFTU }: MyPlanProviderProps) => {
    const value = useMemo(() => {
        return { isFTU };
    }, [isFTU]);
    return <MyPlanContext.Provider value={value}>{children}</MyPlanContext.Provider>;
};

export const useIsFTU = () => {
    return React.useContext(MyPlanContext)?.isFTU;
};
