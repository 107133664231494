import { Components, Theme } from '@mui/material';

const components: Components<Theme> = {
    MuiChip: {
        styleOverrides: {
            root: {
                fontFamily: 'Muller',
            },
        },
    },
};

export default components;
