/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label*/
/* eslint-disable react-hooks/exhaustive-deps */
import { noop } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import classnames from 'classnames';

import { isValidMuxUrl } from 'src/utils/url-utils';

import 'video.js/dist/video-js.css';
import 'src/styles/video-player.scss';

export interface PreviewVideoPlayerOptions {
    id: string;
}

export interface PreviewVideoPlayerProps {
    className?: string;
    onClick?: () => void;
    paused?: boolean;
    pausedAt?: number;
    src: string;
    hasOverlay?: boolean;
}

// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md
const PreviewVideoPlayer = ({
    src,
    onClick = noop,
    className,
    hasOverlay,
}: PreviewVideoPlayerProps) => {
    const videoNode = useRef<HTMLVideoElement>(null);
    const player = useRef<VideoJsPlayer>();

    /**
     * Video.js has a fluid mode that keeps the player sized to a particular aspect ratio.
     * By default, fluid mode will use the intrinsic size of the video once loaded but you
     * can change it with classes or with the aspectRatio option.
     */
    const defaultOptions = {
        autoplay: true,
        muted: true,
        controls: false,
        fluid: true,
        loop: true,
        aspectRatio: '16:9',
        preload: 'auto',
        src: src,
        html5: {
            vhs: {
                enableLowInitialPlayist: true,
                fastQualityChange: true,
            },
        },
    };

    useEffect(() => {
        // componentDidMount
        createPlayer();

        return () => {
            // componentDidUnmount
            disposePlayer();
        };
    }, []);

    useEffect(() => {
        if (player.current && isValidMuxUrl(src)) {
            player.current.src(src);
        }
    }, [src]);

    const initPlayerUI = useCallback(() => {
        if (!player.current) {
            return;
        }

        /**
         * Get video.js components / component classes
         * @see https://docs.videojs.com/tutorial-components.html
         * @see https://docs.videojs.com/component
         */
        const BigPlayButton = videojs.getComponent('BigPlayButton');

        /**
         * Remove the default Video.js UI components
         */
        player.current.removeChild('LoadingSpinner');
        player.current.removeChild('BigPlayButton');
        player.current.removeChild('TextTrackSettings');
        player.current.removeChild('TextTrackDisplay');
        /*
         * Note: Can't remove Control Bar
         * Removing control bar causes JS errors on gatsby route navigation
         */
        player.current.getChild('ControlBar')?.hide();

        /**
         * Create a custom BigPlayButton component
         */
        const PreviewBigPlayButton = videojs.extend(BigPlayButton, {
            constructor: function () {
                BigPlayButton.apply(this, arguments);
                this.controlText('Preview');
            },
            handleClick: function () {
                onClick();
            },
        });

        videojs.registerComponent('PreviewBigPlayButton', PreviewBigPlayButton);

        /**
         * Create a custom ModalDialog component
         */
        const ModalDialog = videojs.getComponent('ModalDialog');
        const previewModal = new ModalDialog(player.current, {
            content: '',
            description: 'Preview video modal overlay',
            label: 'Preview video modal overlay',
            uncloseable: true,
            pauseOnOpen: false,
        });

        player.current.addChild(previewModal);

        /**
         * Open the preview Modal
         */
        previewModal.open();
    }, []);

    const createPlayer = useCallback(() => {
        if (player.current || !isValidMuxUrl(src)) {
            return;
        }

        const initPlayer = () => {
            // instantiate Video.js player using the id of the <video> element
            if (videoNode.current) {
                player.current = videojs(videoNode.current, {
                    ...defaultOptions,
                    sources: [{ src }],
                });
            }
        };

        initPlayer();
        initPlayerUI();
    }, [src, initPlayerUI]);

    const disposePlayer = () => {
        player.current?.dispose();
    };

    const previewVideoContainerStyles = classnames('preview-video-container', {
        [`${className}`]: !!className,
        'has-overlay': hasOverlay,
    });

    return (
        <div className={previewVideoContainerStyles}>
            <div data-vjs-player>
                <video ref={videoNode} className="video-js"></video>
            </div>
        </div>
    );
};

export default PreviewVideoPlayer;
