import { useLocation } from '@reach/router';
import { isHttpsUri, isWebUri } from 'valid-url';
import { navigate } from 'gatsby';
import { includes } from 'lodash';

export const isPvolveDomain = (url: string) => {
    return !!isWebUri(url) && !!new URL(url).hostname.match(/pvolve.com$/);
};

export const isMuxDomain = (url: string) => {
    return !!isHttpsUri(url) && !!new URL(url).hostname.match(/(image|stream)\.mux\.com$/);
};

// This method exists in case we add other criteria for mux urls in the future
export const isValidMuxUrl = (url: string) => {
    return isMuxDomain(url);
};

export const isPathOnly = (url: string) => {
    return !url.match(/^\\w+:\/\//);
};

// This method exists in case we add other criteria to redirect urls in the future
export const isValidRedirectUrl = (redirectUrl: string) => {
    return (
        redirectUrl &&
        (isPathOnly(redirectUrl) ||
            isPvolveDomain(redirectUrl) ||
            new URL(redirectUrl).hostname === 'localhost')
    );
};

export const redirect = (url: string) => {
    if (window && isValidRedirectUrl(url)) {
        window.location.replace(url);
    } else {
        navigate('/');
    }
};

export const linkToExternal = (url?: string, newTab?: boolean) => {
    if (url) {
        window?.open(url, newTab ? '_blank' : '_self')?.focus();
    }
};

export const getTargetWithAnchorInfo: (
    newUrl: string,
    target: any,
    shopifyUrl?: string
) => object = (newUrl, target, shopifyUrl = '') => {
    let href, domain, pathname;
    // get the target with the data attributes (for some cases it's the father)
    let eventTarget = target.closest('[data-cta-name]') || target.closest('[data-test]') || target;

    const isShopifyDomain = shopifyUrl ? newUrl.includes(shopifyUrl) : false;

    if (isShopifyDomain) {
        const indexStartDomain = newUrl.indexOf('//');
        href = newUrl;
        domain = shopifyUrl.substring(indexStartDomain + 2);
        pathname = newUrl.substring(shopifyUrl.length);
    } else {
        const { hostname, origin } = window.location;
        href = origin + newUrl;
        domain = hostname;
        pathname = newUrl;
    }

    const anchorProps = {
        href,
        hostname: domain,
        pathname,
    };

    return Object.assign(eventTarget, { ...anchorProps });
};

// This custom hook is used to store the current location in sessionStorage
export const useSaveUrl = (keyName: string): (() => void) => {
    const location = useLocation();

    const saveInSessionStorage = () => {
        window?.sessionStorage?.setItem(keyName, location?.pathname || '');
    };

    return saveInSessionStorage;
};

// These lines were imported from route-utils.ts which was only being used in one page
export const isStreamingPage = (path: string) => {
    const STREAMING_ROOT_PATH = ['my-plan', 'workouts', 'series', 'trainers'];
    const rootPath = path.split('/')[1];

    return includes(STREAMING_ROOT_PATH, rootPath);
};
