import { createActions } from 'redux-actions';
import { DefaultRoutineActions, DefaultRoutine } from '../../utils';

interface ChallengeActions {
  challenge: {
    list: DefaultRoutineActions,
  };
}

const Actions = <ChallengeActions><unknown>createActions({
  CHALLENGE: {
    LIST: DefaultRoutine,
  },
});

export default Actions;