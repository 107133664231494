import { createActions } from 'redux-actions';
import { FavoriteCollectionName } from '../../../redux/selectors';
import { DefaultRoutineActions, DefaultRoutine } from '../../utils';


interface FavoritesActions {
  favorites: {
    list: DefaultRoutineActions,
    add: DefaultRoutineActions<{ collection: FavoriteCollectionName, id: string }>,
    remove: DefaultRoutineActions<{ collection: FavoriteCollectionName, id: string }>,
  }
}

const Actions = <FavoritesActions><unknown>createActions({
  FAVORITES: {
    LIST: DefaultRoutine,
    ADD: DefaultRoutine,
    REMOVE: DefaultRoutine,
  },
});

export default Actions;