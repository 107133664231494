import React, { useMemo } from 'react';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { v4 as uuidv4 } from 'uuid';

/**
 * Get segment user id or anonymous id from cookie variables. If not existent, return a UUID
 * */
function getUserId(): string {
    const cookieItems = global.document?.cookie?.split('; ');
    const ajs_user_id = () =>
        cookieItems?.find((e) => e.startsWith('ajs_user_id'))?.split('=')[1] ?? null;
    const ajs_anonymous_id = () =>
        cookieItems?.find((e) => e.startsWith('ajs_anonymous_id'))?.split('=')[1] ?? null;
    return ajs_user_id() || ajs_anonymous_id() || uuidv4();
}

// Optimizely Client instance creation
const optimizelyClient = createInstance({
    sdkKey: process.env.PUBLIC_OPTIMIZELY_SDK_KEY,
});

interface ProviderProps {
    children: React.ReactNode;
}

/**
 * Optimizely Provider Custom hook to wrap app components with feature flags context after instance creation
 * */
const useOptimizelyProvider = () => {
    const id = useMemo(() => getUserId(), []);
    const OptProvider = useMemo(() => {
        // Wrapper
        const Provider = ({ children }: ProviderProps) => (
            <OptimizelyProvider
                optimizely={optimizelyClient}
                timeout={1000}
                user={{
                    id,
                    attributes: { id },
                }}
            >
                {children}
            </OptimizelyProvider>
        );
        return Provider;
    }, [id]);
    return { OptProvider };
};

export default useOptimizelyProvider;
