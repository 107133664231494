import { fork } from 'redux-saga/effects';

import BootSagas from '@pvolve/sdk/src/app/modules/boot/sagas';

import './boot/system';
import './boot/app';
import './boot/user';

export default function* rootSaga() {
    yield fork(BootSagas);
}
