import { merge } from 'lodash';
import { TokenTypes } from './TokenStore';
import { ITokenStore } from './TokenStoreFactory';

const KEY = 'tokens';

export default class LocalStorageTokenStore implements ITokenStore {
  clear(): void {
    localStorage.removeItem(KEY);
    localStorage.removeItem('refresh');
    localStorage.removeItem('id');
  }

  updateTokens(tokens: { [key in TokenTypes]: string | null }): void {
    localStorage.setItem(KEY, JSON.stringify(merge(this.getToken(), tokens)));
  }

  get access() {
    return this.getToken()?.access;
  }
  get id() {
    return this.getToken()?.id;
  }
  get refresh() {
    return this.getToken()?.refresh;
  }

  getToken(): { [key in TokenTypes]: string | null } {
    const token = localStorage.getItem(KEY);
    if (!!token) {
      return JSON.parse(token);
    }
  }
}
