import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import { BannerWithButton } from 'src/components/shared';
import ConfirmationModal from 'src/components/shared/ConfirmationModal';

// Redux
import Actions from '@pvolve/sdk/src/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import { ContentSelectors } from '@pvolve/sdk/src/redux/selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

// Utils
import { navigate } from 'gatsby';
import { linkToExternal } from 'src/utils/url-utils';
import moment from 'moment';

// Constants
import { NYNY2023 } from 'src/constants/challenges-constants';
import { SHOPIFY_URL_DEFAULT } from 'src/constants/url-constants';

interface NYNYBannerWithButtonProps {
    path: string;
    setBannerShown: (value: React.SetStateAction<boolean>) => void;
}

interface NYNY2023DatesProps {
    endChallenge: string;
    endPreChallenge: string;
    startChallenge: string;
    startPreChallenge: string;
}

const NYNYBannerWithButton = ({ path, setBannerShown }: NYNYBannerWithButtonProps) => {
    const enrolledSeries = useSelector((state) => PvolveSelectors.series.enrollment.current(state));
    const enrolledSeriesId = enrolledSeries?.series_id;
    const enrolledSeriesObject = useSelector(
        (state) => ContentSelectors.series.indexedBy.id(state)[enrolledSeriesId]?.fields
    );
    const shopifyUrl: string = useSelector(Selectors.config.shopifyUrl) || SHOPIFY_URL_DEFAULT;
    const challenge = useSelector(Selectors.config.challenge);
    const signedUpChallenges = useSelector(Selectors.challenge.signedUpChallenges);
    const isInMyPlan = !!path.match(/home\/?$/) || !!path.match(/home\/?ftu$/);

    const dispatch = useDispatch();
    const [isSignedUpForChallenge, setIsSignedUpForChallenge] = useState(false);
    const [NYNY2023Dates, setNYNY2023Dates] = useState<NYNY2023DatesProps>();
    const [newYearChallengeEnabled, setNewYearChallengeEnabled] = useState(false);
    const [newYearChallengeModalOpen, setNewYearChallengeModalOpen] = useState(false);
    const [newYearChallengeBannerProps, setnewYearChallengeBannerProps] = useState<{
        buttonAction?: () => void;
        bodyText: string;
        buttonText?: string;
            } | null>(null);

    const joinNYNYSeries = useCallback(() => {
        dispatch(Actions.series.join.trigger({ seriesId: NYNY2023.CHALLENGE_INFO.SERIES_ID }));
    }, [dispatch]);

    const onJoinSeriesAgree = useCallback(() => {
        joinNYNYSeries();
        navigate(NYNY2023.CHALLENGE_INFO.SERIES_PAGE_LINK);
    }, [joinNYNYSeries]);

    const onJoinClick = useCallback(() => {
        if (!enrolledSeries) {
            onJoinSeriesAgree();
        } else {
            setNewYearChallengeModalOpen(true);
        }
    }, [onJoinSeriesAgree, enrolledSeries]);

    const linkToChallengePage = useCallback(() => {
        linkToExternal(NYNY2023.CHALLENGE_INFO.CHALLENGE_LINK(shopifyUrl));
    }, [shopifyUrl]);

    const linkToChallengeSeriesPage = useCallback(() => {
        navigate(NYNY2023.CHALLENGE_INFO.SERIES_PAGE_LINK);
    }, []);

    const inclusiveMomentConfig: [null, '[]'] = useMemo(() => [null, '[]'], []);

    const currentDate = moment(moment().format('YYYY-MM-DD'));

    const bannerEnabled = currentDate.isBetween(
        NYNY2023Dates?.startPreChallenge,
        NYNY2023Dates?.endChallenge,
        ...inclusiveMomentConfig
    );

    useEffect(() => {
        if (NYNY2023Dates) {
            if (bannerEnabled) {
                setBannerShown(true);
            }

            const nyPreChallengeInProgress = currentDate.isBetween(
                NYNY2023Dates?.startPreChallenge,
                NYNY2023Dates?.endPreChallenge,
                ...inclusiveMomentConfig
            );
            const nyChallengeInProgress = currentDate.isBetween(
                NYNY2023Dates?.startChallenge,
                NYNY2023Dates?.endChallenge,
                ...inclusiveMomentConfig
            );

            if (nyPreChallengeInProgress) {
                if (!isSignedUpForChallenge) {
                    setnewYearChallengeBannerProps({
                        bodyText: NYNY2023.CHALLENGE_COPIES['NO_LOGGED'].BANNER_TEXT,
                        buttonText: NYNY2023.CHALLENGE_COPIES['NO_LOGGED'].BUTTON_TEXT,
                        buttonAction: linkToChallengePage,
                    });
                } else {
                    setnewYearChallengeBannerProps({
                        bodyText: NYNY2023.CHALLENGE_COPIES['REMINDER'].BANNER_TEXT,
                    });
                }
            } else if (nyChallengeInProgress) {
                if (enrolledSeries?.series_id === NYNY2023.CHALLENGE_INFO.SERIES_ID) {
                    setnewYearChallengeBannerProps({
                        bodyText: NYNY2023.CHALLENGE_COPIES['IN_PROGRESS_JOINED'].BANNER_TEXT,
                        buttonText: NYNY2023.CHALLENGE_COPIES['IN_PROGRESS_JOINED'].BUTTON_TEXT,
                        buttonAction: linkToChallengeSeriesPage,
                    });
                } else {
                    setnewYearChallengeBannerProps({
                        bodyText: NYNY2023.CHALLENGE_COPIES['IN_PROGRESS_JOIN'].BANNER_TEXT,
                        buttonText: NYNY2023.CHALLENGE_COPIES['IN_PROGRESS_JOIN'].BUTTON_TEXT,
                        buttonAction: onJoinClick,
                    });
                }
            }
            setNewYearChallengeEnabled(bannerEnabled && isInMyPlan);
        }
    }, [isSignedUpForChallenge, NYNY2023Dates, bannerEnabled]);

    useEffect(() => {
        if (signedUpChallenges) {
            const isSignedUp = signedUpChallenges.some(
                (challenge) => challenge.name === NYNY2023.CHALLENGE_SLUG
            );
            setIsSignedUpForChallenge(isSignedUp);
        }
    }, [signedUpChallenges]);

    useEffect(() => {
        if (challenge) {
            setNYNY2023Dates(challenge.NYNY2023);
        }
    }, [challenge]);

    return (
        <>
            {newYearChallengeEnabled && newYearChallengeBannerProps && (
                <>
                    <BannerWithButton {...newYearChallengeBannerProps} className="display--flex" />
                    <ConfirmationModal
                        modalOpen={newYearChallengeModalOpen}
                        agreeLabel={NYNY2023.CHALLENGE_MODAL_COPIES.CHANGE_SERIES_BUTTON}
                        cancelLabel={NYNY2023.CHALLENGE_MODAL_COPIES.DONT_CHANGE_SERIES_BUTTON}
                        modalText={NYNY2023.CHALLENGE_MODAL_COPIES.MODAL_TEXT(
                            enrolledSeriesObject?.name,
                            NYNY2023.CHALLENGE_INFO.SERIES_NAME
                        )}
                        setModalOpen={setNewYearChallengeModalOpen}
                        onAgree={onJoinSeriesAgree}
                    />
                </>
            )}
        </>
    );
};

export default NYNYBannerWithButton;
