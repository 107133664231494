import { DefaultRoutine, DefaultRoutineActions } from '@pvolve/sdk/src/app/utils';
import { createActions } from 'redux-actions';

export interface ContentV2ActionsTypes {
    partner: {
        show: DefaultRoutineActions<{ slug: string }>;
    };

    promos: {
        list: DefaultRoutineActions;
    };
    series: {
        list: DefaultRoutineActions;
        show: DefaultRoutineActions<{ id: string }>;
    };
    workoutCategories: {
        list: DefaultRoutineActions;
        show: DefaultRoutineActions<{ id: string }>;
    };
    workouts: {
        new: DefaultRoutineActions;
        list: DefaultRoutineActions<{ ids?: string }>;
        show: DefaultRoutineActions<{ id: string }>;
    };
}

const ContentV2Actions = (createActions({
    PARTNER: {
        SHOW: DefaultRoutine,
    },
    PROMOS: {
        LIST: DefaultRoutine,
    },
    SERIES: {
        LIST: DefaultRoutine,
        SHOW: DefaultRoutine,
    },
    WORKOUT_CATEGORIES: {
        list: DefaultRoutine,
        show: DefaultRoutine,
    },
    WORKOUTS: {
        NEW: DefaultRoutine,
        LIST: DefaultRoutine,
        SHOW: DefaultRoutine,
    },
}) as unknown) as ContentV2ActionsTypes;

export default ContentV2Actions;
