import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from 'semantic-ui-react';

import * as iconTextStyles from 'src/styles/icon-text.module.scss';

const IconText = ({
    icon,
    iconSize,
    iconStyles,
    header,
    content,
    className,
    children,
    ...rest
}) => {
    const containerClasses = classnames({
        [`${iconTextStyles.iconTextContainer}`]: true,
        [`${className}`]: !!className,
    });

    const contentClasses = classnames({
        [`${iconTextStyles.iconContent}`]: true,
        [`${iconTextStyles[iconSize]}`]: true,
    });

    const value = children || content;

    return (
        <div className={containerClasses} {...rest}>
            <Icon className={iconTextStyles.icon} name={icon} size={iconSize} {...iconStyles} />
            <div className={contentClasses}>
                {header && <p className={iconTextStyles.iconHeader}>{header}</p>}
                {value && <p className="secondaryText">{value}</p>}
            </div>
        </div>
    );
};

IconText.propTypes = {
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.oneOf(['small', 'large', 'big', 'huge']),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.element,
    iconStyles: PropTypes.object,
};

IconText.defaultProps = {
    iconSize: 'large',
    iconStyles: {},
};

export default IconText;
