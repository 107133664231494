import React, { useCallback, useEffect, useRef, useState } from 'react';
import Img from 'gatsby-image';
import { Grid } from 'semantic-ui-react';
import { useStaticQuery, graphql } from 'gatsby';

import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import * as maintenanceStyles from 'src/styles/maintenance.module.scss';
import { connect, ConnectedProps } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/web/actions';
import { State } from 'src/state/root-state';

const connector = connect(null, {
    authorizeInternalAccess: (password: string) =>
        Actions.web.authorizeInternalAccess.trigger({ password }),
});

const CLICK_TIMEOUT = 10000;
const CLICKS_REQUIRED = 10;

export const MaintenancePage = connector(
    ({ authorizeInternalAccess }: ConnectedProps<typeof connector>) => {
        const { appStoreButton, playStoreButton, whiteLogo } = useStaticQuery(graphql`
            query {
                appStoreButton: file(relativePath: { eq: "app-store-badge_1.png" }) {
                    childImageSharp {
                        fixed(width: 155) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                playStoreButton: file(relativePath: { eq: "google-play-badge_1_1.png" }) {
                    childImageSharp {
                        fixed(width: 155) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                whiteLogo: file(relativePath: { eq: "logo-horizontal-white.png" }) {
                    childImageSharp {
                        fixed(width: 152) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `);

        const [logoClicks, setLogoClicks] = useState(0);
        const timer = useRef<NodeJS.Timeout>();
        const [showPasswordField, setShowPasswordField] = useState(false);
        const [password, setPassword] = useState('');

        // when this component is mounted no scrolling, when not mounted go back. -
        // thanks blake
        useEffect(() => {
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.overflow = 'auto';
            };
        }, []);

        const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
        }, []);

        const changeLogoClicks = useCallback(() => {
            if (logoClicks === 0) {
                timer.current = setTimeout(() => setLogoClicks(0), CLICK_TIMEOUT);
            } else if (logoClicks >= CLICKS_REQUIRED) {
                setShowPasswordField(true);
            }
            setLogoClicks((clicks) => clicks + 1);
        }, [logoClicks]);

        const onCheckPassword = useCallback(
            (e: React.ChangeEvent<HTMLFormElement>) => {
                e.preventDefault();
                authorizeInternalAccess(password);
            },
            [password, authorizeInternalAccess]
        );

        const { widths } = SUI_GRID_CENTERED;

        const appStoreUrl = 'https://apps.apple.com/us/app/p-volve/id1429328712';
        const googlePlayUrl =
            'https://play.google.com/store/apps/details?id=com.pvolve.my&hl=en_US&gl=US';

        return (
            <div className={`${maintenanceStyles.wrapper}`}>
                <Grid container textAlign="center" centered>
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                            verticalAlign="middle"
                        >
                            <div onClick={changeLogoClicks}>
                                <Img fixed={whiteLogo.childImageSharp.fixed} />
                            </div>
                            <h1 className="upper color-pv-sea bold margin-bottom--small">
                                We've got some big updates on the way
                            </h1>
                            <div className={maintenanceStyles.subText}>
                                <p className="color-pv-sea margin-bottom--large">
                                    The website will be down for a couple of hours while we make
                                    some exciting changes.
                                </p>
                                <p className="color-pv-sea margin-bottom--large">
                                    In the meantime, please visit us on iOS and Android.
                                </p>
                            </div>
                            <div className={maintenanceStyles.logosContainer}>
                                <a href={appStoreUrl} className={maintenanceStyles.appStore}>
                                    <Img fixed={appStoreButton.childImageSharp.fixed} />
                                </a>
                                <a href={googlePlayUrl}>
                                    <Img fixed={playStoreButton.childImageSharp.fixed} />
                                </a>
                            </div>
                        </Grid.Column>
                        {showPasswordField && (
                            <Grid.Column>
                                <form onSubmit={onCheckPassword}>
                                    <input
                                        type="password"
                                        onChange={onPasswordChange}
                                        value={password}
                                    />
                                </form>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
);

const maintenancePageConnector = connect((state: State) => ({
    enabled:
        Selectors.config.maintenanceEnabled(state) && !Selectors.web.internalAccessEnabled(state),
}));
const MaintenancePageSwitcherComponent: React.FC<ConnectedProps<
    typeof maintenancePageConnector
>> = ({ children, enabled }) => (enabled ? <MaintenancePage /> : <>{children}</>);

export const MaintenanceSwitcher: React.FC = maintenancePageConnector(
    MaintenancePageSwitcherComponent
);
