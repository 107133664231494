import React from 'react';
import Helmet from 'react-helmet';

const ZendeskChat = () => {
    // This code let the widget only in My Plan page
    /*     let path = window.location.href;

    useEffect(() => {
        if (!!window.zE) {
                    if (path.includes('home')) {
            window.zE.show();
        } else {
            window.zE.hide();
        }
        }
    }, [path]); */

    return (
        <Helmet>
            <script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=22b10c27-378e-4606-b4d2-28b25f6a2eb5"
            ></script>
        </Helmet>
    );
};

export default ZendeskChat;
