import { createMigrate } from 'redux-persist';
import { initialState as AccountInitialState } from 'src/state/account/reducer';
import { initialState as MusicInitialState } from '@pvolve/sdk/src/app/modules/music/reducer';
import { initialState as SubscriptionsInitialState } from '@pvolve/sdk/src/app/modules/subscriptions/reducer';

export const persistStoreMigrate = createMigrate({
    1: (state) => ({ ...state, account: AccountInitialState, tokens: {} }),
    2: (state) => ({ ...state, music: MusicInitialState }),
    3: (state) => ({ ...state, subscriptions: SubscriptionsInitialState }),
});

export const version = 3;
