import { Draft } from 'immer';
import { handleActionsImmer } from '@pvolve/sdk/src/app/utils';
import Actions from './actions';
import WebActions from '../web/actions';

export interface OrdersState {
    draftId?: string;
}

export const initialState: OrdersState = {
    draftId: undefined,
};

const { orders } = Actions;

const OrdersReducer = handleActionsImmer(
    {
        [orders.setDraftId]: (draft: Draft<OrdersState>, { id }) => {
            draft.draftId = id;
        },
        [orders.clearDraftId]: (draft: Draft<OrdersState>) => {
            delete draft.draftId;
        },
    },
    initialState
);

export default OrdersReducer;
