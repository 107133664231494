import { BasicActionFunction, DefaultRoutineActions } from '@pvolve/sdk/src/app/utils';
import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';

export interface WebActions {
    web: {
        checkoutPageLoaded: BasicActionFunction;
        checkoutComplete: BasicActionFunction;
        checkoutSocialAuthTriggered: BasicActionFunction;
        checkoutSocialAuthComplete: BasicActionFunction;
        checkoutEmailAuthLookup: BasicActionFunction;
        checkoutEmailAuthComplete: BasicActionFunction;
        checkoutAddressComplete: BasicActionFunction;
        checkoutShippingComplete: BasicActionFunction;
        checkoutPromo: BasicActionFunction;
        checkoutValidCreditCard: BasicActionFunction;
        handleLoggedIn: DefaultRoutineActions;
        handleLoggedOut: DefaultRoutineActions;
        handleLogInAs: DefaultRoutineActions<{ userId: string }>;
        handlePageLoad: DefaultRoutineActions;
        loginRequired: BasicActionFunction<{ state: any; replace?: boolean }>;
        startEntitlementsWatcher: DefaultRoutineActions;
        setClearShopifyCartCookie: DefaultRoutineActions;
        authorizeInternalAccess: DefaultRoutineActions<{ password: string }>;
    };
}

const Actions = (createActions({
    WEB: {
        CHECKOUT_PAGE_LOADED: undefined,
        CHECKOUT_COMPLETE: undefined,
        CHECKOUT_SOCIAL_AUTH_TRIGGERED: undefined,
        CHECKOUT_SOCIAL_AUTH_COMPLETE: undefined,
        CHECKOUT_EMAIL_AUTH_LOOKUP: undefined,
        CHECKOUT_EMAIL_AUTH_COMPLETE: undefined,
        CHECKOUT_ADDRESS_COMPLETE: undefined,
        CHECKOUT_SHIPPING_COMPLETE: undefined,
        CHECKOUT_PROMO: undefined,
        CHECKOUT_VALID_CREDIT_CARD: undefined,
        HANDLE_LOGGED_IN: DefaultRoutine,
        HANDLE_LOGGED_OUT: DefaultRoutine,
        HANDLE_LOG_IN_AS: DefaultRoutine,
        HANDLE_PAGE_LOAD: DefaultRoutine,
        LOGIN_REQUIRED: undefined,
        START_ENTITLEMENTS_WATCHER: DefaultRoutine,
        SET_CLEAR_SHOPIFY_CART_COOKIE: DefaultRoutine,
        AUTHORIZE_INTERNAL_ACCESS: DefaultRoutine,
    },
}) as unknown) as WebActions;

export default Actions;
