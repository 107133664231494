import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Selectors from 'src/state/root-selectors';

import * as startTrialStyles from 'src/styles/start-trial.module.scss';
import Actions from 'src/state/root-actions';

const connector = connect((state: IState) => ({
    entitled: Selectors.auth.entitled(state),
    trialQueryURL: Selectors.subscriptions.trialQueryURL(state),
}));

const StartTrial = ({ entitled, trialQueryURL }: ConnectedProps<typeof connector>) => {
    const dispatch = useDispatch();
    const onClick = useCallback(
        (event, data) =>
            // Segment Track event for CTAClicked
            dispatch(
                Actions.segment.track.ctaClicked({
                    cta_name: data?.datatype,
                    event,
                })
            ),
        [dispatch]
    );
    return !entitled ? (
        <div className={classnames('page-section', startTrialStyles.wrapper)}>
            <Grid container stackable>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={12}>
                        <h1 className="accent">Go on demand</h1>
                        <h5 className="upper margin--0">
                            Access your workouts anytime, anywhere on iPhone, Android Phone, Android
                            Tablet and Web.
                        </h5>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={4}>
                        <Button
                            datatype="freeTrial-bottom"
                            as="a"
                            href={trialQueryURL}
                            onClick={onClick}
                            secondary
                        >
                            Start free trial
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    ) : null;
};

export default connector(StartTrial);
