import React from 'react';
import Img, { FixedObject } from 'gatsby-image';
import classnames from 'classnames';

import { Link, useStaticQuery, graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import { Icon, ResponsivePadding } from 'src/components/shared';

import * as Styles from 'src/styles/featured-trainers.module.scss';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

export interface Instructor {
    bio: {
        bio: string;
    };
    image: {
        fixed: FixedObject;
    };
    name: string;
    slug: string;
    active: boolean;
}

interface FeaturedTrainersProps {
    from?: string;
    instructors: Instructor[]; // HELP
}

const FeaturedTrainers = ({ instructors, from }: FeaturedTrainersProps) => {
    const { placeholderImage } = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(
                    relativePath: { eq: "placeholders/placeholder-trainer.png" }
                ) {
                    childImageSharp {
                        fixed(width: 398, quality: 80) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `
    );

    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);

    if (instructors === null) {
        return null;
    }

    const headlineClasses = classnames('bold', 'upper', {
        [`${Styles.trainerName}`]: true,
    });

    const trainerBioClasses = classnames('p1', {
        [`${Styles.bio}`]: true,
    });

    return (
        <div className={Styles.featuredTrainers}>
            <ResponsivePadding>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h2 className="bold upper">
                                {instructors.length > 1 ? 'Featured Trainers' : 'Featured Trainer'}
                            </h2>
                        </Grid.Column>
                    </Grid.Row>
                    {instructors.map((instructor, index: number) => {
                        const isActive = instructor?.active;
                        const bio = instructor?.bio?.bio ? instructor.bio.bio : 'Bio Coming Soon';
                        const instructorNameWithoutPeriod = instructor?.name
                            .toString()
                            .replace(/\.$/, '');

                        const imageProps = {
                            alt: `${instructorNameWithoutPeriod}'s trainer image`,
                            className: 'circle-image',
                        };

                        const rowClassnames = classnames({
                            [`${Styles.reversedRow}`]: index % 2,
                        });

                        return isTabletPortraitOrBelow ? (
                            <div className="margin-bottom--large" key={`trainer-${index}`}>
                                <Grid.Row
                                    className={`${Styles.mobileInstructorRow} ${rowClassnames}`}
                                    columns={2}
                                    verticalAlign="middle"
                                >
                                    <Img
                                        fixed={
                                            instructor?.image?.fixed
                                                ? instructor.image.fixed
                                                : placeholderImage.childImageSharp.fixed
                                        }
                                        {...imageProps}
                                    />

                                    <h5 className={headlineClasses}>{instructor.name}</h5>
                                </Grid.Row>

                                <Grid.Row>
                                    <p className={trainerBioClasses}>{bio}</p>
                                    {isActive && (
                                        <Link
                                            to={`/trainers/${instructor.slug}`}
                                            className={`${Styles.seeAllButton} alignItems--center`}
                                            state={{ from }}
                                        >
                                            <h6 className="bold upper">
                                                See All {instructorNameWithoutPeriod}'s Workouts
                                            </h6>
                                            <Icon size="small" name="arrow right" />
                                        </Link>
                                    )}
                                </Grid.Row>
                            </div>
                        ) : (
                            <Grid.Row
                                className={`margin-bottom--large ${rowClassnames}`}
                                key={`trainer-${index}`}
                                verticalAlign="middle"
                            >
                                <Grid.Column width={5}>
                                    <Img
                                        fixed={
                                            instructor?.image?.fixed
                                                ? instructor.image.fixed
                                                : placeholderImage.childImageSharp.fixed
                                        }
                                        {...imageProps}
                                    />
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <h5 className={headlineClasses}>{instructor.name}</h5>
                                    <p className={trainerBioClasses}>{bio}</p>
                                    {isActive && (
                                        <Link
                                            to={`/trainers/${instructor.slug}`}
                                            className={`${Styles.seeAllButton} alignItems--center`}
                                            state={{ from }}
                                        >
                                            <h6 className="bold upper">
                                                See All {instructorNameWithoutPeriod}'s Workouts
                                            </h6>
                                            <Icon size="small" name="arrow right" />
                                        </Link>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
                </Grid>
            </ResponsivePadding>
        </div>
    );
};

export default FeaturedTrainers;
