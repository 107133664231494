import { styled } from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const Styled = {
    Dialog: styled(Dialog)(({ theme }) => ({
        '.MuiDialog-paperWidthXs': {
            maxWidth: 521,
            border: `3px solid ${theme.palette.tertiary.outlinedHoverBackground}`,

            [theme.breakpoints.down('sm')]: {
                maxWidth: 321,
            },
        },
    })),

    DialogHeader: styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: theme.palette.tertiary.outlinedHoverBackground,
        color: theme.palette.tertiary.main,
        padding: theme.spacing(2),
    })),

    DialogTitle: styled(Typography)(() => ({
        padding: 0,
        marginTop: 5,
    })),

    DialogContent: styled(DialogContent)(({ theme }) => ({
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'center',
        padding: '17px 127px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: '17px 27px 24px',
        },
    })),

    Content: styled(Typography)(({ theme }) => ({
        textAlign: 'center',
        color: theme.palette.primary.main,
        textTransform: 'inherit',
    })),

    Form: styled('form')(() => ({
        marginTop: 3,
    })),

    TextField: styled(TextField)(({ theme }) => ({
        width: '100%',
        margin: theme.spacing(0.5, 0),

        '.MuiOutlinedInput-input': {
            paddingTop: 8.5,
            paddingBottom: 8.5,
            border: `1px solid ${theme.palette.secondary.dark}`,
        },
    })),

    Button: styled(Button)(({ theme }) => ({
        marginTop: theme.spacing(1.5),
    })),

    Alert: styled(Alert)(({ theme }) => ({
        background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
        letterSpacing: 0.15,
        padding: '0 13px',
        margin: '12px 0 4px',

        '.MuiAlert-icon': {
            color: theme.palette.warning.main,
        },
    })),
};

export default Styled;
