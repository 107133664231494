/**
 * Custom Track Events - Set up alfabetically and include Event description as it is on Segment Tracking Plan
 * @key (string) Camel case syntax for proper integration with redux actions and sagas eventmapper
 * @value (string) Event name
 */
export const custom_track_events = {
    /**
     * User initiated the order process (a transaction is created); includes Custom Checkout; excludes free trial */
    checkoutStarted: 'Checkout Started',
    /**
     * User completed a checkout step */
    checkoutStepCompleted: 'Checkout Step Completed',
    /**
     * User viewed a checkout step */
    checkoutStepViewed: 'Checkout Step Viewed',
    /**
     * User added payment information */
    paymentInfoEntered: 'Payment Info Entered',
    /**
     * User had viewed the thank you page */
    thankyouPageViewed: 'Thankyou Page Viewed',
    /**
     * User completes trial checkout flow successfull */
    trialOrderCompleted: 'Trial Order Completed',
    /**
     * User added payment information */
    trialPaymentInfoEntered: 'Trial Payment Info Entered',
    /**
     * User clicks a CTA that brings them into the Trial Signup Flow */
    trialSignupStarted: 'Trial Signup Started',
    /**
     * User viewed a trial signup step */
    trialSignupStepViewed: 'Trial Signup Step Viewed',
    /**
     * User completed a trial signup step (user clicks the button to go to the next step) */
    trialSignupStepCompleted: 'Trial Signup Step Completed',
} as const;

/**
 * Custom Page Events
 * List of pathnames to be tracked as Page view events
 */
export const custom_page_events = ['/checkout/'];
