import classNames from 'classnames';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import * as Styles from 'src/styles/modal-with-image.module.scss';
interface ModalWithImageProps {
    imageUrl: string;
    imageAltText: string;
    children?: React.ReactNode;
}

const ModalWithImage = ({ imageUrl, imageAltText, children }: ModalWithImageProps) => {
    return (
        <div className={Styles.modalWrapper}>
            <Grid className={Styles.modalContent} columns={2}>
                <Grid.Column className={Styles.modalImageCol}>
                    <img src={imageUrl} alt={imageAltText}></img>
                </Grid.Column>
                <Grid.Column className={`${Styles.modalChildrenCol} padding--0`}>
                    {children}
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default ModalWithImage;
