import React, { memo, useContext } from 'react';
import Helmet from 'react-helmet';

import classNames from 'classnames';
import { LayoutContext } from 'src/components/layout/LayoutContext';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import Selectors from 'src/state/root-selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Header from 'src/components/layout/header';
import PlainHeader, { PlainHeaderTheme } from 'src/components/layout/PlainHeader';
import Footer from 'src/components/layout/Footer';
import PromoBanners from 'src/components/layout/PromoBanner';
import GetTheApp from 'src/components/GetTheApp';
import { useDeviceDetect } from 'src/utils/breakpoint-utils';
import { isStreamingPage } from 'src/utils/url-utils';

import 'semantic-ui-less/semantic.less';
import { isEmpty } from 'lodash';

//TODO: fill out these anys
interface PageProps {
    children: React.ReactNode;
    pageContext: any;
}

interface PageHeaderProps {
    pathname: string;
    plainLayout: boolean;
    theme: PlainHeaderTheme;
}

const PageHeader = memo(({ pathname, plainLayout, theme }: PageHeaderProps) => {
    if (plainLayout) {
        return <PlainHeader theme={theme} />;
    }

    return <Header path={pathname} />;
});

const Page = ({ children, pageContext }: PageProps) => {
    const themeContext = useContext(LayoutContext);
    const theme = !isEmpty(themeContext?.layoutData) ? themeContext?.layoutData : pageContext.theme;

    const { pathname } = useLocation();
    const device = useDeviceDetect();
    const featureFlags = useSelector(PvolveSelectors.config.featureFlags);
    const hasBanner = useSelector(Selectors.promos.hasBanners);

    const isMobile = device?.type === 'mobile';
    const streamingPage = isStreamingPage(pathname);
    const hideStreamingPages = featureFlags?.responsiveWeb?.enabled ? false : true;

    const plainLayout = theme?.layout === 'plain';
    const noLayout = theme?.layout === 'none';

    const hasFooter = !plainLayout && !noLayout;
    const hasHeader = !noLayout;

    return (
        <div className={classNames('pageContainer', { 'page-banner': hasBanner })}>
            <Helmet>
                {/**
                 * Responsive viewports
                 * @see https://developer.mozilla.org/en-US/docs/Mozilla/Mobile/Viewport_meta_tag
                 */}
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Helmet>
            {hasHeader && (
                <>
                    <PromoBanners />
                    <PageHeader pathname={pathname} plainLayout={plainLayout} theme={theme} />
                </>
            )}
            <>{isMobile && streamingPage && hideStreamingPages ? <GetTheApp /> : children}</>
            {hasFooter && <Footer />}
        </div>
    );
};

export default memo(Page);
