import React from 'react';
import classNames from 'classnames';
import { Icon } from 'src/components/shared';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { useStaticQuery, graphql } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import BlackPvolveLogo from 'src/assets/pvolve-logo.svg';
import WhitePvolveLogo from 'src/assets/pvolve-logo-white.svg';
import * as Styles from 'src/styles/plain-header.module.scss';
import Colors from 'src/utils/colors';

const connector = connect((state: IState) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

interface PartnerLogoType {
    width: number;
    height: number;
    src: string;
    alt: string;
}

export interface PlainHeaderTheme {
    layout: string;
    headerBgColor?: string;
    partnerLogo?: PartnerLogoType;
}
interface PlainHeaderProps extends ConnectedProps<typeof connector> {
    theme: PlainHeaderTheme;
}

const PartnerLogo = ({ logo }: { logo?: PartnerLogoType }) => {
    if (!logo) {
        return null;
    }
    const { src, width, height, alt } = logo;
    const landscape = width / height >= 1;

    return (
        <>
            <Icon name="pv-plus" size={16} color="black" />
            <img
                src={src}
                width={landscape ? undefined : 55}
                height={landscape ? 45 : undefined}
                alt={alt}
            />
        </>
    );
};

const PlainHeader = ({ theme = {}, shopifyUrl }: PlainHeaderProps) => {
    const headerColor = theme?.headerBgColor;
    const partnerLogo = theme?.partnerLogo;
    let LogoIcon = BlackPvolveLogo;
    if (headerColor === 'transparent' || headerColor === 'black') {
        LogoIcon = WhitePvolveLogo;
    }

    const headerClasses = classNames({
        [`${Styles.plainHeaderWrapper}`]: true,
        [`bgcolor-pv-${headerColor}`]: !!headerColor,
        [`${Styles.plainHeaderTransparent}`]: headerColor === 'transparent',
        partnerLogo,
    });

    return (
        <header className={headerClasses}>
            <div className={Styles.logoContainer}>
                <a href={shopifyUrl} title="Return to the P.volve homepage">
                    <img alt="Pvolve logo" width="130" height="37" src={LogoIcon} />
                </a>
                <PartnerLogo logo={partnerLogo} />
            </div>
        </header>
    );
};

export default connector(PlainHeader);
