export const LOADING = 'Loading...';

export const AVATAR_TRAINER_SIZE = 42;

export const UI_MAX_WIDTH = 1728;

// GRIDS
export const GRID_STANDARD_MARGIN = 24;
export const CLASS_CARD_WIDTH = 260;
export const CLASS_CARD_TABLET_WIDTH = 236;
export const CLASS_CARD_MIN_WIDTH = 236;
export const CLASS_CARD_COLUMN_GAP = 24;
export const CLASS_CARD_COLUMN_GAP_MOBILE = 16;
export const CLASS_COUNT_XL = 5;
export const CLASS_COUNT_LG = 4;
export const CLASS_COUNT_MD_SM = 3;

// BUTTONS
export const ICON_BUTTON_SIZES = {
    small: 32,
    medium: 40,
    large: 50,
};
export const SMALL_LABEL = 'small';
export const MEDIUM_LABEL = 'medium';
export const LARGE_LABEL = 'large';

// HOME COMPONENTS
export const SERIES_RECOMMENDER_WIDTH = 343;
export const SERIES_RECOMMENDER_WIDTH_MOBILE = 270;

// DELAYS
export const COLLECTION_TOAST_DELAY = 15000;

// HEADER
export const HEADER_PADDING_SIDES = 5;
export const HEADER_MOBILE_PADDING_SIDES = 3;
export const HEADER_MOBILE_PADDING_TOP = 8;
export const HEADER_HEIGHT = {
    primary: 78,
    announcement: 46,
    mobile: 64,
};
