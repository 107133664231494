import { takeEvery } from 'redux-saga/effects';
import { loginRequired, routineSaga  } from '@pvolve/sdk/src/app/utils/sagas';
import Actions from './actions';
import Pvolve from '@pvolve/sdk';

const {
  favorites: { add, list, remove },
} = Actions;

class FavoritesSaga {
  *init() {
    yield takeEvery(list.trigger, loginRequired(saga.list));
    yield takeEvery(add.trigger, loginRequired(saga.add));
    yield takeEvery(remove.trigger, loginRequired(saga.remove));
  }

  add = routineSaga({
    routine: add,
    *request({ payload: { collection, id } }) {
      const response = yield Pvolve.api.account.addFavoritesAttributes({
        [collection]: [id],
      });
      return response;
    },
  });

  list = routineSaga({
    routine: list,
    *request() {
      const response = yield Pvolve.api.account.getFavoritesAttributes();
      return response;
    },
  });

  remove = routineSaga({
    routine: remove,
    *request({ payload: { collection, id } }) {
      const response = yield Pvolve.api.account.removeFavoritesAttributes({
        [collection]: [id],
      });
      return response;
    },
  });
}

export const saga = new FavoritesSaga();