import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { dropRight, isEmpty } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';

import { Card } from 'semantic-ui-react';
import { contentfulObjectNamesToString } from 'src/utils/data-utils';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import * as Styles from 'src/styles/series-card.module.scss';
import { ContentfulSeries_Card } from 'src/utils/graphql';
import { ISeries } from '@pvolve/sdk/src/models/contentful_types';
import { IState } from '@pvolve/sdk/src/redux/selectors';

const MAX_EQUIPMENT_SHOWN = 2;

interface SeriesCardProps {
    series: ISeries & ContentfulSeries_Card;
}

const connector = connect((state: IState, { series }: SeriesCardProps) => {
    let data;
    if (series.slug) {
        series.description = series.childContentfulSeriesDescriptionTextNode?.description;
        data = series;
    } else {
        data = PvolveSelectors.content.resolveSeries(state)(series?.sys?.id);
    }
    return { series: data };
});

const SeriesCard = ({ series }: ConnectedProps<typeof connector>) => {
    const { placeholderImage } = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(
                    relativePath: { eq: "placeholders/placeholder-series-card-web.png" }
                ) {
                    childImageSharp {
                        fluid(quality: 80) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    );

    if (isEmpty(series)) {
        return null;
    }

    const {
        name = '',
        equipment = [],
        bodyFocus = [],
        slug,
        chapters = [],
        length,
        level,
        workoutType = [],
        description = '',
        workout,
        thumbnail,
    } = series;

    // TODO: Series don't have a `new` flag in Contentful
    // Once that's available, retrieve new from the graphql query
    const isNew = false;

    const getEquipmentText = () => {
        let equipmentList = 'No equipment';

        const numEquipment = equipment?.length || 0;

        if (numEquipment > 0 && numEquipment <= MAX_EQUIPMENT_SHOWN) {
            equipmentList = contentfulObjectNamesToString(equipment || []);
        } else if (numEquipment > MAX_EQUIPMENT_SHOWN) {
            const numEquipmentHidden = numEquipment - MAX_EQUIPMENT_SHOWN;
            equipmentList =
                contentfulObjectNamesToString(dropRight(equipment, numEquipmentHidden)) +
                ` and ${numEquipmentHidden} more`;
        }

        return equipmentList;
    };

    const handleClick = () => {
        navigate(`/series/${slug}`);
    };

    const imageProps = {
        alt: `series thumbnail for ${name}`,
        className: Styles.cardImage,
    };

    return (
        <Card className={Styles.card} onClick={handleClick} fluid>
            <div className={Styles.thumb}>
                <Img
                    fluid={
                        thumbnail?.fluid ? thumbnail?.fluid : placeholderImage.childImageSharp.fluid
                    }
                    {...imageProps}
                />
                {isNew && <p className={`upper ${Styles.newTag}`}>new</p>}
                <div className={Styles.basicDetails}>
                    <p className={`${Styles.seriesLevel} p1 accent bold margin-bottom--0`}>
                        {level}
                    </p>
                    {length && (
                        <div className={Styles.seriesLength}>
                            <p className="p3 bold margin--0">{length} min</p>
                            {workout && workout.length < 7 ? (
                                <p className="margin--0 l1 bold">
                                    {workout.length} day{workout.length > 1 && 's'}
                                </p>
                            ) : (
                                chapters &&
                                chapters.length && (
                                    <p className="margin--0 l1 bold">
                                        {chapters.length} week{chapters.length > 1 && 's'}
                                    </p>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Card.Content className={Styles.cardContent}>
                {name && <Card.Header className={Styles.header}>{name}</Card.Header>}

                <div className={Styles.description}>
                    <p className={Styles.truncateOverflow3}>{description}</p>
                    <span className={`upper ${Styles.seeMore}`}>See more</span>
                </div>
                <Card.Description>
                    <p
                        className={classNames(
                            'p3',
                            'lower',
                            'margin-bottom--0',
                            Styles.truncateOverflow1
                        )}
                    >
                        {' '}
                        {bodyFocus && contentfulObjectNamesToString(bodyFocus)}
                        {bodyFocus && workoutType && ` • `}
                        {workoutType && contentfulObjectNamesToString(workoutType)}
                    </p>
                    <p className={classNames('p3', 'lower', Styles.truncateOverflow1)}>
                        {getEquipmentText()}
                    </p>
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default connector(SeriesCard);
