import { each } from 'lodash';

import account from './AccountService';
import auth from './AuthDispatcherService';
import cognito from './CognitoService';
import content from './ContentService';
import contentV2 from './ContentV2Service';
import commerce from './CommerceService';
import tokens from './TokensService';
import workout from './WorkoutService';
import stats from './StatsService';
import statsV2 from './StatsServiceV2';
import subscription from './SubscriptionService';
import CoreService from './CoreService';
import featureFlag from './FeatureFlagService';
import partner from './PartnerService';
import promos from './PromoService';

import configure from './configure';
export { configure };

//export configure from './configure'; // gatsby babel presets don't like this


const services = {
  account,
  auth,
  cognito,
  content,
  contentV2,
  commerce,
  partner,
  promos,
  tokens,
  workout,
  stats,
  statsV2,
  subscription,
  featureFlag,
};

export function createService() {
  const coreService = new CoreService();
  each(services, (ServiceClass, name) => {
    coreService.register(name, new ServiceClass());
  });
  return coreService;
}

const DefaultCore = createService();


export default DefaultCore;
