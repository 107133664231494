import queryString from 'query-string';
import Cookies from 'js-cookie';
import sha1 from 'sha1';
import sha256 from 'crypto-js/sha256';
import Pvolve from '@pvolve/sdk';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';

import { navigate } from 'gatsby';
import { takeEvery, select, put, take, spawn, race } from 'redux-saga/effects';
import { routineSaga, sendTo } from '@pvolve/sdk/src/app/utils/sagas';
import { Token } from '@pvolve/sdk/src/app/modules/auth/selectors';
import { TOKEN_UPDATE_ACTION } from '@pvolve/sdk/src/redux/ReduxTokenStore';
import LocalStorageTokenStore from '@pvolve/sdk/src/LocalStorageTokenStore';

import { redirect } from 'src/utils/url-utils';
import { first, isEmpty } from 'lodash';

import { ZELDA_HOME_LINK } from '../../constants/url-constants';
import { Action } from 'redux-actions';

export interface CheckoutPageLoadedEventPayload {
    loggedIn: boolean;
    cart: any;
    draft_order: any;
}

export interface CheckoutSocialTriggeredEventPayload {
    provider: string;
}

export interface CheckoutSocialCompleteEventPayload {
    provider: string;
}

export interface CheckoutEmailAuthEventPayload {
    isSignup: boolean;
    error?: string;
}

export interface CheckoutEmailLookupEventPayload {
    userFound: boolean;
    error?: string;
}

export interface CheckoutAddressEventPayload {
    zip: string;
    country: string;
    province: string;
    draft_order: any;
}

export interface CheckoutShippingEventPayload {
    rateId: number;
    draft_order: any;
    shipping_method: string | undefined;
}

export interface CheckoutPromoEventPayload {
    action: string;
    code?: string;
    draft_order: any;
}

const GTM_ANALYTICS_MAPPER = {
    [Actions.auth.authenticated]: (user: Token) => ({
        event: 'authenticated',
        userId: user?.sub || null,
        customerId: user?.sub || null,
        shopifyCustomerId: user ? user['custom:shopify_customer_id'] : null,
        customerEmail: user?.email ? sha1(user.email) : undefined,
        customerEmail256: user?.email ? sha256(user.email).toString() : undefined,
        name:
            user.given_name && user.given_name !== 'First_Name'
                ? `${user.given_name} ${first(user.family_name)}`
                : undefined,
    }),
    [Actions.subscriptions.load.success]: (user: Token, { payload }) => {
        const subscriptions = payload?.subscriptions || [];
        const subscription = first(subscriptions)?.subscription || {};

        const { state, product_id, original_purchase_date, expiration_date } = subscription;

        const entitled = !isEmpty(user.entitlements);

        return {
            event: 'loadSubscription',
            subscription: {
                entitled,
                state,
                productId: product_id,
                originalPurchaseDate: original_purchase_date,
                expirationDate: expiration_date,
            },
        };
    },
    [Actions.auth.unauthenticated]: (user: Token) => ({
        event: 'unauthenticated',
        userId: null,
        customerId: null,
        shopifyCustomerId: null,
        customerEmail: null,
        customerEmail256: null,
    }),
    [Actions.web.checkoutComplete]: (
        user: Token,
        { payload: { order, freeTrialDays, cart, customer } }
    ) => {
        const isFreeTrial = freeTrialDays > 0;
        const hasPhysicalGoods = !!cart?.requires_shipping;

        return {
            event: 'checkout',
            order,
            isFreeTrial,
            hasPhysicalGoods,
            cart,
            customer,
        };
    },
    [Actions.web.checkoutPageLoaded]: (user: Token, { payload }) => {
        const { cart, draft_order }: CheckoutPageLoadedEventPayload = payload;

        return {
            event: 'checkoutPageLoaded',
            draft_order_id: draft_order?.draft_order_id,
            hasPhysicalGoods: !!cart?.requires_shipping,
            cart,
            total: draft_order?.price_summary?.total,
        };
    },
    [Actions.web.checkoutSocialAuthTriggered]: (user: Token, { payload }) => {
        const { provider }: CheckoutSocialTriggeredEventPayload = payload;

        return {
            event: 'checkoutSocialAuthTriggered',
            provider,
        };
    },
    [Actions.web.checkoutSocialAuthComplete]: (user: Token, { payload }) => {
        const { provider }: CheckoutSocialCompleteEventPayload = payload;

        return {
            event: 'checkoutSocialAuthComplete',
            provider,
        };
    },
    [Actions.web.checkoutEmailAuthLookup]: (user: Token, { payload }) => {
        const { userFound, error }: CheckoutEmailLookupEventPayload = payload;

        return {
            event: 'checkoutEmailAuthLookup',
            userFound,
            error,
        };
    },
    [Actions.web.checkoutEmailAuthComplete]: (user: Token, { payload }) => {
        const { isSignup, error }: CheckoutEmailAuthEventPayload = payload;

        return {
            event: 'checkoutEmailAuthComplete',
            isSignup,
            error,
        };
    },
    [Actions.web.checkoutAddressComplete]: (user: Token, { payload }) => {
        const { zip, country, province, draft_order }: CheckoutAddressEventPayload = payload;

        return {
            event: 'checkoutAddress',
            zip,
            country,
            province,
            total: draft_order?.price_summary?.total,
        };
    },
    [Actions.web.checkoutShippingComplete]: (user: Token, { payload }) => {
        const { rateId, draft_order }: CheckoutShippingEventPayload = payload;

        return {
            event: 'checkoutShipping',
            rateId,
            total: draft_order?.price_summary?.total,
        };
    },
    [Actions.web.checkoutPromo]: (user: Token, { payload }) => {
        const { action, code, draft_order }: CheckoutPromoEventPayload = payload;

        return {
            event: 'checkoutPromo',
            action,
            code,
            total: draft_order?.price_summary?.total,
        };
    },
    [Actions.account.saveUserAttrs.success]: (user: Token, { payload }) => {
        return {
            event: 'saveUserAttrs.success',
            userAttrs: payload,
        };
    },
    [Actions.account.getAllAttrs.success]: (user: Token, { payload }) => {
        const userData = payload?.user?.object || {};

        const gender = userData?.gender;
        const waiver = userData?.waiver;
        const time_zone = userData?.time_zone;
        const is_experienced = !!userData?.is_experienced;
        const feed_fm_disabled = !!userData?.feed_fm_disabled;
        const feed_fm_playing = !!userData?.feed_fm_playing;

        return {
            event: 'app.userProfile',
            userProfile: {
                gender,
                waiver,
                time_zone,
                is_experienced,
                feed_fm_disabled,
                feed_fm_playing,
            },
        };
    },
    [Actions.workouts.complete.success]: (user: Token, { payload: { workout_id } }) => {
        return {
            event: 'walk_day',
            workout_id,
        };
    },
};

const ENTITLEMENT_COOKIE_NAME = 'pv-ent';
const ID_COOKIE_NAME = 'pv-cog';
const REDIRECT_COOKIE_NAME = 'pv-red';
const CLEAR_SHOPIFY_CART_COOKIE_NAME = 'pv-cle';
const TOKENS_LOCAL_STORAGE_NAME = 'tokens';

const {
    account: { getAllAttrs },
    auth: { authenticated, unauthenticated },
    web: {
        authorizeInternalAccess,
        handlePageLoad,
        handleLoggedIn,
        handleLoggedOut,
        handleLogInAs,
        loginRequired,
        startEntitlementsWatcher,
        setClearShopifyCartCookie,
    },
    subscriptions: { setActivationCode },
    series: { join, leave, enrolled },
} = Actions;

class WebSaga {
    *init() {
        const saga = this;
        yield takeEvery(authorizeInternalAccess.trigger, saga.authorizeInternalAccess);
        yield takeEvery(setActivationCode.fulfill, saga.enableActivationCode);
        yield takeEvery(loginRequired, saga.loginRequired);
        yield takeEvery(handleLoggedOut.trigger, saga.handleLoggedOut);
        yield takeEvery(startEntitlementsWatcher.trigger, saga.startEntitlementsWatcher);
        yield takeEvery(setClearShopifyCartCookie.trigger, saga.setClearShopifyCartCookie);
        yield takeEvery(handlePageLoad.trigger, saga.handlePageLoad);
        yield takeEvery(handleLoggedIn.trigger, saga.handleLoggedIn);
        yield takeEvery(join.success, sendTo(enrolled.trigger));
        yield takeEvery(leave.success, sendTo(enrolled.trigger));
        yield spawn(this.gtmWatcher);
        yield takeEvery(handleLogInAs.trigger, saga.handleLogInAs);
        yield spawn(this.watchAuthenticated);
    }

    // TODO fix signup params
    /*
    logout = routineSaga({
        routine: logout,
        *request() {
            yield Pvolve.api.tokens.clear();
            yield put(getAllAttrs.clear()); // TODO move this logic to boot sequence
        },
    });
    */

    *enableActivationCode() {
        const checkoutFlow = yield select(Selectors.subscriptions.setActivationCode.checkoutFlow);
        const activationCodeProduct = yield select(
            Selectors.subscriptions.setActivationCode.product
        );

        if (checkoutFlow) {
            yield put(Actions.subscriptions.setActivationCode.clear());
            navigate(`/checkout/?sku=${activationCodeProduct.sku}`);
        } else if (activationCodeProduct) {
            navigate('/activate/confirm');
        }
    }

    startEntitlementsWatcher = routineSaga({
        routine: startEntitlementsWatcher,
        *request() {
            yield spawn(saga.pushEntitlementsToCookies);
        },
    });

    authorizeInternalAccess = routineSaga({
        routine: authorizeInternalAccess,
        *request({ payload: { password } }) {
            const { hash, s } = yield select(Selectors.config.maintenance);
            return { enabled: sha1(s + password) === hash };
        },
    });

    setClearShopifyCartCookie = routineSaga({
        routine: setClearShopifyCartCookie,
        *request() {
            if (!Cookies.get(CLEAR_SHOPIFY_CART_COOKIE_NAME)) {
                const domain = yield select(Selectors.config.shopifyCookieDomain);

                const cookieOptions: Cookies.CookieAttributes = {
                    expires: 365,
                    domain,
                };

                // TODO: we can make this smarter in the future and add purchased skus to
                // this cookie if it's useful on the shopoify side.
                Cookies.set(CLEAR_SHOPIFY_CART_COOKIE_NAME, 'purchase-complete', cookieOptions);
            }
        },
    });

    *pushEntitlementsToCookies() {
        do {
            const domain = yield select(Selectors.config.shopifyCookieDomain);

            const user = yield select(Selectors.auth.user);

            const idToken = yield Pvolve.api.tokens.id;

            if (user) {
                const entitlements = yield select(Selectors.auth.entitlements);

                const cookieOptions: Cookies.CookieAttributes = {
                    expires: 365,
                    domain,
                };
                const userFirstName =
                    user.given_name && user.given_name !== 'First_Name'
                        ? user.given_name
                        : undefined;
                const userFullName = userFirstName
                    ? `${userFirstName} ${user.family_name}`
                    : undefined;

                const id = {
                    cognitoId: user['cognito:username'],
                    userFirstName,
                    userFullName,
                    email: user.email,
                    shopifyCustomerId: user['custom:shopify_customer_id'],
                    id: user.sub || '',
                    token: idToken,
                };

                Cookies.set(ENTITLEMENT_COOKIE_NAME, entitlements.join(','), cookieOptions);
                Cookies.set(ID_COOKIE_NAME, id, cookieOptions);
            } else {
                const cookieOptions: Cookies.CookieAttributes = { domain };
                Cookies.remove(ENTITLEMENT_COOKIE_NAME, cookieOptions);
                Cookies.remove(ID_COOKIE_NAME, cookieOptions);
            }

            yield race({
                unauthenticated: take(unauthenticated),
                authenticated: take(authenticated),
                tokensUpdated: take(TOKEN_UPDATE_ACTION),
            });
        } while (true);
    }

    handleLoggedOut = routineSaga({
        routine: handleLoggedOut,
        async *request() {
            Pvolve.api.tokens.clear();
            yield put(getAllAttrs.clear());
            const url = new URL(window.location.href);
            let redirectUrl = await Cookies.get(REDIRECT_COOKIE_NAME);
            const removeCookies = async () => {
                await Cookies.remove(REDIRECT_COOKIE_NAME);
                await Cookies.remove(ID_COOKIE_NAME);
                await Cookies.remove('id', { domain: process.env.AUTH_COOKIE_DOMAIN });
                await Cookies.remove('refresh', { domain: process.env.AUTH_COOKIE_DOMAIN });
                await localStorage.removeItem('id');
                await localStorage.removeItem('refresh');
                await localStorage.removeItem(TOKENS_LOCAL_STORAGE_NAME);

                if (url.pathname.includes('/subscription/')) {
                    /* On mobile app, if the user has a Stripe subscription, it will be redirected to the subscription
                        page on web in order to cancel their subscription. In the url we will have access to the userId
                        that's logged in on mobile, so if it doesn't match with the current userId, we will redirect the user to the
                        login page and after that to the subscription page again. We need this cookie for the redirect after the login */
                    return await Cookies.set(REDIRECT_COOKIE_NAME, '/account/subscription');
                }
            };

            await removeCookies();
            if (url.pathname.match(/^\/checkout|log-in-as/)) return;
            redirect(redirectUrl || '/continue');
        },
    });

    handleLoggedIn = routineSaga({
        routine: handleLoggedIn,
        async *request() {
            const url = new URL(window.location.href);
            const redirectCookie = Cookies.get(REDIRECT_COOKIE_NAME);
            if (url.pathname.match(/^\/checkout/)) {
                // on the checkout page we don't want to redirect
                return;
            } else if (url.pathname.match(/^\/(continue|login|signup)/) || redirectCookie) {
                // tokens
                new LocalStorageTokenStore().updateTokens({
                    id: (localStorage.getItem('id') || Cookies.get('id')) ?? null,
                    refresh: (localStorage.getItem('refresh') || Cookies.get('refresh')) ?? null,
                    access: null,
                });
                if (!localStorage.getItem('refresh') && !Cookies.get('refresh')) {
                    Pvolve.api.tokens.clear();
                    return navigate('/continue');
                }
                if (!localStorage.getItem('id') && !Cookies.get('id')) {
                    Pvolve.api.tokens.freshTokens();
                }
                // redirect
                const shopifyUrl = yield select(Selectors.config.shopifyUrl);
                const entitlements = yield select(Selectors.auth.entitlements);
                const return_to =
                    redirectCookie || (entitlements?.length === 0 ? shopifyUrl : ZELDA_HOME_LINK);

                const urlPattern = new RegExp(`^${window.location.origin}`);
                const newUrl = return_to.replace(urlPattern, '');
                Cookies.remove(REDIRECT_COOKIE_NAME);
                if (newUrl.match(/^\//)) {
                    redirect(newUrl);
                } else {
                    redirect(return_to);
                }
            }
            const user = yield select(Selectors.auth.user);
            // START SEGMENT Identify and Track event for Account logged in
            yield put(
                Actions.segment.identify({
                    userId: user?.email,
                    traits: {
                        email: user?.email,
                    },
                })
            );
            yield put(Actions.segment.track.accountLoggedIn());
            // END SEGMENT
        },
    });

    handleLogInAs = routineSaga({
        routine: handleLogInAs,
        *request({ payload: { userId } }: { payload: { userId: string } }) {
            const data = yield Pvolve.api.auth.logInAs(userId);
            return data;
        },
    });

    handlePageLoad = routineSaga({
        routine: handlePageLoad,
        *request() {
            const { location } = window;
            const query = queryString.parse(location.search);

            let redirectUrl = query.redirectUrl;
            if (typeof redirectUrl === 'string' && redirectUrl) {
                delete query[REDIRECT_COOKIE_NAME];
                if (redirectUrl.includes('log-in-as') && typeof query.userEmail === 'string') {
                    redirectUrl = `${redirectUrl}&userEmail=${encodeURIComponent(query.userEmail)}`;
                }

                Cookies.set(REDIRECT_COOKIE_NAME, redirectUrl);
            }

            if (query.code) {
                const { code } = query;
                const url = new URL(location.href);

                const redirectUri = `${url.origin}${url.pathname}`;
                yield put(Actions.auth.processOauthCode.trigger({ code, redirectUri }));
            }
        },
    });

    *loginRequired(action) {
        const { location } = window;
        if (location) {
            const user = yield select(Selectors.auth.user);

            if (!user) {
                Cookies.set(REDIRECT_COOKIE_NAME, location.href);
                navigate('/continue', action?.payload);
            }
        }
    }

    *gtmWatcher() {
        while (true) {
            const action = yield take(({ type }: { type: string }) => !!GTM_ANALYTICS_MAPPER[type]);
            const mapper = GTM_ANALYTICS_MAPPER[action.type];

            const { dataLayer } = window;

            if (dataLayer && mapper) {
                const user = yield select(Selectors.auth.user);
                const event = mapper(user, action);
                if (event) {
                    // console.log('**** GTM DATALAYER ****', { event });
                    dataLayer.push(event);
                }
            }
        }
    }

    *watchAuthenticated() {
        const loginAction = Actions.auth.authenticated.toString();
        while (true) {
            yield take((action: Action<any>) => loginAction === action.type);
            yield put(Actions.web.handleLoggedIn.trigger());
        }
    }
}

export const saga = new WebSaga();
