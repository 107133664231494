/** TODO: delete if no Account Reducers */
import { handleActionsImmer } from 'src/utils/redux';
import Actions from 'src/state/root-actions';
import SdkActions from '@pvolve/sdk/src/app/actions';
import { combineActions } from 'redux-actions';
import { USER_COHORT } from '@pvolve/sdk/src/app/modules/auth/constants';

const {
    auth: { unauthenticated },
    account: {
        getAllAttrs,
        removeFavoritesAttrs,
        saveFavoritesAttrs,
        saveUserAttrs,
        saveEmailAttrs,
        getTokenAttributes,
        setEmail,
        submitQuestionnaireAnswers,
    },
} = Actions;

const {
    auth: { changeDefaultStation },
} = SdkActions;

export const initialState = {
    getAllAttrs: {
        data: {},
    },
    favorites: {
        data: [],
    },
    saveUserAttrs: {},
    loadingCohort: false,
    loadingTokenAttrs: false,
    userCohort: USER_COHORT.pvolve,
    email: '',
    cx: false,
};

const reducer = {
    [getAllAttrs.success]: (draft, response) => {
        draft.getAllAttrs.data = response;
    },
    [combineActions(getAllAttrs.clear, unauthenticated)]: () => initialState,
    [saveFavoritesAttrs.success]: (draft, response) => {
        draft.favorites.data = response;
    },
    [saveEmailAttrs.success]: (draft, response) => {
        draft.getAllAttrs.data.email = response;
    },
    [removeFavoritesAttrs.success]: (draft, response) => {
        draft.favorites.data = response;
    },
    [changeDefaultStation.success]: (draft, response) => {
        draft.getAllAttrs.data.user = response;
    },
    [getTokenAttributes.trigger]: (draft) => {
        draft.loadingCohort = true;
        draft.loadingTokenAttrs = true;
    },
    [getTokenAttributes.success]: (draft, response) => {
        draft.userCohort = response.userCohort;
        draft.cx = response.cx;
        draft.loadingCohort = false;
        draft.loadingTokenAttrs = false;
    },
    [getTokenAttributes.failure]: (draft) => {
        draft.loadingCohort = false;
    },
    [setEmail.trigger]: (draft, data) => {
        draft.email = data.email;
    },
    [submitQuestionnaireAnswers.success]: (draft, response) => {
        draft.getAllAttrs.data = response;
    },
};

const Reducer = handleActionsImmer(reducer, initialState, { getAllAttrs, saveUserAttrs });

export default Reducer;
