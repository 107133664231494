import { cloneDeep, compact, get, uniqWith } from 'lodash';

// TODO delete this file

import { createSelector } from 'reselect';
import { ContentfulModel } from '@pvolve/sdk/src/app/utils';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import { IWorkout } from 'src/models/contentful_types';

export interface Sys {
  type: string;
  linkType: string;
  id: string;
}

export interface ResolvedBenefit {
  name: string;
  order: number;
}

export interface ResolvedBodyFocus {
  name: string;
  order: number;
}

export interface ResolvedEquipment {
  name: string;
  icon: GlyphName;
  order: number;
  image: {
    sys: Sys;
  };
}

export interface ResolvedInstructor {
  bio: string;
  image: {
    sys: Sys;
  };
  name: string;
  order: number;
  slug: string;
}

export interface ResolvedWorkoutCategory {
  color: string;
  icon: GlyphName;
  image: {
    sys: Sys;
  };
  name: string;
  order: number;
  slug: string;
  workouts: IWorkout[];
}

export interface ResolvedWorkoutType {
  name: string;
  order: number;
  backgroundColor: string;
  color: string;
}

export interface ResolvedWorkout {
  benefit: ResolvedBenefit[];
  bodyFocus: ResolvedBodyFocus[];
  contentful_id: string;
  description: string;
  equipment: ResolvedEquipment[];
  instructor: ResolvedInstructor[];
  length: number;
  migrationIds: string[];
  name: string;
  new?: boolean;
  type?: string
  level?: string;  
  createdAt?: string;
  previewVideo: {
    sys: Sys;
  };
  purpleId: string;
  series: {
    sys: Sys;
  };
  sku: string;
  slug: string;
  thumbnail: {
    fixed?: {
      src?: string;
      srcWebp?: string;
    };
    fluid?: {
      src?: string;
      srcWebp?: string;
    };
  };
  video: {
    sys: Sys;
  };
  workoutCategory: ResolvedWorkoutCategory;
  workoutType: ResolvedWorkoutType[];
}

const Selectors = {
  content: (state: IState) => state.content,
  data: (state: IState) => state.content?.data,
};

{
  const data = state => state.content?.data?.series || [];

  const indexById = createSelector(data, items => {
    const index = {};
    for (const item of items) {
      const model = new ContentfulModel(item);
      index[model.id] = model;
    }

    return index;
  });

  const list = createSelector(indexById, index => Object.values(index));

  Selectors.series = {
    list,
    indexById,
  };
}

{
  const data = state => state.content?.data?.page || [];

  const indexBySlug = createSelector(data, items => {
    const index = {};

    for (const item of items) {
      const model = new ContentfulModel(item);
      index[model?.fields?.slug] = model;
    }

    return index;
  });

  const list = createSelector(indexBySlug, Object.values);

  Selectors.page = {
    list,
    indexBySlug,
  };
}

{
  const data = state => state.content?.data?.workout || [];

  const indexes = createSelector(data, items => {
    const allIndexes = {
      byId: {},
      bySeriesId: {},
    };
    for (const item of items) {
      const model = new ContentfulModel(item);
      allIndexes.byId[model.id] = model;

      const seriesId = get(model.field('series'), 'sys.id');
      if (seriesId) {
        allIndexes.bySeriesId[seriesId] = allIndexes.bySeriesId[seriesId] || [];
        allIndexes.bySeriesId[seriesId].push(model);
      }
    }

    return allIndexes;
  });

  const indexById = state => indexes(state).byId;
  const indexBySeriesId = state => indexes(state).bySeriesId;

  const list = createSelector(indexById, index => Object.values(index));

  Selectors.workouts = {
    indexes,
    indexById,
    indexBySeriesId,
    list,
  };
}

const loading = createSelector(
  Selectors.content,
  content => content?.findAll?.loading,
);

const workoutFilter = createSelector(
  Selectors.content,
  content => content?.searches?.default?.query || {},
);

const isSameWorkout = (wrk1: IWorkout, wrk2: IWorkout) => {
  const thumbnail = wrk1?.item?.fields?.thumbnail?.sys?.id === wrk2?.item?.fields?.thumbnail?.sys?.id;

  if (!thumbnail) {
    return false;
  }

  const previewVideo = wrk1?.item?.fields?.previewVideo?.sys?.id === wrk2?.item?.fields?.previewVideo?.sys?.id;
  const video = wrk1?.item?.fields?.video?.sys?.id === wrk2?.item?.fields?.video?.sys?.id;

  return previewVideo && video;
};

const completeFilteredResults = createSelector(
  Selectors.content,
  (content) => {
    const searches = cloneDeep(content?.searches?.default || {});
    const mainResults = uniqWith(searches.results, isSameWorkout);
    const resultsEquipment = uniqWith(searches.resultsWithEquipmentOnly, isSameWorkout);
    const resultsAdditionalEquipment = uniqWith(searches.resultsWithAdditionalEquipment, isSameWorkout);

    searches.results = mainResults;
    searches.resultsWithAdditionalEquipment = resultsAdditionalEquipment;
    searches.resultsWithEquipmentOnly = resultsEquipment;
    return searches;
  },
);

const workoutResults = createSelector(
  completeFilteredResults,
  content => content.results || [],
);

const resolveField = (item, fieldsToIndex, field: string) => {
  const index = fieldsToIndex[field];
  const attribute = item.fields[field];
  if (Array.isArray(attribute)) {
    return compact(attribute?.map(item => get(index[item.sys.id], 'fields')));
  } else if (attribute?.sys?.id) {
    return get(index[attribute.sys.id], 'fields');
  } else {
    return attribute;
  }
};

const resolveWorkout = createSelector(
  ContentSelectors.asset.indexedBy.id,
  ContentSelectors.benefit.indexedBy.id,
  ContentSelectors.bodyFocus.indexedBy.id,
  ContentSelectors.equipment.indexedBy.id,
  ContentSelectors.instructor.indexedBy.id,
  ContentSelectors.workoutCategory.indexedBy.id,
  ContentSelectors.workoutType.indexedBy.id,
  ContentSelectors.workout.indexedBy.id,
  (asset, benefit, bodyFocus, equipment, instructor, workoutCategory, workoutType, workoutIndex) => {
    const fieldsToIndex = { benefit, bodyFocus, equipment, instructor, workoutCategory, workoutType, thumbnail: asset };
    return (workoutId: string) => {
      const workoutItem = workoutIndex[workoutId];
      if (!workoutItem) {
        return {};
      }
      const thumbnail = resolveField(workoutItem, fieldsToIndex, 'thumbnail');
      const resolvedWorkout: ResolvedWorkout = {
        ...workoutItem.fields,
        contentful_id: workoutId,
        createdAt: workoutItem?.sys?.createdAt,
        benefit: resolveField(workoutItem, fieldsToIndex, 'benefit'),
        bodyFocus: resolveField(workoutItem, fieldsToIndex, 'bodyFocus'),
        equipment: resolveField(workoutItem, fieldsToIndex, 'equipment'),
        instructor: resolveField(workoutItem, fieldsToIndex, 'instructor'),
        workoutCategory: resolveField(workoutItem, fieldsToIndex, 'workoutCategory'),
        workoutType: resolveField(workoutItem, fieldsToIndex, 'workoutType'),
        thumbnail: {
          fixed: {
            src: thumbnail?.file?.url ? `${thumbnail.file.url}?h=1200&q=90` : undefined,
            srcWebp: thumbnail?.file?.url ? `${thumbnail.file.url}?fm=webp&h=1200&q=90` : undefined,
          }
        },
      };

      return resolvedWorkout;
    };
  },
);

const resolveSeries = createSelector(
  ContentSelectors.asset.indexedBy.id,
  ContentSelectors.bodyFocus.indexedBy.id,
  ContentSelectors.equipment.indexedBy.id,
  ContentSelectors.instructor.indexedBy.id,
  ContentSelectors.benefit.indexedBy.id,
  ContentSelectors.workoutType.indexedBy.id,
  ContentSelectors.series.indexedBy.id,
  (asset, bodyFocus, equipment, instructor, benefit, workoutType, seriesIndex) => {
    const fieldsToIndex = { bodyFocus, equipment, instructor, benefit, workoutType, thumbnail: asset };
    return (seriesId: string) => {
      const seriesItem = seriesIndex[seriesId];
      if (!seriesItem) {
        return {};
      }
      const {
        file: {
          url,
          details: {
            image: {
              height,
              width,
            }
          }
        }
      } = resolveField(seriesItem, fieldsToIndex, 'thumbnail');
      return {
        ...seriesItem.fields,
        bodyFocus: resolveField(seriesItem, fieldsToIndex, 'bodyFocus'),
        equipment: resolveField(seriesItem, fieldsToIndex, 'equipment'),
        instructor: resolveField(seriesItem, fieldsToIndex, 'instructor'),
        benefit: resolveField(seriesItem, fieldsToIndex, 'benefit'),
        workoutType: resolveField(seriesItem, fieldsToIndex, 'workoutType'),
        thumbnail: {
          fluid: {
            aspectRatio: width / height,
            src: url || null,
            srcWebp: url ? `${url}?fm=webp` : null,
          }
        },
      };
    };
  },
);


export default { ...Selectors, loading, resolveWorkout, resolveSeries, workoutFilter, workoutResults, completeFilteredResults };
