import Box from '@mui/material/Box';
import { HEADER_MOBILE_PADDING_TOP, HEADER_MOBILE_PADDING_SIDES } from 'src/constants/ui';
import { styled } from '@mui/material/styles';

const Styled = {
    NavDrawerMobile: styled(Box)(({ theme }) => ({
        width: '100vw',
        height: '100vh',
        zIndex: 1108,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(HEADER_MOBILE_PADDING_TOP, HEADER_MOBILE_PADDING_SIDES, 0),
        backgroundColor: theme.palette.brandColors.simpleWhite,
    })),

    SecondaryTabsMobile: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(5),
    })),

    AccountWrapperMobile: styled(Box)(({ theme }) => ({
        paddingTop: theme.spacing(5),
    })),
};

export default Styled;
