import { generateName, trimNotNull } from './filterUtils';
import { isEqual, isObject, transform } from 'lodash';

export const parsingFilters = (filters: string[]) => {
    let newFilters = {};
    filters.forEach(filter => {
        var filterData = Object.values(filter);
        if (filterData[0] || filterData[1]) {
            let filterName = filterData[0];
            let fitlerValue = trimNotNull(filterData[1]);
            let name: Record<string, unknown>;
            switch (filterName) {
                case 'patientName':
                    name = generateName(filterName, fitlerValue);
                    return (newFilters = { ...newFilters, ...name });
                case 'providerName':
                    if (fitlerValue.indexOf(',') === -1) {
                        name = { providerNameOrPractice: fitlerValue };
                    } else {
                        name = generateName(filterName, fitlerValue);
                    }
                    return (newFilters = { ...newFilters, ...name });
                default:
                    return (newFilters = {
                        ...newFilters,
                        [filterName]: fitlerValue
                    });
            }
        }
    });
    return newFilters;
};

// Used to determine what is the difference between two objects.
export const difference = (object, base) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key]))
          ? changes(value, base[key])
          : value;
      }
    });
  }

  return changes(object, base);
}

export const dateToEpoch = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
}

export function isEmptyValues(value: unknown) {
  return value === undefined
    || value === null
    || (typeof value === 'number' && isNaN(value))
    || (typeof value === 'object' && Object.keys(value).length === 0)
    || (typeof value === 'string' && value.trim().length === 0);
}
