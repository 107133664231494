import React, { ReactChildren, useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface ActionProps {
    label: string;
    handler?: () => void;
    secondary?: boolean;
}

interface ModalProps {
    actions: ActionProps[];
    children?: ReactChildren;
    closeButton?: boolean;
    onClose?: () => void;
    open: boolean;
    text?: string;
}

const defaultActions = [
    {
        label: 'Ok',
        secondary: true,
    },
];

export default ({
    children,
    text,
    actions = defaultActions,
    closeButton,
    onClose,
    open,
}: ModalProps) => {
    const [modelOpen, setModalOpen] = useState(open);

    useEffect(() => {
        setModalOpen(open);
    }, [open, setModalOpen]);

    const button = (
        { label, handler = onClose, secondary = false }: ActionProps,
        index: number
    ) => (
        <Button key={index} onClick={handler} secondary={secondary}>
            {label}
        </Button>
    );

    return (
        <Modal size="mini" open={modelOpen} onClose={onClose} closeIcon={closeButton}>
            <Modal.Content>{children || <p className="p1">{text}</p>}</Modal.Content>
            <Modal.Actions>{actions.map(button)}</Modal.Actions>
        </Modal>
    );
};
