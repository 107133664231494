export default class LocalTokenStore {

  constructor(tokens) {
    this._tokens = tokens || {};
  }

  updateTokens(tokens) {
    Object.assign(this._tokens, tokens);
  }

  set id(token) {
    this._tokens.id = token;
  }

  set access(token) {
    this._tokens.access = token;
  }

  set refresh(token) {
    this._tokens.refresh = token;
  }

  get id() {
    return this._tokens.id;
  }

  get refresh() {
    return this._tokens.refresh;
  }

  get access() {
    return this._tokens.access;
  }


}
