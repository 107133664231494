import { createActions } from 'redux-actions';
import { DefaultRoutineActions, DefaultRoutine } from '../../utils';

interface WorkoutCategoriesActions {
  workoutCategories: {
    loadWorkoutCategoriesContent: DefaultRoutineActions<{ tags: string }>;
  };
}

const Actions = <WorkoutCategoriesActions>(<unknown>createActions({
  WORKOUT_CATEGORIES: {
    LOAD_WORKOUT_CATEGORIES_CONTENT: DefaultRoutine,
  },
}));

export default Actions;
