import { createSelector } from 'reselect';

// TODO find out why this doesn't work in jest
import Pvolve from '@pvolve/sdk';
import { get, isEmpty } from 'lodash';

const idToken = state => state.tokens?.id || Pvolve.api.tokens.id;
const refreshToken = state => state.tokens?.refresh || Pvolve.api.tokens.refresh;
const tokens = state => state.tokens;
const auth = state => state.auth;
const attributes = state => state.auth.user;
const settings = state => state.auth.settings;
const onboardingCount = state => state.auth.onboardingCount;
const acceptingWaiver = state => state.auth.acceptingWaiver;
const federated = state => state.auth.federated;
const confirmationShown = state => state.auth.confirmationShown;
const isExperienced = state => state.auth.isExperienced;
const accountAttrs = state => state.account.getAllAttrs;
const seriesHistory = state => state.series.enrolled; //remove after removing isFTU selector

const loggedIn = createSelector(idToken, token => !!token);

interface FederatedIdentity {
  userId: string;
  providerName: string;
  providerType: string;
  primary: 'true' | 'false';
  dateCreated: string;
}

export interface Token {
  entitlements: string;
  entitlement_source: string;
  at_hash: string;
  sub: string;
  email_verified: boolean;
  'custom:stripe_customer_id': string;
  iss: string;
  'cognito:username': string;
  given_name: string;
  family_name: string;
  aud: string;
  identities: FederatedIdentity[];
  'custom:shopify_customer_id': string;
  token_use: 'id';
  auth_time: number;
  exp: number;
  iat: number;
  email: string;
}

const user = createSelector(idToken, refreshToken, (id, refresh) =>
  id && refresh
    ? {
        ...(<Token>Pvolve.api.tokens.decode(id)),
      }
    : null,
);

const shopifyId = createSelector(user, user =>
  get(user, 'custom:shopify_customer_id'),
);
const stripeId = createSelector(user, user =>
  get(user, 'custom:stripe_customer_id'),
);
const tokensReady = createSelector(
  shopifyId,
  stripeId,
  (shopifyId, stripeId) => shopifyId && stripeId,
);

const userId = createSelector(user, u => u?.sub);

const entitlements = createSelector(user, user =>
  isEmpty(user?.entitlements) ? [] : JSON.parse(user.entitlements),
);
const entitled = createSelector(
  entitlements,
  entitlements => entitlements.length > 0,
);

const legacyUser = createSelector(
  user,
  user => !!get(user, 'custom:legacy_id'),
);

const forgotPassword = createSelector(
  auth,
  authData => authData.forgotPassword,
);

const resendConfirmationCode = createSelector(
  auth,
  authData => authData.resendConfirmationCode,
);

const forgotPasswordReady = createSelector(
  forgotPassword,
  forgotPassword => forgotPassword.loadedAt,
);

const forgotPasswordError = createSelector(
  forgotPassword,
  forgotPassword => forgotPassword.error,
);

const login = createSelector(auth, authData => authData.login);

const signup = createSelector(auth, authData => authData.signup);
const signupError = createSelector(signup, signup => signup.error);

const failed = createSelector(auth, authState => {
  return authState?.federated?.failed;
});

const errorMessage = createSelector(auth, authState => {
  return authState?.federated?.error?.error_description;
});

const userAttributes = createSelector(
  attributes,
  userAttributes => userAttributes?.object,
);

const acceptingWaiverLoading = createSelector(
  acceptingWaiver,
  accepting => accepting.loading,
);

const federatedLoading = createSelector(federated, fed => fed.loading);

const isFTUWeb = createSelector(accountAttrs, accountAttrs => {
  return Boolean(accountAttrs?.data?.user?.object.is_ftu);
});

const isFTUMobile = createSelector(attributes, attributes => {
  return Boolean(attributes?.object?.is_ftu);
});

const isFTU = createSelector(tokens, seriesHistory, (tokens, seriesHistory) => { /* Delete after adjusting new selectors for web and mobile */
  let response = false;

  if (isEmpty(tokens)) {
    return response;
  } else {
    if (isEmpty(seriesHistory)) { // Check for empty series enroll history (means user never enrolled in any series)
      response = true;
    }
  }

  return response;
});

export default {
  acceptingWaiverLoading,
  attributes,
  auth,
  confirmationShown,
  entitlements,
  entitled,
  errorMessage,
  failed,
  federatedLoading,
  forgotPassword,
  resendConfirmationCode,
  forgotPasswordError,
  forgotPasswordReady,
  idToken,
  isExperienced,
  isFTUWeb,
  isFTUMobile,
  isFTU,
  legacyUser,
  loggedIn,
  login,
  onboardingCount,
  settings,
  signup,
  signupError,
  tokens,
  user,
  userAttributes,
  userId,
  tokensReady,
  stripeId,
  shopifyId,
};
