import { isEmpty } from 'lodash';
import { custom_track_events } from '../events';
import { numberOrUndefined } from '@pvolve/sdk/src/app/modules/segment/utils';
import { CartItem } from 'src/components/checkout/checkout-context';
import { LooseObject } from '@pvolve/sdk/src/app/modules/segment/types';
import {
    OrderProperties,
    ProductProperties,
    TrialProductProperties,
    ThankyouPageViewedProperties,
} from '../types';

const {
    checkoutStarted,
    checkoutStepCompleted,
    checkoutStepViewed,
    paymentInfoEntered,
    trialSignupStarted,
    trialSignupStepViewed,
    trialSignupStepCompleted,
    trialPaymentInfoEntered,
    trialOrderCompleted,
    thankyouPageViewed,
} = custom_track_events;

let order_details: OrderProperties = {};
let trial_details: TrialProductProperties = {};

export const helpers = {
    session_step_count: 0,
    set sessionStepCount(step) {
        this.session_step_count = step;
    },
    get sessionStepCount() {
        return this.session_step_count;
    },
    increaseStepCount() {
        this.session_step_count++;
    },
    saveSessionStepCountBackup() {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('step-backup', this.session_step_count.toString());
        }
    },
    cleanSessionStorageOnRefresh() {
        if (typeof window !== 'undefined') {
            if (sessionStorage.getItem('step-backup')) {
                this.sessionStepCount = +(sessionStorage.getItem('step-backup') as string);
                sessionStorage.removeItem('step-backup');
            }
        }
    },
    getProducts(items: CartItem[]) {
        let product_details: ProductProperties[] = [];
        items.forEach((product) => {
            product_details.push({
                brand: product.vendor,
                compare_at_price: product.compare_at_price
                    ? product.compare_at_price / 100
                    : undefined,
                image_url: product.image,
                name: product.title,
                quantity: product.quantity,
                presentment_amount: product.price / 100,
                price: product.price / 100,
                product_id: product.product_id,
                shopify_product_id: product.product_id,
                shopify_variant_id: product.variant_id,
                sku: product.sku,
                url: product.url,
                variant: product.variant_title,
            });
        });
        return product_details;
    },
    setCheckoutOrderDetails(data: LooseObject) {
        const cart_details = data?.cart;
        const checkout_id = data?.draft_order?.draft_order_id;
        const price_summary = data?.draft_order?.price_summary;
        const discount_summary = data?.draft_order?.discount_summary;
        const discount =
            price_summary?.discount && price_summary?.discount !== 0
                ? price_summary?.discount / 100
                : undefined;
        const coupon = !isEmpty(discount_summary?.discount?.code)
            ? discount_summary?.discount?.code
            : undefined;
        order_details = {
            cart_id: cart_details?.token,
            category: 'Custom checkout',
            checkout_id,
            coupon,
            currency: cart_details?.currency,
            discount,
            payment_method: 'stripe',
            presentment_currency: cart_details?.currency,
            products: this.getProducts(cart_details?.items),
            sent_from: 'Pvolve',
            source_name: 'web',
            subtotal: price_summary?.subtotal / 100,
        };
        return order_details;
    },
    setTrialOrderDetails(data: LooseObject) {
        const cart_details = data?.cart;
        const cart_item = cart_details?.items[0];
        trial_details = {
            account_type: data?.isSignup ? 'pvolve' : data?.provider ?? undefined,
            cart_id: undefined,
            checkout_id: data?.draft_order?.draft_order_id,
            name: cart_item?.product_title,
            payment_method: 'stripe',
            product_id: cart_item?.product_id,
            subscription_price: cart_item?.price / 100,
            sku: cart_item?.sku,
            source_name: 'web',
            url: document.location?.href,
        };
        return trial_details;
    },
};

helpers.cleanSessionStorageOnRefresh();

export default {
    events: {
        [checkoutStarted]: (data: LooseObject) => {
            return helpers.setCheckoutOrderDetails(data);
        },
        [checkoutStepViewed]: (data: LooseObject) => {
            helpers.increaseStepCount();
            return {
                ...order_details,
                step: helpers.sessionStepCount,
            };
        },
        [checkoutStepCompleted]: (data: LooseObject) => {
            // Save step count backup on Social Login
            if (data?.socialAuthTriggered) {
                helpers.saveSessionStepCountBackup();
            }

            // Update order_details with discount_summary when present
            if (data?.draft_order?.discount_summary) {
                const discount_summary = data?.draft_order.discount_summary;
                const price_summary = data?.draft_order.price_summary;
                order_details.discount =
                    discount_summary.total_discount !== 0
                        ? discount_summary.total_discount / 100
                        : undefined;
                order_details.coupon = !isEmpty(discount_summary.discount.code)
                    ? discount_summary.discount.code
                    : undefined;
                order_details.subtotal = (price_summary.subtotal - price_summary.discount) / 100;
            }

            // Update shipping_method when present
            if (data?.shipping_method) {
                order_details.shipping_method = data?.shipping_method;
            }
            return {
                ...order_details,
                step: helpers.sessionStepCount,
            };
        },
        [paymentInfoEntered]: (data: LooseObject) => {
            helpers.increaseStepCount();
            return {
                ...order_details,
                step: helpers.sessionStepCount,
            };
        },
        [trialSignupStarted]: (data: LooseObject) => {
            return helpers.setTrialOrderDetails(data);
        },
        [trialSignupStepViewed]: (data: LooseObject) => {
            helpers.increaseStepCount();
            return {
                ...trial_details,
                step: helpers.sessionStepCount,
            };
        },
        [trialSignupStepCompleted]: (data: LooseObject) => {
            // Flag to save step count as backup on Social Login
            if (data?.socialAuthTriggered) {
                helpers.saveSessionStepCountBackup();
                return;
            }

            // Update account_type on login/Signup from checkout
            trial_details.account_type = data?.account_type;

            return {
                ...trial_details,
                step: helpers.sessionStepCount,
            };
        },
        [trialPaymentInfoEntered]: (data: LooseObject) => {
            helpers.increaseStepCount();
            return {
                ...trial_details,
                step: helpers.sessionStepCount,
            };
        },
        [trialOrderCompleted]: (data: LooseObject) => ({
            order_id: data?.order_id,
            subscriptionId: data?.subscription_id,
            source_name: trial_details.source_name,
            payment_method: trial_details.payment_method,
            name: trial_details.name,
            subscription_price: trial_details.subscription_price,
            product_id: trial_details.product_id,
            sku: trial_details.sku,
        }),
        [thankyouPageViewed]: (data: LooseObject): ThankyouPageViewedProperties => {
            const price_summary = data?.order?.price_summary;
            return {
                currency: price_summary?.currency,
                discount: numberOrUndefined(price_summary?.discount / 100),
                email: data?.email,
                order_id: `#${data?.order?.order_number}`,
                presentment_currency: price_summary?.currency,
                presentment_total: (price_summary?.total / 100)?.toString(),
                products: helpers.getProducts(data?.cart?.items),
                shipping: price_summary?.shipping / 100,
                tax: price_summary?.taxes / 100,
                total: price_summary?.total / 100,
            };
        },
    },
};
