import React from 'react';
import Img from 'gatsby-image';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { connect, ConnectedProps } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import { useStaticQuery, graphql } from 'gatsby';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import * as getAppStyles from 'src/styles/get-the-app.module.scss';
import classNames from 'classnames';

const connector = connect((state: IState) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

const GetTheApp = ({ shopifyUrl }: ConnectedProps<typeof connector>) => {
    const { appStoreButton, playStoreButton, checkmark } = useStaticQuery(graphql`
        query {
            appStoreButton: file(relativePath: { eq: "app-store-badge_1.png" }) {
                childImageSharp {
                    fixed(width: 155) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            playStoreButton: file(relativePath: { eq: "google-play-badge_1_1.png" }) {
                childImageSharp {
                    fixed(width: 155) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            checkmark: file(relativePath: { eq: "checkmark-circle-2.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const checkmarkCircle = checkmark.childImageSharp.fixed;
    const appStoreUrl = 'https://apps.apple.com/us/app/p-volve/id1429328712';
    const googlePlayUrl =
        'https://play.google.com/store/apps/details?id=com.pvolve.my&hl=en_US&gl=US';

    return (
        <div className={getAppStyles.container}>
            <div className={classNames('bgcolor-pv-light-mint', getAppStyles.section)}>
                <h4 className="accent stacked">Looking for your workout?</h4>
                <h1 className="upper bold margin-top--0">Get the new P.volve app</h1>
                <p>
                    Download now for enhanced features and improved access to our entire library of
                    workouts.
                </p>
                <div className={getAppStyles.logosContainer}>
                    <a href={appStoreUrl} className={getAppStyles.appStore}>
                        <Img fixed={appStoreButton.childImageSharp.fixed} />
                    </a>
                    <a href={googlePlayUrl}>
                        <Img fixed={playStoreButton.childImageSharp.fixed} />
                    </a>
                </div>
            </div>
            <div className={classNames('bgcolor-pv-black', getAppStyles.section)}>
                <h4 className="accent color-pv-white stacked">Looking for equipment?</h4>
                <h1 className="upper bold color-pv-white margin-top--0">Start Shopping</h1>
                <Button as="a" href={`${shopifyUrl}/collections/all`} size="big">
                    Shop Now
                </Button>
            </div>
            <div className={classNames(getAppStyles.featureList, getAppStyles.section)}>
                <div className="upper bold h4">So, what’s new about this app?</div>
                <div className={getAppStyles.cards}>
                    <div>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={1}>
                                    <Img fixed={checkmarkCircle} />
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={13}>
                                    <div className="upper bold">ENHANCED</div>
                                    <div className={getAppStyles.subTitle}>video quality</div>
                                    <div>
                                        Access workouts with enhanced playing quality and streaming
                                        effectiveness.
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={1}>
                                    <Img fixed={checkmarkCircle} />
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={13}>
                                    <div className="upper bold">IMPROVED</div>
                                    <div className={getAppStyles.subTitle}>
                                        filtering & categorization
                                    </div>
                                    <div>
                                        Find the exact workout you’re looking for with improved
                                        filters that allow you to browse by equipment, body part
                                        focus, time and class type.
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={1}>
                                    <Img fixed={checkmarkCircle} />
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={13}>
                                    <div className="upper bold">PICK YOUR</div>
                                    <div className={getAppStyles.subTitle}>preferred music</div>
                                    <div>
                                        Move to the beat of music with five popular genres to choose
                                        from and personal volume controls.
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={1}>
                                    <Img fixed={checkmarkCircle} />
                                </Grid.Column>
                                <Grid.Column width={1}></Grid.Column>
                                <Grid.Column width={13}>
                                    <div className="upper bold">IMPROVED</div>
                                    <div className={getAppStyles.subTitle}>plan</div>
                                    <div>
                                        Easily access your favorite workouts and track your progress
                                        with weekly stats.
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connector(GetTheApp);
