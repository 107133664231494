import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-activity';

interface LoaderProps {
    children?: React.ReactNode;
    spinnerColor?: string;
    className?: string;
}

const Loader = ({ children, spinnerColor, className }: LoaderProps) => (
    <div
        className={classNames('display-column alignItems--center text-align--center', className)}
        style={{ width: '100%' }}
    >
        {children}
        <Spinner size={40} speed={0.75} color={spinnerColor} aria-label="spinner" />
    </div>
);

export default Loader;
