import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

// Components
import Tabs from 'src/components/shared/tabs.component';
import Styled from './header-tabs.styled';

// Utils
import { useDispatchSegmentEvents } from 'src/utils/custom-hooks/useDispatchSegmentEvents';

// Constants
import { HeaderTabItem, HEADER_TABS } from '../header-constants';

const HeaderTabs = () => {
    const { dispatchCTAClickedEvent: dispatchCTA } = useDispatchSegmentEvents();

    const { pathname } = useLocation();
    const [selectedTab, setSelectedTab] = useState<number | boolean>(false);
    const [tabItems, setTabItems] = useState<HeaderTabItem[]>([]);

    useEffect(() => {
        setTabItems(HEADER_TABS);
    }, []);

    useEffect(() => {
        tabItems.forEach((tab: HeaderTabItem, index: number) => {
            if (pathname.includes(tab.pathname)) {
                if (selectedTab !== index) {
                    setSelectedTab(index);
                }
                return;
            }
        });
    });

    return (
        <Styled.HeaderTabsWrapper>
            <Tabs
                aria-label="Navigation bar tabs"
                sectionName="Header"
                onClick={dispatchCTA}
                selectedTab={selectedTab}
                tabItems={tabItems}
                scrollButtons={false}
                variant="standard"
            />
        </Styled.HeaderTabsWrapper>
    );
};

export default HeaderTabs;
