import ReduxTokenStore, { TOKEN_UPDATE_ACTION } from './ReduxTokenStore';
import ReduxConfigStore, { CONFIG_UPDATE_ACTION } from './ReduxConfigStore';

import configureWeb from './configureWeb';
import configureMobile from './configureMobile';

const initialState = {};

export const TokensReducer = (state = initialState, { type, payload: tokens }) =>
  type === TOKEN_UPDATE_ACTION ? { ...state, ...tokens } : state;

export const ConfigReducer = (state = initialState, { type, payload: config }) =>
  type === CONFIG_UPDATE_ACTION ? { ...state, ...config } : state;

export { ReduxConfigStore, ReduxTokenStore, configureWeb, configureMobile, TOKEN_UPDATE_ACTION, CONFIG_UPDATE_ACTION };
