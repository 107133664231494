import { createSelector } from 'reselect';
import { IState, IQuestionnaireState } from '../../../redux/selectors';

const questionnaire = (state: IState): IQuestionnaireState => state.questionnaire;
const accountAttrs = state => state.account.getAllAttrs;

const questionnaireContent = createSelector(questionnaire, (questionnaire: IQuestionnaireState) => {
  return questionnaire.questionnaireContent;
});

export default {
  questionnaireContent,
};
