import React, { useCallback } from 'react';
import { Modal } from 'src/components/shared';

interface ConfirmationModalProps {
    agreeLabel: string;
    cancelLabel: string;
    modalText: string;
    modalOpen: boolean;
    setModalOpen: (value: React.SetStateAction<boolean>) => void;
    onAgree: () => void;
}

const ConfirmationModal = ({
    agreeLabel,
    cancelLabel,
    modalText,
    modalOpen,
    setModalOpen,
    onAgree,
}: ConfirmationModalProps) => {
    const onCancelClick = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const onAgreeClick = useCallback(() => {
        onAgree();
        setModalOpen(false);
    }, [onAgree, setModalOpen]);

    const modalActions = [
        {
            label: agreeLabel,
            handler: onAgreeClick,
        },
        {
            label: cancelLabel,
            handler: onCancelClick,
            secondary: true,
        },
    ];

    return (
        <>
            <Modal open={modalOpen} text={modalText} actions={modalActions} />
        </>
    );
};

export default ConfirmationModal;
