import { handleActionsImmer, DefaultInitialState } from '@pvolve/sdk/src/app/utils';
import Actions from '@pvolve/sdk/src/app/actions';
import { IWorkoutState, IWorkoutHistoryItem } from '@pvolve/sdk/src/redux/selectors';

export const initialState: IWorkoutState = {
  history: {
    ...DefaultInitialState,
    data: [],
  },
  stats: {
    data: {},
    loading: false,
  },
  current: null,
  targetedNewWorkouts: [],
};

const {
  auth: { logout },
  workouts: { loadHistory, loadStats, loadCurrent, loadTargetedNewWorkouts },
} = Actions;

const Reducer = handleActionsImmer(
  {
    [logout.success]: () => initialState,
    [loadHistory.success]: (
      draft: IWorkoutState,
      histories: IWorkoutHistoryItem[],
    ) => {
      draft.history.data = histories;
    },
    [loadStats.success]: (draft, stats) => {
      draft.stats.data = stats;
    },
    [loadCurrent.trigger]: (draft, currentWorkout) => {
      draft.current = currentWorkout;
    },
    [loadTargetedNewWorkouts.success]: (draft, data) => {
      draft.targetedNewWorkouts = data;
    },
  },
  initialState,
  {
    history: loadHistory,
    stats: loadStats,
  },
);

export default Reducer;
