import React from 'react';
import { map } from 'lodash';
import { Grid } from 'semantic-ui-react';
import * as Styles from 'src/styles/progress-bar.module.scss';
import ProgressBlock from 'src/components/shared/progressBarElements/ProgressBlock';

interface ProgressBarProps {
    phaseAttributes?: any; //TODO: Change to interface from SDK selectors
    vertical?: boolean;
    showHeader?: boolean;
    displayIndicator?: boolean;
    highlightedPhase?: 'menstrual' | 'follicular' | 'ovulatory' | 'luteal';
}

const ProgressBar = ({
    phaseAttributes,
    vertical = false,
    showHeader = false,
    displayIndicator = true,
    highlightedPhase,
}: ProgressBarProps) => {
    const getLastDay = () => {
        if (phaseAttributes?.phases && phaseAttributes?.phases?.length > 0) {
            const lastDay = parseInt(phaseAttributes?.phases[3].end.day);
            return lastDay;
        }
        return 0;
    };

    return (
        phaseAttributes && (
            <Grid className={Styles.progressBar}>
                {showHeader && (
                    <Grid.Row>
                        <p className={Styles.phaseHeader}>{phaseAttributes?.current} phase</p>
                    </Grid.Row>
                )}
                <div className={!vertical ? Styles.progressBarContainer : 'align--center'}>
                    <div
                        className={
                            vertical ? Styles.progressBarVertical : Styles.progressBarHorizontal
                        }
                    >
                        {map(phaseAttributes.phases, (phase, index) => {
                            return (
                                <ProgressBlock
                                    key={index}
                                    phase={phase}
                                    lastDay={getLastDay()}
                                    isCurrentPhase={phase.name === phaseAttributes?.current}
                                    highlightedPhase={highlightedPhase}
                                    currentDay={phaseAttributes?.currentDay}
                                    currentPhase={phaseAttributes?.current}
                                    vertical={vertical}
                                    displayIndicator={displayIndicator}
                                />
                            );
                        })}
                    </div>
                </div>
            </Grid>
        )
    );
};

export default ProgressBar;
