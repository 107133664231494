import 'semantic-ui-less/semantic.less';
import React, { memo, useEffect } from 'react';

// Hotjar
import { hotjar } from 'react-hotjar';

import { LayoutProvider } from 'src/components/layout/LayoutContext';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'src/utils/themes';
import 'src/assets/fonts/fonts.css';
import useOptimizelyProvider from 'src/utils/custom-hooks/useOptimizelyProvider';

import Page from './Page';
import ZendeskChat from '../my-plan/Zendesk';

// Redux
import { useSelector } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

interface LayoutProps {
    children: React.ReactNode;
    pageContext: any;
}

const Layout = ({ children, pageContext }: LayoutProps) => {
    const { OptProvider } = useOptimizelyProvider();
    const hotjarConfig = useSelector(PvolveSelectors.config.hotjarSelector);

    useEffect(() => {
        //Initialize hotjar with our hjid and hjsv
        if (hotjarConfig) {
            hotjar.initialize(hotjarConfig?.hjid, hotjarConfig?.hjsv);
        }
    }, [hotjarConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <OptProvider>
            <LayoutProvider>
                <ZendeskChat></ZendeskChat>
                <ThemeProvider theme={theme}>
                    <Page pageContext={pageContext} children={children} />
                </ThemeProvider>
            </LayoutProvider>
        </OptProvider>
    );
};

export default memo(Layout);
