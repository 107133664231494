import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Styled = {
    Alert: styled(Alert)(({ theme }) => ({
        border: `1px solid ${theme.palette.secondary.outlinedRestingBackground}`,
        backgroundColor: theme.palette.tertiary.light,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '21px auto 1px',
        maxWidth: 869,
        padding: theme.spacing(1.5, 7),

        '.MuiAlert-message': {
            padding: theme.spacing(0),
            letterSpacing: 0.15,
            color: theme.palette.secondary.contrastText,

            a: {
                color: theme.palette.tertiary.main,
                textDecoration: 'underline',
            },

            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(0),
            },
        },

        '.MuiAlert-action': {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
            display: 'flex',
            alignItems: 'center',
        },

        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 40px)',
            justifyContent: 'center',
            textAlign: 'center',
            flexWrap: 'wrap',
            paddingRight: 9,
            flexDirection: 'column',
        },

        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 47px)',
            fontSize: 14,
            lineHeight: '20px',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2.25),
            padding: theme.spacing(1.5),
        },
    })),

    Button: styled(Button)(({ theme }) => ({
        fontSize: 13,
        padding: '4px 10px',
        textAlign: 'center',
        letterSpacing: 0.46,
        borderRadius: 4,
        marginLeft: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1.25),
            marginLeft: theme.spacing(0),
        },
    })),

    Title: styled(Typography)(() => ({
        fontWeight: 500,
        textAlign: 'center',
    })),
};

export default Styled;
