import { all, take } from 'redux-saga/effects';
import AWS from 'aws-sdk';
import BootSequence, { getBootSequence } from '@pvolve/sdk/src/app/modules/boot/BootSequence';

import { saga as auth } from '@pvolve/sdk/src/app/modules/auth/sagas';
import { saga as analytics } from '@pvolve/sdk/src/app/modules/analytics/sagas';
import { saga as content } from '@pvolve/sdk/src/app/modules/content/sagas';
import { saga as config } from '@pvolve/sdk/src/app/modules/config/sagas';
import { saga as favorites } from '@pvolve/sdk/src/app/modules/favorites/sagas';
import { saga as phases } from '@pvolve/sdk/src/app/modules/phaseFunction/sagas';
import { saga as series } from '@pvolve/sdk/src/app/modules/series/sagas';
import { saga as seriesCategory } from '@pvolve/sdk/src/app/modules/seriesCategory/sagas';
import { saga as workouts } from '@pvolve/sdk/src/app/modules/workouts/sagas';
import { saga as subscriptions } from '@pvolve/sdk/src/app/modules/subscriptions/sagas';
import { saga as flags } from '@pvolve/sdk/src/app/modules/featureFlag/sagas';
import { saga as questionnaire } from '@pvolve/sdk/src/app/modules/questionnaire/sagas';
import { saga as account } from 'src/state/account/sagas';
import { saga as promos } from 'src/state/promos/sagas';
import { saga as partner } from 'src/state/partner/sagas';
import { saga as segment } from 'src/state/segment/sagas';
import { saga as web } from 'src/state/web/sagas';
import { saga as workoutCategories } from '@pvolve/sdk/src/app/modules/workoutCategories/sagas';
import { saga as challenge } from '@pvolve/sdk/src/app/modules/challenge/sagas';

const system: BootSequence = getBootSequence('system', {});

system.startWhen = all([take('persist/REHYDRATE')]);

analytics.AWS = AWS;
system.registerSagas(
    account,
    analytics,
    auth,
    content,
    config,
    favorites,
    flags,
    phases,
    partner,
    promos,
    questionnaire,
    segment,
    series,
    seriesCategory,
    subscriptions,
    web,
    workouts,
    workoutCategories,
    challenge
);

system.onStart();
