import { map } from 'lodash';
import React from 'react';
import { AuthorSource } from '.';

interface ISources {
    name: string;
    source: {
        [x: string]: any;
    };
}

const Sources = ({ name, source }: ISources) => {
    return (
        <div className="margin-bottom--xlarge">
            <h5 className="bold upper margin-bottom--xsmall">{name}</h5>
            {map(source, (e, i) => {
                const authorData = {
                    cta: e.cta,
                    ctaLabel: e.ctaLabel,
                    author: e.author,
                    bookTitle: e.bookTitle,
                    edition: e.edition,
                    editorial: e.editorial,
                    year: e.year,
                    pages: e.pages,
                };
                return <AuthorSource {...authorData} key={i} />;
            })}
        </div>
    );
};

export default Sources;
