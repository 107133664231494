import { createActions } from 'redux-actions';
import { DefaultRoutineActions, DefaultRoutine, BasicActionFunction } from '../../utils';

interface QuestionnaireActions {
    questionnaire: {
      loadQuestionnaireContent: DefaultRoutineActions<{ category: string, subcategory: string }>;
      cleanQuestionnaire: BasicActionFunction;
    }
  }
  
  const Actions = <QuestionnaireActions><unknown>createActions({
    QUESTIONNAIRE: {
      LOAD_QUESTIONNAIRE_CONTENT: DefaultRoutine,
      CLEAN_QUESTIONNAIRE: undefined,
    },
  });
  
  export default Actions;