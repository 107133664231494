import { Link } from 'gatsby';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Icon } from '.';

interface IAuthorSource {
    cta?: string;
    ctaLabel?: string;
    author?: string;
    bookTitle?: string;
    edition?: string;
    editorial?: string;
    year?: string;
    pages?: string;
}

const AuthorSource = ({
    cta,
    ctaLabel,
    author,
    bookTitle,
    edition,
    editorial,
    year,
    pages,
}: IAuthorSource) => {
    let bookSource = [edition, editorial, year, pages]
        .filter((e) => !!e && e.length > 0)
        .join(', ')
        .trimEnd();
    if (author && author.length > 0) {
        author += '.';
    }
    if (bookTitle && bookTitle.length > 0) {
        bookTitle += ', ';
    }
    if (bookSource && bookSource.length > 0) {
        bookSource += '.';
    }

    return (
        <Grid className="margin--0">
            <Grid.Row className="padding-vertical--xsmall">
                {!!cta && (
                    <a className="margin-right--xsmall" href={cta} target="_blank">
                        <Icon name="pv-external-link" size={18} />
                    </a>
                )}
                <Grid.Column className="padding--0" width={15}>
                    <Grid.Row>
                        {!!cta && (
                            <a href={cta} className="bold" target="_blank">
                                {ctaLabel}
                            </a>
                        )}
                    </Grid.Row>
                    <Grid.Row>
                        <span>{author}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <span className="bold accent">{bookTitle}</span>
                        <span>{bookSource}</span>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AuthorSource;
