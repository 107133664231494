import React from 'react';
import { Grid } from 'semantic-ui-react';

import * as checkoutStyles from 'src/styles/checkout.module.scss';

const CheckoutFooter = () => (
    <div className={checkoutStyles.footer}>
        <Grid container>
            <Grid.Row>
                <Grid.Column>
                    <ul className={checkoutStyles.list}>
                        <li>
                            <a
                                className="p2 bold lower"
                                href="https://www.pvolve.com/23795349/policies/refund-policy.html?locale=en"
                            >
                                refund policy
                            </a>
                        </li>
                        <li>
                            <a
                                className="p2 bold lower"
                                href="https://www.pvolve.com/23795349/policies/shipping-policy.html?locale=en"
                            >
                                shipping policy
                            </a>
                        </li>
                        <li>
                            <a
                                className="p2 bold lower"
                                href="https://www.pvolve.com/pages/terms-of-service"
                            >
                                terms of service
                            </a>
                        </li>
                    </ul>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <p className={checkoutStyles.copyright}>
                        &copy; P.volve {new Date().getFullYear()}. All Rights Reserved.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
);

export default CheckoutFooter;
