// extracted by mini-css-extract-plugin
export const mini = "stay-in-touch-module--mini--T3BTK";
export const tiny = "stay-in-touch-module--tiny--D-pkC";
export const small = "stay-in-touch-module--small--Koo8O";
export const medium = "stay-in-touch-module--medium--MUEQ0";
export const p3 = "stay-in-touch-module--p3--3MdwU";
export const large = "stay-in-touch-module--large--2VOpo";
export const p2 = "stay-in-touch-module--p2--1Kirn";
export const l1 = "stay-in-touch-module--l1--3KNJF";
export const label = "stay-in-touch-module--label--31g3T";
export const big = "stay-in-touch-module--big--3MWdz";
export const p1 = "stay-in-touch-module--p1--ZjTnV";
export const huge = "stay-in-touch-module--huge--ZK9iE";
export const p0 = "stay-in-touch-module--p0--3MOfM";
export const huge36 = "stay-in-touch-module--huge36--3JHQD";
export const huge52 = "stay-in-touch-module--huge52--3idwv";
export const massive = "stay-in-touch-module--massive--iZGyS";
export const h1 = "stay-in-touch-module--h1--RCDi0";
export const h2 = "stay-in-touch-module--h2--16uTb";
export const h3 = "stay-in-touch-module--h3--1VSSU";
export const h4 = "stay-in-touch-module--h4--1WdvW";
export const h5 = "stay-in-touch-module--h5--NfcrJ";
export const h6 = "stay-in-touch-module--h6--3Lm9p";
export const accent = "stay-in-touch-module--accent--1vXUw";
export const accenth5 = "stay-in-touch-module--accenth5--_emCC";
export const accenth6 = "stay-in-touch-module--accenth6--KAqSo";
export const upper = "stay-in-touch-module--upper--Wg22c";
export const lower = "stay-in-touch-module--lower--1yhuf";
export const underline = "stay-in-touch-module--underline--3lSMq";
export const bold = "stay-in-touch-module--bold--20aFp";
export const semibold = "stay-in-touch-module--semibold--12UZz";
export const normal = "stay-in-touch-module--normal--32U3B";
export const stacked = "stay-in-touch-module--stacked--2f2cn";
export const link = "stay-in-touch-module--link---LRtq";
export const textLinks = "stay-in-touch-module--text-links--1WmMK";
export const stayInTouchTitle = "stay-in-touch-module--stay-in-touch-title--3IP57";
export const stayInTouchDescription = "stay-in-touch-module--stay-in-touch-description--ljtHt";
export const stayInTouchInputContainer = "stay-in-touch-module--stay-in-touch-input-container--3i_1p";
export const stayInTouchInput = "stay-in-touch-module--stay-in-touch-input--3xFFc";
export const stayInTouchBtn = "stay-in-touch-module--stay-in-touch-btn--1mGSz";
export const stayInTouchSuccess = "stay-in-touch-module--stay-in-touch-success--SdEEa";