import React from 'react';
import Img from 'gatsby-image';
import { Grid } from 'semantic-ui-react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { ResponsivePadding } from 'src/components/shared';

import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

import * as Styles from 'src/styles/equipment-used.module.scss';
import { ContentfulWorkout_Equipment } from 'src/utils/graphql';

const connector = connect((state: IState) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

interface EquipmentProps {
    equipment: ContentfulWorkout_Equipment[];
}

const EquipmentUsed = ({
    equipment,
    shopifyUrl,
}: EquipmentProps & ConnectedProps<typeof connector>) => {
    const { placeholderImage } = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(
                    relativePath: { eq: "placeholders/placeholder-square.png" }
                ) {
                    childImageSharp {
                        fixed(width: 150, height: 150, quality: 80) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `
    );

    const isMobile = useBreakpoints(Breakpoints.lg, Directions.down);

    const paddingClassnames = classnames({
        'padding--small': true,
    });

    if (equipment === null) {
        return null;
    }

    // !: Array contains one element even when no equipment is used.
    const noEquipmentUsed =
        equipment.length <= 1 && equipment[0].name.toLowerCase() === 'no equipment';

    return (
        <div className={Styles.wrapper}>
            <ResponsivePadding>
                <Grid columns={isMobile ? 1 : 3} className={paddingClassnames}>
                    <Grid.Row>
                        <Grid.Column className="padding-left--0">
                            <h2 className="bold upper">
                                {noEquipmentUsed ? 'No Equipment Used' : 'Equipment Used'}
                            </h2>
                        </Grid.Column>
                    </Grid.Row>
                    {!noEquipmentUsed &&
                        equipment.map((item, index: number) => {
                            const splitLink = item.productUrl?.split('/');
                            const productPath = splitLink ? splitLink[splitLink.length - 1] : '';

                            return (
                                <Grid.Column
                                    className="alignItems--center padding-left--0"
                                    key={`equipment-${index}`}
                                >
                                    <a
                                        className="alignItems--center"
                                        href={`${shopifyUrl}/products/${productPath}`}
                                    >
                                        <Img
                                            className="verticalAlign--middle"
                                            fixed={
                                                item?.image?.fixed
                                                    ? item.image.fixed
                                                    : placeholderImage.childImageSharp.fixed
                                            }
                                        />
                                        <h6 className="bold upper">{item.name}</h6>
                                    </a>
                                </Grid.Column>
                            );
                        })}
                </Grid>
            </ResponsivePadding>
        </div>
    );
};

export default connector(EquipmentUsed);
