import { isEmpty, map } from 'lodash';
import React from 'react';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';

/**
 * Function that takes a text from contentful and returns an array of <p> elements per breakline found.
 *
 * It can take a className prop to add it to every <p> element.
 *
 * @param text string
 * @param className string
 */
export const breakParagraphs = (text: string, className?: string) =>
    !!text &&
    text
        .split('\n')
        .filter((e) => e.length > 0)
        .map((e, i) => (
            <p className={className ?? 'p1'} key={`paragraph-${i}`}>
                {paragraphWithLink(e)}
            </p>
        ));

/**
 * Function to return html links found within string
 * Links inside string must be as the following format:
 *      [<LABEL>|<URL>]
 *
 * @param text string
 * @returns text elements with:
 *      <a> element if <URL> includes 'http'
 *      <LinkWithPrevPath> element if <URL> as url param
 *      no link if links list is empty
 */
export const paragraphWithLink = (text: string) => {
    const regexp = /\[([^[]*)\|([^|]*)\]/g; // Regex for 2 groups inside of square brackets with a pipe: [<LABEL>|<URL>]
    const linksList = [...text.matchAll(regexp)]; // List of matching groups

    let splitText = undefined;
    if (linksList && linksList.length > 0) {
        splitText = text.split(/(\[[^[]*\|[^|]*\])/g); // Split regular str into array per group of square brackets with a pipe found

        return (
            <>
                {map(splitText, (element, i) => {
                    for (let link of linksList) {
                        if (element === link[0]) {
                            if (!link[1] || !link[2] || isEmpty(link[1]) || isEmpty(link[2])) {
                                return ''; // Return empty string if <LABEL> or <URL> do not exist
                            }
                            const externalLink = link[2].includes('http'); // Boolean for external link
                            const target = link[2].includes('pvolve.com') ? '' : '_blank'; // Set target empty if link from pvolve.com

                            return externalLink ? (
                                <a
                                    href={link[2]}
                                    target={target}
                                    className="text-links"
                                    key={`text-link-${i}`}
                                >
                                    {link[1]}
                                </a>
                            ) : (
                                <LinkWithPrevPath
                                    to={link[2]}
                                    className="text-links"
                                    key={`text-link-${i}`}
                                >
                                    {link[1]}
                                </LinkWithPrevPath>
                            );
                        }
                    }

                    return element;
                })}
            </>
        );
    }

    return text;
};

/**
 * Function that takes image URL from shopify and resize it.
 *
 * It replaces the url and includes the image size.
 *
 * @param imageUrl string
 * @param size number
 */
export const shopifyImageResize = (imageUrl: string, size: number) => {
    return imageUrl.replace('.jpg', `_${size}x.jpg`);
};
