import { flatMap } from 'lodash';

export const contentfulObjectNamesToString = (arr = []) =>
    arr.map((item) => item.name || '').join(', ');

/**
 * Example GraphQL response:
 *     {
 *         "data": {
 *             "allContentfulWorkoutCategory": {
 *                 "edges": [
 *                     {
 *                         "node": {
 *                             "contentful_id": "foo"
 *                         }
 *                     },
 *                     {
 *                         "node": {
 *                             "contentful_id": "bar"
 *                         }
 *                     },
 *                     ...
 *                  ]
 *              }
 *          }
 *      }
 *
 * Example Usage:
 *     const workoutCategories = flattenGraphQlEdges(data.allContenfulWorkoutCategory.edges);
 *     // workoutCategories = [{contentful_id: "foo"}, {contentful_id: "bar"}, ...]
 *
 * @param {Array} edges Edges from GraphQL response of a `allContentful<X>` query
 * @param {Boolean} sortByOrder Boolean to sort items by order set per indexed node
 * @return {Array} flattened array where each item is an object the was previously indexed as `.node`
 */
export const flattenGraphQLEdges = (edges, sortByOrder = false) =>
    sortByOrder
        ? flatMap(edges, (obj) => obj.node).sort((a, b) => a.order - b.order)
        : flatMap(edges, (obj) => obj.node);
