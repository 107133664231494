// extracted by mini-css-extract-plugin
export const mini = "workout-card-module--mini--2sdWs";
export const tiny = "workout-card-module--tiny--1JMx5";
export const newTag = "workout-card-module--new-tag--2RWNu";
export const small = "workout-card-module--small--jAaTl";
export const medium = "workout-card-module--medium--2a-Hp";
export const p3 = "workout-card-module--p3--2u3s3";
export const large = "workout-card-module--large--1PDhd";
export const p2 = "workout-card-module--p2--2sPNX";
export const l1 = "workout-card-module--l1--lWQ1R";
export const label = "workout-card-module--label--3llQA";
export const big = "workout-card-module--big--2H8ep";
export const p1 = "workout-card-module--p1--28-9Z";
export const huge = "workout-card-module--huge--2vIw-";
export const p0 = "workout-card-module--p0--3aEQR";
export const huge36 = "workout-card-module--huge36--dwcCG";
export const huge52 = "workout-card-module--huge52--ukUBL";
export const massive = "workout-card-module--massive--1LGv4";
export const h1 = "workout-card-module--h1--1TBMS";
export const h2 = "workout-card-module--h2--2iwtn";
export const h3 = "workout-card-module--h3--1LAuF";
export const h4 = "workout-card-module--h4--3ovUf";
export const h5 = "workout-card-module--h5--1PY4h";
export const h6 = "workout-card-module--h6--3iLMh";
export const accent = "workout-card-module--accent--Y1FAX";
export const accenth5 = "workout-card-module--accenth5--CQsw-";
export const accenth6 = "workout-card-module--accenth6--3BzYC";
export const upper = "workout-card-module--upper--1qVhT";
export const lower = "workout-card-module--lower--3ndxu";
export const underline = "workout-card-module--underline--2Uxi7";
export const bold = "workout-card-module--bold--5hUqk";
export const semibold = "workout-card-module--semibold--1ucGm";
export const normal = "workout-card-module--normal--3gruP";
export const stacked = "workout-card-module--stacked--e9rxR";
export const link = "workout-card-module--link--uWbN9";
export const textLinks = "workout-card-module--text-links--2mWVr";
export const content = "workout-card-module--content--1tSCL";
export const previewTag = "workout-card-module--preview-tag--j54Rq";
export const imageContainer = "workout-card-module--image-container--1bJWS";
export const playIcon = "workout-card-module--play-icon--8ORMH";
export const placeholder = "workout-card-module--placeholder--3n5Xy";
export const videoLink = "workout-card-module--video-link--3j-zy";
export const workoutLevel = "workout-card-module--workout-level--22SJp";
export const length = "workout-card-module--length--3YbAN";
export const meta = "workout-card-module--meta--1MVCP";
export const header = "workout-card-module--header--2ukfl";
export const favIcon = "workout-card-module--fav-icon--3eovD";
export const completedAt = "workout-card-module--completed-at--2TbVT";
export const cardImage = "workout-card-module--card-image--1sae3";