import { SemanticWIDTHS } from 'semantic-ui-react';

interface SUIGridCenetered {
    widths: {
        mobile: SemanticWIDTHS;
        tablet: SemanticWIDTHS;
        computer: SemanticWIDTHS;
    };
}

export const SUI_GRID_CENTERED: SUIGridCenetered = {
    widths: {
        mobile: 16,
        tablet: 8,
        computer: 6,
    },
};
