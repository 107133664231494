import { toNumber } from 'lodash';
import moment from 'moment';
import { Product, Subscription } from '../../../SubscriptionService';
import { Payment } from '@pvolve/sdk/src/CommerceService';

export const priceDescription = (product: Product, discount_amount?: number) => {
  let pricePerPeriod: string;
  let intervalDescription: string;

  let amount = product.cost.unit_amount;
  if (discount_amount) {
    amount = amount - discount_amount;
  }
  amount = amount / 100;

  pricePerPeriod = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: product.cost.currency,
  });
  const { subscription_details } = product;
  if (!subscription_details) {
    return '';
  }
  const { interval, interval_type } = subscription_details;
  if (interval === 1) {
    intervalDescription = `per ${interval_type}`;
  } else {
    intervalDescription = `every ${interval} ${interval_type}s`;
  }

  return `${pricePerPeriod} ${intervalDescription}`;
};

export const isMonthly = (product: Product) => {
  const { subscription_details } = product;
  if (!subscription_details) {
    return '';
  }
  const { interval, interval_type } = subscription_details;

  return (interval === 1 && interval_type === 'month');
}

export function pricePerMonthEstimate(product: Product, discount_amount?: number) {
  let amount = product.cost.unit_amount;
  if (discount_amount) {
    amount = amount - discount_amount;
  }
  const { subscription_details } = product;
  if (!subscription_details) {
    return 0;
  }
  const { interval, interval_type } = subscription_details;
  const costPerInterval = amount / interval;
  let costPerMonth = costPerInterval;

  if (interval_type === 'year') {
    costPerMonth = costPerInterval / 12;
  } else if (interval_type === 'week') {
    costPerMonth = costPerInterval * 30 / 7;
  } else if (interval_type === 'day') {
    costPerMonth = costPerInterval * 30;
  }

  return Math.floor(costPerMonth) / 100.0;
}

export function trialDays(product: Product): number {
  return toNumber(product?.subscription_details?.trial_days);
}

export function pricePerInterval(product: Product, discount_amount = 0): string {
  const {
    cost: { unit_amount },
    subscription_details: { interval = 1 },
  } = product;
  const pricePerPeriod = Math.round((unit_amount - discount_amount) / interval) / 100;
  return pricePerPeriod.toLocaleString('en-US', {
    style: 'currency',
    currency: product?.cost?.currency ?? 'USD',
  });
}

export function pricePerPeriodDescription(product: Product, useBillingPeriod: boolean = false, discount_amount = 0): string {
  if (!product?.cost) { return ''; }
  
  if (useBillingPeriod) {
    const { subscription_details } = product;
    const billedAmount = product.cost.unit_amount - discount_amount;
    const billedPrice = (billedAmount / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: product.cost.currency ?? 'USD',
    });
    if (!subscription_details) {
      return '';
    }
    const { interval, interval_type } = subscription_details ?? {};
    const intervalLabel = interval === 1 ? interval_type : `${interval} ${interval_type}s`;
    return `${billedPrice} / ${intervalLabel}`
  };
  
  const { interval_type } = product.subscription_details ?? {};
  const intervalPrice = pricePerInterval(product, discount_amount);
  return `${intervalPrice} / ${interval_type}`;
}

export function paymentExpired(paymentMethod: Payment): boolean | void {
  if (!paymentMethod) {
    return;
  }

  const now = moment();
  const month = now.month() + 1;
  const year = now.year();
  if (paymentMethod.credit_card.exp_year < year) {
    return true;
  } else if (paymentMethod.credit_card.exp_year > year) {
    return false;
  } else {
    return paymentMethod.credit_card.exp_month < month;
  }
}

export function subscriptionEndDate(subscription?: Subscription): string {
  if (!subscription) {
    return '';
  }

  return moment(subscription.expiration_date).format('M/D/YY');
}

export function subscriptionCancelledDate(subscription: Subscription): string {
  if (!subscription || !subscription.cancellation_date) {
    return '';
  }

  return moment(subscription.cancellation_date).format('M/D/YY');
}

export function inTrialPeriod(subscription: Subscription, product: Product): boolean {
  if (!product) { return false; }
  const { subscription_details: { trial_days } } = product;
  const { original_purchase_date } = subscription;
  const now = moment();
  return moment(original_purchase_date).add(trial_days, 'days').isAfter(now);
}
