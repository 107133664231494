import React from 'react';
import { CircleCheckmark } from 'src/components/shared';
import * as Styles from 'src/styles/start-trial-14.module.scss';

interface BenefitsTrialProps {
    items?: string[];
    title?: string;
    withStyles?: boolean;
}

/* If not in an actual CMS, this copy belongs in Remote Config. */
const benefits = [
    '100s of classes on demand and new content weekly',
    'Class types for every goal, level and ability',
    'Free 15-minute trainer consultation to get started',
    'Access to the Live Virtual Studio',
];

const renderBenefits = (items: string[]) =>
    items.map((benefit, index) => (
        <li key={`benefit-${index}`} className={`display--flex ${Styles.itemList}`}>
            <CircleCheckmark />
            {benefit}
        </li>
    ));

const BenefitsTrial = ({ items = benefits, title, withStyles }: BenefitsTrialProps) => (
    <div className={`${withStyles && Styles.benefitsBox}`}>
        <h5>{title}</h5>
        <ul className={Styles.benefits}>{renderBenefits(items)}</ul>
    </div>
);

export default BenefitsTrial;
