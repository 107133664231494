import React, { useState } from 'react';
require('src/styles/switch-toggle.scss');

interface SwitchToggleProps {
    active: boolean;
    onChange: (val: boolean) => void;
    label?: string;
}

export const SwitchToggle = ({ active, onChange, label }: SwitchToggleProps) => {
    const [selected, setSelected] = useState(active);

    const onSwitchChange = () => {
        const newValue = !selected;
        setSelected(newValue);
        onChange(newValue);
    };

    const toggleContainerClassName = `switch-toggle-container switch-toggle-container${
        selected ? '--right' : '--left'
    }`;

    const toggleWrapperClassName = `switch-toggle-wrapper switch-toggle-wrapper${
        selected ? '--right' : '--left'
    }`;

    const toggleButtonClassName = `switch-toggle-button switch-toggle-button${
        selected ? '--right' : '--left'
    }`;

    return (
        <div className="switch-toggle">
            <div className={toggleWrapperClassName}>
                <label className="switch-toggle-label">{selected ? 'on' : 'off'}</label>
                <div className={toggleContainerClassName} onClick={onSwitchChange}>
                    <div className={toggleButtonClassName}></div>
                </div>
            </div>
            {label && <label className="switch-toggle-optional-label">{label}</label>}
        </div>
    );
};

export default SwitchToggle;
