import React from 'react';
import { Link } from 'gatsby';
import { Grid, Card } from 'semantic-ui-react';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { SeriesCard, Icon } from 'src/components/shared';
import { ContentfulSeries_Card } from 'src/utils/graphql';

interface SeriesGridProps {
    title: string;
    numSeries: number;
    series: ContentfulSeries_Card[];
}

const SeriesGrid = ({ title, numSeries, series }: SeriesGridProps) => {
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    return (
        <Grid className="margin-bottom--xlarge">
            <Grid.Row className="page-section padding--0">
                <Grid.Column>
                    <Grid container>
                        {isMobile ? (
                            <div className={'padding--0 margin-bottom--large margin-top--xlarge'}>
                                <Grid.Row className="padding--0">
                                    <h2 className="bold upper">{title}</h2>
                                </Grid.Row>
                                {numSeries && (
                                    <Grid.Row className="padding--0">
                                        <p className="p3">{numSeries} Series</p>
                                    </Grid.Row>
                                )}
                            </div>
                        ) : (
                            <Grid.Row
                                className="padding--0 margin-bottom--large margin-top--xlarge"
                                verticalAlign="bottom"
                            >
                                <Grid.Column width={6} floated="left">
                                    <h2 className="bold upper">{title}</h2>
                                </Grid.Column>
                                {numSeries && (
                                    <Grid.Column width={3} floated="right" textAlign="right">
                                        <p className="p3">{numSeries} Series</p>
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        )}
                        {numSeries ? (
                            <Card.Group stackable={true} itemsPerRow={3}>
                                {series.map((s, i: number) => (
                                    <SeriesCard key={i} series={s} />
                                ))}
                            </Card.Group>
                        ) : (
                            <p>No series found</p>
                        )}
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default SeriesGrid;
