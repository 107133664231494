import React, { lazy, Suspense } from 'react';
import { Loader } from 'src/components/shared/index';

const ExtraContent = (props) => {
    const Component = lazy(() =>
        import(`./${props.template}`).catch(() => ({ default: () => <></> }))
    );

    return (
        <>
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        </>
    );
};

export default ExtraContent;
