import { Draft } from 'immer';
import { handleActionsImmer } from '@pvolve/sdk/src/app/utils';
import Actions from '../contentV2/actions';
import { Promo, PromoState } from '@pvolve/sdk/src/redux/selectors';

export const PromoTypes = {
    banner: 'banner',
    module: 'module',
} as const;

type PromoTypeKeys = keyof typeof PromoTypes;
export type PromoType = typeof PromoTypes[PromoTypeKeys];

export const BannerTypes = {
    black: 'black',
    pink: 'pink',
} as const;

type BannerTypeKeys = keyof typeof BannerTypes;
export type BannerType = typeof BannerTypes[BannerTypeKeys];

const { promos } = Actions;

export const initialState: PromoState = {
    data: [],
    loading: false,
    error: undefined,
};

const Reducer = handleActionsImmer(
    {
        [promos.list.trigger]: (draft: Draft<PromoState>) => {
            draft.loading = true;
        },
        [promos.list.success]: (draft: Draft<PromoState>, data: Promo[]) => {
            draft.data = data;
        },
        [promos.list.failure]: (draft: Draft<PromoState>, error: unknown) => {
            draft.error = error;
        },
        [promos.list.fulfill]: (draft: Draft<PromoState>) => {
            draft.loading = false;
        },
    },
    initialState
);

export default Reducer;
