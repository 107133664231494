import React from 'react';
import classnames from 'classnames';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { Image } from 'semantic-ui-react';
import { PreviewVideoPlayer } from 'src/components/shared';
import Colors from 'src/utils/colors';

export interface HighlightedCardProps {
    title: string;
    description: string;
    isNew: boolean;
    previewLoaded: boolean;
    previewMedia: any;
    previewThumbnail: any;
    handleClick: (args: any) => void;
    Styles: React.CSSProperties;
}

const HighlightedCard = ({
    title,
    description,
    isNew,
    previewLoaded,
    previewMedia,
    previewThumbnail,
    handleClick,
    Styles,
}: HighlightedCardProps) => {
    const isDesktop = useBreakpoints(Breakpoints.xxl, Directions.up);
    const isTabletLandscape = useBreakpoints(Breakpoints.lg, Directions.up) && !isDesktop;
    const isTabletPortrait = useBreakpoints(Breakpoints.lg, Directions.down);

    return (
        <div className={Styles.highlightedCardWrapper} onClick={handleClick}>
            {isTabletPortrait && isNew && <div className={Styles.newTag}>new</div>}
            <div
                className={classnames(
                    `padding-horizontal--${isDesktop ? 'large' : 'small'}`,
                    Styles.highlightedCardInfo
                )}
                style={{ backgroundColor: Colors.forest }}
            >
                {!isTabletPortrait && isNew && (
                    <div className={classnames('margin-bottom--small', Styles.newTag)}>new</div>
                )}
                <p
                    className={classnames(
                        isTabletLandscape || isTabletPortrait ? 'h2' : 'h1',
                        'accent color-pv-white margin-bottom--small'
                    )}
                >
                    {title}
                </p>
                <p className="p1 color-pv-white margin--0">{description}</p>
            </div>

            <div className={Styles.highlightedCardPreview}>
                {!previewLoaded || (previewLoaded && !previewMedia) ? (
                    <Image src={previewThumbnail} className={Styles.highlightedCardImage} />
                ) : (
                    <PreviewVideoPlayer
                        src={previewMedia}
                        className={Styles.highlightedCardVideo}
                    />
                )}
            </div>
        </div>
    );
};

export default HighlightedCard;
