import { parseInt } from 'lodash';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon } from 'src/components/shared';
import classNames from 'classnames';
import Actions from '@pvolve/sdk/src/app/actions';
import Selectors from 'src/state/root-selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';

const HIDE_TIMER = 3000;

interface VolumeControlsProps {
    enableMusic: boolean;
    onHide: () => void;
}

const connector = connect((state: IState) => ({
    music: Selectors.music.music(state),
    volume: Selectors.video.volume(state),
    feedFmEnabled: !Selectors.account.userAttributes(state)?.object?.feed_fm_disabled,
}));

const VolumeControls = ({
    dispatch,
    enableMusic,
    music,
    onHide,
    volume,
    feedFmEnabled,
}: VolumeControlsProps & ConnectedProps<typeof connector>) => {
    const [visible, setVisible] = useState(false);
    const mounted = useRef<boolean>();
    const hideTimer = useRef<NodeJS.Timeout>();

    const onIconClick = useCallback(() => {
        setVisible(false);
        setTimeout(onHide, 500);
    }, [onHide]);

    const clearHideTimer = useCallback(() => {
        if (hideTimer.current) {
            clearTimeout(hideTimer.current);
        }
    }, [hideTimer]);

    const setHideTimer = useCallback(() => {
        hideTimer.current = setTimeout(onIconClick, HIDE_TIMER);
    }, [onIconClick]);

    const setMusicVolume = useCallback(
        (e) => {
            e.persist();
            if (e.target) {
                const { value } = e.target;
                if (mounted.current) {
                    dispatch(Actions.music.setVolume(parseInt(value) / 100));
                }
            }
        },
        [mounted, dispatch]
    );

    const setVideoVolume = useCallback(
        (e) => {
            e.persist();
            if (e) {
                const { value } = e.target;
                if (mounted.current) {
                    dispatch(Actions.video.setVolume(parseFloat(value)));
                }
            }
        },
        [mounted, dispatch]
    );

    useEffect(() => {
        mounted.current = true;

        setTimeout(() => setVisible(true));

        return () => {
            if (hideTimer.current) {
                clearTimeout(hideTimer.current);
            }

            mounted.current = false;
        };
    }, []);

    return (
        <div
            className={classNames('volume-controls', { visible })}
            onMouseOver={clearHideTimer}
            onMouseOut={setHideTimer}
        >
            <Icon onClick={onIconClick} name="pv-volume-up" color="white" size={46} />
            <div className="sliders">
                {feedFmEnabled && enableMusic && (
                    <div className="slider">
                        <p className="slider-label">music</p>
                        <input
                            className="slider-control"
                            type="range"
                            onChange={setMusicVolume}
                            min={0}
                            max={100}
                            defaultValue={music.volume * 100}
                        />
                    </div>
                )}
                <div className="slider">
                    <p className="slider-label">trainer</p>
                    <input
                        className="slider-control"
                        type="range"
                        onChange={setVideoVolume}
                        min={0}
                        max={1}
                        step={0.01}
                        defaultValue={volume}
                    />
                </div>
            </div>
        </div>
    );
};

export default connector(VolumeControls);
