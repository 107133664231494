import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const Styled = {
    AccountButton: styled(Button)(({ theme }) => ({
        '&.showLabel': {
            paddingLeft: theme.spacing(0.5),
        },
    })),

    AccountInitials: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.brandColors.bone,
        minWidth: 32,
        minHeight: 32,
        padding: 0,
        borderRadius: 16,
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '26px',

        '&.showLabel': {
            marginRight: theme.spacing(2),
        },
    })),
};

export default Styled;
