import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { Icon } from 'src/components/shared';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import * as Styles from 'src/styles/phases-length.module.scss';

interface PhasesLengthProps {
    iconName?: string /* controls wether or not an icon and phase title is shown */;
    iconDesc?: string /* icon description */;
    iconColor?: string /* icon color */;
    iconSize?: number /* icon size */;
    header?: string /* controls wether or not a header is shown */;
    phaseName: string /* which phase it is, if dynamic this should receive phaseAttributes.current */;
    fromDay?: string /* if not used it will use the default value */;
    toDay?: string /* if not used it will use the default value */;
}

enum MenstrualDefaultLength {
    fromDay = '1',
    toDay = '4',
}

enum FollicularDefaultLength {
    fromDay = '5',
    toDay = '13',
}

enum OvulatoryDefaultLength {
    fromDay = '14',
    toDay = '16',
}

enum LutealDefaultLength {
    fromDay = '17',
    toDay = '28',
}

const getDefaultDay = (dayType: string, phase: string) => {
    let phaseEnum;
    if (phase == 'menstrual') {
        phaseEnum = MenstrualDefaultLength;
    }
    if (phase == 'follicular') {
        phaseEnum = FollicularDefaultLength;
    }
    if (phase == 'ovulatory') {
        phaseEnum = OvulatoryDefaultLength;
    }
    if (phase == 'luteal') {
        phaseEnum = LutealDefaultLength;
    }
    if (dayType === 'from') {
        return phaseEnum?.fromDay;
    } else {
        return phaseEnum?.toDay;
    }
};

const PhasesLength = ({
    iconName,
    iconDesc,
    iconColor,
    iconSize,
    header,
    phaseName,
    fromDay,
    toDay,
}: PhasesLengthProps) => {
    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);
    const [fromDayState, setFromDayState] = useState('');
    const [toDayState, setToDayState] = useState('');

    useEffect(() => {
        setFromDayState(fromDay ? fromDay : getDefaultDay('from', phaseName));
    }, [fromDay]);

    useEffect(() => {
        setToDayState(toDay ? toDay : getDefaultDay('to', phaseName));
    }, [toDay]);

    return (
        <div
            className={classNames(
                Styles.phasesLengthContainer,
                iconName && Styles.withIcon,
                !isDesktop && 'padding-horizontal--0'
            )}
        >
            {iconName && (
                <Icon
                    className={Styles.phasesLengthIcon}
                    name={iconName}
                    size={iconSize}
                    color={iconColor}
                />
            )}
            <div>
                {iconDesc && (
                    <LinkWithPrevPath
                        to={`/more-about/${phaseName}`}
                        className={Styles.phaseDescription}
                    >
                        {iconDesc}
                    </LinkWithPrevPath>
                )}
                {header && <p className={Styles.header}>{header}</p>}
                <p className={classNames(Styles.days, iconName && Styles.smallFont)}>
                    {fromDayState != toDayState
                        ? `~ days ${fromDayState} to ${toDayState}`
                        : `~ day ${toDayState}`}
                </p>
            </div>
            {!isDesktop && iconName && (
                <Icon
                    className={Styles.phasesLengthArrow}
                    name="pv-arrow-right"
                    color={'blue'}
                    size={25}
                />
            )}
        </div>
    );
};

export default PhasesLength;
