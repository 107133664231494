import { USER_COHORT, ENTITLEMENT_SOURCE } from '../auth/constants';

export const QUESTIONNAIRE_TYPE = {
    wizard: 'WIZARD',
    singlePage: 'SINGLE_PAGE',
}

export const QUESTION_TYPE = {
    selection: 'selection',
    rating: 'rating',
    textarea: 'textarea'
}

export const QUESTIONNAIRE_CATEGORY = {
    onboarding: 'onboarding',
    feedback: 'feedback',
}

export const QUESTIONNAIRE_SUBCATEGORY = {
    [ENTITLEMENT_SOURCE.optum]: 'b2b001',
    [USER_COHORT.d2c]: 'd2c001',
    [USER_COHORT.trialist]: 'd2c002',
    series: 'series',
}

export const COHORT_CODES = Object.values(QUESTIONNAIRE_SUBCATEGORY)?.filter((code, i, subcatArray) => code !== 'series' && subcatArray.indexOf(code) === i);

export const COHORT_NAMES = Object.keys(QUESTIONNAIRE_SUBCATEGORY)?.filter((code) => code !== 'series');
