import React from 'react';
import Calendar from 'react-calendar';
import { Icon } from 'src/components/shared';
import { CALENDAR_LOCALE, CALENDAR_TYPE, WEEK_DAYS } from './constants';
import 'react-calendar/dist/Calendar.css';
import * as Styles from 'src/styles/calendar.module.scss';

interface PhaseCalendarProps {
    input: {
        range: [string, string];
    };
    required?: boolean;
    updateValue: any;
    currentValue: Date | null;
    inputError?: (state: boolean, message: string) => void;
}

const formatShortDays = (locale: any, date: Date) => [...WEEK_DAYS][date.getDay()];

const calcDiff = (date: Date, dateRange: number) => date.getDate() - dateRange;

const PhaseCalendar = ({ input, updateValue, currentValue }: PhaseCalendarProps) => {
    let minDate = new Date();
    let maxDate = new Date();

    if (input.range && input.range.length > 1) {
        const min = input.range[0];
        const max = input.range[1];

        const minDateRange = parseInt(min);
        minDate.setDate(calcDiff(minDate, minDateRange));

        if (max !== 'NOW') {
            const maxDateRange = parseInt(max);
            maxDate.setDate(calcDiff(maxDate, maxDateRange));
        }
    }

    const rangeDate = {
        ...(input.range && { minDate: minDate }),
        ...(input.range && { maxDate: maxDate }),
    };

    return (
        <Calendar
            onChange={updateValue}
            locale={CALENDAR_LOCALE}
            calendarType={CALENDAR_TYPE}
            formatShortWeekday={formatShortDays}
            className={Styles.reactCalendar}
            prev2Label={null}
            next2Label={null}
            tileContent={<Icon size={33} color="mint" name="pv-checkmark" />}
            prevLabel={<Icon size={24} color="blue" name="pv-arrow-left" />}
            nextLabel={<Icon size={24} color="blue" name="pv-arrow-right" />}
            value={currentValue}
            {...rangeDate}
        />
    );
};

export default PhaseCalendar;
