import ConfigService, { EnvironmentOptions } from './ConfigService';

export default class HttpConfigService extends ConfigService {
  private lastUri: string;
  private activeRequest: {
    name: string,
    promise: Promise<any>;
  }

  /**
   * 
   */
  constructor(environmentOptions: EnvironmentOptions) {
    super(environmentOptions);
  }


  defaultEndpoint() {
    const environmentOptions = this.getEnvironmentOptions();
    return Object.values(environmentOptions)[0];
  }


  async refreshConfig(name?: string) {
    const { initialEnvironmentOptions, currentEnvironment, lastUri } = this;
    const environmentOptions = this.findSync('environments') || initialEnvironmentOptions;

    const environmentName = name || currentEnvironment;
    const environment = environmentOptions[environmentName];

    if(!environment) {
      console.warn(`environment not found name: ${environmentName} options: ${environmentOptions}`)
    }

    if(!this.activeRequest) {
      const request = async() => {
        const uri = environment?.uri || lastUri;

        if (!uri) {
          throw new Error('No environments found');
        }

        const { status, headers: { etag }, data } = await this.rawRequest({
          url: `${uri}?${new Date().getTime()}`,
        });

        if(status >= 200 && status < 300) {
          this.updateConfig(data, etag);
          this.lastUri = uri;
        }
      };

      this.activeRequest = {
        name: environmentName,
        promise: request() 
      };
    }

    const { activeRequest } = this;
    try {
      await this.activeRequest.promise;
    } finally {
      // if there was no other active request created
      if(activeRequest === this.activeRequest) {
        delete this.activeRequest;
      }
    }

    return this;
  }

}
