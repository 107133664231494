import { each, isEmpty, isNil } from 'lodash';
import { CookieAttributes, CookiesStatic } from 'js-cookie';

import { ReduxTokenStore } from './redux';
import LocalTokenStore from './LocalTokenStore';
import { TokenTypes } from './TokenStore';
import { ITokenStore } from './TokenStoreFactory';
import Cookies from 'js-cookie';

/**
 * TODO explain that you shouldn't store id, refresh, and access tokens together in cookies
 *
 *
 * for now only store refreshToken in cookies
 */
export default class CookieTokenStore implements ITokenStore {
  private _cookieOptions: {
    [key in TokenTypes]?: CookieAttributes;
  };
  private _cookies: CookiesStatic;

  // TODO create a proper interface for TokenStore
  private _delegate: LocalTokenStore | ReduxTokenStore;

  /**
   *
   * @param { cookies } assume an instance of js-cookie
   */
  constructor({
    cookies,
    delegate,
    cookieOptions = {
      [TokenTypes.refresh]: {},
      [TokenTypes.id]: {}
    }
  }) {
    this._cookieOptions = cookieOptions;
    this._delegate = delegate;
    this._cookies = cookies;
  }

  updateTokens(
    tokens: {
      [key in TokenTypes]: string | null;
    },
  ) {
    const { _cookieOptions } = this;

    each(tokens, (token, name) => {
      if (_cookieOptions[name] && !isNil(token)) this[name] = token;
    });
    this._delegate.updateTokens(tokens);
  }

  clear() {
    this.updateTokens({
      id: null,
      refresh: null,
      access: null,
    });
  }

  get access() {
    return this.getToken(TokenTypes.access);
  }
  get id() {
    const cookie = Cookies.get('id');
    if (cookie) {
      localStorage.setItem('id', cookie)
      Cookies.remove('id', {domain: process.env.AUTH_COOKIE_DOMAIN});
    }
    return localStorage.getItem('id')
  }
  get refresh() {
    const cookie = Cookies.get('refresh');
    if (cookie) {
      localStorage.setItem('refresh', cookie)
      Cookies.remove('refresh', {domain: process.env.AUTH_COOKIE_DOMAIN});
    }
    return localStorage.getItem('refresh')
  }

  set access(value) {
    this.setCookie(TokenTypes.access, value);
  }
  set id(value) {
    localStorage.setItem('id', value)
  }
  set refresh(value) {
    localStorage.setItem('refresh', value)
  }

  getToken(name: TokenTypes) {
    const { _delegate, _cookieOptions, _cookies } = this;

    let value = _delegate[name];

    // TODO consider dealing with the case where the delegate contains the token but the cookie does not
    if (_cookieOptions[name]) {
      value = _cookies.get(name);
      value = isEmpty(value) ? null : value;
    }

    if (name !== TokenTypes.refresh && !this.refresh && value) {
      // if refresh token is empty this clear all tokens
      this.clear();
      return null;
    } else {
      return value;
    }
  }

  setCookie(name: TokenTypes, value: string | null) {
    const { _cookieOptions, _cookies } = this;
    if (!_cookieOptions[name]) {
      throw new Error(`not allowed to set ${name} token in cookie`);
    }
    _cookies.set(name, value, _cookieOptions[name]);
  }
}
