import {
    IQuestionnaireInputQuestionTypeState,
    IQuestionnaireRatingQuestionTypeState,
    IQuestionnaireSelectionQuestionTypeState,
} from '../../../../redux/selectors';
import { map, reduce, snakeCase } from 'lodash';
import {
    QuestionnaireQuestionAnsweredProperties,
    QuestionnaireQuestionAnsweredEventPayload,
} from '../types';
import { track_events } from '../events';

const { questionnaireQuestionAnswered } = track_events;

type Question =
    | IQuestionnaireInputQuestionTypeState
    | IQuestionnaireSelectionQuestionTypeState
    | IQuestionnaireRatingQuestionTypeState;

const helpers = {
    survey_question_details: {
        questionnaire_id: '',
        questionnaire_type: '',
        questionnaire_name: '',
    },
    set surveyQuestionDetails(data: any) {
        Object.assign(this.survey_question_details, data);
    },
    get surveyProperties() {
        return this.survey_question_details;
    },
    /**
     * Return a `string` or `undefined` depending on `type` of question
     * If it is `selection` type then append `single` or `multiple` based on boolean `allowMultiple` if not return original `type`
     * If `q` or `type` is not existent return `undefined`
     * @param q Survey current question
     * */
    questionType(q: Question): string | undefined {
        return q?.type === 'selection'
            ? (q as IQuestionnaireSelectionQuestionTypeState)?.allowMultiple
                ? `${q?.type} multiple`
                : `${q?.type} single`
            : q?.type;
    },
    /**
     * Return `string[]`, `string` or `undefined` depending on `type` of question
     * If it is `selection` and `allowMultiple` then return an array of strings, if not `allowMultiple` then return answer text value
     * If it is not `selection` then return param `a`
     * If `q` or `a` is not existent return `undefined`
     * @param q Survey current question
     * @param a Survey selected values
     * */
    questionAnswers(
        q: Question,
        a: { [k: string]: any }
    ): string | (string | undefined)[] | undefined {
        if (!q || !a) {
            return undefined;
        }
        const currentQuestionAnswers: string[] | string = a[q.id];
        return q.type === 'selection'
            ? (q as IQuestionnaireSelectionQuestionTypeState).allowMultiple
                ? map(
                    currentQuestionAnswers,
                    (a) =>
                        (q as IQuestionnaireSelectionQuestionTypeState).options?.find(
                            (b) => b.id === a
                        )?.optionText
                )
                : (q as IQuestionnaireSelectionQuestionTypeState).options?.find(
                    (b) => b.id === currentQuestionAnswers
                )?.optionText
            : currentQuestionAnswers;
    },
    /**
     * Return `object` of Question including answers
     * @param questions List of questions
     * @param a Survey answers
     * */
    questions(questions: Question[], a: { [k: string]: any }) {
        return reduce(
            questions,
            (p, q) =>
                Object.assign(p, {
                    [`question_${snakeCase(q?.name)}`]: {
                        number: questions?.indexOf(q) + 1,
                        text: q?.text,
                        type: helpers.questionType(q),
                        id: q?.id,
                        answers: helpers.questionAnswers(q, a) ?? 'skipped',
                    },
                }),
            {}
        );
    },
};

export default {
    events: {
        [questionnaireQuestionAnswered]: (
            data: QuestionnaireQuestionAnsweredEventPayload
        ): QuestionnaireQuestionAnsweredProperties => {
            const surveyContent = data?.questionnaire;
            const surveyAnswers = data?.values;
            helpers.surveyQuestionDetails = Object.assign(
                {},
                {
                    questionnaire_id: surveyContent?.id,
                    questionnaire_type: surveyContent?.type,
                    questionnaire_name: surveyContent?.name,
                },
                helpers.questions(surveyContent?.questions, surveyAnswers)
            );
            return helpers.surveyProperties;
        },
    },
};
