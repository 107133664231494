import React from 'react';

// Components
import NameCollectionModal from 'src/components/shared/name-collection-modal/name-collection-modal.component';

// Hooks
import Selectors from 'src/state/root-selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Constants
import { DEFAULT_NAME_VALUES } from 'src/constants/name-collection-constants';

const NameCollection = () => {
    const [open, setOpen] = useState(false);
    const user = useSelector(Selectors.account.userAttributes)?.object;
    const isLoggedIn: boolean = useSelector(Selectors.auth.loggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            if (
                user?.first_name === DEFAULT_NAME_VALUES.FIRST_NAME ||
                user?.last_name === DEFAULT_NAME_VALUES.LAST_NAME
            ) {
                setOpen(true);
            }
        }
    }, [isLoggedIn, user]);

    return <NameCollectionModal open={open} setOpen={setOpen} />;
};

export default NameCollection;
