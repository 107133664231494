import BaseService from './BaseService';
import { pick } from 'lodash';

/**
 * uses attributes api to store and retrieve the user account
 *
 * future work:
 *  may handle conflict resolution
 *
 * @example
 *    // using CoreService (recommended)
 *    import Core from '@pvolve/sdk';
 *
 *    const { value: account } = await Core.api.account.get();
 *    console.log(account); // should be something like { birthdate: }
 *
 *    await Core.api.account.save({ videos: [] });
 */
class AccountService extends BaseService {

  constructor() {
    super({ configPath: 'services.account' });
  }

  async deactivate() {
    return await this.requestResource('deactivate', {
      method: 'POST',
    });
  };

  async findUserByEmail(email) {
    return await this.requestResource('findUserByEmail', {
      tokenType: 'none',
      method: 'GET',
      params: { email }
    });
  }

  async getAllAttributes() {
    try {
      return await this.requestResource('allAttributes', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }

  async getUserAttributes() {
    try {
      return await this.requestResource('userAttributes', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }

  async saveUserAttributes(
    version,
    attributes,
  ) {
    const attribute_names = [
      'username',
      'email',
      'birthday',
      'gender',
      'phone',
      'waiver',
      'default_station_id',
      'experience_count',
      'confirmation_shown',
      'feed_fm_disabled',
      'feed_fm_playing',
      'closed_captions',
      'wifi_only',
    ];
    const object = pick(attributes, attribute_names);
    object.first_name = attributes?.firstName;
    object.last_name = attributes?.lastName;

    return await this.requestResource('userAttributes', {
      method: 'PUT',
      data: {
        version,
        object,
      },
    });
  }

  async getEmailAttributes() {
    try {
      return await this.requestResource('emailAttributes', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }

  async saveEmailAttributes(version, { optOut }) {
    try {
      return await this.requestResource('emailAttributes', {
        method: 'PUT',
        data: {
          // version, // Don't send this key temporarily because it's not really necessary as backend keeps count of the version,
          //and also it's causing errors when creating a user
          object: {
            opt_out: optOut
          }
        }
      })
    } catch (err) {
      return err.response;
    }
  }

  async getFavoritesAttributes() {
    try {
      return await this.requestResource('favoritesAttributes', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }

  async addFavoritesAttributes({ workouts, instructors, series }) {
    return await this.saveFavoritesAttributes('add', { workouts, instructors, series });
  }

  async removeFavoritesAttributes({ workouts, instructors, series }) {
    return await this.saveFavoritesAttributes('remove', { workouts, instructors, series });
  }

  async saveFavoritesAttributes(operation, { workouts, instructors, series }) {
    try {
      return await this.requestResource('favoritesAttributes', {
        method: 'PUT',
        data: {
          object: {
            [operation]: {
              workouts,
              instructors,
              series,
            },
          }
        },
      });
    } catch (err) {
      return err.response;
    }
  }
  
  async getQuestionnairesAttributes() {
    try {
      return await this.requestResource('questionnairesAttributes', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }

  async saveQuestionnairesAttributes( questionnaireResponse ) {
    try {
      return await this.requestResource('questionnairesAttributes', {
        method: 'PUT',
        data: {
          object: {
            add: {
              questionnaires: [
                questionnaireResponse
              ]
            },
          }
        },
      });
    } catch (err) {
      return err.response;
    }
  }

  /**
  * @function updateQuestionnairesAttributes A method to save updated responses to user's questionnaire attributes
  * @param {questionnaireResponse} Object New responses from questionnaire
  * @return {Promise} The request to save new responses
  */
  async updateQuestionnairesAttributes( questionnaireResponse: {id: string, responses: Array<any>} ) {
    const { id, responses } = questionnaireResponse;
    const { object } = await this.getQuestionnairesAttributes();
    const pastResponses = object.questionnaires?.find(
      (e: {id: string, responses: Array<object>}) => e.id === id
    ).responses;

    for (const response in responses) {
      let index = pastResponses.findIndex((e: { question_id: string }) => e.question_id === responses[response].question_id)
      pastResponses[index] = Object.assign({}, pastResponses[index], responses[response]);
    }
  
    questionnaireResponse.responses = pastResponses;

    return await this.saveQuestionnairesAttributes(questionnaireResponse)
  }

  async redirectMultipassUser(return_to: string, userIdToken: string) {
    try {
      return await this.requestResource('multipass', {
        method: 'POST',
				headers: {
					'Authorization': `Bearer ${userIdToken}`
				},
        data: {return_to}
      })
    } catch (err) {
      return err.response;
    }
  }

  async listSignedUpChallenges() {
    try {
      return await this.requestResource('challenges', {
        method: 'GET',
      });
    } catch (err) {
      return err.response;
    }
  }
}

export default AccountService;

