import Actions from '../root-actions';
import { bootEnded } from '@pvolve/sdk/src/app/modules/boot/actions';
import { getBootSequence, Priority } from '@pvolve/sdk/src/app/modules/boot/BootSequence';
import { all, take } from 'redux-saga/effects';
import 'scroll-behavior-polyfill';

const app = getBootSequence('app', {});
app.startWhen = all([take(bootEnded('system', 'start')), take(Actions.config.loaded)]);

app.onStart(
    { priority: Priority.HIGH, routine: Actions.web.startEntitlementsWatcher },
    { priority: Priority.HIGH, routine: Actions.web.handlePageLoad },
    { routine: Actions.flags.phaseAndFunction },
    { routine: Actions.content.findAll },
    { routine: Actions.subscriptions.loadProducts },
    { routine: Actions.subscriptions.getDefaultPromoDiscount },
    { routine: Actions.workouts.loadHistory },
    { routine: Actions.promos.list }
);

app
    .onStart
    /*
    // start loading screen
    { priority: Priority.HIGH, routine: Actions.profile.load },
    { priority: Priority.HIGH, routine: Actions.programs.collection.load },
    { priority: Priority.HIGH, routine: Actions.videoHistory.load },

    // end loading screen
    { routine: Actions.downloads.start },
    { routine: Actions.photos.load },
    { routine: Actions.favorites.load },
    { routine: Actions.video.preferences.load },
    { routine: Actions.video.listener },
    { routine: Actions.communications.loadEmailPreferences },
    { routine: Actions.auth.refreshTokens },

    { priority: Priority.LOW, routine: Actions.zendesk.configure },
    */
    ();
