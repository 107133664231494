import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import Colors from 'src/utils/colors';
import * as pillStyles from 'src/styles/pill.module.scss';

interface PillProps extends HTMLAttributes<HTMLDivElement> {
    bgcolor?: string;
    color?: string;
    content?: React.ReactNode | string;
}

const Pill: React.FC<PillProps> = ({
    children,
    content,
    className,
    bgcolor = 'gray-30',
    color = 'black',
    ...rest
}) => {
    const value = children || content;

    if (!value) {
        return null;
    }

    const containerClasses = classNames({
        [`${pillStyles.pillContainer}`]: true,
        [`${className}`]: !!className,
    });

    return (
        <div className={containerClasses} {...rest}>
            <div
                className={classNames(
                    pillStyles.pill,
                    `bgcolor-pv-${bgcolor}`,
                    `color-pv-${color}`
                )}
            >
                {value}
            </div>
        </div>
    );
};

export default Pill;
