import { createActions } from 'redux-actions';
import { DefaultRoutine } from '../../utils';
import { IWorkout } from '../../../models/contentful_types';
import { DefaultRoutineActions } from '../../utils';

interface WorkoutsActions {
  workouts: {
    complete: DefaultRoutineActions<{ workout_id: string }>;
    loadHistory: DefaultRoutineActions;
    loadStats: DefaultRoutineActions;
    loadCurrent: DefaultRoutineActions<IWorkout>;
    loadTargetedNewWorkouts: DefaultRoutineActions;
  }
}

const Actions = <WorkoutsActions><unknown>createActions({
  WORKOUTS: {
    COMPLETE: DefaultRoutine,
    LOAD_HISTORY: DefaultRoutine,
    LOAD_STATS: DefaultRoutine,
    LOAD_CURRENT: DefaultRoutine,
    LOAD_TARGETED_NEW_WORKOUTS: DefaultRoutine
  },
});

export default Actions;
