import { omit } from 'lodash';
import React, { memo } from 'react';
import classnames from 'classnames';

import { Icon } from 'src/components/shared';

import * as infoMessageStyles from 'src/styles/info-message.module.scss';
import { GlyphName } from './pvolveGlyphMap';

export type InfoMessageTypes = 'DEFAULT' | 'ERROR' | 'SUCCESS' | 'INFO_ERROR';

type InfoMessageType = {
    [key in InfoMessageTypes]: {
        color: string;
        icon: GlyphName;
        textColor?: string;
        bkgColor?: string;
    };
};

const TYPES: InfoMessageType = {
    DEFAULT: {
        icon: 'pv-info',
        color: 'black',
    },
    ERROR: {
        icon: 'pv-alert-circle-outline',
        color: 'red',
        bkgColor: 'red',
    },
    SUCCESS: {
        icon: 'pv-checkmark-circle',
        color: 'black',
    },
    INFO_ERROR: {
        icon: 'pv-alert-circle',
        color: 'red',
        textColor: 'red',
    },
};

interface InfoMessageProps {
    content: string;
    type?: InfoMessageTypes;
    icon?: GlyphName;
    floatIcon?: boolean;
    severity?: InfoMessageTypes;
}

const InfoMessage: React.FC<InfoMessageProps> = ({
    content,
    icon,
    type = 'DEFAULT',
    floatIcon = false,
    severity = 'DEFAULT',
    ...rest
}) => {
    const iconName = icon ? icon : TYPES[type].icon;
    const color = TYPES[type].color;
    const textColor = TYPES[type].textColor;
    const bkgColor = TYPES[severity].bkgColor;

    const msgClasses = classnames(
        'info-msg',
        !!textColor ? `color-pv-${textColor}` : `color-pv-${color}`,
        floatIcon ? infoMessageStyles.floatIcon : '',
        severity && infoMessageStyles[bkgColor]
    );
    const props = omit(rest, 'prompt');

    return (
        <div className={msgClasses} {...props}>
            {iconName && <Icon name={iconName} color={color} className={infoMessageStyles.icon} />}
            {floatIcon ? <p>{content}</p> : content}
        </div>
    );
};

export default memo(InfoMessage);
