import React, { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import InspirationalQuote from './inspirational-quote';
import Styled from './my-plan-banner.styled';
import classNames from 'classnames';

interface MyPlanBannerProps {
    bannerBeforeQuote?: boolean;
}

const MyPlanBanner = ({ bannerBeforeQuote = false }: MyPlanBannerProps) => {
    const [scrollPos, setScrollPos] = useState(0);
    const [isBannerCollapsed, setBannerCollapsed] = useState(false);

    const handleScroll = useCallback(() => {
        const scrollY = window?.scrollY || 0;
        if ((scrollY > 200 && scrollY < 400) || scrollY < 10) {
            throttle(() => {
                setScrollPos((prevScroll) => {
                    if (scrollY < 10) {
                        if (prevScroll === 1) {
                            return 0;
                        }
                    } else {
                        if (prevScroll === 0) {
                            return 1;
                        }
                    }
                    return prevScroll;
                });
            }, 100)();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        setBannerCollapsed(scrollPos === 1);
    }, [scrollPos]);

    const Banner = useCallback(() => {
        return (
            <InspirationalQuote>
                {(quoteText) => (
                    <h1 className="huge">
                        <span className="upper bold display--block"> {quoteText.headline} </span>
                        <span className="accent lower"> {quoteText.subheading}</span>
                    </h1>
                )}
            </InspirationalQuote>
        );
    }, []);

    return (
        <Styled.MyPlanBanner
            className={classNames(
                isBannerCollapsed ? 'bannerCollapsed' : '',
                bannerBeforeQuote ? 'bannerBeforeQuote' : ''
            )}
        >
            <Banner />
        </Styled.MyPlanBanner>
    );
};

export default MyPlanBanner;
