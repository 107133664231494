import React from 'react';
import { Icon, LinkWithArrow } from 'src/components/shared';
import * as Styles from 'src/styles/widget-icon.module.scss';

interface WidgetIconProps {
    className?: string;
    info: {
        heading: string;
        icon: string;
        iconSize?: number;
        linkText: string;
        text: string;
        url?: string;
    };
}

const WidgetIcon = ({ className, info }: WidgetIconProps) => (
    <div className={`${Styles.widget} ${className}`}>
        <div>
            <Icon name={info.icon} size={info.iconSize || 60} />
        </div>
        <div className={Styles.widgetDetails}>
            <h5>{info.heading}</h5>
            <p>{info.text}</p>
            {info.url && (
                <LinkWithArrow
                    to={info.url}
                    label={`${info.linkText}`}
                    className="display-inline-flex h5 bold"
                />
            )}
        </div>
    </div>
);

export default WidgetIcon;
