import storage from 'redux-persist/lib/storage';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { TokensReducer as tokens, ConfigReducer as config } from '@pvolve/sdk/src/redux';

import series from '@pvolve/sdk/src/app/modules/series/reducer';
import workouts from '@pvolve/sdk/src/app/modules/workouts/reducer';
import favorites from '@pvolve/sdk/src/app/modules/favorites/reducer';
import phases from '@pvolve/sdk/src/app/modules/phaseFunction/reducer';
import content from '@pvolve/sdk/src/app/modules/content/reducer';
import subscriptions from '@pvolve/sdk/src/app/modules/subscriptions/reducer';
import music from '@pvolve/sdk/src/app/modules/music/reducer';
import questionnaire from '@pvolve/sdk/src/app/modules/questionnaire/reducer';
import workoutCategories from '@pvolve/sdk/src/app/modules/workoutCategories/reducer';
import orders from './orders/reducer';
import partner from './partner/reducer';
import promos from './promos/reducer';
import video from '@pvolve/sdk/src/app/modules/video/reducer';
import flags from '@pvolve/sdk/src/app/modules/featureFlag/reducer';
import seriesCategory from '@pvolve/sdk/src/app/modules/seriesCategory/reducer';
import challenge from '@pvolve/sdk/src/app/modules/challenge/reducer';
import { Reducer as boot } from '@pvolve/sdk/src/app/modules/boot/actions';
import { ReduxEffectReducer as action } from '@pvolve/sdk/src/app/utils/useReduxEffect';

import AccountReducer from 'src/state/account/reducer';
import web from 'src/state/web/reducer';

import { persistStoreMigrate as migrate, version } from './migrations';

const APP_NAME = 'Pvolve';
const key = process.env === 'development' ? `${APP_NAME}-dev` : APP_NAME;

const reducers = {
    action,
    boot,
    config,
    tokens,
    account: AccountReducer,
    content,
    favorites,
    subscriptions,
    music,
    questionnaire,
    workoutCategories,
    orders,
    workouts,
    phases,
    series,
    seriesCategory,
    partner,
    promos,
    video,
    web,
    flags,
    challenge,
};

export default persistReducer(
    {
        migrate,
        version,
        storage,
        key,
        whitelist: [
            'tokens',
            'config',
            'account',
            'music',
            'orders',
            'partner',
            'questionnaire',
            'workoutCategories',
            'subscriptions',
            'seriesCategory',
            'video',
            'web',
        ],
    },
    combineReducers(reducers)
);
