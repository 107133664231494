const _Colors = {
    sea: '#B9DFC9',
    forest: '#0B320C',
    desert: '#BE8A4E',
    bone: '#E7DECD',
    mud: '#645B3D',
    dune: '#9C9481',
    white: '#FBFAF8',
    gray10: '#F4F4F4',
    gray30: '#C6C6C6',
    gray50: '#8D8D8D',
    gray70: '#525252',
    gray90: '#262626',
    black: '#161616',
    mint: '#00BAA4',
    lightMint: '#9FEFD2',
    pink: '#F249AD',
    lightPink: '#F26DBC',
    gold: '#FCB400',
    lightGold: '#FBD132',
    lime: '#BCF91A',
    lightLime: '#D0F964',
    blue: '#1689F1',
    lightBlue: '#41ADF8',
    red: '#EB3212',
    lightRed: '#EB5035',
    success: '#EDF7ED',
    menstrual: '#FA6155',
    follicular: '#BDD175',
    ovulatory: '#FDA9C8',
    luteal: '#ABC2F8',
    pnfBg: '#FCF3ED',
    pnfIntroBg: '#FAEFE9',
};

const Colors = {
    ..._Colors,
    text: _Colors.gray90,
};

export default Colors;

export type ColorNames = keyof typeof _Colors;
