// extracted by mini-css-extract-plugin
export const mini = "calendar-module--mini--2Z2b0";
export const tiny = "calendar-module--tiny--tTez5";
export const small = "calendar-module--small--3915y";
export const medium = "calendar-module--medium--ATaFz";
export const p3 = "calendar-module--p3--2KCO_";
export const large = "calendar-module--large--20rNp";
export const p2 = "calendar-module--p2--2NCQg";
export const l1 = "calendar-module--l1--1UCnQ";
export const label = "calendar-module--label--1xNOb";
export const big = "calendar-module--big--1SeIh";
export const p1 = "calendar-module--p1--NasyU";
export const huge = "calendar-module--huge--2aD6C";
export const p0 = "calendar-module--p0--2Q9_b";
export const huge36 = "calendar-module--huge36--1Ti7U";
export const huge52 = "calendar-module--huge52--3XvdD";
export const massive = "calendar-module--massive--1-q9a";
export const h1 = "calendar-module--h1--1i2vQ";
export const h2 = "calendar-module--h2--1gOGz";
export const h3 = "calendar-module--h3--1i_-7";
export const h4 = "calendar-module--h4--i9h__";
export const h5 = "calendar-module--h5--3reJH";
export const h6 = "calendar-module--h6--2VMpC";
export const accent = "calendar-module--accent--kdIgv";
export const accenth5 = "calendar-module--accenth5--1O3Z0";
export const accenth6 = "calendar-module--accenth6--2q5fp";
export const upper = "calendar-module--upper--326KT";
export const lower = "calendar-module--lower--3M5Wc";
export const underline = "calendar-module--underline--2Dbxd";
export const bold = "calendar-module--bold--2LCgt";
export const semibold = "calendar-module--semibold--2U21e";
export const normal = "calendar-module--normal--2z0SV";
export const stacked = "calendar-module--stacked--3VEJS";
export const link = "calendar-module--link--gUK9C";
export const textLinks = "calendar-module--text-links--nJ2an";
export const reactCalendar = "calendar-module--react-calendar--1QNRJ";