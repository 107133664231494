import React from 'react';
import { LinkWithArrow } from 'src/components/shared';
import * as Styles from 'src/styles/footer-link-right.module.scss';

interface FooterLinkRightProps {
    to: string;
    label: string;
    linkClass?: string;
}

const FooterLinkRight = ({ to, label, linkClass }: FooterLinkRightProps) => {
    return (
        <div
            className={`${Styles.footerLink} alignItems--center bgcolor-pv-pnf-bg justifyContent-flex-end`}
        >
            <LinkWithArrow to={to} label={label} className={linkClass} />
        </div>
    );
};

export default FooterLinkRight;
