import React, { useState, useEffect, useRef, useContext } from 'react';

import { Icon, LinkWithArrow } from 'src/components/shared';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    CarouselContext,
} from 'pure-react-carousel';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

import * as Styles from 'src/styles/carousel.module.scss';
import { isNull } from 'lodash';

interface CardCarouselSliderProps {
    totalSlides: number;
    visibleSlides: number;
    isDesktop: boolean;
    data: any;
    title: string;
    to: string;
    renderItem: any;
    textInverse?: boolean;
    description?: string;
}

interface CardCarouselProps {
    naturalSlideWidth?: number;
    naturalSlideHeight?: number;
    data: any;
    title: string;
    to: string;
    renderItem: any;
    textInverse?: boolean;
    description?: string;
}

const CardCarouselSlider = ({
    totalSlides,
    visibleSlides,
    isDesktop,
    title,
    to,
    renderItem: RenderItem,
    data,
    textInverse,
    description,
}: CardCarouselSliderProps) => {
    const carouselContext = useContext(CarouselContext);
    const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

    const seeMore = totalSlides > visibleSlides;
    const isCarouselProgressed = currentSlide > 0;

    const carouselClasses = description
        ? {
            header: Styles.carouselHeaderDescription,
            buttonsRow: Styles.carouselHeaderDescriptionButtonsRow,
            buttons: Styles.carouselHeaderDescriptionButtons,
        }
        : {
            header: Styles.carouselHeader,
            buttonsRow: '',
            buttons: Styles.carouselHeaderButtons,
        };

    useEffect(() => {
        function onChange() {
            setCurrentSlide(carouselContext.state.currentSlide);
        }

        carouselContext.subscribe(onChange);

        return () => carouselContext.unsubscribe(onChange);
    }, [carouselContext]);

    return (
        <>
            <div className={carouselClasses.header}>
                {description || !isDesktop || isCarouselProgressed ? (
                    <div className="display--block">
                        <h2 className={`bold upper margin--0 ${textInverse && 'color-pv-white'}`}>
                            {title}
                        </h2>
                    </div>
                ) : (
                    <div />
                )}
                <div className={carouselClasses.buttonsRow}>
                    {description && (
                        <p className="p1 margin--0 alignItems--center">{description}</p>
                    )}
                    {seeMore && (
                        <div className={carouselClasses.buttons}>
                            <ButtonBack
                                className={Styles.carouselButtonLeft}
                                aria-hidden={currentSlide === 0 ? true : false}
                            >
                                <Icon size={25} name="pv-arrow-left" color="gray" />
                            </ButtonBack>
                            <ButtonNext
                                className={Styles.carouselButtonRight}
                                aria-hidden={
                                    currentSlide + visibleSlides === totalSlides ? true : false
                                }
                            >
                                <Icon size={25} name="pv-arrow-right" color="blue" />
                            </ButtonNext>
                        </div>
                    )}
                </div>
            </div>
            <Slider>
                {' '}
                {!description && isDesktop && (
                    <Slide>
                        <h2 className={`bold upper ${textInverse && 'color-pv-white'}`}>{title}</h2>
                        {to && seeMore && (
                            <LinkWithArrow
                                to={to}
                                label={`See All ${data.length}`}
                                className={textInverse ? 'color-pv-white' : ''}
                            />
                        )}
                    </Slide>
                )}
                {data?.map((item, index) => (
                    <Slide key={index} index={index}>
                        <RenderItem data={item} />
                    </Slide>
                ))}
            </Slider>

            {(description || !isDesktop || isCarouselProgressed) && to && seeMore && (
                <LinkWithArrow
                    className={`${Styles.mobileSeeMore} ${textInverse && 'color-pv-white'}`}
                    to={to}
                    label={`See All ${data.length}`}
                />
            )}
        </>
    );
};

const CardCarousel = ({
    naturalSlideWidth = 300,
    naturalSlideHeight = 480,
    data,
    title,
    to,
    renderItem,
    textInverse,
    description,
    ...carouselProviderProps
}: CardCarouselProps) => {
    const [carouselWidth, setCarouselWidth] = useState();
    const [visibleSlides, setVisibleSlides] = useState(null);

    const isDesktop = useBreakpoints(Breakpoints.xxl, Directions.up);
    const isTabletLandscape = useBreakpoints(Breakpoints.lg, Directions.up) && !isDesktop;
    const isTabletPortrait = useBreakpoints(Breakpoints.sm, Directions.up) && !isTabletLandscape;
    const carouselRef = useRef();

    const getWidth = () => {
        if (carouselRef?.current) {
            return carouselRef?.current?.clientWidth || null;
        }

        return null;
    };

    const getVisibleSlideCount = () => {
        let numSlides, calculatedSlides, breakPointNumSlides;

        const carouselWidth = getWidth();
        const slideHorizontalPadding = 20;

        if (isNull(carouselWidth)) {
            return;
        }

        const fractionalNumSlides = carouselWidth / (naturalSlideWidth + slideHorizontalPadding);
        const floorNumSlides = Math.floor(fractionalNumSlides);

        if (isDesktop) {
            numSlides = floorNumSlides;
        } else if (isTabletLandscape) {
            calculatedSlides = floorNumSlides;
            breakPointNumSlides = naturalSlideWidth > 300 ? 3 : 4;
            numSlides = Math.min(calculatedSlides, breakPointNumSlides);
        } else if (isTabletPortrait) {
            calculatedSlides =
                Math.abs(fractionalNumSlides - floorNumSlides) < 0.75
                    ? floorNumSlides
                    : Math.round(fractionalNumSlides);
            breakPointNumSlides = naturalSlideWidth > 300 ? 1 : 2;
            numSlides = Math.max(calculatedSlides, breakPointNumSlides);
        } else {
            // numSlides = naturalSlideWidth > 300 ? 1 : 2;
            numSlides = 1;
        }

        return numSlides;
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!carouselRef.current) {
            return;
        }

        let timeoutId = null;

        const updateCarouselWidth = () => {
            clearTimeout(timeoutId);

            // change width after 200ms (debounce)
            timeoutId = setTimeout(() => setCarouselWidth(getWidth()), 200);
        };

        setCarouselWidth(getWidth());

        if (window) {
            window.addEventListener('resize', updateCarouselWidth);

            return () => window.removeEventListener('resize', updateCarouselWidth);
        }
    }, [carouselRef]);

    useEffect(() => {
        const numVisibleSlides = getVisibleSlideCount();
        if (visibleSlides !== numVisibleSlides) {
            setVisibleSlides(numVisibleSlides);
        }
    }, [carouselWidth]);

    const totalSlides = isDesktop ? data?.length + 1 : data?.length;

    return (
        <div className={Styles.carouselWrapper} ref={carouselRef}>
            {carouselWidth && visibleSlides && (
                <CarouselProvider
                    naturalSlideWidth={naturalSlideWidth}
                    naturalSlideHeight={naturalSlideHeight}
                    visibleSlides={visibleSlides}
                    totalSlides={totalSlides}
                    step={visibleSlides}
                    dragStep={visibleSlides}
                    className="carousel-module"
                    isIntrinsicHeight={true}
                    lockOnWindowScroll={true}
                    {...carouselProviderProps}
                >
                    <CardCarouselSlider
                        totalSlides={totalSlides}
                        visibleSlides={visibleSlides}
                        isDesktop={isDesktop}
                        title={title}
                        to={to}
                        renderItem={renderItem}
                        data={data}
                        textInverse={textInverse}
                        description={description}
                    />
                </CarouselProvider>
            )}
        </div>
    );
};

export default CardCarousel;
