import { createSelector } from 'reselect';
import { IState, IChallengeState } from '../../../redux/selectors';

const challenge = (state: IState): IChallengeState => state.challenge;

const signedUpChallenges = createSelector(
  challenge,
  (challenge: IChallengeState) => {
    return challenge.data.challenges;
  },
);

export default {
  signedUpChallenges,
};
