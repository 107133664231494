import { v4 } from 'uuid';

export type OrientationTypes =
  | 'LANDSCAPE-LEFT'
  | 'LANDSCAPE-RIGHT'
  | 'PORTRAIT';


type SupportedContentType = 'workout';

export interface VideoPlayerProps {
  videoParams: {
    id: string;
    type: SupportedContentType;
    fieldName: 'video';
  };
}

export enum VideoEventType {
  VIDEO_START = 'VIDEO_START',
  VIDEO_LOADED = 'VIDEO_LOADED',
  VIDEO_PROGRESS = 'VIDEO_PROGRESS',
  VIDEO_PLAY = 'VIDEO_PLAY',
  VIDEO_SEEK = 'VIDEO_SEEK',
  VIDEO_PAUSE = 'VIDEO_PAUSE',
  VIDEO_CLOSE = 'VIDEO_CLOSE',
  CHROMECAST_MEDIA_END = 'CHROMECAST_MEDIA_END',
  CHROMECAST_MEDIA_START = 'CHROMECAST_MEDIA_START',
  VIDEO_MUTED = 'VIDEO_MUTED',
  VIDEO_UNMUTED = 'VIDEO_UNMUTED',
  VIDEO_ERROR = 'VIDEO_ERROR',
  VIDEO_END = 'VIDEO_END',
  VIDEO_READY = 'VIDEO_READY',
  CHROMECAST_SESSION_ENDED = 'CHROMECAST_SESSION_ENDED',
  CHROMECAST_SESSION_START_FAILED = 'CHROMECAST_SESSION_START_FAILED',
  CHROMECAST_MEDIA_PROGRESS = 'CHROMECAST_MEDIA_PROGRESS',
}

export interface EventPayload {
  /**
   * The ID of the event which must be a v4 uuid string
   */
  event_id: string;

  /**
   * The type of event being emitted
   */
  event_type: VideoEventType;

  /**
   * The unix timestamp of the event
   */
  event_timestamp: number;

  /**
   * The user's sub
   */
  user_id?: string;

  /**
   * A string that represents a unique visitor, typically a v4 uuid string
   */
  visitor_id: string;

  /**
   * @deprecated 
   * The Workout's ID
   */
  workout_id: string; // TODO remove this and use type and id instead

  /**
   * The Contentful content model (Workout/Nutrition) for the object which owns the Video
   */
  model_type?: string;

  /**
   * The ID of the Contentful object which owns the Video
   */
  model_id?: string;

  /**
   * The ID of the Contentful Video object
   */
  video_id: string;

  /**
   * The ID of the workout's series
   */
  series_id?: string;

  /**
   * A string made up of the `user_id` or `visitor_id` + `workout_id` + a unix timestamp of the video's start time.
   */
  session_id?: string;

  /**
   * The browser agent or device type.
   */
  user_agent?: string | null;

  /**
   * Indicates whether the video player is streaming through a Google Chromecast connection
   */
  casting?: boolean;

  /**
   * Indicates whether the video player is streaming through an Apple Airplay connection
   */
  airplay?: any;

  /**
   * The video's timecode in seconds for the event being thrown
   */
  time: number;

  /**
   * The duration of the video in minutes.
   */
  duration: number;

  /**
   * Mobile App build and version information
   */
  app?: App;

  /**
   * Information about the device playing the video
   */
  device: Device;

  /**
   * Screen width/height and orientation
   */
  screen: Screen;

  error: any;
}

interface App {
  build: string;
  version: string;
}

interface Device {
  kind: string;
  os_name: string;
  os_version: string;
  type: string;
}

interface Screen {
  height: number;
  orientation: string;
  width: number;
}

export interface Source {
  uri: string;
  headers: object;
}

export interface VideoPlayerState {
  agent: string | null;
  airplay: any;
  buffering: boolean;
  casting: boolean;
  cover: boolean;
  duration: number;
  muted: boolean;
  volume: number;
  musicVolume: number;
  orientation: string;
  overlay: boolean;
  paused: boolean;
  ready: boolean;
  seekable: number;
  seeking: boolean;
  time: number;
  loading: boolean;
  source: Source | null;
  image: Source | null;
  enableMusic: boolean | null;
  playbackRate: number;
}

export interface ActionProps {
  cover: boolean;
  disableCover?: boolean;
  volume: number;
  musicVolume: number;
  orientation: string;
  enableMusic: boolean;
  setVolume: () => void;
  setMusicVolume: () => void;
  handleCover: () => void;
}

export interface VControlProps {
  volume: number;
  setVolume: () => void;
  musicVolume: number;
  setMusicVolume: () => void;
  orientation: string;
  enableMusic: boolean;
}

interface PlaybackId {
  sku: string;
}

export interface Video {
  assetId: string;
  playbackId: string;
  ready: boolean;
}

interface Progress {
  fieldName?: string;
  id?: string;
  type?: string;
}

export interface ProgressProps {
  duration: number;
  hideTime?: boolean;
  paused: boolean;
  time: number;
  handlePause: () => void;
  handlePlay: () => void;
  handleSeek: (position: number) => void;
  showOverlay: () => void;
  progress: Progress;
}

export interface SeekProps {
  seekBackwards: () => void;
  seekForward: () => void;
}

interface VisibilityProps {
  visible: boolean;
  handleClose: () => void;
}

export interface ControlProps extends ProgressProps, SeekProps {
  buffering: boolean;
  landscape?: boolean;
}

export interface OverlayProps
  extends ActionProps,
    ControlProps,
    VisibilityProps {}
