import { Theme, useMediaQuery, useTheme } from '@mui/material';

const useResponsive = (): {
    /** Desktop large */
    isDesktopLarge: boolean;
    /** Only Desktop resolutions */
    isDesktop: boolean;
    /** Desktop & Tablet resolutions */
    isDesktopAndTablet: boolean;
    /** Only Tablet resolutions */
    isTablet: boolean;
    /** Tablet & Mobile resolutions */
    isTabletAndMobile: boolean;
    /** Only Mobile resolutions */
    isMobile: boolean;
    /** Mobile extra small */
    isMobileXS: boolean;
} => {
    const theme: Theme = useTheme();

    const isTabletAndMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isDesktopAndTablet = useMediaQuery(theme.breakpoints.up('sm'));
    const isMobile: boolean = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet: boolean = isTabletAndMobile && !isMobile;
    const isDesktopLarge: boolean = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobileXS: boolean = useMediaQuery(theme.breakpoints.down('mxs'));

    return {
        isDesktopLarge,
        isDesktop,
        isDesktopAndTablet,
        isTablet,
        isTabletAndMobile,
        isMobile,
        isMobileXS,
    };
};

export default useResponsive;
