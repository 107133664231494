import React, { useState } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { noop } from 'lodash';

interface LinkWithPrevPathProps {
    children: React.ReactNode | string;
    to: string;
    state?: any;
    className?: string;
    style?: { [key: string]: any };
    onClick?: () => void;
}

const LinkWithPrevPath = ({
    children,
    state,
    to,
    onClick = noop,
    ...rest
}: LinkWithPrevPathProps) => {
    const [hover, setHover] = useState(false);
    const linkClassName = `${rest['className']} ${
        hover ? !rest['className']?.includes('schedule') && 'underline' : ''
    }`;

    return (
        <Location>
            {({ location }) => (
                //make sure user's state is not overwritten
                <Link
                    {...rest}
                    to={to}
                    state={{ prevPath: location.pathname, ...(state || {}) }}
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={linkClassName}
                >
                    {children}
                </Link>
            )}
        </Location>
    );
};

export default LinkWithPrevPath;
