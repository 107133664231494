// extracted by mini-css-extract-plugin
export const mini = "promo-banner-module--mini--1mf6M";
export const tiny = "promo-banner-module--tiny--2ABz_";
export const small = "promo-banner-module--small--ZdIa7";
export const medium = "promo-banner-module--medium--2TbuI";
export const p3 = "promo-banner-module--p3--lYkal";
export const large = "promo-banner-module--large--2nwPf";
export const p2 = "promo-banner-module--p2--3R07C";
export const l1 = "promo-banner-module--l1--37eR2";
export const label = "promo-banner-module--label--2UGmm";
export const big = "promo-banner-module--big--3WMhR";
export const p1 = "promo-banner-module--p1--2I0xM";
export const huge = "promo-banner-module--huge--2yy5K";
export const p0 = "promo-banner-module--p0--1Q-CU";
export const huge36 = "promo-banner-module--huge36--3UwBE";
export const huge52 = "promo-banner-module--huge52--1Dei_";
export const massive = "promo-banner-module--massive--1hw1w";
export const h1 = "promo-banner-module--h1--1pIH6";
export const h2 = "promo-banner-module--h2--P3ifT";
export const h3 = "promo-banner-module--h3--2Q5vh";
export const h4 = "promo-banner-module--h4--3xfob";
export const h5 = "promo-banner-module--h5--Y9UQH";
export const h6 = "promo-banner-module--h6--33nak";
export const accent = "promo-banner-module--accent--1SXUj";
export const accenth5 = "promo-banner-module--accenth5--3f2V9";
export const accenth6 = "promo-banner-module--accenth6--keFrW";
export const upper = "promo-banner-module--upper--2xz80";
export const lower = "promo-banner-module--lower--2CHoO";
export const underline = "promo-banner-module--underline---Uabh";
export const bold = "promo-banner-module--bold--2VZCC";
export const semibold = "promo-banner-module--semibold--VRT5j";
export const normal = "promo-banner-module--normal--DRhkm";
export const stacked = "promo-banner-module--stacked--1o2Sh";
export const link = "promo-banner-module--link--10d4o";
export const textLinks = "promo-banner-module--text-links--1ZUIc";
export const container = "promo-banner-module--container--hNm7W";
export const centerImage = "promo-banner-module--center-image--MdKEF";
export const copy = "promo-banner-module--copy--3SVkY";
export const copyLeft = "promo-banner-module--copy-left--1tW1n";
export const copyRight = "promo-banner-module--copy-right--2-dlG";
export const closeButton = "promo-banner-module--close-button--2boWm";
export const wrapper = "promo-banner-module--wrapper--2i3df";
export const black = "promo-banner-module--black--1E5u3";
export const pink = "promo-banner-module--pink--2PWOP";