import Box from '@mui/material/Box';
import {
    HEADER_HEIGHT,
    HEADER_PADDING_SIDES,
    HEADER_MOBILE_PADDING_SIDES,
    UI_MAX_WIDTH,
} from 'src/constants/ui';
import { styled } from '@mui/material/styles';

const Styled = {
    PrimaryNavBar: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        height: HEADER_HEIGHT.primary,
        zIndex: 1109,
        width: '100%',

        [theme.breakpoints.down('md')]: {
            height: HEADER_HEIGHT.mobile,
        },
    })),

    TabsWrapper: styled(Box)(({ theme }) => ({
        paddingLeft: theme.spacing(HEADER_PADDING_SIDES),

        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        },

        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
        marginLeft: 'auto',
    })),

    AccountWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
        marginRight: 'auto',
        paddingRight: theme.spacing(HEADER_PADDING_SIDES),

        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        },
    })),

    AccountWrapperMobile: styled(Box)(({ theme }) => ({
        paddingTop: theme.spacing(5),
    })),

    Logo: styled(Box)(() => ({
        width: 88,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    })),

    LinkDrawerMobile: styled(Box)(({ theme }) => ({
        width: '100vw',
        height: '100vh',
        zIndex: 1108,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: HEADER_HEIGHT.mobile,
        paddingLeft: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        paddingRight: theme.spacing(HEADER_MOBILE_PADDING_SIDES),
        backgroundColor: theme.palette.info.contrastText,
    })),

    SecondaryTabsMobile: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(5),
    })),

    PrimaryNavBarInternalWrapper: styled(Box)(() => ({
        maxWidth: UI_MAX_WIDTH,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    })),
};

export default Styled;
