import { styled } from '@mui/material/styles';

const Styled = {
    MyPlanBanner: styled('div')(({ theme }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        textAlign: 'center',
        overflow: 'visible',
        transition:
            'height 0.5s cubic-bezier(0.66, 0, 0.36, 1), padding 0.5s cubic-bezier(0.66, 0, 0.36, 1), opacity 0.35s',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(15.5, 0, 0),
        },

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(20, 0, 0),
        },

        '&.bannerBeforeQuote': {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(6, 0, 0),
            },

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(4, 0, 0),
            },
        },

        '&.bannerCollapsed': {
            height: 0,
            padding: 0,
            opacity: 0,
            overflow: 'hidden',
        },

        'span:first-of-type': {
            whiteSpace: 'nowrap',
        },

        h1: {
            [theme.breakpoints.up('md')]: {
                fontSize: '62px',
                lineHeight: '74px',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '52px',
                lineHeight: '62px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '30px',
                lineHeight: '40px',
            },
        },
    })),
};

export default Styled;
