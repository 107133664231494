/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { store } from 'src/state/store';

import Actions from 'src/state/root-actions';
import { saga as segment } from 'src/state/segment/sagas';

import 'src/styles/global.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

export { default as wrapRootElement } from 'src/components/WrapRootElement';

export const onClientEntry = async () => {
    store.dispatch(Actions.initialPageLoad.trigger());

    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }
};

export const onRouteUpdate = ({ location }) => {
    segment.page(location.pathname);
}
