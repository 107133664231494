import React from 'react';
import createSagaMiddleware from 'redux-saga';
import cookies from 'js-cookie';
import Pvolve from '@pvolve/sdk';

import { configureWeb } from '@pvolve/sdk/src/redux';
import { configureHook as configureReduxHook } from '@pvolve/sdk/src/app/utils/useReduxEffect';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import persistedReducer from 'src/state/root-reducer';
import SagaManager from 'src/state/saga-manager';

/**
 * MIDDLEWARES
 */

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
        collapsed: true,
        predicate: (getState, action) => {
            // log only in browser, not in server
            return typeof window !== undefined;
        },
    });

    middlewares.push(logger);
}

/**
 * CREATE & PERSIST STORE
 */
export const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);

configureReduxHook(React, store);

SagaManager.startSagas(sagaMiddleware);

// TODO remove this in favor of domain based config
const configUri =
    process.env.REMOTE_CONFIG_URL || 'https://pvolve-config.s3.amazonaws.com/client/local/web.json';

configureWeb(Pvolve, {
    environments: {
        dev: {
            uri: configUri,
        },
    },
    store,
    cookies,
});

export const persistor = persistStore(store);
