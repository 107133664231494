import { DefaultRoutineActions } from '@pvolve/sdk/src/app/utils';
import { createActions } from 'redux-actions';
import { DefaultRoutine } from 'src/utils/redux';
import { IQuestionnaireAnswersState } from '@pvolve/sdk/src/redux/selectors';

interface AccountActions {
    account: {
        getAllAttrs: DefaultRoutineActions;
        saveUserAttrs: DefaultRoutineActions<any>;
        saveEmailAttrs: DefaultRoutineActions;
        getEmailAttrs: DefaultRoutineActions;
        getFavoritesAttrs: DefaultRoutineActions;
        saveFavoritesAttrs: DefaultRoutineActions;
        removeFavoritesAttrs: DefaultRoutineActions;
        getStats: DefaultRoutineActions;
        saveQuestionnairesAttrs: DefaultRoutineActions;
        getQuestionnaires: DefaultRoutineActions;
        getTokenAttributes: DefaultRoutineActions;
        setEmail: DefaultRoutineActions<{ email: string }>;
        submitQuestionnaireAnswers: DefaultRoutineActions<IQuestionnaireAnswersState>;
    };
}

const Actions = (createActions({
    ACCOUNT: {
        GET_ALL_ATTRS: DefaultRoutine,
        SAVE_USER_ATTRS: DefaultRoutine,
        SAVE_EMAIL_ATTRS: DefaultRoutine,
        GET_EMAIL_ATTRS: DefaultRoutine,
        GET_FAVORITES_ATTRS: DefaultRoutine,
        SAVE_FAVORITES_ATTRS: DefaultRoutine,
        REMOVE_FAVORITES_ATTRS: DefaultRoutine,
        GET_STATS: DefaultRoutine,
        SAVE_QUESTIONNAIRES_ATTRS: DefaultRoutine,
        GET_QUESTIONNAIRES: DefaultRoutine,
        GET_TOKEN_ATTRIBUTES: DefaultRoutine,
        SET_EMAIL: DefaultRoutine,
        SUBMIT_QUESTIONNAIRE_ANSWERS: DefaultRoutine,
    },
}) as unknown) as AccountActions;

export default Actions;
