import React, { BaseSyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import { BannerDataTypes } from 'src/state/promos/selectors';

import * as Styles from 'src/styles/promo-banner.module.scss';
import { isEmpty } from 'lodash';
import { Icon } from 'src/components/shared/';

interface ContentProps {
    splitCopy: boolean;
    copy: string;
    icon?: string;
    copy2?: string;
    target_link?: string;
    color?: string;
}

const Content = ({ splitCopy, copy, icon, copy2, color }: ContentProps) =>
    splitCopy && copy2 ? (
        <>
            <h5 style={{ color }} className={`bold ${Styles.copy} ${Styles.copyLeft}`}>
                {copy}
            </h5>
            <img className={Styles.centerImage} src={icon} alt="" />
            <h5 style={{ color }} className={`bold ${Styles.copy} ${Styles.copyRight}`}>
                {copy2}
            </h5>
        </>
    ) : (
        <span style={{ color }} className={Styles.copy}>
            {copy}
        </span>
    );

const PromoBanners = () => {
    const banners: BannerDataTypes[] = useSelector((state) => Selectors.promos.banners(state));
    const banner = (banner: BannerDataTypes, index: number) => (
        <PromoBanner key={`banner-${index}`} {...banner} />
    );

    return isEmpty(banners) ? null : <>{banners.map(banner)}</>;
};

const PromoBanner = ({
    copy,
    copy2,
    link,
    linkEntitled,
    linkUnentitled,
    icon,
    bannerType,
    color,
    backgroundColor,
    closable,
}: BannerDataTypes) => {
    const [collapsed, setCollapsed] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const entitled = useSelector((state) => Selectors.auth.entitled(state));

    const onTransitionEnd = () => {
        setEnabled(false);
    };

    const onCloseBannerPress = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setCollapsed(true);
    };

    const promoBannerContainerClasses = classNames(Styles.container, [Styles[bannerType]], {
        collapsed,
    });

    const target_link =
        isEmpty(linkEntitled) || isEmpty(linkUnentitled)
            ? link
            : entitled
                ? linkEntitled
                : linkUnentitled;

    if (!enabled) {
        return null;
    }

    return (
        <div
            style={{ backgroundColor }}
            className={promoBannerContainerClasses}
            onTransitionEnd={onTransitionEnd}
        >
            {isEmpty(target_link) ? (
                <div className={Styles.wrapper}>
                    <Content
                        color={color}
                        copy={copy}
                        copy2={copy2}
                        icon={icon}
                        splitCopy={bannerType === 'pink'}
                    />
                </div>
            ) : (
                <a className={Styles.link} href={target_link}>
                    <Content
                        color={color}
                        copy={copy}
                        copy2={copy2}
                        icon={icon}
                        splitCopy={bannerType === 'pink'}
                    />
                </a>
            )}
            {closable && (
                <Icon
                    name="pv-close"
                    size={12}
                    className={Styles.closeButton}
                    onClick={onCloseBannerPress}
                />
            )}
        </div>
    );
};

export default PromoBanners;
