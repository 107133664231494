import React from 'react';
import classNames from 'classnames';

import { Button } from 'semantic-ui-react';

import * as buttonLinkStyles from 'src/styles/button-link.module.scss';

const ButtonLink = ({ className = '', ...props }) => {
    const classes = classNames(buttonLinkStyles.link, className);

    return (
        <div className={buttonLinkStyles.buttonLinkWrapper}>
            <Button className={classes} {...props} />
        </div>
    );
};

export default ButtonLink;
