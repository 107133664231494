import BaseService from './BaseService';
import { ApiServices } from './types';

/**
 * A class to bundle all of the loosely coupled service definitions together
 * and allows for configuration and logging to be controlled in one place
 *
 * @author Masahji C. Stewart
 */
export default class CoreService {
  private _services: {
    [k: string]: BaseService
  }
  private _api: ApiServices;
  private _requestAdapter: any;
  private _graphQLAdapter: any;

  constructor(services = {}) {
    this._services = { ...services };
    this._api = <ApiServices><unknown>new Proxy(this._services, {
      set: (obj, prop:string) => {
        throw new ReferenceError(`api ${prop} cannot be set`);
      }
    });
  }

  clone() {
    const c = new CoreService(this._services);
    return c;
  }


  /**
   * @returns a read only object which can access all services defined in this SDK
   *
   * @example
   * coreServiceInstance.api.someService.blahServiceMethod()
   *
   *
   */
  get api(): ApiServices {
    return this._api;
  }

  /**
   * @param {string} serviceName
   * @param {string} serviceInstance
   *
   * "registers" (defines) the serviceName as the serviceInstance within this.api
   * registration also means that serviceInstance will be have its `core` instance
   * set to `this`
   *
   * @example
   * coreServiceInstance.register('example', new ExampleService());
   * coreServiceInstance.api.example.someExampleServiceMethod();
   */
  register(serviceName:string, serviceInstance: BaseService) {
    this._services[serviceName] = serviceInstance;
    serviceInstance.core = this;
  }


  set requestAdapter(adapter) {
    this._requestAdapter = adapter;
  }

  get requestAdapter() {
    return this._requestAdapter;
  }

  set graphQLAdapter(adapter) {
    this._graphQLAdapter = adapter;
  }

  get graphQLAdapter() {
    return this._graphQLAdapter;
  }

}
