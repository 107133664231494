import { createActions } from 'redux-actions';
import { BasicActionFunction } from '../../utils';

export interface ConfigActions {
  config: {
    loaded: BasicActionFunction,
  }
}

const Actions = <ConfigActions><unknown>createActions({
  CONFIG: {
    LOADED: undefined,
  },
});

export default Actions;