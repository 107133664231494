import React, { useCallback } from 'react';

// Material
import { Theme, useTheme } from '@mui/material/styles';

// Components
import Styled from '../nav-drawer-mobile/nav-drawer-mobile.styled';
import Button from '@mui/material/Button';
import HeaderTabs from '../header-tabs/header-tabs.component';

// Types, Utils
import { AnnouncementBarButton } from 'src/utils/types/header';
import { KindOfTab, linkToExternal } from 'src/utils/route-utils';

interface NavDrawerMobileProps {
    announcementBarButtons: AnnouncementBarButton[];
    accountComponent: React.ReactNode;
}

const NavDrawerMobile = ({ announcementBarButtons, accountComponent }: NavDrawerMobileProps) => {
    const theme: Theme = useTheme();
    const handleAnnouncementBarClick = useCallback(
        (path: string) => () => linkToExternal(path, KindOfTab.NEW_TAB),
        []
    );

    return (
        <Styled.NavDrawerMobile>
            <HeaderTabs />
            <Styled.SecondaryTabsMobile>
                {announcementBarButtons.map((tab, index) => {
                    return (
                        <Button
                            key={index}
                            startIcon={
                                <tab.Icon sx={{ color: theme.palette.brandColors.emberGlow }} />
                            }
                            variant="text"
                            onClick={handleAnnouncementBarClick(tab.link)}
                        >
                            {tab.text}
                        </Button>
                    );
                })}
            </Styled.SecondaryTabsMobile>
            <Styled.AccountWrapperMobile>{accountComponent}</Styled.AccountWrapperMobile>
        </Styled.NavDrawerMobile>
    );
};
export default NavDrawerMobile;
