import { takeEvery } from 'redux-saga/effects';
import Pvolve from '@pvolve/sdk';

import Actions from '../contentV2/actions';
import { routineSaga } from '@pvolve/sdk/src/app/utils/sagas';

const { promos } = Actions;

class PromosSaga {
    *init() {
        yield takeEvery(promos.list.trigger, saga.list);
    }

    list = routineSaga({
        routine: promos.list,
        *request() {
            const response = yield Pvolve.api.promos.list();
            return response;
        },
    });
}

export const saga = new PromosSaga();

export default function* combined() {
    yield takeEvery(promos.list.trigger, saga.list);
}
