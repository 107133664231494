import { Draft } from 'immer';
import { handleActionsImmer } from '@pvolve/sdk/src/app/utils';
import Actions from '../contentV2/actions';
import { PartnerState, Partner } from '@pvolve/sdk/src/redux/selectors';

const { partner } = Actions;

export const initialState: PartnerState = {
    current: {
        slug: undefined,
        name: undefined,
        logo: undefined,
    },
    loading: false,
    error: undefined,
};

const Reducer = handleActionsImmer(
    {
        [partner.show.trigger]: (draft: Draft<PartnerState>) => {
            draft.error = undefined;
            draft.loading = true;
        },
        [partner.show.success]: (draft: Draft<PartnerState>, data: Partner) => {
            const updatedAt = Date.now();
            draft.current = { ...data, updatedAt };
        },
        [partner.show.failure]: (draft: Draft<PartnerState>, error: unknown) => {
            draft.current = initialState.current;
            draft.error = error;
        },
        [partner.show.fulfill]: (draft: Draft<PartnerState>) => {
            draft.loading = false;
        },
        [partner.show.clear]: (draft: Draft<PartnerState>) => {
            draft.current = initialState.current;
            draft.error = undefined;
        },
    },
    initialState
);

export default Reducer;
