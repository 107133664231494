import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import { last } from 'lodash';
import { Button } from 'semantic-ui-react';
import { useLocation } from '@reach/router';
import { Icon } from 'src/components/shared';
import { saveStorage, getStorage } from 'src/utils/storage-utils';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import * as Styles from 'src/styles/start-trial.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';

const FloatingTryForFree = () => {
    const trialQueryURL = useSelector(Selectors.subscriptions.trialQueryURL);

    const [isClosed, setIsClose] = useState(!!getStorage('CTA_close'));
    const dispatch = useDispatch();
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);
    const location = useLocation();
    const closeTryForFree = () => {
        setIsClose(true);
        saveStorage('CTA_close', 'true');
    };
    const setLocation = last(location.pathname?.split('/'));
    const setIdCTA = `${isMobile ? 'mobile-' : ''}web-try-free-${setLocation}`;
    const onClick = useCallback(
        (event, data) =>
            // Segment Track event for CTAClicked
            dispatch(
                Actions.segment.track.ctaClicked({
                    cta_name: data?.datatype,
                    event,
                })
            ),
        [dispatch]
    );

    if (isClosed) {
        return null;
    }

    return (
        <div className={`${Styles.floatingFreeTrial} display--flex`}>
            <div className={`${Styles.buttonContainer} alignItems--center`}>
                <Button
                    id={setIdCTA}
                    datatype="freeTrial-appPopup"
                    as="a"
                    href={trialQueryURL}
                    className={Styles.freeTrialButton}
                    onClick={onClick}
                    secondary
                >
                    Try For Free
                </Button>
            </div>
            <div className={`${Styles.textContent} color-pv-white`}>
                <div>
                    <Icon size={16} color="white" name="pv-checkmark" />
                    <span>100s of classes on demand</span>
                </div>
                <div>
                    <Icon size={16} color="white" name="pv-checkmark" />
                    <span>Access to the Live Virtual Studio</span>
                </div>
            </div>
            <div className={`${Styles.buttonClose} alignItems--center`} onClick={closeTryForFree}>
                <Icon size={14} color="white" name="pv-close" />
            </div>
        </div>
    );
};

export default FloatingTryForFree;
