import React from 'react';
import CircleCheckmark from 'src/components/shared/CircleCheckmark';

require('src/styles/icon-list.scss');

interface IconListProps {
    useIcon?: boolean;
    title?: string;
    list: Array<string> | undefined;
}

const IconList = ({ useIcon, title, list }: IconListProps) => {
    return (
        <div className="icon-list">
            {title && <h5 className="upper">{title}</h5>}
            {list?.map((entry, index) => {
                const icon = useIcon ? (
                    <CircleCheckmark size={33} />
                ) : (
                    <div className="icon-list-circle">
                        <label className="l1 accent bold">{index + 1}</label>
                    </div>
                );
                return (
                    <div key={index} className="icon-list-item">
                        {icon}
                        <p className="p1 icon-list-text">{entry}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default IconList;
