// extracted by mini-css-extract-plugin
export const card = "series-card-module--card--1Nil6";
export const seriesLevel = "series-card-module--series-level--22UGZ";
export const header = "series-card-module--header--3NwTq";
export const description = "series-card-module--description--9C22X";
export const cardContent = "series-card-module--card-content--3-9z4";
export const seeMore = "series-card-module--see-more--1D_Nr";
export const basicDetails = "series-card-module--basic-details--2_O8G";
export const seriesLength = "series-card-module--series-length--2eUwK";
export const newTag = "series-card-module--new-tag--XfL7S";
export const thumb = "series-card-module--thumb--3pC2g";
export const cardImage = "series-card-module--card-image--3ErDl";
export const truncateOverflow3 = "series-card-module--truncate-overflow-3--18fZz";
export const truncateOverflow1 = "series-card-module--truncate-overflow-1--3NBea";