import { PaletteOptions } from '@mui/material';
// TODO import Colors from 'src/utils/colors';

// TODO map Legacy color variables with the next ones
// i.e. mainBlack: $pvolveBlack
const mainBlack = '#161616';
const mainWhite = '#FBFAF8';
const simpleBlack = '#000000';
const simpleWhite = '#FFFFFF';
const sea = '#B9DFC9';
const forest = '#0B320C';
const bone = '#E7DECD';
const dune = '#9C9481';
const gray10 = '#F4F4F4';
const gray30 = '#C6C6C6';
const gray50 = '#8D8D8D';
const gray70 = '#525252';
const gray70At15 = 'rgba(82, 82, 82, 0.15)'; // Hx: #525252 15%
const gray90 = '#262626';
const successBackground = '#EDF7ED';
const emberGlow = '#CD7C5B';
const emberGlowDark = '#9A5D44';
const emberGlowLight = '#E6BDAD';

const palette: PaletteOptions = {
    primary: {
        main: mainBlack, // Main color used by most components
        dark: mainBlack, // Alternative dark shade
        light: gray50, // Alternative light shade
        contrastText: mainWhite, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: '#5C5C5C', // Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedHoverBackground: 'rgba(236, 236, 236, 0.08)', // Hx: #ECECEC 8% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedRestingBackground: 'rgba(138, 138, 138, 0.5)', // Hx: #8A8A8A 50% Used for outlined variant components in resting state (Button, Chip, etc)
    },
    secondary: {
        main: mainWhite, // Secondary color used by most components
        dark: gray30, // Alternative dark shade
        light: gray10, // Alternative light shade
        contrastText: simpleBlack, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: 'rgba(251, 250, 248, 0.8)', // Hx: #FBFAF8 80% Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedHoverBackground: 'rgba(251, 250, 248, 0.08)', // Hx: #FBFAF8 8% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedRestingBackground: 'rgba(251, 250, 248, 0.5)', // Hx: #FBFAF8 50% Used for outlined variant components in resting state (Button, Chip, etc)
    },
    tertiary: {
        main: emberGlow, // Tertiary color used by most components
        dark: emberGlowDark, // Alternative dark shade
        light: emberGlowLight, // Alternative light shade
        contrastText: mainBlack, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: '#856137', // Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedHoverBackground: 'rgba(205, 124, 91, 0.08)', // Hx: #CD7C5B 8% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedRestingBackground: 'rgba(205, 124, 91, 0.5)', // Hx: #CD7C5B 50% Used for outlined variant components in resting state (Button, Chip, etc)
    },
    text: {
        primary: mainBlack, // Text primary
        secondary: 'rgba(22, 22, 22, 0.6)', // Hx: #161616 60% Text secondary
        disabled: 'rgba(22, 22, 22, 0.38)', // Hx: #161616 38% Text disabled
        legal: '#767676', // The lightest gray that passes AA, usable for fine print text
    },
    action: {
        active: 'rgba(82, 82, 82, 0.75)', // Hx: #525252 75% Fill color for components in active state (List, Table, etc)
        hover: 'rgba(82, 82, 82, 0.05)', // Hx: #525252 5% Fill background for components in hover state (List, Table, etc)
        selected: 'rgba(82, 82, 82, 0.1)', //Hx: #525252 10% Fill background for components in selected state (List, Table, etc)
        disabled: 'rgba(82, 82, 82, 0.35)', // Hx: #525252 35% Content color for components in disabled state (Button, List, Table, etc)
        disabledBackground: gray70At15, // Fill background for components in disabled state (List, Table, etc)
        focus: gray70At15, // Fill background for components in focus state (List, Table, etc)
    },
    success: {
        main: sea, // Used for alert component
        dark: '#3B873E', // Alternative dark shade
        light: '#7BC67E', // Alternative light shade
        contrastText: forest, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: '#357B38', // Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedRestingBorder: 'rgba(76, 175, 80, 0.5)', // Hx: #4CAF50 50% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedHoverBackground: 'rgba(76, 175, 80, 0.08)', // Hx: #4CAF50 8% Used for outlined variant components in resting state (Button, Chip, etc)
        content: '#1E4620', // Text color for the success Alert component
        background: '#EDF7ED', // Text color for the success Alert component
    },
    warning: {
        main: '#ED6C02', // Used for alert component
        dark: '#C77700', // Alternative dark shade
        light: '#FFB547', // Alternative light shade
        contrastText: simpleWhite, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: '#A64C01', // Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedRestingBorder: 'rgba(237, 108, 2, 0.5)', // Hx: #ED6C02 50% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedHoverBackground: 'rgba(237, 108, 2, 0.08)', // Hx: #ED6C02 8% Used for outlined variant components in resting state (Button, Chip, etc)
        content: '#5F2B01', // Text color for the warning Alert component
        background: '#FDF0E6', // Text color for the warning Alert component
    },
    error: {
        main: '#D32F2F', // Used for alert component
        dark: '#C62828', // Alternative dark shade
        light: '#EF5350', // Alternative light shade
        contrastText: simpleWhite, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: 'rgba(211, 47, 47, 0.7)', // Hx: #D32F2F 70% Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedRestingBorder: 'rgba(211, 47, 47, 0.08)', // Hx: #D32F2F 8% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedHoverBackground: 'rgba(211, 47, 47, 0.5)', // Hx: #D32F2F 50% Used for outlined variant components in resting state (Button, Chip, etc)
        content: '#621B16', // Text color for the error Alert component
        background: '#FEECEB', // Text color for the error Alert component
    },
    info: {
        main: '#2196F3', // Used for alert component
        dark: '#0B79D0', // Alternative dark shade
        light: '#64B6F7', // Alternative light shade
        contrastText: simpleWhite, // Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
        containedHoverBackground: '#1769AA', // Fill background color for contained variant components in hover state (Button, FAB, etc)
        outlinedRestingBorder: 'rgba(33, 150, 243, 0.5)', // Hx: #2196F3 50% Fill background color for outlined & text variant components in hover state (Button, etc)
        outlinedHoverBackground: 'rgba(33, 150, 243, 0.08)', // Hx: #2196F3 8% Used for outlined variant components in resting state (Button, Chip, etc)
        content: '#0D3C61', // Text color for the info Alert component
        background: '#E9F4FE', // Text color for the info Alert component
    },
    common: {
        divider: 'rgba(198, 198, 198, 0.5)', // Hx: #C6C6C6 50% Divider fill color
        outlineBorder: 'rgba(0, 0, 0, 0.23)', //  Hx: #000000 23% Border style for outlined variant components in resting state (Text Field, Select, Chips, etc)
        standardInputLine: 'rgba(0, 0, 0, 0.42)', // Hx: #000000 42% Border style for standard variant Text Field & Select
        backdropOverlay: 'rgba(0, 0, 0, 0.5)', // Hx: #000000 50% Backdrop overlay style
        ratingActive: '#FFB400', // Active state color for Rating component
        snackbarBackground: gray90, // Snackbar background
    },
    brandColors: {
        sea,
        forest,
        bone,
        dune,
        simpleWhite,
        white: mainWhite,
        gray10,
        gray30,
        gray50,
        gray70,
        gray90,
        black: mainBlack,
        simpleBlack,
        successBackground,
        emberGlow,
        emberGlowDark,
        emberGlowLight,
    },
};

export default palette;
