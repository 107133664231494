import React from 'react';

// Components
import { IconButton as MuiIconButton } from '@mui/material';
import Button from '@mui/material/Button';

// Constants & Utils
import { ICON_BUTTON_SIZES, SMALL_LABEL } from 'src/constants/ui';
import { ButtonProps as MuiButtonProps } from '@mui/material';
import { getIconSizeByWidth } from 'src/utils/ui-utils';

interface IconButtonProps extends MuiButtonProps {
    /** Icon to display on the button */
    Icon: React.ElementType;
    /** Handler for the button click */
    onClick: () => void;
    /** Mui Color for the button */
    color?: MuiButtonProps['color'];
    /** Mui variant for the button */
    variant?: MuiButtonProps['variant'];
    /** Size for the icon-button's width/height (as it's an 4:4 icon button). If it is undefined, then it calculates it based on iconSize or assigns a default value */
    width?: number;
    /** Size for Icon. If it is undefined, then it calculates it based on width or assigns a default value */
    iconSize?: 'small' | 'medium' | 'large';
    ariaLabel?: MuiButtonProps['aria-label'];
    rounded?: boolean;
}
/** Icon button with relation 4:4, that extends Button styles, to include variants and their effects like hover, click, etc */
const IconButton = ({
    Icon,
    onClick,
    color = 'primary',
    variant,
    width,
    iconSize,
    ariaLabel = 'icon-button',
    rounded = true,
    ...props
}: IconButtonProps) => {
    let iconWidthStyle = width || 32;
    let iconSizeStyle: string = iconSize || SMALL_LABEL;

    if (width && !iconSize) {
        iconSizeStyle = getIconSizeByWidth(width);
    }
    if (iconSize && !width) {
        iconWidthStyle = ICON_BUTTON_SIZES[iconSize];
    }

    // With a variant, use our custom component. For NO variant, use the IconButton from MUI
    return (
        <>
            {variant ? (
                <Button
                    color={color}
                    variant={variant}
                    aria-label={ariaLabel}
                    onClick={onClick}
                    style={{
                        width: iconWidthStyle,
                        minWidth: iconWidthStyle,
                        height: iconWidthStyle,
                        ...(rounded && { borderRadius: 50 }),
                    }}
                    {...props}
                >
                    <Icon
                        data-testid={undefined}
                        fontSize={iconSizeStyle}
                        style={{ pointerEvents: 'none' }}
                    />
                </Button>
            ) : (
                <MuiIconButton
                    color={color}
                    aria-label={ariaLabel}
                    onClick={onClick}
                    size={iconSize}
                    style={{ ...(!rounded && { borderRadius: 5 }) }}
                >
                    <Icon
                        data-testid={undefined}
                        fontSize={iconSizeStyle}
                        style={{ pointerEvents: 'none' }}
                    />
                </MuiIconButton>
            )}
        </>
    );
};

export default IconButton;
