import { ICON_BUTTON_SIZES, LARGE_LABEL, MEDIUM_LABEL, SMALL_LABEL } from '../constants/ui';

export const scrollToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
    });
};

/**
 * Returns the Icon fontSize depending on the icon width/height
 */
export const getIconSizeByWidth = (iconWidth: number): string => {
    let iconSize;

    if (iconWidth < ICON_BUTTON_SIZES[MEDIUM_LABEL]) {
        iconSize = SMALL_LABEL;
    } else if (iconWidth < ICON_BUTTON_SIZES[LARGE_LABEL]) {
        iconSize = MEDIUM_LABEL;
    } else {
        iconSize = LARGE_LABEL;
    }

    return iconSize;
};
