import React from 'react';
import cn from 'classnames';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';

interface LinkWithArrowProps {
    to?: string;
    href?: string;
    label: string;
    back?: boolean;
    centered?: boolean;
    className?: string;
}

const LinkWithArrow = ({
    to,
    href = '#',
    back,
    centered,
    className = '',
    label,
    ...rest
}: LinkWithArrowProps) => {
    const classes = cn(
        `bold upper display-flex align-items--center link-with-arrow`,
        className,
        back ? 'pv-icon pv-arrow-left' : 'pv-icon-after pv-arrow-right',
        { 'text-align--center': centered }
    );

    return to ? (
        <LinkWithPrevPath className={classes} to={to} {...rest}>
            {label}
        </LinkWithPrevPath>
    ) : (
        <a href={href} className={classes} {...rest}>
            {label}
        </a>
    );
};

export default LinkWithArrow;
