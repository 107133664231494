import React from 'react';
import { map } from 'lodash';
import { Icon } from 'src/components/shared';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import classnames from 'classnames';
import * as Styles from 'src/styles/highlighted-series-hero.module.scss';

interface HighlightedHeroProps {
    contentList: any[];
    renderItem: any;
}

const HighlightedHero = ({ contentList, renderItem: RenderItem }: HighlightedHeroProps) => {
    return (
        <CarouselProvider
            className={classnames(
                'margin-top--small margin-bottom--xlarge',
                Styles.highlightedHero
            )}
            naturalSlideWidth={100}
            naturalSlideHeight={120}
            totalSlides={contentList.length}
            isIntrinsicHeight
        >
            <Slider>
                {map(contentList, (content, i) => (
                    <Slide key={i} index={i}>
                        <RenderItem content={content} Styles={Styles} />
                    </Slide>
                ))}
            </Slider>
            {contentList.length > 1 && (
                <div
                    className={classnames(
                        'margin-right--small margin-top--small',
                        Styles.hightlightedButtonGroup
                    )}
                >
                    <ButtonBack className={Styles.buttonLeft}>
                        <Icon size={24} name="pv-arrow-left" />
                    </ButtonBack>
                    <ButtonNext className={Styles.buttonRight}>
                        <Icon size={24} name="pv-arrow-right" />
                    </ButtonNext>
                </div>
            )}
        </CarouselProvider>
    );
};

export default HighlightedHero;
