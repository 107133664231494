import React, { useEffect, useState } from 'react';
import * as Styles from 'src/styles/progress-bar.module.scss';
import 'src/styles/progress-bar-config.scss';
import classNames from 'classnames';
import ProgressArrow from 'src/components/shared/progressBarElements/ProgressArrow';

interface ProgressBlockProps {
    phase: {
        end: {
            day: string;
        };
        name: string;
        start: {
            day: string;
        };
    };
    lastDay: number;
    isCurrentPhase: boolean;
    currentDay: string;
    currentPhase: string;
    vertical: boolean;
    displayIndicator: boolean;
    highlightedPhase?: 'menstrual' | 'follicular' | 'ovulatory' | 'luteal';
}

const ProgressBlock = ({
    phase,
    lastDay,
    isCurrentPhase,
    currentDay,
    currentPhase,
    vertical,
    displayIndicator,
    highlightedPhase,
}: ProgressBlockProps) => {
    const [blockWidth, setblockWidth] = useState({});
    const startDay = phase.start.day;
    const endDay = phase.end.day;
    const phaseName = phase.name;
    const isLuteal = phaseName === 'luteal';

    useEffect(() => {
        /* based on startDay, endDay and lastDay determine the length of the segment */
        const startDayNumber = parseInt(startDay) - 1;
        const endDayNumber = parseInt(endDay);
        const days = endDayNumber - startDayNumber;
        let measure;

        if (vertical) {
            measure = (100 * days) / lastDay;
        } else {
            measure = (91 * days) / lastDay; //using 91 as percentage because margins use 9% of width (horizontal)
        }

        if (isLuteal) {
            /* if in luteal phase, no margin should be applied */
            return setblockWidth({
                ...(vertical
                    ? { height: `${measure.toString()}%` }
                    : { width: `${Math.trunc(measure).toString()}%` }),
            });
        } else {
            return setblockWidth({
                ...(vertical
                    ? { height: days > 2 && `${measure.toString()}%` }
                    : { width: `${Math.trunc(measure).toString()}%`, marginRight: '3%' }),
            });
        }
    }, [startDay, endDay, phaseName, vertical]);

    return (
        <div
            className={classNames(Styles.progressBlock, vertical && `bgcolor-pv-${phaseName}`)}
            style={blockWidth}
        >
            {vertical && (
                <div className={Styles.blockName}>
                    <span>{phaseName}</span>
                </div>
            )}

            {!vertical && (
                <span
                    className={
                        !!highlightedPhase
                            ? highlightedPhase == phaseName
                                ? classNames(Styles.active, `bgcolor-pv-${phaseName}`)
                                : classNames(`bgcolor-pv-${phaseName}`)
                            : isCurrentPhase
                                ? classNames(Styles.active, `bgcolor-pv-${phaseName}`)
                                : classNames(`bgcolor-pv-${phaseName}`)
                    }
                ></span>
            )}
            <div className={Styles.range}>
                {!vertical ? <p>{startDay}</p> : startDay != endDay && <p>{startDay}</p>}
                {vertical ? <p>{endDay}</p> : isLuteal && <p>{endDay}</p>}
            </div>
            {isCurrentPhase && displayIndicator && (
                <ProgressArrow
                    firstDay={startDay}
                    lastDay={endDay}
                    currentDay={currentDay}
                    currentPhase={currentPhase}
                    vertical={vertical}
                />
            )}
        </div>
    );
};

export default ProgressBlock;
