import Pvolve from '@pvolve/sdk';
import { takeEvery, select } from 'redux-saga/effects';

import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import { loginRequired, routineSaga, sendTo } from '@pvolve/sdk/src/app/utils/sagas';
import { getUserCohort } from '@pvolve/sdk/src/app/modules/auth/utils';
import { getOnboardingUserAttributes } from '@pvolve/sdk/src/app/utils/sagas';
import { hasAnsweredOnboarding } from '@pvolve/sdk/src/app/modules/questionnaire/utils';

const {
    account: {
        getAllAttrs,
        saveUserAttrs,
        saveEmailAttrs,
        getEmailAttrs,
        getFavoritesAttrs,
        saveFavoritesAttrs,
        removeFavoritesAttrs,
        getStats,
        getQuestionnaires,
        getTokenAttributes,
        submitQuestionnaireAnswers,
    },
} = Actions;

class AccountSaga {
    *init() {
        yield takeEvery(getAllAttrs.trigger, loginRequired(saga.getAllAttrs));
        yield takeEvery(saveUserAttrs.trigger, saga.saveUserAttrs);
        yield takeEvery(saveEmailAttrs.trigger, saga.saveEmailAttrs);
        yield takeEvery(getEmailAttrs.trigger, saga.getEmailAttrs);
        yield takeEvery(getStats.trigger, saga.getStats);
        yield takeEvery(
            [getFavoritesAttrs.trigger, saveFavoritesAttrs.trigger],
            saga.getFavoritesAttrs
        );
        yield takeEvery(saveFavoritesAttrs.trigger, saga.saveFavoritesAttrs);
        yield takeEvery(removeFavoritesAttrs.trigger, saga.removeFavoritesAttrs);
        yield takeEvery(saveUserAttrs.fulfill, sendTo(getAllAttrs.trigger));
        yield takeEvery(getQuestionnaires.trigger, saga.getQuestionnaires);
        yield takeEvery(
            getTokenAttributes.trigger,
            getOnboardingUserAttributes(saga.getTokenAttributes)
        );
        yield takeEvery(submitQuestionnaireAnswers.trigger, saga.submitQuestionnaireAnswers);
    }
    getAllAttrs = routineSaga({
        routine: getAllAttrs,
        *request() {
            const allAttributes = yield Pvolve.api.account.getAllAttributes();
            return {
                ...allAttributes,
                hasAnsweredOnboarding: hasAnsweredOnboarding(
                    allAttributes?.questionnaires?.object?.questionnaires
                ),
            };
        },
    });

    getFavoritesAttrs = routineSaga({
        routine: getFavoritesAttrs,
        *request() {
            return yield Pvolve.api.account.getFavoritesAttributes();
        },
    });

    getQuestionnaires = routineSaga({
        routine: getQuestionnaires,
        *request() {
            return yield Pvolve.api.account.getQuestionnairesAttributes();
        },
    });

    getStats = routineSaga({
        routine: getStats,
        *request() {
            return yield Pvolve.api.stats.workouts();
        },
    });

    saveFavoritesAttrs = routineSaga({
        routine: saveFavoritesAttrs,
        *request({ payload: { workoutId } }) {
            return yield Pvolve.api.account.addFavoritesAttributes({
                workouts: [workoutId],
            });
        },
    });

    removeFavoritesAttrs = routineSaga({
        routine: removeFavoritesAttrs,
        *request({ payload: { workoutId } }) {
            return yield Pvolve.api.account.removeFavoritesAttributes({
                workouts: [workoutId],
            });
        },
    });

    getEmailAttrs = routineSaga({
        routine: getEmailAttrs,
        *request() {
            return yield Pvolve.api.account.getEmailAttributes();
        },
    });

    saveUserAttrs = routineSaga({
        routine: saveUserAttrs,
        *request({
            payload: {
                firstName,
                lastName,
                email,
                version,
                birthday,
                gender,
                phone,
                feed_fm_disabled,
                feed_fm_playing,
                closed_captions,
            },
        }) {
            return yield Pvolve.api.account.saveUserAttributes(version, {
                firstName,
                lastName,
                email,
                birthday,
                gender,
                phone,
                feed_fm_disabled,
                feed_fm_playing,
                closed_captions,
            });
        },
    });

    saveEmailAttrs = routineSaga({
        routine: saveEmailAttrs,
        *request({ payload: { version, optOut } }) {
            return yield Pvolve.api.account.saveEmailAttributes(version, {
                optOut,
            });
        },
    });

    getTokenAttributes = routineSaga({
        routine: getTokenAttributes,
        *request(payload) {
            const onboardingReleaseDate = yield select(Selectors.config.onboarding);
            return {
                userCohort: yield getUserCohort(onboardingReleaseDate?.releaseDate, payload),
                cx: payload.tokenAttributes.cx ?? false,
            };
        },
    });

    submitQuestionnaireAnswers = routineSaga({
        routine: submitQuestionnaireAnswers,
        *request({ payload: { questionnaireResponse } }) {
            yield Pvolve.api.account.saveQuestionnairesAttributes(questionnaireResponse);
            const allAttributes = yield Pvolve.api.account.getAllAttributes();
            return {
                ...allAttributes,
                hasAnsweredOnboarding: hasAnsweredOnboarding(
                    allAttributes?.questionnaires?.object?.questionnaires
                ),
            };
        },
    });
}

export const saga = new AccountSaga();
