import { find, isEmpty, noop } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Menu } from 'semantic-ui-react';
import * as Styles from 'src/styles/series-carousel.module.scss';
import { CardCarousel, SeriesCard } from 'src/components/shared';
import { Spinner } from 'react-activity';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import Selectors from 'src/state/root-selectors';
import { ISeries } from '@pvolve/sdk/src/models/contentful_types';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/state/root-actions';
import { DEFAULT_SELECTED } from '@pvolve/sdk/src/app/modules/seriesCategory/constants';

interface AllSeriesCategory {
    name: string;
    slug: string;
    series: any;
}

interface SeriesCarouselProps {
    allSeriesCategory: AllSeriesCategory;
    showMenu: boolean;
}

const SeriesCarousel = ({ allSeriesCategory, showMenu }: SeriesCarouselProps) => {
    const dispatch = useDispatch();
    const userCohort = useSelector(Selectors.account.userCohort);
    const seriesCategoryList = useSelector(Selectors.seriesCategory.seriesCategoryList);
    const seriesCategories = useSelector((state: IState) =>
        ContentSelectors.seriesCategory.list(state)
    );
    const seriesIndex = useSelector((state: IState) => ContentSelectors.series.list(state));
    const defaultCategoryObject = seriesCategoryList?.find((i) =>
        i.tags?.includes(DEFAULT_SELECTED)
    );
    const checkDefaultCategory = (defaultCategoryObject: any) => {
        if (defaultCategoryObject) {
            const activeCategory = find(seriesCategories, {
                fields: { slug: defaultCategoryObject.slug },
            })?.fields;
            const seriesIndexIds = seriesIndex?.map((s) => s.sys.id);
            const publishedSeries = activeCategory?.series.filter((s: ISeries) =>
                seriesIndexIds.includes(s.sys.id)
            );
            return { activeCategory, publishedSeries };
        } else {
            return {
                activeCategory: { ...allSeriesCategory },
                publishedSeries: allSeriesCategory?.series,
            };
        }
    };

    /* const defaultInitialState = checkDefaultCategory(defaultCategoryObject); */
    const [activeSeriesCategory, setActiveSeriesCategory] = useState(
        checkDefaultCategory(defaultCategoryObject).activeCategory
    );
    const [displayedSeries, setDisplayedSeries] = useState(
        checkDefaultCategory(defaultCategoryObject).publishedSeries
    );
    const [hideCarousel, setHideCarousel] = useState(false);

    useEffect(() => {
        setHideCarousel(true);
        dispatch(Actions.series.loadHighlightedSeries.trigger());
        dispatch(Actions.seriesCategory.getSeriesCategory.trigger());
    }, [userCohort, dispatch]);

    useEffect(() => {
        setHideCarousel(false);
    }, [displayedSeries]);

    useEffect(() => {
        setActiveSeriesCategory(checkDefaultCategory(defaultCategoryObject).activeCategory);
        setDisplayedSeries(checkDefaultCategory(defaultCategoryObject).publishedSeries);
    }, [defaultCategoryObject]);

    const onSeriesCategoryClick = useCallback(
        (slug: string) => {
            // Hide carousel when changing tabs so it can re-render and start from the beginning
            setHideCarousel(true);

            const newSeriesCategory =
                slug === 'all-series'
                    ? allSeriesCategory
                    : find(seriesCategories, { fields: { slug } })?.fields;

            if (newSeriesCategory && newSeriesCategory !== activeSeriesCategory) {
                setActiveSeriesCategory(newSeriesCategory);

                if (slug === 'all-series') {
                    setTimeout(() => {
                        setDisplayedSeries(allSeriesCategory?.series);
                    });
                } else {
                    const seriesIndexIds = seriesIndex.map((s) => s.sys.id);
                    const publishedSeries = newSeriesCategory.series.filter((s: ISeries) =>
                        seriesIndexIds.includes(s.sys.id)
                    );
                    setTimeout(() => {
                        setDisplayedSeries(publishedSeries);
                    });
                }
            }
        },
        [activeSeriesCategory, allSeriesCategory, seriesCategories, seriesIndex]
    );

    const RenderItem = ({ data }, index: number) => {
        const datum = data?.node || data;
        return <SeriesCard key={`series-card-${index}`} series={datum} />;
    };

    const SeriesCategoryMenuItem = useCallback(
        ({ name, slug }, index) =>
            activeSeriesCategory ? (
                <Menu.Item
                    key={`seriesMenuIten-${index}`}
                    name={name}
                    active={activeSeriesCategory?.slug === slug}
                    onClick={onSeriesCategoryClick.bind(this, slug)}
                />
            ) : null,
        [activeSeriesCategory, onSeriesCategoryClick]
    );

    if (isEmpty(seriesCategories)) {
        return (
            <Container textAlign="center">
                <Spinner size={40} speed={0.75} />
            </Container>
        );
    }

    return (
        <div className={Styles.container}>
            {showMenu && (
                <Menu pointing secondary>
                    <Menu.Item
                        name="All Series"
                        active={activeSeriesCategory?.slug === 'all-series'}
                        onClick={onSeriesCategoryClick.bind(this, 'all-series')}
                    />
                    {seriesCategoryList.map(SeriesCategoryMenuItem)}
                </Menu>
            )}
            {isEmpty(displayedSeries) && <Spinner size={40} speed={0.75} />}
            {!hideCarousel && !isEmpty(displayedSeries) && (
                <CardCarousel
                    naturalSlideHeight={610}
                    naturalSlideWidth={430}
                    title={activeSeriesCategory?.name}
                    to={`/series/categories/${activeSeriesCategory?.slug}`}
                    data={displayedSeries}
                    renderItem={RenderItem}
                />
            )}
        </div>
    );
};

SeriesCarousel.propTypes = {
    series: PropTypes.object,
    onVideoClick: PropTypes.func,
};

SeriesCarousel.defaultProps = {
    onVideoClick: () => noop,
};

export default SeriesCarousel;
