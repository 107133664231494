import React from 'react';
import { Icon } from 'src/components/shared';
import Colors from 'src/utils/colors';

import * as Styles from 'src/styles/circle-checkmark.module.scss';

interface CircleCheckmarkProps {
    checkColor?: string;
    circleBgColor?: string;
    size?: number;
}

const CircleCheckmark = ({
    checkColor = 'sea',
    circleBgColor = Colors.forest,
    size = 18,
}: CircleCheckmarkProps) => {
    return (
        <div
            style={{
                backgroundColor: circleBgColor,
                height: size + size * 0.2,
                width: size + size * 0.2,
                minWidth: size + size * 0.2,
            }}
            className={`${Styles.checkmark}`}
        >
            {/* TODO change this hardcoded string color and use the one in Colors file, having in mind to verify every CircleCheckmark component in the project */}
            <Icon color={checkColor} size={size} name="pv-checkmark" />
        </div>
    );
};

export default CircleCheckmark;
