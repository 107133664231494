import React from 'react';
import classNames from 'classnames';
import { Icon } from 'src/components/shared';
import * as Styles from 'src/styles/border-block-with-button.module.scss';
import { Button, ButtonProps } from 'semantic-ui-react';

interface BorderBlockWithButtonProps {
    children: React.ReactNode;
    alignment: 'top' | 'left';
    iconName: string;
    iconSize: number;
    buttonProps?: ButtonProps;
}

const BorderBlockWithButton = ({
    children,
    alignment,
    iconName,
    iconSize,
    buttonProps,
}: BorderBlockWithButtonProps) => {
    const containerClass =
        alignment == 'top'
            ? `display-column alignItems--center text-align--center ${Styles.borderBlockContainer}`
            : `display-row alignItems--center justifyContent-space-between ${Styles.borderBlockContainer}`;

    const verticalAlign = alignment == 'top' ? true : false;

    return (
        <div className={containerClass}>
            <Icon
                name={iconName}
                size={iconSize}
                color="light-gray"
                bgcolor="white"
                className={classNames(
                    Styles.iconHeader,
                    alignment == 'top' ? Styles.top : Styles.left
                )}
            />
            <div
                className={classNames(
                    !verticalAlign ? Styles.childrenContainerHorizontal : 'margin-bottom--small'
                )}
            >
                {children}
            </div>
            <div className={Styles.buttonContainer}>
                <Button {...buttonProps} />
            </div>
        </div>
    );
};

export default BorderBlockWithButton;
