import { handleActionsImmer } from '@pvolve/sdk/src/app/utils';
import Actions from 'src/state/root-actions';

export interface WebState {
    internalAccessEnabled: boolean;
}

export const initialState: WebState = {
    internalAccessEnabled: false,
};

const {
    web: { authorizeInternalAccess },
} = Actions;

export default handleActionsImmer<WebState>(
    {
        [authorizeInternalAccess.success]: (draft: WebState, { enabled }: { enabled: boolean }) => {
            draft.internalAccessEnabled = enabled;
        },
    },
    initialState
);
