/**
 * Segment Track Events const. Set up alfabetically
 * @key (string) Camel case syntax for proper integration with redux actions and sagas eventmapper
 * @value (string) Event name
 */
export const track_events = {
  /**
   * User logged in to pvolve account */
  accountLoggedIn: 'Account Logged In',
  /**
   * When details of a User's account has been updated */
  accountDetailsUpdated: 'Account Details Updated',
  /**
   * When modal is triggered and user views it */
  modalViewed: 'Modal Viewed',
  /**
   * When user clicks on "continue" on modal*/
  modalSubmitted: 'Modal Submitted',
  /**
   * User submits for their subscription to be cancelled */
  appSubscriptionCancellationSubmitted:
    'App Subscription Cancellation Submitted',
  /**
   * User views a step in the app subscription cancellation flow */
  appSubscriptionCancelStepViewed: 'App Subscription Cancel Step Viewed',
  /**
   * User views the subscription page under their account on app.pvolve */
  appSubscriptionPageViewed: 'App Subscription Page Viewed',
  /**
   * User updates their subscription term (e.g. from monthly to annual or as a cancel/save from annual to 3 months for X amount) */
  appSubscriptionPlanUpdateSubmitted: 'App Subscription Plan Update Submitted',
  /**
   * User accepts the subscription update confirmation modal */
  appSubscriptionUpdateConfirmationModalAccepted:
    'App Subscription Update Confirmation Modal Accepted',
  /**
   * User views the subscription update confirmation modal */
  appSubscriptionUpdateConfirmationModalViewed:
    'App Subscription Update Confirmation Modal Viewed',
  /**
   * User views the subscription update page under their account on app.pvolve */
  appSubscriptionUpdatePageViewed: 'App Subscription Update Page Viewed',
  /**
   * User clicks on an element with a 'data-cta-name' | 'datatype' attribute */
  ctaClicked: 'CTA Clicked',
  /**
   * User clicks on an element with a 'data-app-button-name' attribute */
  appButtonClicked: 'App Button Clicked',
  /**
   * User submits their email or SMS for marketing */
  marketingOptedIn: 'Marketing Opted In',
  /**
   * User submits their questionnaire answers */
  questionnaireQuestionAnswered: 'Questionnaire Question Answered',
} as const;
