export enum KindOfTab {
    NEW_TAB = 'newTab',
    NEW_WINDOW = 'newWindow',
}

/** Function to redirect to External sites or Internal sites on a newTab / newWindow
 * Note: Please DON'T USE this function for internal routing in the same tab, instead use Next router
 */
export const linkToExternal = (url: string, kindOfTab?: KindOfTab) => {
    if (url) {
        const isNewWindow = kindOfTab === KindOfTab.NEW_WINDOW;
        window
            ?.open(
                url,
                kindOfTab ? '_blank' : '_self',
                isNewWindow ? 'width=600,height=400,noopener,noreferrer,fullscreen=0' : ''
            )
            ?.focus();
    }
};

export const getEnvUrls = () => {
    if (typeof window === 'undefined') {
        return {};
    }

    const currentPath: string = window.location.host;
    const urlSplit: string[] = currentPath.split('.');
    const env = urlSplit[1] || 'dev';
    const isProd = env === 'pvolve';

    return {
        appUrl: `https://app${isProd ? '' : '.' + env}.pvolve.com/`,
        shopifyUrl: `https://${isProd ? 'www' : env}.pvolve.com/`,
    };
};

export const getDirectoryFromPath = (path: string) => {
    const indexFirstBar = path.indexOf('/', 1);
    let lastPos: number = path.length;

    if (indexFirstBar > 0) {
        lastPos = indexFirstBar - 1;
    }

    return path.substring(1, lastPos);
};
