import { AudioFile } from '@pvolve/sdk/src/app/modules/music/types';
import React, { memo } from 'react';
import Ticker from 'react-ticker';

import * as Styles from 'src/styles/feed-player.module.scss';

interface InfoTickerProps {
    audio_file: AudioFile;
}

const Info = ({ audio_file }: InfoTickerProps) => {
    const { artist, track, release } = audio_file;

    return (
        <div className={`${Styles.artistTrack} bold`}>
            {track.title}
            <span className={Styles.by}>from</span>
            {release.title}
            <span className={Styles.by}>by</span>
            {artist.name}
        </div>
    );
};

const InfoTicker = ({ audio_file }: InfoTickerProps) => {
    if (!audio_file) {
        return null;
    }

    return (
        <Ticker mode="smooth" offset={50}>
            {() => <Info audio_file={audio_file} />}
        </Ticker>
    );
};

export default memo(InfoTicker);
